import React, { useCallback, useEffect, useState } from 'react';
import { ObjectSchema, Shape, ValidationError } from 'yup';
import { deepMerge } from '../services/deep-merge';

export type FormErrors = Record<string, string | any | undefined>;

export interface UseFormResult<TFormFields> {
  inputs: TFormFields;
  errors: FormErrors;
  handleInputChange: (event: React.ChangeEvent<any>) => Promise<void>;
  handleInputValueChange: (name: string, value: any) => Promise<void>;
  handleFormSubmit: (event?: React.FormEvent<HTMLFormElement>) => Promise<boolean>;
  handleValidate: () => Promise<[boolean, FormErrors]>;
  hasChanges: () => boolean;
}

export function useForm<TFormFields extends object>(
  initialValues: TFormFields,
  validationSchema?: ObjectSchema<Shape<TFormFields, any>>,
  onSubmit?: (inputs: TFormFields) => void,
  onSubmitError?: () => void,
  onChange?: () => void,
  validationSchemaContext?: object
): UseFormResult<TFormFields> {
  const [inputs, setInputs] = useState<TFormFields>(initialValues);
  const [errors, setErrors] = useState<FormErrors>({});
  useEffect(() => {
    setInputs(initialValues);
  }, [initialValues]);

  const getErrorPath = useCallback((path: string): string => {
    return path.split('[')[0];
  }, []);

  const getErrorTree = useCallback((error: ValidationError): any => {
    const pathItems = error.path?.split('.');
    const errorObj = pathItems?.reverse().reduce<any>(
      (acc, path) => ({ [getErrorPath(path)]: acc }),
      error.message
    ) || {};
    return errorObj;
  }, [getErrorPath]);

  const validate = useCallback(async (): Promise<boolean> => {
    if (validationSchema) {
      try {
        await validationSchema.validate(inputs, { abortEarly: false, context: validationSchemaContext });
      } catch (err:any) {
        const errs: ValidationError[] = err.inner;
        const errorTree = errs.reduce<any>((acc, error) => deepMerge(acc, getErrorTree(error)), {});
        setErrors(errors => deepMerge(errors, errorTree));
        return false;
      }
    }
    return true;
  }, [inputs, validationSchema, validationSchemaContext, getErrorTree]);

  const validateWithErrors = useCallback(async (): Promise<[boolean, FormErrors]> => {
    if (validationSchema) {
      try {
        await validationSchema.validate(inputs, { abortEarly: false, context: validationSchemaContext });
      } catch (err:any) {
        const errs: ValidationError[] = err.inner;
        const errorTree = errs.reduce<any>((acc, error) => deepMerge(acc, getErrorTree(error)), {});
        const newErrors = deepMerge(errors, errorTree);
        setErrors(newErrors);
        return [false, newErrors];
      }
    }
    return [true, {}];
  }, [inputs, errors, validationSchema, validationSchemaContext, getErrorTree]);

  const handleInputValueChange = useCallback(async (name: string, value: any): Promise<void> => {
    setInputs(inputs => ({
      ...inputs,
      [name]: value
    }));
    const values = {
      ...inputs,
      [name]: value
    };
    if (validationSchema) {
      try {
        await validationSchema.validateAt(name, values, { context: validationSchemaContext });
        setErrors(errors => ({
          ...errors,
          [name]: undefined,
        }));
      } catch (err:any) {
        setErrors(errors => deepMerge(errors, getErrorTree(err)));
      }
    }
    if (onChange) {
      onChange();
    }
  }, [inputs, validationSchema, validationSchemaContext, onChange, getErrorTree]);

  const handleInputChange = useCallback(async (event: React.ChangeEvent<any>): Promise<void> => {
    if (event) {
      event.persist();
    }
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    return handleInputValueChange(event.target.name, value);
  }, [handleInputValueChange]);

  const handleFormSubmit = useCallback(async (event?: React.FormEvent<HTMLFormElement>): Promise<boolean> => {
    if (event) {
      event.preventDefault();
    }
    const isValid = await validate();
    if (isValid) {
      if (onSubmit) {
        onSubmit(inputs);
      }
    } else {
      if (onSubmitError) {
        onSubmitError();
      }
    }
    return isValid;
  }, [inputs, validate, onSubmit, onSubmitError]);

  const handleValidate = useCallback((): Promise<[boolean, FormErrors]> => validateWithErrors(), [validateWithErrors]);

  const hasChanges = useCallback((): boolean => {
    const inputsMap = new Map(Object.entries(inputs));
    const initialValuesMap = new Map(Object.entries(initialValues));
    return Object.keys(inputs).some(key => inputsMap.get(key) !== initialValuesMap.get(key));
  }, [inputs, initialValues]);

  return {
    inputs,
    errors,
    handleInputChange,
    handleInputValueChange,
    handleFormSubmit,
    handleValidate,
    hasChanges,
  };
}

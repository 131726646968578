import React, { useState } from 'react';
import Icon from '@visa/vds/icon';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export interface MaskedCellProps {
    value: string;
}

export const MaskedCell: React.FC<MaskedCellProps> = ({ value }) => {
    const [showValue, setShowValue] = useState(false);

    const toggleValueVisibility = () => {
        setShowValue((preShowValue) => !preShowValue);
    };
    
    return (
        <>
            {showValue ? value : "********"}
            <Icon name="password-show" resolution="tiny" aria-label="password show" onClick={toggleValueVisibility}>
                {showValue ? <VisibilityOff /> : <Visibility />}
            </Icon>
        </>
    );
};
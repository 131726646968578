import { makeStyles, Theme } from '@material-ui/core/styles';

export const AppDialogStyles = makeStyles((theme: Theme) => ({
    dialogSlide: {
        marginLeft: '15px',
        flex: 1
    },
    emailsContent: {
        fontSize: '14px',
        width: theme.spacing(60),
        height: theme.spacing(40)
    },
    rolesContent: {
        fontSize: '14px',
        width: theme.spacing(60),
        height: theme.spacing(-100)
    },
    dialogTitleWidth: {
        width: '50px'
    }
}));

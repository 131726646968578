import { Dispatch } from 'react';
import { Action } from '../../../state/actions';
import { useStore } from '../../../state/app-store';
import { B2C2AppsState } from './apps-reducer';

export const useB2C2AppsStore = () => {
  const store = useStore();
  const appsStore: [B2C2AppsState, Dispatch<Action>] = [store[0].b2c2apps, store[1]];
  return appsStore;
}

export const useAppsState = () => useB2C2AppsStore()[0];

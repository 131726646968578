import React, { useCallback, useState } from 'react';
import Grid from '@visa/vds/grid';
import Row from '@visa/vds/row';
import Dialog from '@visa/vds/dialog';
import DialogCardBody from '@visa/vds/dialog-card-body';
import DialogCardHead from '@visa/vds/dialog-card-head';
import DialogCardHeadTitle from '@visa/vds/dialog-card-head-title';
import DialogCardClose from '@visa/vds/dialog-card-close';
import ButtonGroup from '@visa/vds/button-group';
import ButtonText from '@visa/vds/button-text';
import ButtonIcon from '@visa/vds/button-icon';
import Icon from '@visa/vds/icon';
import DialogCardFooter from '@visa/vds/dialog-card-footer';
import { FormControl, FormHelperText } from '@material-ui/core';
import { AppDialogStyles } from './app-dialog.styles';
import ChipInput from 'material-ui-chip-input';
import { FormErrors } from '../../../../hooks/use-form.hook';
import { FormLabel } from '@mui/material';
import clsx from 'clsx';
import { useFormsStyles } from '../../../../styles/forms.styles';
import { dialogStyles } from "../table-abstraction/dialogStyles.styles";

export interface ApprovedEmailsDialogProps {
    whitelistedIdentities?: string[];
    open: boolean;
    setOpen: any;
    onUpdate: (emailId: string) => void;
    onClose: () => void;
    onChange: (identities: string[]) => void;
}

export const isEmailValid = (item: string) => {
    const emailRegex = /.*@[a-zA-Z0-9.-]+$/;
    return emailRegex.test(item);
}

export default function WhitelistedIdentitiesDialog(dialogProps: ApprovedEmailsDialogProps) {
    const styles = AppDialogStyles();
    const formstyles = useFormsStyles();
    const [errors, setErrors] = useState<FormErrors>({});
    const _styles = dialogStyles();

    const handleInputUpdate = useCallback((item: string) => {
        const isValid = isEmailValid(item);
        if (!isValid) {
            setErrors({ whitelistedIdentities: 'Email must be of *@xyz.com format' });
        } else {
            setErrors({});
            dialogProps.onUpdate(item);
        }
    }, [dialogProps.onUpdate]);

    const handleEmailDelete = useCallback((emailId: string, index: number) => {
        let emailsAfterDeletion = dialogProps.whitelistedIdentities !== undefined ? (dialogProps.whitelistedIdentities?.filter((identity, id) => (identity !== emailId && id !== index))) : [];
        dialogProps.onChange(emailsAfterDeletion);
    }, [dialogProps.whitelistedIdentities, dialogProps.onChange]);
    return (
        <Grid>
            <Row>
                <Dialog
                    aria-labelledby="whitelisted-box-title"
                    aria-describedby="whitelisted-box-content"
                    open={!!dialogProps.open}
                    onExit={() => dialogProps.setOpen(false)}
                    className={_styles.vdsDialogBoxCard}
                >
                    <DialogCardClose>
                        <ButtonGroup direction="right">
                            <ButtonIcon
                                ariaLabel="close"
                                iconType="light-tiny"
                                onClick={() => dialogProps.setOpen(false)}
                            >
                                <Icon name="close" resolution="tiny" />
                            </ButtonIcon>
                        </ButtonGroup>
                    </DialogCardClose>
                    <DialogCardHead>
                        <DialogCardHeadTitle id="whitelisted-box-title">Whitelisted Emails</DialogCardHeadTitle>
                    </DialogCardHead>
                    <DialogCardBody id="whitelisted-box-content">
                        <FormLabel className={formstyles.label} htmlFor='whitelistedIdentities' required>Whitelisted Emails (Use Backspace for removing the Whitelisted emails)</FormLabel>
                        <FormControl className={clsx(formstyles.formControlFull, formstyles.marginTop)} error={!!errors.whitelistedIdentities}>
                            <ChipInput
                                fullWidth
                                disableUnderline={true}
                                variant="outlined"
                                id="whitelistedIdentities"
                                label=""
                                value={dialogProps.whitelistedIdentities || []}
                                onAdd={handleInputUpdate}
                                error={!!errors.whitelistedIdentities}
                                onDelete={handleEmailDelete}
                            />
                            <div aria-live='assertive'>
                                {errors.whitelistedIdentities && <FormHelperText>{errors.whitelistedIdentities}</FormHelperText>}
                            </div>
                        </FormControl>
                    </DialogCardBody>
                    <DialogCardFooter>
                        <ButtonGroup direction="right">
                            <ButtonText colorScheme="secondary" onClick={dialogProps.onClose}>
                                OK
                            </ButtonText>
                        </ButtonGroup>
                    </DialogCardFooter>
                </Dialog>
            </Row>
        </Grid>
    );
}
import { useBlockingLoading } from '../../../hooks/use-blocking-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { networkFileUploadSrv } from '../services/network-file-upload.service';

export interface UseFileDownloadResult {
  loading: boolean;
  downloadFileAsync: (id: string) => Promise<string | undefined>;
}

export function useCredentialsFileDownload(): UseFileDownloadResult {
  const { loading, startLoading, stopLoading } = useBlockingLoading();
  const { pushNotification } = useNotifications();

  const downloadFileAsync = async (id: string): Promise<string | undefined> => {
    startLoading();
    try {
      return await networkFileUploadSrv.downloadFileAsync(id);
    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      pushNotification({
        message: `Upload file failed with '${error}'. Please, try again later.`,
        variant: 'error',
        type: 'bar',
      });
      return undefined;
    } finally {
      stopLoading();
    }
  };

  return {
    loading,
    downloadFileAsync,
  };
}

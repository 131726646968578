import {Grid, Typography} from '@material-ui/core';
import React, { RefObject, useContext, useRef } from 'react';
import { B2C2AppsCard } from './cards/list-cards/b2c2-apps-card';
import { B2C2ProductsCard } from './cards/list-cards/b2c2-products-card';
import {dashboardStyles} from './dashboard.styles';
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../state/skip-to-main-store';
import { Helmet } from 'react-helmet';

export interface PilotProps {
  isb2b: boolean;
}

export const Dashboard: React.FC<PilotProps> = ({isb2b}) => {
  const styles = dashboardStyles();
  const mainHighlightRef:RefObject<HTMLHeadingElement> = useRef(null);
  const context:LandmarkContextType = useContext(LandmarkContext);
  useLandmarkHook(mainHighlightRef,context[1]);
  useHighlightH1(mainHighlightRef);
  return (
    <>
    <Helmet>
      <title>B2C Dashboard | Visa Prototype Validation Platform</title>
    </Helmet>
    <main className={styles.root}>
        <div className={styles.content}>
      <Typography variant="h5" component={"h1"} color={"primary"} gutterBottom innerRef={mainHighlightRef} tabIndex={-1}>
        B2C Dashboard
      </Typography>
        </div>
        <div className={styles.content}>
        <Grid >
          <B2C2AppsCard /> 
        </Grid>
        </div>
        <div className={styles.content}>
        <Grid >
          <B2C2ProductsCard/> 
        </Grid>
        </div>
    </main>
    </>
  );
};

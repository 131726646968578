import Grid from "@material-ui/core/Grid";
import Moment from 'moment';
import React from 'react';
import { B2C2ApprovalRequest } from "../../model/product";
import { useProductApprovalPreviewStyles } from '../approvals/b2c2-product-approval-preview.styles';

export interface B2C2ApprovalRequestDetailsProps {
  approvalRequest?: B2C2ApprovalRequest | null;
}

export const B2C2ApprovalRequestDetails: React.FC<B2C2ApprovalRequestDetailsProps> = ({ approvalRequest }) => {
  const styles = useProductApprovalPreviewStyles();
  if (!approvalRequest) {
    return null;
  }
  return (
    <Grid container className={styles.approvalRequest} spacing={3}>
      <Grid item md={3} xs={12}><b>Request Type</b></Grid>
      <Grid item md={9} xs={12}>{approvalRequest.requestType}</Grid>
      {approvalRequest.adminRequestEntity?.name && <>
        <Grid item md={3} xs={12}><b>Product Name</b></Grid>
        <Grid item md={9} xs={12}>{approvalRequest.adminRequestEntity?.name}</Grid>
      </>}
      {approvalRequest.adminRequestEntity?.productId && <>
        <Grid item md={3} xs={12}><b>Product Id</b></Grid>
        <Grid item md={9} xs={12}>{approvalRequest.adminRequestEntity?.productId}</Grid>
      </>}
      {approvalRequest.adminRequestEntity?.name && <>
        <Grid item md={3} xs={12}><b>App Name</b></Grid>
        <Grid item md={9} xs={12}>{approvalRequest.adminRequestEntity?.name}</Grid>
      </>}
      {approvalRequest.adminRequestEntity?.appId && <>
        <Grid item md={3} xs={12}><b>App Id</b></Grid>
        <Grid item md={9} xs={12}>{approvalRequest.adminRequestEntity?.appId}</Grid>
      </>}
      <Grid item md={3} xs={12}><b>Requestor</b></Grid>
      <Grid item md={9} xs={12}>{approvalRequest.requestor}</Grid>
      {approvalRequest.reviewerSubjectName && <>
        <Grid item md={3} xs={12}><b>Reviewer</b></Grid>
        <Grid item md={9} xs={12}>{approvalRequest.reviewerSubjectName}</Grid>
      </>}
      <Grid item md={3} xs={12}><b>Current Status</b></Grid>
      <Grid item md={9} xs={12}>{approvalRequest.status}</Grid>
      <Grid item md={3} xs={12}><b>Date Last Modified</b></Grid>
      <Grid item md={9} xs={12}>{Moment(approvalRequest.lastModifiedAt).format('MMM D YYYY')}</Grid>
      {approvalRequest.saNumber && <>
        <Grid item md={3} xs={12}><b>SA</b></Grid>
        <Grid item md={9} xs={12}>{approvalRequest.saNumber}</Grid>
      </>}
      {approvalRequest.businessJustification && <>
        <Grid item md={3} xs={12}><b>Business Justification</b></Grid>
        <Grid item md={9} xs={12}>{approvalRequest.businessJustification}</Grid>
      </>}
    </Grid>
  );
}

import { Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { useProductPreviewStyles } from "../product-view/product-preview.styles";

export const ProductPreviewDetailsPlaceholder: React.FC = () => {
    const styles = useProductPreviewStyles();
    return (
        <Typography component="div" className={styles.details}>
            <Grid container direction={'row'} spacing={3}>
                <Grid item xs={2}><b>Product name</b></Grid>
                <Grid item xs={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
            </Grid>
            <Grid container direction={'row'} spacing={3}>
                <Grid item xs={2}><b>Is this a backend service?</b></Grid>
                <Grid item xs={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
            </Grid>
            <Grid container direction={'row'} spacing={3}>
                <Grid item xs={2}><b>Documentation URL</b></Grid>
                <Grid item xs={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
            </Grid>
            <Grid container direction={'row'} spacing={3}>
                <Grid item xs={2}><b>Context Root</b></Grid>
                <Grid item xs={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
            </Grid>
            <Grid container direction={'row'} spacing={3}>
                <Grid item xs={2}><b>Owners</b></Grid>
                <Grid item xs={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
            </Grid>
        </Typography>
    );
};

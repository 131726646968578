import { Grid, IconButton, ThemeProvider, Typography } from '@material-ui/core';
import { AddBox, Visibility, VisibilityOff } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { DataTableSelect } from '../../../../components/data-table-fields/data-table-select';
import { DataTableTextInput } from '../../../../components/data-table-fields/data-table-text-input';
import { FormErrors } from '../../../../hooks/use-form.hook';
import { b2c2ApplicationB2C2BArgsMleSchema } from '../../model/b2c2-application-validations';
import { appEditStyles, backgroundGrey, hostTheme } from './app-edit.styles';
import * as yup from 'yup';
import { B2C2ApplicationB2C2BArgsMle } from '../../model/application';
import { useNotifications } from '../../../../hooks/use-notifications';
import { MaskedCell } from './b2c2-masked-cell';
import { ColumnDef, SortingState, Table, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { EditCellWrapper } from '../../../appsNew/views/table-abstraction/table-cells/cell-wrappers/EditCellWrappers';
import { TableCore } from '../table-abstraction/table-core';
import { HeaderSearchAddButton } from '../table-abstraction/filter-implementation/header-search-addButton';
import { TableFormEditDeleteAction } from '../table-abstraction/table-cells/b2c2-apps/table-form-edit-delete-action';
import { AddMleKeyCall } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b/add-mle-key-cell';
import { AddMleStatusCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b/add-mle-status-cell';
import { MleClientKeyCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b/add-mle-client-key-cell';
import { MleServerCertKeyCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b/add-mle-server-cert-key-cell';
import { ValidationError } from 'yup';
import { B2C2BAppsMle } from '../table-abstraction/table-rows/b2c2-apps/add-b2c2b-mle-row';

const MLE_STATUS: string[] = [
    'ACTIVE',
    'INACTIVE',
];

export interface AppEditB2C2BMlesProps {
    mles: B2C2ApplicationB2C2BArgsMle[];
    existingMles: B2C2ApplicationB2C2BArgsMle[] | undefined;
    onMlesChange: (data: B2C2ApplicationB2C2BArgsMle[]) => void;
}

export type Row = B2C2ApplicationB2C2BArgsMle & { tableData?: any } & { mleClientKeyIdFileName?: string};

const mleStatusMap = MLE_STATUS.reduce<Record<string, string>>(
    (acc, mleStatus) => ({ ...acc, [mleStatus]: mleStatus }),
    {}
);

export const AppEditB2C2BMles: React.FC<AppEditB2C2BMlesProps> = ({
    mles, existingMles, onMlesChange
}) => {
    const styles = appEditStyles();
    const { pushNotification } = useNotifications();
    const [errors, setErrors] = useState<FormErrors>({});

    // const columns = useMemo<Column<Row>[]>(() => ([
    //     {
    //         title: 'MLE Key Id',
    //         field: 'mleKeyId',
    //         filtering: false,
    //         width: '20%',
    //         editable: 'onAdd',
    //         editComponent: (props) => (<DataTableTextInput errors={errors} {...props} />),
    //     },
    //     {
    //         title: 'MLE Status',
    //         field: 'mleStatus',
    //         cellStyle: { whiteSpace: 'nowrap' },
    //         width: '100%',
    //         filtering: false,
    //         lookup: mleStatusMap,
    //         editComponent: (props) => (<DataTableSelect errors={errors} {...props} />),
    //     },
    //     {
    //         title: 'MLE Client Key Id',
    //         field: 'mleClientKeyId',
    //         width: '100%',
    //         filtering: false,
    //         render: (row) => (
    //             <MaskedCell value={row.mleClientKeyId} />
    //         ),
    //         editComponent: (props) => (<MleClientKeySelect isMle={true} {...props} />),
    //     },
    //     {
    //         title: 'MLE Server Cert Id',
    //         field: 'mleServerCertId',
    //         width: '100%',
    //         filtering: false,
    //         render: (row) => (
    //             <MaskedCell value={row.mleServerCertId} />
    //         ),
    //         editComponent: (props) => (<MleServerCertSelect isMle={true} {...props} />),
    //     },
    //     { title: 'Id', field: 'id', editable: 'never', hidden: true},
    // ]), [errors]);

    const validateData = useCallback(async (data: Row): Promise<void> => {
        try {
            b2c2ApplicationB2C2BArgsMleSchema.validateSync(data);
            setErrors({});
        } catch (ex) {
            const error = ex as yup.ValidationError;
            const field = error.path.split('[')[0];
            setErrors({ [field]: error.message });
            pushNotification({ message: `Valid ${field} must be provided to continue`, type: 'snackbar', variant: 'error' });
            throw error;
        }
    }, []);
    
    const handleAdd = useCallback(async (row: Row): Promise<void> => {
        const { tableData, ...newData } = row;
        await validateData(newData);
        const minId = mles.reduce((min, mle) => Math.min(mle.id, min), 0);
        onMlesChange([
            ...mles,
            { ...newData, id: minId - 1 },
        ]);
    }, [mles, onMlesChange, validateData]);

    const handleDelete = useCallback((row: Row): Promise<void> => {
        onMlesChange(mles.filter(mle => mle.id !== row.id));
        return Promise.resolve();
    }, [mles, onMlesChange]);

    const handleUpdate = useCallback(async (row: Row, oldData?: Row): Promise<void> => {
        const { tableData, ...newData } = row;
        if (!oldData) {
            throw Error('Missing oldData');
        }
        await validateData(newData);
        onMlesChange(mles.map(mle => mle.id !== oldData.id ? mle : { ...mle, ...newData }));
    }, [mles, onMlesChange, validateData]);

    const rows = useMemo<Row[]>(() => mles?mles.map(mle => ({ ...mle })):[], [mles]);

    const columns1 = useMemo<ColumnDef<B2C2ApplicationB2C2BArgsMle>[]>(() => ([
        {
            header: 'MLE Key Id',
            accessorKey: 'mleKeyId',
        },
        {
            header: 'MLE Status',
            accessorKey: 'mleStatus',
            cell: (context) => <EditCellWrapper context={context} element={<AddMleStatusCell {...context.table} />} />,
        },
        {
            header: 'MLE Client Key Id',
            accessorKey: 'mleClientKeyId',
            cell: MleClientKeyCell
        },
        {
            header: 'MLE Server Cert Id',
            accessorKey: 'mleServerCertId',
            cell: MleServerCertKeyCell
        },
        {
            header: "Actions",
            accessorKey: "id",
            enableSorting: false,
            cell: TableFormEditDeleteAction,
            meta:{
              styles:{
                minWidth: "150px"
              }
            }
          }
    ]), []);

    const [sort, setSort] = useState<SortingState>([]);
    const [search, setSearch] = useState<string>("");
    const [currentEditingRow, setCurrentEditingRow] = useState<string[]>([]);
    const [dirtyRow, setDirtyRow] = useState<Row | null>(() => null);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const table = useReactTable({
        columns: columns1,
        data: rows,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (e) => `${e.id}`,
        // debugTable: true,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSort,
        enableSorting: true,
        state: {
            sorting: sort,
            globalFilter: search
        },
        meta: {
            data: {
                mles : mles,
            },
            updateEditingRowState: (id: string) => {
                setCurrentEditingRow([id]);
                setDirtyRow(() => ({ ...rows.filter(e => String(e.id) === id)[0] }));
            },
            currentEditingRowId: currentEditingRow,
            revertingToStaticState: () => {
                setCurrentEditingRow([]);
                setDirtyRow(null);
            },
            setEditCelldata: (id, newData, columnid) => {
                setDirtyRow((oldState) => {
                    if (!oldState) {
                        return oldState;
                    }
                    return {
                        ...oldState,
                        [columnid]: newData
                    }

                }
                );
            },
            dirtyRow: dirtyRow,
            handleUpdate: (id: string) => {
                onMlesChange(mles.map(mle => `${mle.id}` !== id ? mle : { ...mle, ...dirtyRow }));
                setCurrentEditingRow([]);
                setDirtyRow(null);
            },
            deleteRow: (id: string) => {
                onMlesChange(mles!.filter(mle => String(mle.id) !== id));
            },
            isValid: (table: Table<any>) => {
                return b2c2ApplicationB2C2BArgsMleSchema.isValidSync(table.options.meta?.dirtyRow);
            },
            isIndividualCellValid: (columnName: string, newData: any) => {
                try {
                    b2c2ApplicationB2C2BArgsMleSchema.validateSyncAt(columnName, { [columnName]: newData });
                    return "";
                } catch (e) {
                    return (e as ValidationError).message;
                }
            },
            isAdd: isAdd,
            addRowNode: B2C2BAppsMle,
            startAddMode: () => {
                setCurrentEditingRow(["new"]);
                setDirtyRow({
                    id: 0,
                    mleKeyId: '',
                    mleStatus: '',
                    mleClientKeyId: '',
                    mleServerCertId: ''                    
                });
                setIsAdd(true);
            }, stopAddMode: () => {
                setCurrentEditingRow([]);
                setDirtyRow(null);
                setIsAdd(false);
            },
            handleAdd: () => {
                if (!dirtyRow) {
                    return;
                }
                const minId = mles.reduce((min, mle) => Math.min(mle.id, min), 0);
                onMlesChange([
                    ...mles,
                    { ...dirtyRow, id: minId - 1 },
                ]);
                setCurrentEditingRow([]);
                setDirtyRow(null);
                setIsAdd(false);
            }
        }
    });
    
    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography component="h2" className={styles.smallTitle}>
                Note: MLE Key Id cannot be edited or deleted.
            </Typography>
            <Grid className={styles.marginBtm}>
                {/* <ThemeProvider theme={hostTheme}>
                    <MaterialTable
                        columns={columns}
                        data={rows}
                        style={backgroundGrey}
                        title={<Typography variant="h3" className={styles.title}>MLEs</Typography>}
                        icons={{
                            Add: forwardRef
                            ((props, ref) => <AddBox{...props} ref={ref} color="primary" className={styles.addBtn}/>),
                        }}
                        options={{
                            toolbar: true,
                            showTitle: true,
                            search: false,
                            actionsColumnIndex: -1,
                            paging: false,
                        }}
                        editable={{
                            onRowAdd: handleAdd,
                            onRowUpdate: handleUpdate,
                            isDeletable: rowData => !existingMles || !existingMles.some(mle => mle.mleKeyId === rowData.mleKeyId),
                            onRowDelete: handleDelete,
                        }}
                    />
                </ThemeProvider> */}

<HeaderSearchAddButton table={table} addButtonFunction={table.options.meta?.startAddMode ? table.options.meta.startAddMode : () => { }} heading='MLEs' search={search} setSearch={setSearch} ></HeaderSearchAddButton>
      <TableCore caption='MLEs' table={table} loading={false}></TableCore>
            </Grid>
        </Grid>
    );
};

import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Select from '@visa/vds/select';
import { ProductEndpointUpdate } from "../../../../../../products/model/product-update";

export const B2cMleTypeCell: React.FC<Table<ProductEndpointUpdate>> = (table) => {
    const [b2bmleTypeError, setB2bMleTypeError] = useState<string>("");
    const mleTypeMap = table.options.meta?.data?.mleTypesMap || {};
    return <Select
        nam="B2b_MLE_Type"
        label="B2B MLE Type"
        fullWidth
        value={table.options.meta?.dirtyRow?.b2bMleType || ''}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setB2bMleTypeError(table.options.meta?.isIndividualCellValid("b2bMleType", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "b2bMleType");
        }}
        onBlur={()=>setB2bMleTypeError(table.options?.meta?.isIndividualCellValid("b2bMleType",table.options.meta.dirtyRow?.b2bMleType) || "")}
        showErrorText={!!b2bmleTypeError}
        errorText={b2bmleTypeError}
        invalid={!!b2bmleTypeError}
    >
        <option value="" disabled>Select MLE Type</option>
        {Object.entries(mleTypeMap).map(([key, value]) => <option key={key} value={value as string}>{key}</option>)}
    </Select>
}
import { Grid, Typography } from "@mui/material";
import React from "react";
import { SearchBar } from "../common-component/search-bar";

export interface IHeadingSearchFilter {
    heading: string;
    search: string;
    setSearch: Function;
}

export const HeadingSearchFilter: React.FC<IHeadingSearchFilter> = ({ heading, setSearch, search }) => {
    return <Grid sx={(theme) => ({
        padding: theme.spacing(2),
        backgroundColor: '#F2F4F8',
        borderRadius: theme.spacing(0.5, 0.5, 0, 0)
    })}
        container
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        rowGap={2}
    >
        <Grid item xs={12} lg={9}>
        <Typography variant="h2" style={{ fontSize: '20px' }}>{heading}</Typography>
        </Grid>
        <Grid item xs={12} lg={3}>
            <SearchBar label={`Search  ${heading}`} search={search} setSearch={setSearch}></SearchBar>  
        </Grid>
    </Grid>
}
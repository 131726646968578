import { config } from '../../../config';
import { networkSrv } from '../../../services/network.service';
import { Product, ProductHeader } from '../model/product';
import { ProductHeaderCreate } from '../model/product-create';
import { ProductHeaderRQ, ProductHeaderRS, ProductHeadersRQ, ProductRS } from './dtos';
import { mapProduct, mapProductHeaders, mapProductHeadersCreate, mapProductHeaderUpdate } from './model-maps';

export async function addProductHeadersAsync(productId: string, headers: ProductHeaderCreate[]): Promise<Product> {
  const headersRQ = headers.map(mapProductHeadersCreate);
  const request: ProductHeadersRQ = { "productHeaders": headersRQ };
  const headersRS = await networkSrv.postAsync<ProductHeadersRQ, ProductRS>(`${config.urls.products}/${productId}/headers`, request);
  return mapProduct(headersRS);
}

export function deleteProductHeaderAsync(productId: string, header: Partial<ProductHeader>): Promise<void> {
  const headerId = header.headerId;
  return networkSrv.deleteAsync(`${config.urls.products}/${productId}/headers/${headerId}`);
}

export function updateProductHeaderAsync(productId: string, header: Partial<ProductHeader>): Promise<void> {
  const headerId = header.headerId;
  const productHeaderRQ = mapProductHeaderUpdate(header);
  return networkSrv.patchAsync<Partial<ProductHeaderRQ>, void>(`${config.urls.products}/${productId}/headers/${headerId}`, productHeaderRQ);
}

export const networkProductHeadersSrv = {
  addProductHeadersAsync,
  deleteProductHeaderAsync,
  updateProductHeaderAsync,
}

import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useB2C2CredentialDetailsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        padding: '5px',
        border: '1px solid grey',
        backgroundColor: '#F2F4F8'
    },
    instruction1: {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
        marginBottom: theme.spacing(1)
    },
  }),
);

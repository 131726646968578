import React, { useState } from "react";
import Grid from '@visa/vds/grid';
import Row from '@visa/vds/row';
import Col from '@visa/vds/col';
import ButtonGroup from '@visa/vds/button-group';
import ButtonIcon from '@visa/vds/button-icon';
import ButtonText from '@visa/vds/button-text';
import Dialog from '@visa/vds/dialog';
import DialogCardBody from '@visa/vds/dialog-card-body';
import DialogCardClose from '@visa/vds/dialog-card-close';
import DialogCardFooter from '@visa/vds/dialog-card-footer';
import DialogCardHead from '@visa/vds/dialog-card-head';
import DialogCardHeadIcon from '@visa/vds/dialog-card-head-icon';
import DialogCardHeadTitle from '@visa/vds/dialog-card-head-title';
import Icon from '@visa/vds/icon';
import Typography from '@visa/vds/typography';
import { dialogStyles } from "../table-abstraction/dialogStyles.styles";

export interface DialogInterface {
  mleKeyId: string;
  dialogState: any;
  setDialogState: any;
  onClose: () => void;
}
export const B2C2CredentialDialog: React.FC<DialogInterface> = ({ mleKeyId, dialogState, setDialogState, onClose }) => {
  const _styles = dialogStyles();

  return (
    <Grid>
      <Row>
        <Col>
        <Dialog
          aria-labelledby="credential-dialog-title"
          aria-describedby="credential-dialog-description"
          open={dialogState.open}
          type="success"
          className={_styles.vdsDialogBoxCard}
        >
          <DialogCardHead>
            <DialogCardHeadIcon>
              <Icon name="success" resolution="low" />
            </DialogCardHeadIcon>
            <DialogCardHeadTitle id="credential-dialog-title">Key Id : { mleKeyId }, Deleted Successfully</DialogCardHeadTitle>
          </DialogCardHead>
          <DialogCardBody id="credential-dialog-description">
            <Typography>
              Do you want to delete other records ?
            </Typography>
          </DialogCardBody>
          <DialogCardFooter>
            <ButtonGroup direction="right">
              <ButtonText colorScheme="primary" onClick={() => setDialogState({ open: !dialogState.open })}>
                Yes
              </ButtonText>
              <ButtonText colorScheme="secondary" onClick={onClose}>
                No
              </ButtonText>
            </ButtonGroup>
          </DialogCardFooter>
        </Dialog>
        </Col>
      </Row>
    </Grid> 
  );

}
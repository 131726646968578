import { useEffect, useState } from 'react';
import { getUserManager } from '../service/user-service';
import { actions } from '../state/actions';
import { useAuthStore } from '../state/store';

export interface UseRefreshTokenResult {
  loading: boolean;
  authenticated: boolean;
}

export function useRefreshToken(): UseRefreshTokenResult {
  const [state, dispatch] = useAuthStore();
  const [loading, setLoading] = useState<boolean>(!state.loggedOut);

  useEffect(() => {
    const refreshTokenAsync = async () => {
      try {
        const user = await getUserManager().signinSilent();
        dispatch(actions.authenticationSuccess(user));
      } catch {
      } finally {
        setLoading(false);
      }
    }

    if (!state.loggedOut) {
      refreshTokenAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    authenticated: !!state.user && !state.user.expired,
  };
}

import * as yup from "yup";
import { httpMethods } from "../../../model/http-method";
import { mleTypes } from "../../../model/mle-types";
import {
  approvalRequestStatus,
  serviceHostDataCenters,
} from "./product";
import {
  ProductB2C2BArgsCreate,
  ProductCreate,
  ProductEndpointCreate,
  ProductHeaderCreate,
  ProductHostCreate,
} from "./product-create";
import { B2C2ApprovalRequestUpdate } from "./product-update";

export const productHostCreateSchema: yup.ObjectSchema<
  yup.Shape<ProductHostCreate, any>
> = yup.object().shape<ProductHostCreate>({
  dataCenter: yup.mixed().oneOf(serviceHostDataCenters).required("Required"),
  url: yup.string().url("Invalid URL").required("Required"),
  hostId: yup.string(),
});

export const productEndpointCreateSchema: yup.ObjectSchema<
  yup.Shape<ProductEndpointCreate, any>
> = yup.object().shape<ProductEndpointCreate>({
  // id: yup.number(),
  name: yup.string().required("Required"),
  description: yup.string(),
  methodType: yup.mixed().oneOf(httpMethods).required("Required"),
  internalPath: yup
    .string()
    .required("Required")
    .matches(
      /^\/[A-Za-z0-9].*$/,
      "Internal path must be prepended with /"
    ),
  externalPath: yup
    .string()
    .required("Required")
    .matches(
      /^\/innovation.*$/,
      "External path must be prepended with /innovation"
    ),
  // endpointId: yup.string(),
  status: yup.string(),
  version: yup.string(),
  b2cMleType: yup.mixed().oneOf(mleTypes),
  b2bMleType: yup.mixed().oneOf(mleTypes),
});

export const productHeaderCreateSchema: yup.ObjectSchema<
  yup.Shape<ProductHeaderCreate, any>
> = yup.object().shape<ProductHeaderCreate>({
  // id: yup.number(),
  name: yup.string().required("Required"),
  dataType: yup.string().required("Required"),
  isRequired: yup.boolean(),
  defaultValue: yup.string(),
});

export const productB2C2BArgsCreateSchema: yup.ObjectSchema<
  yup.Shape<ProductB2C2BArgsCreate, any>
> = yup.object().shape<ProductB2C2BArgsCreate>({
  useVp2Connector: yup.boolean().required("Required"),
  business: yup.string(),
});

export const productCreateSchema: yup.ObjectSchema<
  yup.Shape<ProductCreate, any>
> = yup.object().shape<ProductCreate>({
  // id: yup.number(),
  name: yup.string().required("Required"),
  description: yup.string(),
  contextRoot: yup
    .string()
    .required("Required")
    .matches(/^\S*$/, "Context root should not have spaces."),
  documentationUrl: yup.string().url("Invalid URL"),
  productHosts: yup.array(productHostCreateSchema).required("Required"),
  productEndpoints: yup.array(productEndpointCreateSchema).required("Required"),
  productHeaders: yup.array(productHeaderCreateSchema).notRequired(),
  productOwners: yup.array<string>(),
  productId: yup.string(),
  requestId: yup.string(),
  productB2C2BArgs: productB2C2BArgsCreateSchema,
});

export const b2c2ApprovalRequestUpdateSchema: yup.ObjectSchema<
  yup.Shape<B2C2ApprovalRequestUpdate, any>
> = yup.object().shape<B2C2ApprovalRequestUpdate>({
  saNumber: yup
    .string()
    .required("Required")
    .matches(
      /^[a-zA-Z-\s\d]+$/,
      "Invalid saNumber. Only characters and numbers are allowed,"
    ),
  businessJustification: yup.string().required("Required"),
  status: yup.mixed().oneOf(approvalRequestStatus),
  reason: yup.string(),
  sendBackMsg: yup
    .string()
    .when("requestStatus", (requestStatus: string) =>
      requestStatus === "OPEN"
        ? yup.string().required("Required")
        : yup.string().notRequired()
    ),
});

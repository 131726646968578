import { Button, FormHelperText, MenuItem, Paper, Tab, Tabs, ThemeProvider, Typography } from '@material-ui/core';
import ButtonText from '@visa/vds/button-text';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Routes, useNavigate, useParams } from 'react-router-dom';
import { useForm } from '../../../../hooks/use-form.hook';
import { useNotifications } from '../../../../hooks/use-notifications';
import { buttonStyles } from '../../../../styles/button.styles';
import ProductDialog from "../product-dialog/product-dialog";
import { ProductDetailsPlaceHolder } from '../product-edit/product-details-placeholder';
import { hostTheme, useProductEditStyles } from '../product-edit/product-edit.styles';
import { useFormsStyles } from '../../../../styles/forms.styles';
import { ProductEditDetails } from './product-edit-details';
import { ProductEditHosts } from './product-edit-hosts';
import { ProductEditEndpoints } from './product-edit-endpoints';
import { ProductEditHeaders } from './product-edit-headers';
import { ProductEndpointUpdate, ProductHeaderUpdate, ProductHostUpdate, ProductUpdate } from '../../model/product-update';
import { useProduct } from '../../hooks/use-product';
import { productCreateSchema } from '../../model/product-validations';
import { useProductUpdate } from '../../hooks/use-product-update';
import { ToggleButtonGroup, Grid, ToggleButton, useTheme, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import Select from '@visa/vds/select';
import { vdsStyleOverrides } from "../../../../vds-overrides.styles";

enum Panels {
  Details = 0,
  Hosts = 1,
  Endpoints = 2,
  Headers = 3,
}

interface TabErrors {
  details?: boolean;
  productHosts?: boolean;
  productEndpoints?: boolean;
  productHeaders?: boolean;
}

const defaultProduct: ProductUpdate = {
  id: -1,
  name: '',
  documentationUrl: '',
  statusType: '',
  description: '',
  contextRoot: '',
  productEndpoints: [],
  productHosts: [],
  productHeaders: [],
  productId: '',
  requestId: '',
  productB2C2BArgs: undefined,
};

const b2c2bArgsBusiness = [
  {
    business: "VDP",
  },
];

export const ProductEdit: React.FC = () => {
  const styles = useProductEditStyles();
  const vdsStyles = vdsStyleOverrides();
  const formStyles = useFormsStyles();
  const btnStyles = buttonStyles();
  const navigate = useNavigate();
  const { pushNotification } = useNotifications();
  const [blockNavigation, setBlockNavigation] = useState(false);
  const { productId } = useParams();
  const { currentProduct, loading } = useProduct(productId);
  const { updateProductAsync } = useProductUpdate();
  const [tabErrors, setTabErrors] = useState<TabErrors>({});
  const [currentPanel, setCurrentPanel] = useState<Panels>(Panels.Details);
  const { inputs, errors, handleInputChange, handleInputValueChange, handleValidate } = useForm(currentProduct || defaultProduct, productCreateSchema);

  const [dialog, setDialog] = React.useState(0);
  const handleDialog = useCallback((index: number) => {
    setDialog(index);
  }, []);

  useEffect(() => {
    if (inputs?.productB2C2BArgs?.useVp2Connector === undefined) {
      handleInputValueChange("productB2C2BArgs", {
        useVp2Connector: false,
        business: undefined,
      })
    }
  }, [inputs, handleInputValueChange]);

  useEffect(() => {
    //   if (currentProduct === undefined) {
    //       navigate('/404', {replace: true});
    //   }
  }, [currentProduct, navigate]);

  useEffect(() => {
    const errorList = [tabErrors.details, tabErrors.productHosts, tabErrors.productEndpoints, tabErrors.productHeaders];
    if (!errorList[currentPanel]) {
      const nextError = errorList.indexOf(true);
      if (nextError >= 0) {
        setCurrentPanel(nextError);
      }
    }
    // eslint-disable-next-line
  }, [tabErrors, currentPanel]);



  const handleCancel = useCallback(() => {
    navigate('/b2c/products');
  }, [navigate]);

  const handleEditComplete = useCallback(() => {
    navigate('/b2c/products/' + currentProduct?.productId);
  }, [navigate, currentProduct]);

  const handleTabChange = useCallback((_: React.ChangeEvent<{}>, panel: number) => {
    setCurrentPanel(panel);
  }, []);

  const handleBack = useCallback(() => setCurrentPanel(currentPanel => currentPanel - 1), []);
  const handleNext = useCallback(() => setCurrentPanel(currentPanel => currentPanel + 1), []);

  const areDetailsInvalid = useCallback(
    (errors: Record<string, any>) => errors.name || errors.contextRoot || errors.documentationUrl || errors.productOwners,
    []
  );

  const openCancelDialog = useCallback(() => handleDialog(4), [handleDialog]);

  const handleContinue = useCallback(async (evt: React.MouseEvent<any>) => {
    if (currentPanel === Panels.Endpoints && inputs.contextRoot) {
      let validEndpoint = true;
      let contextRoot = inputs?.contextRoot || '';

      if (inputs?.productEndpoints) {
        validEndpoint = inputs?.productEndpoints?.every((endpoint) => {
          let pathStrings = endpoint?.externalPath?.split("/");
          return pathStrings.indexOf(contextRoot) === 2;
        });
      }
      if (!validEndpoint) {
        pushNotification({ message: `Context root should be present in the external path`, type: 'snackbar', variant: 'error' });
        return false;
      }
    }

    if (currentPanel === Panels.Endpoints) {
      let validB2CMleType = true;
      let validB2BMleType = true;
      if (inputs?.productB2C2BArgs?.useVp2Connector && inputs?.productEndpoints) {
        validB2CMleType = inputs?.productEndpoints?.every((endpoint) => {
          return endpoint?.b2cMleType !== undefined;
        });
      }
      if (!validB2CMleType) {
        pushNotification({
          message: `B2C Mle Type is required when using VP2 Connector`,
          type: "snackbar",
          variant: "error",
        });
        return false;
      }
      if (inputs?.productB2C2BArgs?.useVp2Connector && inputs?.productEndpoints) {
        validB2BMleType = inputs?.productEndpoints?.every((endpoint) => {
          return endpoint?.b2bMleType !== undefined;
        });
      }
      if (!validB2BMleType) {
        pushNotification({
          message: `B2B Mle Type is required when using VP2 Connector`,
          type: "snackbar",
          variant: "error",
        });
        return false;
      }
    }
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    if (currentPanel === Panels.Hosts) {

      if (inputs?.productHosts.length === 0) {
        pushNotification({
          message: `At least one Host Url is required.`,
          type: "snackbar",
          variant: "error",
        });
        return false;
      }
    }

    if (currentPanel === Panels.Endpoints) {

      if (inputs?.productEndpoints.length === 0) {
        pushNotification({
          message: `At least one Endpoint is required.`,
          type: "snackbar",
          variant: "error",
        });
        return false;
      }
    }

    if (Object.values(errors).some(err => !!err)) {
      if(errors["name"]){
        document.getElementById("product-name-id")?.focus();
      }else if(errors["contextRoot"]){
        document.getElementById("context-root-id")?.focus();
      }
      pushNotification({ message: 'Fix errors before continue', type: 'snackbar', variant: 'error' });
    } else {
      await handleSave();
      handleNext();
    }

  }, [handleInputValueChange, inputs, currentPanel])

  const handleSubmit = useCallback(async () => {
    if (!currentProduct || !inputs)
      return;
    setBlockNavigation(false);
    handleDialog(0);
    const success = await updateProductAsync(currentProduct, inputs);
    if (success) {
      handleDialog(2);
    } else {
      setBlockNavigation(true);
    }
  }, [currentProduct, inputs, handleDialog, handleInputValueChange]);

  const handleSave = useCallback(async () => {
    if (!currentProduct || !inputs) {
      return;
    }
    const [, errors] = await handleValidate();
    setTabErrors(tabErrors => ({
      ...tabErrors,
      details: areDetailsInvalid(errors),
      productHosts: errors.hosts,
      productEndpoints: errors.operations,
      productHeaders: errors.allowedHeaders
    }));
    if (Object.values(errors).some(err => !!err)) {
      pushNotification({ message: 'Fix errors before continue', type: 'snackbar', variant: 'error' });
    } else {
      const success = await updateProductAsync(currentProduct, inputs);
      if (!success) {
        setBlockNavigation(true);
      }
    }
  }, [handleValidate, pushNotification, areDetailsInvalid, handleDialog, inputs, currentProduct, handleInputValueChange]);

  const handleHostsChange = useCallback(
    (productHosts: ProductHostUpdate[]) => handleInputValueChange('productHosts', productHosts),
    [handleInputValueChange]
  );
  const handleEndpointsChange = useCallback(
    (productEndpoints: ProductEndpointUpdate[]) => handleInputValueChange('productEndpoints', productEndpoints),
    [handleInputValueChange]
  );
  const handleHeadersChange = useCallback(
    (productHeaders: ProductHeaderUpdate[]) => handleInputValueChange('productHeaders', productHeaders),
    [handleInputValueChange]
  );

  const [selectValue, setSelectValue] = useState(currentProduct?.productB2C2BArgs?.useVp2Connector ? "true" : "false");

  const handleVp2ConnectorChange = useCallback(
    (evt: React.ChangeEvent<any>) => {
      handleInputChange(evt);
      setSelectValue(evt.target.value);
      if (evt.target.value === "false") {
        handleInputValueChange("productB2C2BArgs", {
          useVp2Connector: evt.target.value === "true",
          business: undefined,
        });
        handleInputValueChange("productHosts", []);
        addEndpointId(inputs);
        let productEndpointsWithoutMleTypes = inputs.productEndpoints.map((
          { b2bMleType, b2cMleType, ...rest }) => rest);
        handleInputValueChange("productEndpoints", productEndpointsWithoutMleTypes);
      } else if (evt.target.value === "true") {
        handleInputValueChange("productB2C2BArgs", {
          useVp2Connector: evt.target.value === "true",
          business: "VDP",
        });
        const host = window.location.host;
        const splits = host.split("-");
        let b2c2bConnectorUrl = "";
        let hostType = "OCE";
        if (splits.length > 2) {
          if (splits[2] === "devb.oce") {
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-devb.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "devwkspceb.oce") {
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-devwkspceb.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "qapenb.oce") {
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-qapenb.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "qaperfb.oce") {
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-qaperfb.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "qab.oce") {
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-qab.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "qaintb.oce") {
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-qaintb.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "aacertdc1b") {
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-aacertdc1b.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2] === "aacertdc2b") {
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-aacertdc2b.oce-np-sm-ddp-b-en.trusted.visa.com";
          } else if (splits[2].includes("sbx")) {
            if (splits[3].includes("oce")) {
              b2c2bConnectorUrl =
                "https://b2c2b-connector-service-sbxb-k8l73.oce-ex-ccte-sm-ddp-b.trusted.visa.com";
            } else if (splits[3].includes("occ")) {
              b2c2bConnectorUrl =
                "https://b2c2b-connector-service-sbxb-k8l55.occ-ex-ccte-sm-ddp-b.trusted.visa.com";
              hostType = "OCC";
            }
          } else if (splits[2].includes("prod")) {
            if (splits[3].includes("oce")) {
              b2c2bConnectorUrl =
                "https://b2c2b-connector-service-prodb2cb-k8l73.oce-ex-b2c-sm-ddp-b.trusted.visa.com";
            } else if (splits[3].includes("occ")) {
              b2c2bConnectorUrl =
                "https://b2c2b-connector-service-prodb2cb-k8l55.occ-ex-b2c-sm-ddp-b.trusted.visa.com";
              hostType = "OCC";
            }
          } else {
            // for local testing
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-devb.oce-np-sm-ddp-b-en.trusted.visa.com";
          }
          handleInputValueChange("productHosts", [
            {
              url: b2c2bConnectorUrl,
              dataCenter: hostType,
              id: 1,
            },
          ]);
        } else if (splits.length === 1) {
          if (splits[0] === "innovationportal.pilot.visa.com") {
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-prodb2cb-k8l73.oce-ex-b2c-sm-ddp-b.trusted.visa.com";
          } else if (splits[0] === "sandbox.innovationportal.pilot.visa.com") {
            b2c2bConnectorUrl =
              "https://b2c2b-connector-service-sbxb-k8l73.oce-ex-ccte-sm-ddp-b.trusted.visa.com";
          }
          handleInputValueChange("productHosts", [
            {
              url: b2c2bConnectorUrl,
              dataCenter: hostType,
              id: 1,
            },
          ]);
        }
      }
    },
    [handleInputChange, handleInputValueChange]
  );

  const handleB2C2BArgsBusinessChange = useCallback(
    (evt: React.ChangeEvent<any>) => {
      handleInputValueChange("productB2C2BArgs", {
        useVp2Connector: selectValue,
        business: evt.target.value,
      });
    },
    [handleInputChange, handleInputValueChange]
  );

  function addHostId(inputs: ProductUpdate): void {
    if (inputs.productHosts !== undefined) {
      for (var i = 0; i < inputs.productHosts.length; i++) {
        if (inputs.productHosts[i].id === undefined) {
          inputs.productHosts[i].id = i + 1;
        }
      }
    }
  }

  function addEndpointId(inputs: ProductUpdate): void {
    if (inputs.productEndpoints !== undefined) {
      for (var i = 0; i < inputs.productEndpoints.length; i++) {
        if (inputs.productEndpoints[i].id === undefined) {
          inputs.productEndpoints[i].id = i + 1;
        }
      }
    }
  }

  function addHeaderId(inputs: ProductUpdate): void {
    if (inputs.productHeaders !== undefined) {
      for (var i = 0; i < inputs.productHeaders.length; i++) {
        if (inputs.productHeaders[i].id === undefined) {
          inputs.productHeaders[i].id = i + 1;
        }
      }
    }
  }


  const theme = useTheme();
  const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <div>
      <Routes>
        when={blockNavigation}
        message={'You may have pending changes that you will lose if you continue. Do you want to continue?'}
      </Routes>
      <ThemeProvider theme={hostTheme}>
        <Paper square role="navigation" aria-label='All Stages of edit product'>
          <Tabs
            classes={{ indicator: styles.bigIndicator }}
            value={currentPanel}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            orientation={mediaCheck ? "vertical" : "horizontal"}
          >
            <Tab label="Product Details" disabled={loading}
              icon={tabErrors.details ? (<WarningIcon color="error" />) : undefined} />
            <Tab label="Host URL" disabled={loading}
              icon={tabErrors.productHosts ? (<WarningIcon color="error" />) : undefined} />
            <Tab label="Endpoints" disabled={loading}
              icon={tabErrors.productEndpoints ? (<WarningIcon color="error" />) : undefined} />
            <Tab label="Allowed Headers" disabled={loading}
              icon={tabErrors.productHeaders ? (<WarningIcon color="error" />) : undefined} />
          </Tabs>
        </Paper>
      </ThemeProvider>

      <main>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {currentPanel === Panels.Details && (
            <Grid container spacing={2}>
              {loading
                ? (<ProductDetailsPlaceHolder />)
                : (<ProductEditDetails details={inputs} onInputChange={handleInputChange} onInputValueChange={handleInputValueChange} errors={errors} />)
              }
            </Grid>
          )}
          {currentPanel === Panels.Hosts && (
            <Grid container spacing={2} className={styles.marginT30}>
              <>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Helmet>
                    <title>
                      Host URL | Edit product (2 of 4) | Visa Prototype Validation Platform
                    </title>
                  </Helmet>
                  <TypographyHighLight headerTitle="Host URL | Edit product (2 of 4)" variant='h5' />
                  <Typography color="primary" className={styles.instruction1}>
                    Please fill in your product B2C2B Args
                  </Typography>
                  <Grid container spacing={2} className={styles.marginB30}>
                    <Grid item xs={6}>
                      <Typography className={formStyles.label}>
                        Use VP2 Connector*
                      </Typography>
                      <div>
                        <ToggleButtonGroup
                          size="large"
                          color="primary"
                          value={selectValue === "true"}
                          exclusive
                          onChange={handleVp2ConnectorChange}
                        >
                          <ToggleButton id="YES" value={true as any}>
                            YES
                          </ToggleButton>
                          <ToggleButton id="NO" value={false as any}>
                            NO
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      {selectValue !== undefined && selectValue === "true" ? (
                        <Select
                          name="business"
                          label="Business*"
                          value={inputs.productB2C2BArgs?.business}
                          className={vdsStyles.selectFullWidth}
                          onChange={handleB2C2BArgsBusinessChange}
                        >
                          {b2c2bArgsBusiness.map(item => (
                            <option key={item.business} value={item.business}>{item.business}</option>
                          ))}
                        </Select>
                      ) : (
                        <></>
                      )}
                      <br />
                    </Grid>
                  </Grid>
                </Grid>
                {addHostId(inputs)}
                <ProductEditHosts productHosts={inputs.productHosts} useVp2Connector={selectValue === "true"} onChange={handleHostsChange} />
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} />
              </>
            </Grid>
          )}
          {currentPanel === Panels.Endpoints && (
            <Grid container spacing={2} className={styles.marginT30}>
              <>
                {addEndpointId(inputs)}
                <ProductEditEndpoints endpoints={inputs.productEndpoints}
                  useVp2Connector={selectValue === "true"} onChange={handleEndpointsChange} />
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} />
              </>
            </Grid>
          )}
          {currentPanel === Panels.Headers && (
            <Grid container spacing={2}>
              <>
                {addHeaderId(inputs)}
                <ProductEditHeaders headers={inputs.productHeaders || []} onChange={handleHeadersChange} />
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} />
              </>
            </Grid>
          )}
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container direction='row' columnGap={2}>
                <Grid item lg={5}>
                  <ButtonText
                    onClick={openCancelDialog}
                    colorScheme="primary"
                    isFullWidth
                  >
                    Cancel
                  </ButtonText>
                </Grid>
                {currentPanel !== Panels.Details && (
                  <Grid lg={5}>
                    <ButtonText
                      onClick={handleBack}
                      colorScheme="primary"
                      isFullWidth>
                      Back
                    </ButtonText>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              {currentPanel !== Panels.Headers && (
                <ButtonText
                  onClick={handleContinue}
                  colorScheme="primary"
                  isFullWidth
                >
                  Save & Continue
                </ButtonText>
              )}
              {currentPanel === Panels.Headers && (
                <ButtonText
                  colorScheme="primary"
                  disabled={!currentProduct}
                  onClick={handleSubmit}
                  isFullWidth>
                  Save & Review
                </ButtonText>
              )}
            </Grid>
          </Grid>
        </Grid>
        {dialog ? <ProductDialog handleDialog={handleDialog} handleCancel={handleCancel} handleSubmit={handleSubmit}
          handleEditComplete={handleEditComplete} dialog={dialog} /> : <div />}
      </main>
    </div>
  );
};
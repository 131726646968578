import { AppBar, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import { UserMenu } from '../../features/auth/components/user-menu/user-menu';
import { useIsAuthenticated } from '../../lib/oidc-client-react';
import { appHeaderStyles } from './app-header.styles';
import { HelpMenu } from "../../features/auth/components/help-menu/help-menu";
import { FeedbackMenu } from "../../features/auth/components/feedback-menu/feedback-menu";
import { CombinedLogo, LogoColorTheme } from '../logos/CombinedLogo';
import { SkipToMainContent } from '../skipToComponent/skipToMainContent';
import { SkipToLogin } from '../skipToComponent/skipToLogin';

export interface AppHeaderProps {
  drawerOpen: boolean;
  onDrawerToggle: () => void;
  isHomePage: boolean;
}

export const AppHeader: React.FC<AppHeaderProps> = ({ drawerOpen, onDrawerToggle, isHomePage }) => {
  const styles = appHeaderStyles();
  const isAuthenticated = useIsAuthenticated();
  if (!isAuthenticated) return <><SkipToMainContent />
    <SkipToLogin /></>;
  return (
    <header className={styles.root}>
      <SkipToMainContent />
      <SkipToLogin />
      <AppBar
        component={"div"}
        position="static"
        className={clsx(styles.appBar, {
          [styles.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar className={!isAuthenticated ? styles.emptyBar : undefined}>
          {isAuthenticated && !isHomePage && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerToggle}
              edge="start"
              className={clsx(styles.menuButton, {
                [styles.hide]: drawerOpen,
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
          <CombinedLogo logoColor={LogoColorTheme.WHITE}></CombinedLogo>
          {isAuthenticated && <>
            <Hidden smDown>
              <FeedbackMenu />
              </Hidden>
              <Hidden smDown>
              <HelpMenu />
            </Hidden>
            </>
          }
          {/* Cannot merge both the conditions as fragments are not allowed to be a child of Toolbar */}
          {isAuthenticated && <UserMenu />}
        </Toolbar>
      </AppBar>
    </header>
  );
};

import { useEffect } from 'react';
import { useLoading } from '../../../hooks/use-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { B2C2Application } from '../model/application';
import { networkAppsNewSrv } from '../services/network-apps.service';
import { appsActions } from '../state/apps-actions';
import { useB2C2AppsStore } from '../state/apps-selectors';

export interface UseB2C2ApplicationsResult {
  b2c2Applications: B2C2Application[];
  loading: boolean;
}

export function useB2C2Applications(): UseB2C2ApplicationsResult {
  const [state, dispatch] = useB2C2AppsStore();
  const { startLoading, stopLoading } = useLoading();
  const { pushNotification } = useNotifications();

  useEffect(() => {
    const loadB2C2ApplicationsAsync = async () => {
      startLoading();
      dispatch(appsActions.loadB2C2Applications());
      try {
        const applications = await networkAppsNewSrv.getB2C2ApplicationsAsync();
        const pendingApplications = await networkAppsNewSrv.getB2C2ApplicationsPendingAsync();
        const allApplications =[...applications, ...pendingApplications]
        dispatch(appsActions.loadB2C2ApplicationsSuccess(allApplications));
      } catch (e:any) {
        const error = e.message ? e.message as string : 'Response error';
        dispatch(appsActions.loadB2C2ApplicationsError(error));
        pushNotification({
          message: `Load B2C applications failed with '${error}'`,
          variant: 'error',
          type: 'bar',
        });
      } finally {
        stopLoading();
      }
    }

    if (!state.loading && !state.error && state.b2c2Applications.length === 0 ) {
      loadB2C2ApplicationsAsync();
    }
  }, [state.b2c2Applications, state.loading, state.error, dispatch, pushNotification, startLoading, stopLoading]);

  return {
    b2c2Applications: state.b2c2Applications,
    loading: state.loading,
  };
}
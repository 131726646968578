import { User } from '../../oidc-client';
import { useAuthState } from '../state/store';

export interface UseUserResult {
  user: User | null;
  loading: boolean;
}

export function useUser(): UseUserResult {
  const { user, authenticating: loading } = useAuthState();
  return {
    user, 
    loading
  };
}

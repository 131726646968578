import { CellContext } from "@tanstack/react-table";
import React from "react";
import Icon from '@visa/vds/icon';
import ButtonIcon from '@visa/vds/button-icon';

export const TableFormEditDeleteCustomizationsAction: React.FC<CellContext<any, any>> = (context: CellContext<any, any>) => {
    if (context.table.options?.meta?.currentEditingRowId?.length === 0) {
        return <>
            <ButtonIcon ariaLabel={`Edit ${context.getValue()}`} iconType="light-tiny" onClick={() => context.table.options?.meta?.updateEditingRowState(context.row.id)}>
                <Icon name="edit" resolution="tiny" />
            </ButtonIcon>
            <ButtonIcon ariaLabel={`Delete ${context.getValue()}`} iconType="light-tiny" onClick={() => context.table.options?.meta?.deleteRow && context.table.options?.meta?.deleteRow(context.row.id)}>
                <Icon name="delete" resolution="tiny" />
            </ButtonIcon>
        </>
    }
    if (context.table.options?.meta?.currentEditingRowId[0] === context.row.id) {
        return <>
             <ButtonIcon ariaLabel={`Submit ${context.getValue()}`} iconType="light-tiny" onClick={() => context.table.options?.meta?.handleUpdate && context.table.options?.meta?.handleUpdate(context.row.id)}>
                <Icon name="checkmark" resolution="tiny" />
            </ButtonIcon>
            <ButtonIcon ariaLabel={`Revert the value for ${context.getValue()}`} iconType="light-tiny" onClick={() => context.table.options?.meta?.revertingToStaticState()}>
                <Icon name="close" resolution="tiny" />
            </ButtonIcon></>
    } else {
        return <>
            <ButtonIcon ariaLabel={`Edit ${context.getValue()}`} iconType="light-tiny" onClick={() => context.table.options?.meta?.updateEditingRowState(context.row.id)} disabled>
                <Icon name="edit" resolution="tiny" />
            </ButtonIcon>
            <ButtonIcon ariaLabel={`Delete ${context.getValue()}`} iconType="light-tiny" onClick={() => context.table.options?.meta?.deleteRow && context.table.options?.meta?.deleteRow(context.row.id)} disabled>
                <Icon name="delete" resolution="tiny" />
            </ButtonIcon>
        </>
    }
}
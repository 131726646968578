import {
  Button,
  Card,
  CardContent,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography, Container, useTheme
} from '@material-ui/core';
import ButtonText from '@visa/vds/button-text';
import Col from '@visa/vds/col';
import Grid from '@visa/vds/grid';
import Icon from '@visa/vds/icon';
import Row from '@visa/vds/row';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import React, { RefObject, useCallback, useContext, useRef, useState } from 'react';
import { config } from '../../config';
import { SSO_RETRIES_ALLOWED } from '../../features/apps/constants';
import { useLogin } from '../../lib/oidc-client-react';
import { retrySSOSrv } from '../../services/retry-sso.service';
import { buttonStyles } from '../../styles/button.styles';
import { getStartedStyles } from './get-started.styles';
import { CardData, cardDataInitial, rows } from './initial-data';
import { Helmet } from 'react-helmet';
import { CombinedLogo, LogoColorTheme } from '../../components/logos/CombinedLogo';
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../state/skip-to-main-store';

export const GetStarted: React.FC = () => {
  const styles = getStartedStyles();
  const btnStyles = buttonStyles();
  const { login } = useLogin();
  const [cardData, setCardData] = useState(cardDataInitial);
const theme = useTheme();
  const handleExpand = useCallback((index: number) => {
    const newCardData: CardData[] = cardData.map((item, i) => i !== index
      ? item : {
        ...item,
        expanded: !item.expanded,
      }
    );
    setCardData(newCardData);
  }, [cardData]);

  const handleLogin = useCallback(() => {
    retrySSOSrv.setNoOfRetries(SSO_RETRIES_ALLOWED);
    login();
  }, [login]);
  const ref: RefObject<HTMLHeadingElement> = useRef<HTMLHeadingElement>(null);
  const context: LandmarkContextType = useContext(LandmarkContext);
  useLandmarkHook(ref, context[1]);
  useHighlightH1(ref);
  return (
    <>
      <Helmet>
        <title>Getting Started | Visa Prototype Validation Platform </title>
      </Helmet>
      <Grid container className={styles.hero}>
        <header>
          <Container maxWidth={false} className={styles.logobarMenu}>
            <CombinedLogo logoColor={LogoColorTheme.BLUE} />
          </Container>
        </header>
        <main>
            <Typography component="h1" variant="h5" tabIndex={-1} className={styles.header1} ref={ref}>Getting started with Visa Prototype Validation Platform</Typography>
            <Typography component="p" variant="body1" className={styles.description}>
              Visa’s Prototype Validation Platform (VPVP) is a piloting platform/API gateway that enables rapid product concept validation and innovation pilots around consumer payments, new payment flows, and value-added services.
            </Typography>
            <Typography component="h2" variant="h5" className={styles.header1}>Platform capabilities</Typography>
              {
                cardData.map((cardItem: CardData, i: number) =>
                  <Card key={i} className={styles.cardStyle} variant="elevation">
                    <CardContent component="div" id={cardItem.title.replaceAll(" ", "_")}>
                      <Typography component="h3" variant="h6" >{cardItem.title}</Typography>
                      <Typography component="p" variant="body2" className={styles.cardItemDescription}>
                        {cardItem.description}
                      </Typography>
                      <Button
                        onClick={() => handleExpand(i)}
                        className={styles.expandBtn}
                        aria-expanded={cardItem.expanded}
                        aria-controls={"accordian_" + cardItem.title.replaceAll(" ", "_")}
                        aria-label={"APIs included in " + cardItem.title}>
                        APIs Included
                        {cardItem.expanded ? <ArrowDropUp fontSize={"large"} /> : <ArrowDropDown fontSize={"large"} />}
                      </Button>
                      <div id={"accordian_" + cardItem.title.replaceAll(" ", "_")}>
                        <Collapse mountOnEnter={false} in={cardItem.expanded}  >
                          <ul>
                            {
                              cardItem.APIs.map((api: string, index: number) =>
                                <li key={index}>{api}</li>)
                            }
                          </ul>
                        </Collapse>

                      </div>
                    </CardContent>
                  </Card>
                )
              }
              <Typography component="h2" variant="h5" className={styles.header1}>Which admin role is right for me?</Typography>
              <TableContainer component={Paper}>
                <Table component="table">
                  <caption style={{display:"none"}} >Role table</caption>
                  <TableHead>
                    <TableRow>
                      <TableCell component={"th"} scope='col'><b>Role name</b></TableCell>
                      <TableCell component={"th"} scope='col'><b>Description</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow component="tr" key={row.roleName}>
                        <TableCell component="td" role={undefined}>{row.roleName}</TableCell>
                        <TableCell component="td" role={undefined}>{row.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography component="h2" variant="h5" className={styles.header1}>How do I request access to the Prototype Validation Platform?</Typography>
              <Typography component="p" variant="body1" className={styles.description}>
                You can request for roles by submitting Ask Now requests.
              </Typography>
              <Row>
                <Col>
                  <ButtonText rel="noopener" aria-label="Request Access (opens a new tab)" tag='a' href={config.externalUrls.visaWikiRequestRole} target="_blank">
                    Request Access
                    <Icon name="maximize" resolution="low" />
                  </ButtonText>
                </Col>
              </Row>
            <Container maxWidth={false} className={styles.signinMenu} >
              <Typography component="p" variant="body1" className={styles.description}> Already have an account?
                <Button
                  tabIndex={0}
                  className={styles.signInBtn}
                  onClick={handleLogin}
                >Sign In</Button>
              </Typography>
            </Container>

        </main>
      </Grid>
    </>
  );
};

import * as yup from 'yup';
import { Identity } from '../../../model/entities';
import { httpMethods } from '../../../model/http-method';
import { mleTypes } from '../../../model/mle-types';
import { dataTypes, MultiDataType } from '../../../model/multi-type';
import { approvalRequestStatus, rateLimitTypes, serviceHostDataCenters } from './service';
import {
  B2BEntitlementConfigCreate,
  B2BOperationCreate, B2BOutboundEventsCreate, B2BOutboundHostCreate, B2BServiceCreate, B2BServiceHeadersCreate,
  B2BServiceHostCreate,
  ServiceConfigurationCreate,
  ServiceHeaderCreate,
  ServiceHostCreate,
  ServiceOperationCreate
} from './service-create';
import {
  B2BApprovalRequestUpdate,
  B2BEntitlementConfigUpdate,
  B2BOperationUpdate, B2BOutboundEventsUpdate, B2BOutboundHostUpdate, B2BPendingOperationUpdate, B2BRateLimitsUpdate, B2BRateLimitUpdate, B2BServiceHeadersUpdate,
  B2BServiceHostUpdate,
  B2BServiceUpdate,
  ServiceConfigurationUpdate,
  ServiceHeaderUpdate,
  ServiceHostUpdate,
  ServiceOperationUpdate
} from './service-update';

export const serviceHostCreateSchema: yup.ObjectSchema<yup.Shape<ServiceHostCreate, any>> = yup.object().shape<ServiceHostCreate>({
  dataCenter: yup.mixed().oneOf(serviceHostDataCenters).required('Required'),
  url: yup.string().url('Invalid URL').required('Required')
  // is_active: yup.string().required('Required')
});
export const serviceHostUpdateSchema: yup.ObjectSchema<yup.Shape<ServiceHostUpdate, any>> = serviceHostCreateSchema.concat(yup.object().shape<Identity>({
  id: yup.number().required('Required'),
}));

export const serviceOperationCreateSchema: yup.ObjectSchema<yup.Shape<ServiceOperationCreate, any>> = yup.object().shape<ServiceOperationCreate>({
  name: yup.string().required('Required'),
  method: yup.mixed().oneOf(httpMethods).required('Required'),
  internalPath: yup.string().required('Required'),
  externalPath: yup.string().required('Required'),
});
export const serviceOperationUpdateSchema: yup.ObjectSchema<yup.Shape<ServiceOperationUpdate, any>> = serviceOperationCreateSchema.concat(yup.object().shape<Identity>({
  id: yup.number().required('Required'),
}));

export const serviceConfigCreateSchema: yup.ObjectSchema<yup.Shape<ServiceConfigurationCreate, any>> = yup.object().shape<ServiceConfigurationCreate>({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
  dataType: yup.mixed().oneOf(dataTypes).required('Required'),
  defaultValue: yup.mixed().when('dataType', (dataType: MultiDataType) => {
    switch (dataType) {
      case 'number':
        return yup.number().required('Required');
      case 'boolean':
        return yup.boolean().required('Required');
      case 'string[]':
        return yup.array(yup.string()).required('Required');
      case 'number[]':
        return yup.array(yup.number()).required('Required');
      case 'string':
      default:
        return yup.string().required('Required');
    }
  }),
});
export const serviceConfigUpdateSchema: yup.ObjectSchema<yup.Shape<ServiceConfigurationUpdate, any>> = serviceConfigCreateSchema.concat(yup.object().shape<Identity>({
  id: yup.number().required('Required'),
}));

export const serviceHeaderCreateSchema: yup.ObjectSchema<yup.Shape<ServiceHeaderCreate, any>> = yup.object().shape<ServiceHeaderCreate>({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
});
export const serviceHeaderUpdateSchema: yup.ObjectSchema<yup.Shape<ServiceHeaderUpdate, any>> = serviceHeaderCreateSchema.concat(yup.object().shape<Identity>({
  id: yup.number().required('Required'),
}));

// export const serviceCreateSchema: yup.ObjectSchema<yup.Shape<ServiceCreate, any>> = yup.object().shape<ServiceCreate>({
//   name: yup.string().required('Required'),
//   region: yup.string().required('Required'),
//   isBackend: yup.boolean(),
//   backendApiKey: yup.string().when('isBackend', (isBackend: boolean) => (isBackend ? yup.string().required('Required'): yup.string().notRequired())),
//   documentationUrl: yup.string().url('Invalid URL'),
//   serviceOwners: yup.array<string>(),
//   hosts: yup.array(serviceHostCreateSchema).required('Required'),
//   operations: yup.array(serviceOperationCreateSchema).required('Required'),
//   requiredConfigurations: yup.array(serviceConfigCreateSchema).notRequired(),
//   allowedHeaders: yup.array(serviceHeaderCreateSchema).notRequired(),
//   // deploymentRegion: yup.string()
// });

export const b2bServiceHostCreateSchema: yup.ObjectSchema<yup.Shape<B2BServiceHostCreate, any>> = yup.object().shape<B2BServiceHostCreate>({
  dataCenter: yup.mixed().oneOf(serviceHostDataCenters).required('Required'),
  url: yup.string().url('Invalid URL').required('Required'),
  id: yup.number(),
  isAutoDC: yup.boolean(),
  is_active: yup.string().when('isAutoDC',{
    is:(isAutoDC:boolean)=>(isAutoDC===true),
    then:yup.string().notRequired(),
    otherwise:yup.string().required('Required')
  })
});

export const rateLimitUpdateSchema: yup.ObjectSchema<yup.Shape<B2BRateLimitUpdate, any>> = yup.object().shape<B2BRateLimitUpdate>({
  name: yup.string().required('Required'),
  type: yup.mixed().oneOf(rateLimitTypes).required('Required'),
  numberOfCalls: yup.string().required('Required').matches(/^([\s\d]+)$/, 'please enter numeric value'),
  timeWindow: yup.string().required('Required').matches(/^([\s\d]+)$/, 'please enter numeric value'),
  numberOfClients: yup.string().required('Required').matches(/^([\s\d]+)$/, 'please enter numeric value'),
});

export const rateLimitsUpdateSchema: yup.ObjectSchema<yup.Shape<B2BRateLimitsUpdate, any>> = yup.object().shape<B2BRateLimitsUpdate>({
  limits: yup.array(rateLimitUpdateSchema),
});

export const b2bOperationCreateSchema: yup.ObjectSchema<yup.Shape<B2BOperationCreate, any>> = yup.object().shape<B2BOperationCreate>({
  name: yup.string().required('Required'),
  description: yup.string(),
  methodType: yup.mixed().oneOf(httpMethods).required('Required'),
  mleType: yup.mixed().oneOf(mleTypes).required('Required'),
  internalPath: yup.string().required('Required'),
  externalPath: yup.string().required('Required').matches(/^\/innovation.*$/, 'External path must be prepended with /innovation'),
  id: yup.number(),
  rateLimits: yup.array(rateLimitUpdateSchema),
});

export const b2bEntitlementConfigsCreateSchema: yup.ObjectSchema<yup.Shape<B2BEntitlementConfigCreate, any>> = yup.object().shape<B2BEntitlementConfigCreate>({
  productProvisioningType: yup.string().required('Required'),
  clientOnboardingCallBackUrl: yup.string().url('Invalid URL').when('productProvisioningType',{
    is:(productProvisioningType: string)=>(productProvisioningType==="OFFLOAD"),
    then:yup.string().required("Required"),
    otherwise:yup.string().notRequired()
  })
  })

export const b2bEntitlementConfigsUpdateSchema: yup.ObjectSchema<yup.Shape<B2BEntitlementConfigUpdate, any>> = yup.object().shape<B2BEntitlementConfigUpdate>({
  productProvisioningType: yup.string().required('Required'),
  clientOnboardingCallBackUrl: yup.string().url('Invalid URL')
});

export const b2bServiceHeadersCreateSchema: yup.ObjectSchema<yup.Shape<B2BServiceHeadersCreate, any>> = yup.object().shape<B2BServiceHeadersCreate>({
  name: yup.string().required('Required').matches(/^[a-zA-Z-_]+$/, 'Invalid name. Allowed characters are alphabets or special characters: -_'),
  type: yup.string().required('Required'),
  required: yup.string().required('Required'),
  defaultValue: yup.string().matches(/^[a-zA-Z0-9_=&'()*+,-./:;%?"]+$/, `Invalid name. Allowed characters are **numbers or** alphabets or special characters: _=&'()*+,-./:;%?"`),
  id: yup.number(),
});

export const b2bOutboundHostCreateSchema: yup.ObjectSchema<yup.Shape<B2BOutboundHostCreate, any>> = yup.object().shape<B2BOutboundHostCreate>({
  // callbackHost: yup.string().required('Required'),
  callbackUrl: yup.string().required('Required').url('Invalid URL'),
  is_active: yup.string().required('Required'),
  dataCenter: yup.mixed().oneOf(serviceHostDataCenters).required('Required'),
  id: yup.number(),
});

export const b2bOutboundEventsCreateSchema: yup.ObjectSchema<yup.Shape<B2BOutboundEventsCreate, any>> = yup.object().shape<B2BOutboundEventsCreate>({
  eventName: yup.string().required('Required').matches(/^\w+$/, 'Invalid name. Allowed characters are: [a-zA-Z0-9_]'),
  outboundHosts: yup.array(b2bOutboundHostCreateSchema).required('Required'),
});

export const b2bServiceCreateSchema: yup.ObjectSchema<yup.Shape<B2BServiceCreate, any>> = yup.object().shape<B2BServiceCreate>({
  name: yup.string().required('Required'),
  description: yup.string(),
  documentationUrl: yup.string().url('Invalid URL'),
  serviceOwners: yup.array<string>(),
  serviceHosts: yup.array(b2bServiceHostCreateSchema).required('Required'),
  serviceHeaders: yup.array(b2bServiceHeadersCreateSchema),
  operations: yup.array(b2bOperationCreateSchema).required('Required'),
  outboundEvents: yup.array(b2bOutboundEventsCreateSchema),
  entitlementConfigs: b2bEntitlementConfigsCreateSchema,
  contextRoot: yup.string(),
  deploymentRegion: yup.string().required('Required'),
  autoDC: yup.boolean(),
  healthEndpoint:yup.string().when('autoDC',{
    is:(autoDC:boolean)=>(autoDC===true),
    then:yup.string().required('Required'),
    otherwise:yup.string().notRequired()
  })
});

export const b2bServiceHostUpdateSchema: yup.ObjectSchema<yup.Shape<B2BServiceHostUpdate, any>> = yup.object().shape<B2BServiceHostUpdate>({
  dataCenter: yup.mixed().oneOf(serviceHostDataCenters).required('Required'),
  url: yup.string().url('Invalid URL').required('Required'),
  isAutoDC: yup.boolean(),
  is_active: yup.string().when('isAutoDC',{
    is:(isAutoDC:boolean)=>(isAutoDC===true),
    then:yup.string().notRequired(),
    otherwise:yup.string().required('Required')
  })
});

export const b2bServiceHeadersUpdateSchema: yup.ObjectSchema<yup.Shape<B2BServiceHeadersUpdate, any>> = yup.object().shape<B2BServiceHeadersUpdate>({
  name: yup.string().required('Required').matches(/^[a-zA-Z-_]+$/, 'Invalid name. Allowed characters are alphabets or special characters: -_'),
  type: yup.string().required('Required'),
  required: yup.string().required('Required'),
  defaultValue: yup.string().matches(/^[a-zA-Z0-9_=&'()*+,-./:;%?"]+$/, `Invalid name. Allowed characters are **numbers or** alphabets or special characters: _=&'()*+,-./:;%?"`),
});

export const b2bOperationUpdateSchema: yup.ObjectSchema<yup.Shape<B2BOperationUpdate, any>> = yup.object().shape<B2BOperationUpdate>({
  name: yup.string().required('Required'),
  description: yup.string(),
  methodType: yup.mixed().oneOf(httpMethods).required('Required'),
  mleType: yup.mixed().oneOf(mleTypes).required('Required'),
  internalPath: yup.string().required('Required'),
  externalPath: yup.string().required('Required').matches(/^\/innovation.*$/, 'External path must be prepended with /innovation'),
});

export const b2bPendingOperationUpdateSchema: yup.ObjectSchema<yup.Shape<B2BPendingOperationUpdate, any>> = yup.object().shape<B2BPendingOperationUpdate>({
  name: yup.string().required('Required'),
  methodType: yup.mixed().oneOf(httpMethods).required('Required'),
  mleType: yup.mixed().oneOf(mleTypes).required('Required'),
  internalPath: yup.string().required('Required'),
  externalPath: yup.string().required('Required').matches(/^\/innovation.*$/, 'External path must be prepended with /innovation'),
});


export const b2bOutboundHostUpdateSchema: yup.ObjectSchema<yup.Shape<B2BOutboundHostUpdate, any>> = yup.object().shape<B2BOutboundHostUpdate>({
  outboundHostGuid: yup.string(),
  outboundEventGuid: yup.string(),
  is_active: yup.string().required('Required'),
  callbackUrl: yup.string().required('Required'),
  dataCenter: yup.mixed().oneOf(serviceHostDataCenters).required('Required'),
  id: yup.number(),
});

export const b2bOutboundEventsUpdateSchema: yup.ObjectSchema<yup.Shape<B2BOutboundEventsUpdate, any>> = yup.object().shape<B2BOutboundEventsUpdate>({
  eventGuid: yup.string(),
  eventName: yup.string().required('Required'),
  outboundHosts: yup.array(b2bOutboundHostUpdateSchema).required('Required'),
});

export const b2bServiceUpdateSchema: yup.ObjectSchema<yup.Shape<B2BServiceUpdate, any>> = yup.object().shape<B2BServiceUpdate>({
  name: yup.string().required('Required'),
  description: yup.string(),
  documentationUrl: yup.string().url('Invalid URL'),
  serviceOwners: yup.array<string>(),
  serviceHosts: yup.array(b2bServiceHostUpdateSchema).required('Required'),
  operations: yup.array(b2bOperationUpdateSchema).required('Required'),
  pendingOperations: yup.array(b2bPendingOperationUpdateSchema),
  serviceHeaders: yup.array(b2bServiceHeadersCreateSchema),
  outboundEvents: yup.array(b2bOutboundEventsUpdateSchema),
  entitlementConfigs: b2bEntitlementConfigsUpdateSchema,
  contextRoot: yup.string(),
  deploymentRegion: yup.string().required('Required'),
  autoDC: yup.boolean(),
  healthEndpoint:yup.string().when('autoDC',{
    is:(autoDC:boolean)=>(autoDC===true),
    then:yup.string().required('Required'),
    otherwise:yup.string().notRequired()
  }),
  changeDetails: yup.object().shape({
    changeRequestNumber: yup.string(),
    changeJustification: yup.string()
  })
});

export const b2bServiceEditOperationsSchema: yup.ObjectSchema<yup.Shape<B2BServiceUpdate, any>> = yup.object().shape<B2BServiceUpdate>({
  name: yup.string().required('Required'),
  description: yup.string(),
  documentationUrl: yup.string().url('Invalid URL'),
  serviceOwners: yup.array<string>(),
  serviceHosts: yup.array(b2bServiceHostUpdateSchema),
  operations: yup.array(b2bOperationUpdateSchema).required('Required'),
  pendingOperations: yup.array(b2bPendingOperationUpdateSchema),
  serviceHeaders: yup.array(b2bServiceHeadersCreateSchema),
  contextRoot: yup.string(),
  deploymentRegion: yup.string().required('Required')
});

export const b2bEditServiceUpdateSchema: yup.ObjectSchema<yup.Shape<B2BServiceUpdate, any>> = yup.object().shape<B2BServiceUpdate>({
  name: yup.string().required('Required'),
  description: yup.string(),
  documentationUrl: yup.string().url('Invalid URL'),
  serviceOwners: yup.array<string>(),
  serviceHosts: yup.array(b2bServiceHostUpdateSchema),
  operations: yup.array(b2bOperationUpdateSchema),
  pendingOperations: yup.array(b2bPendingOperationUpdateSchema),
  serviceHeaders: yup.array(b2bServiceHeadersCreateSchema),
  contextRoot: yup.string(),
  deploymentRegion: yup.string().required('Required'),
  autoDC: yup.boolean(),
  healthEndpoint:yup.string().when('autoDC',{
    is:(autoDC:boolean)=>(autoDC===true),
    then:yup.string().required('Required'),
    otherwise:yup.string().notRequired()
  })
});
export const b2bApprovalRequestUpdateSchema: yup.ObjectSchema<yup.Shape<B2BApprovalRequestUpdate, any>> = yup.object().shape<B2BApprovalRequestUpdate>({
  saNumber: yup.string().required('Required').matches(/^[Ss][Aa]-[0-9]+$/, 'Invalid SA Number. Please follow this format: SA-123456'),
  businessJustification: yup.string().required('Required'),
  requestStatus: yup.mixed().oneOf(approvalRequestStatus),
  reason:yup.string(),
  sendBackMessage:yup.string().when('requestStatus', (requestStatus: string) => (requestStatus === 'OPEN') ? yup.string().required('Required'): yup.string().notRequired())
});
import { Chip, FormHelperText, Grid, IconButton, MenuItem, Select, ThemeProvider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { AddBox, OpenInNew, Search } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import { DataTableMultiTypeField } from '../../../../components/data-table-fields/data-table-multi-type-field';
import { MultiTypeInput } from '../../../../components/inputs/multi-type-input';
import { FormErrors } from '../../../../hooks/use-form.hook';
import { dataTypes, MultiDataType, MultiType } from '../../../../model/multi-type';
import { WHITELIST_VISA_EMAILS_FOR_ROLES } from '../../constants';
import { useApplicationConfigs } from '../../hooks/use-application-configs';
import { ApplicationConfigBase, B2C2ApplicationConfigBase } from '../../model/application-create';
import { ApplicationConfigUpdate, B2C2ApplicationConfigUpdate, } from '../../model/application-update';
import { b2c2ApplicationConfigCreateSchema } from '../../model/b2c2-application-validations';
import WhitelistEmailsDialog from '../app-dialog/whitelist-emails-dialog';
import { AppEditDetailsPlaceHolder } from './app-edit-details-placeholder';
import { appEditStyles, searchStyle } from './app-edit.styles';
import { Helmet } from 'react-helmet';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { ColumnDef, SortingState, Table, getCoreRowModel, getFilteredRowModel, getSortedRowModel } from '@tanstack/table-core';
import { useReactTable } from '@tanstack/react-table';
import { TableCore } from '../table-abstraction/table-core';
import { HeaderSearchAddButton } from '../table-abstraction/filter-implementation/header-search-addButton';
import { TableFormEditDeleteAction } from '../table-abstraction/table-cells/b2c2-apps/table-form-edit-delete-action';
import { ValidationError } from 'yup';
import { B2C2AppsAddConfig } from '../table-abstraction/table-rows/b2c2-apps/add-config-row';
import { ConfigValueTypeSelect } from '../table-abstraction/table-cells/b2c2-apps/app-configs/config-value-type-select';

export type Row = B2C2ApplicationConfigUpdate & { tableData?: any };

const dataTypesMap = dataTypes.reduce<Record<string, string>>(
    (acc, dataCenter) => ({ ...acc, [dataCenter]: dataCenter }),
    {}
);


export interface B2C2AppEditConfigsProps {
    configs: B2C2ApplicationConfigUpdate[];
    onChange: (configs: B2C2ApplicationConfigUpdate[]) => void;
    isCreate: boolean;
}

export const B2C2AppEditConfigs: React.FC<B2C2AppEditConfigsProps> = ({ configs, onChange, isCreate }) => {
    const styles = appEditStyles();
    const [errors, setErrors] = useState<FormErrors>({});
    const { configs: allowedConfigs, loading } = useApplicationConfigs();
    const [drawerOpen, setDrawerOpen] = useState<Boolean>(false);

    const handleCloseDrawer = useCallback(() => setDrawerOpen(false), []);
    const handleOpenDrawer = useCallback(() => setDrawerOpen(true), []);

    const [multiInputBoxOpen, setMultiInputBoxOpen] = useState<boolean>(false);
    const [editConfigValue, setEditConfigValue] = useState<B2C2ApplicationConfigUpdate | undefined>(undefined);
    const handleMultiInputBox = useCallback(() => {

        setMultiInputBoxOpen(() => !multiInputBoxOpen);
    }, [multiInputBoxOpen]);


    const updateAppConfig = useCallback((rowData: any) => {
        setEditConfigValue(rowData);
        setMultiInputBoxOpen(() => !multiInputBoxOpen);
    }, []);

    const allowAdd = configs.length < allowedConfigs.length;

    const usedConfigsMap = useMemo(() => configs.reduce<Map<string, B2C2ApplicationConfigBase>>(
        (result, config) => result.set(config.configKey, config),
        new Map<string, B2C2ApplicationConfigBase>()
    ), [configs]);

    // const handleConfigUpdate = useCallback((config: ApplicationConfigUpdate) => {
    //     let newConfigs = configs;
    //     let objIndex = newConfigs.findIndex((obj => obj.key === WHITELIST_VISA_EMAILS_FOR_ROLES));
    //     if (objIndex >= 0) {
    //         newConfigs[objIndex].configValue = config.value;
    //     }

    //     setErrors({ ['field']: "error.message" });

    //     onChange(newConfigs)
    // }, [configs]);

    const WhiteListEmailGrid = useCallback((rowData: Row) => {
        const error = errors["approvedEmailIds"];

        return (
            <>
                <Grid container className={styles.chipContainerGrid} alignItems={!!rowData.configValue ? 'flex-start' : 'center'}>
                    <Grid item xs={10} sm={10} className={styles.chipItemGrid}>
                        {(!!rowData.configValue && Array.isArray(rowData.configValue)) ?
                            rowData.configValue.map((email) => <Chip key={email} className={styles.chipStyle} variant="outlined"
                                label={email} />)
                            : <Typography className={styles.expandText}>Expand to add roles</Typography>
                        }
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <IconButton onClick={() => updateAppConfig(rowData)}><OpenInNew /></IconButton>

                    </Grid>
                </Grid>
                {!!errors && (
                    <>
                        <br />
                        <Typography variant="caption" color="error">{error}</Typography>
                    </>
                )}
            </>);
    }, [updateAppConfig, editConfigValue, errors, styles]);

    const filteredConfigs = useMemo(() => !allowedConfigs
        ? []
        : allowedConfigs.filter(config => !usedConfigsMap.get(config.configKey)),
        [allowedConfigs, usedConfigsMap]);

    const configsMap = useMemo<ReadonlyMap<string, B2C2ApplicationConfigBase>>(() => (
        allowedConfigs.reduce<Map<string, B2C2ApplicationConfigBase>>(
            (result, config) => result.set(config.configKey, config),
            new Map<string, B2C2ApplicationConfigBase>()
        )
    ), [allowedConfigs]);

    const rows = useMemo<Row[]>(() => configs.map(config => ({ ...config })), [configs]);

    const columns = useMemo<ColumnDef<Row>[]>(() => ([
        {
            header: 'Key',
            accessorKey: 'configKey',
        },
        {
            header: 'Value',
            accessorKey: 'configValue',
            cell: ConfigValueTypeSelect
        },
        {
            header: "Action",
            cell: TableFormEditDeleteAction,
            enableSorting: false,
            accessorKey: "id"
        }
    ]), []);

    const [sort, setSort] = useState<SortingState>([]);
    const [search, setSearch] = useState<string>("");
    const [currentEditingRow, setCurrentEditingRow] = useState<string[]>([]);
    const [dirtyRow, setDirtyRow] = useState<Row | null>(() => null);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const table = useReactTable({
        columns: columns,
        data: rows,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (e) => String(e.id),
        // debugTable: true,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSort,
        enableSorting: true,
        state: {
            sorting: sort,
            globalFilter: search
        },
        meta: {
            data: filteredConfigs,
            updateEditingRowState: (id: string) => {
                setCurrentEditingRow([id]);
                setDirtyRow(() => ({ ...rows.filter(e => String(e.id) === id)[0] }));
            },
            currentEditingRowId: currentEditingRow,
            revertingToStaticState: () => {
                setCurrentEditingRow([]);
                setDirtyRow(null);
            },
            setEditCelldata: (id, newData, columnid) => {
                setDirtyRow((oldState) => {
                    if (!oldState) {
                        return oldState;
                    }
                    return {
                        ...oldState,
                        [columnid]: newData
                    }

                }
                );
            },
            dirtyRow: dirtyRow,
            handleUpdate: (id: string) => {
                onChange(configs.map(config => {
                    return config.id !== dirtyRow?.id ? config : {
                        id: dirtyRow.id,
                        configKey: dirtyRow?.configKey,
                        dataType: dirtyRow?.dataType,
                        configValue: dirtyRow?.configValue==="true",
                       
                    }
                }));
                setCurrentEditingRow([]);
                setDirtyRow(null);
            },
            deleteRow: (id: string) => {
                onChange(configs!.filter(config => String(config.id) !== id));
            },
            isValid: (table: Table<any>) => {
                return b2c2ApplicationConfigCreateSchema.isValidSync({
                    id: table.options.meta?.dirtyRow?.id,
                    configKey: table.options.meta?.dirtyRow?.configKey,
                    dataType: table.options.meta?.dirtyRow?.dataType,
                    configValue: table.options.meta?.dirtyRow?.configValue,
                })
            },
            isIndividualCellValid: (columnName: string, newData: any) => {
                try {
                    b2c2ApplicationConfigCreateSchema.validateSyncAt(columnName, { [columnName]: newData });
                    return "";
                } catch (e) {
                    return (e as ValidationError).message;
                }
            },
            isAdd: isAdd,
            addRowNode: B2C2AppsAddConfig,
            startAddMode: () => {
                setCurrentEditingRow(["new"]);
                setDirtyRow({
                    id: 0,
                    configKey: '',
                    dataType:'boolean',
                    configValue: '',
                    
                });
                setIsAdd(true);
            }, stopAddMode: () => {
                setCurrentEditingRow([]);
                setDirtyRow(null);
                setIsAdd(false);
            },
            handleAdd: () => {
                if (!dirtyRow) {
                    return;
                }
                const minId = configs.reduce((min, config) => Math.min(config.id, min), 0);
                onChange([
                    ...configs!,
                    {
                        id: minId - 1,
                        configKey: dirtyRow.configKey,
                        dataType: dirtyRow.dataType,
                        configValue: dirtyRow.configValue === "true",
                    },
                ]);
                setCurrentEditingRow([]);
                setDirtyRow(null);
                setIsAdd(false);
            }
        }
    });
    return (
        <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Helmet>
                    <title>
                        {isCreate ? "App Configs (4 of 6) | Add new B2C Application | Visa Prototype Validation Platform" : `App Configs (4 of 6) | Edit B2C Application | Visa Prototype Validation Platform`}
                    </title>
                </Helmet>
                <TypographyHighLight headerTitle={isCreate ? "App Configs (4 of 6) | Add new B2C Application" : `App Configs (4 of 6) | Edit B2C Application`} variant='h5' />
                <Typography color="primary" className={styles.instruction1}>
                    Specify your app configurations
                </Typography>
                <Typography component="h2" className={styles.smallTitle}>
                    This is an optional step
                </Typography>
                {loading ? (<AppEditDetailsPlaceHolder />) : <>
                    <HeaderSearchAddButton table={table} heading='App configurations' search={search} setSearch={setSearch} addButtonFunction={table.options.meta?.startAddMode ? table.options.meta.startAddMode : () => { }}></HeaderSearchAddButton>
                    <TableCore caption='App configurations' table={table}></TableCore></>}
            </Grid>
        </Grid>
    );
};
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import React, { useState } from 'react';
import { statusListCardStyles } from './list-card.styles';
import Moment from "moment";
import { Grid, TableContainer, Typography } from '@mui/material';
import Icon from '@visa/vds/icon';
import ButtonIcon from '@visa/vds/button-icon';
import ButtonText from '@visa/vds/button-text';
import Select from '@visa/vds/select';
import { vdsStyleOverrides } from '../../../../vds-overrides.styles';
import ScreenReader from '@visa/vds/screen-reader';
export type Status = 'active' | 'stopped' | 'disabled';

export interface B2C2ProductEntry {
  productId: string;
  name: string;
  description: string;
  documentationUrl: string;
  dateModified: string;
  statusType: string;
}

export interface Headers {
  name?: string;
  productId?: string;
  description?: string;
  documentationUrl?: string;
  dateModified?: string;
  statusType?: string;
  action?: string;
}

export interface ActionItem {
  title: string;
  action: () => void;
}

export interface StatusListCardProps {
  title: string;
  entries: B2C2ProductEntry[];
  ownedEntries: B2C2ProductEntry[];
  topAction?: ActionItem;
  bottomAction?: ActionItem;
  onEntryClick?: (serviceGuid: string) => void;
  className?: string;
  headers?: Headers;
}

export const B2C2ProductStatusListCard: React.FC<StatusListCardProps> = ({ title, entries, ownedEntries, topAction, bottomAction, onEntryClick, className, headers, ...rest }) => {
  const styles = statusListCardStyles();
  const vdsStyles= vdsStyleOverrides();
  const heads: Headers = {
    name: 'Product name',
    productId: 'Product Id',
    documentationUrl: 'Documentation URL',
    dateModified: 'Date modified',
    statusType: 'Status',
    action: 'Action',
    ...headers,
  };
  const [displayAll, setDisplayAll] = useState(false);
  const handleChange = (event: any) => {
    setDisplayAll(event.target.value === 'all');
  };
  return (
    <Card>
      <Grid container className={styles.cardHeader} direction={"row"} alignItems={"center"} rowGap={2}>
        <Grid item xs={12} lg={8}>
          <Typography variant='h5' component={"h2"}>{title}</Typography>
        </Grid>
        {topAction && <Grid item xs={12} lg={4}>
          <Grid container rowGap={2}>
            <Grid item xs={12} lg={8}>
                <Select
                name="product_filter"
                label="Product filter"
                onChange={handleChange}
                defaultValue={"my"}
                className={vdsStyles.selectFullWidth}
              >
                  <option value="all">All products</option>
                  <option value="my">My products</option>
                </Select>
            </Grid>
            <Grid item xs={12} lg={4} justifyContent={"center"} alignItems={"center"} style={{display: 'flex'}}>
              <ButtonText
                onClick={topAction.action} isFullWidth>
                {topAction.title}
              </ButtonText>
            </Grid>

          </Grid>

        </Grid>}

      </Grid>
      <CardContent className={styles.content}>
        <TableContainer style={{ minWidth: "100%", width: "80vw", overflowX: "scroll" }} tabIndex={0}>
          <Table>
          <ScreenReader tag="caption">B2C Products : Product name in column 1 and details in the next 4 columns.actions for each product are available in column 6</ScreenReader>
            <TableHead>
              <TableRow>
                <TableCell component={"th"} scope='col' style={{ minWidth: "200px" }}><b>{heads.name}</b></TableCell>
                <TableCell component={"th"} scope='col' style={{ minWidth: "400px" }}><b>{heads.productId}</b></TableCell>
                <TableCell component={"th"} scope='col' style={{ minWidth: "500px" }}><b>{heads.documentationUrl}</b></TableCell>
                <TableCell component={"th"} scope='col' style={{ minWidth: "200px" }}><b>{heads.dateModified}</b></TableCell>
                <TableCell component={"th"} scope='col' style={{ minWidth: "200px" }}><b>{heads.statusType}</b></TableCell>
                <TableCell component={"th"} scope='col' style={{ minWidth: "100px" }}><b>{heads.action}</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(displayAll ? entries : ownedEntries).map((entry) => (
                <TableRow
                  hover
                  key={entry.productId}>
                  <TableCell>{entry.name}</TableCell>
                  <TableCell>{entry.productId}</TableCell>
                  <TableCell>{entry.documentationUrl}</TableCell>
                  <TableCell>{Moment(entry.dateModified).format('MMM D YYYY')}</TableCell>
                  <TableCell>{entry.statusType}</TableCell>
                  <TableCell>
                    <ButtonIcon ariaLabel={"Click for action on application " + entry.name} iconType="light-tiny" onClick={onEntryClick ? () => onEntryClick(entry.productId) : undefined} role="link" >
                      <Icon name="password-show" resolution="tiny" />
                    </ButtonIcon>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={styles.actions}>
        {bottomAction && (
          <ButtonText
            colorScheme="secondary"
            onClick={bottomAction.action}>
            {bottomAction.title}
          </ButtonText>
        )}
      </CardActions>
    </Card>
  );
};

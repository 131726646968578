import { Identity } from '../../../model/entities';
import { ApplicationCommunicationCreate, ApplicationCommunicationTemplateCreate, ApplicationConfigCreate, ApplicationConsumerCreate, ApplicationRoleCreate, ApplicationServiceConfigCreate, B2C2ApplicationCommunicationTemplateCreate, B2C2ApplicationConfigCreate } from './application-create';
import {B2C2ApplicationHeaders, B2C2ApplicationB2C2BArgs} from "./application";

export interface ApplicationConsumerUpdate extends Identity, ApplicationConsumerCreate {
}

export interface ApplicationRoleUpdate extends Identity, ApplicationRoleCreate {
  consumerUsers: ApplicationConsumerUpdate[];
}

export interface ApplicationServiceConfigUpdate extends Identity, ApplicationServiceConfigCreate {
}

export interface ApplicationConfigUpdate extends Identity, ApplicationConfigCreate {
}

export interface B2C2ApplicationConfigUpdate extends Identity, B2C2ApplicationConfigCreate {
}

export interface ApplicationCommunicationTemplateUpdate extends Identity, ApplicationCommunicationTemplateCreate {
}

export interface ApplicationCommunicationTemplatesUpdate {
  accountActivation: ApplicationCommunicationTemplateUpdate;
  accountActivationSuccess: ApplicationCommunicationTemplateUpdate;
  passwordExpiry: ApplicationCommunicationTemplateUpdate;
  resetPassword: ApplicationCommunicationTemplateUpdate;
  accountDeactivation: ApplicationCommunicationTemplateUpdate;
  forgotUsername: ApplicationCommunicationTemplateUpdate;
  changePassword: ApplicationCommunicationTemplateUpdate;
}

export interface ApplicationCommunicationUpdate extends ApplicationCommunicationCreate {
  templates: ApplicationCommunicationTemplatesUpdate;
}

export interface B2C2ApplicationUpdate extends B2C2ApplicationDetailsUpdate {
  allowedEndpoints?: string[];
  appProductHeaders?: B2C2ApplicationHeaders[];
  owners?: string[];
  configs?: B2C2ApplicationConfigUpdate[]
  whitelistedIdentities?: string[];
  fromId: string;
  fromDisplayName: string;
  templates?: B2C2ApplicationCommunicationTemplateCreate[];
  b2c2bArgs?: B2C2ApplicationB2C2BArgs;
}

export interface B2C2ApplicationDetailsUpdate {
  name?: string;
  description?: string;
  namespace?: string;
  supportGdl: string;
  status?: string;
}

export function cloneB2C2ApplicationUpdate(details: B2C2ApplicationUpdate): B2C2ApplicationUpdate {
  return {
    name: details.name,
    description: details.description,
    namespace: details.namespace,
    allowedEndpoints: details.allowedEndpoints,
    owners: details.owners,
    appProductHeaders: details.appProductHeaders,
    supportGdl: details.supportGdl,
    fromId: details.fromId,
    fromDisplayName: details.fromDisplayName,
    b2c2bArgs: details.b2c2bArgs,
    whitelistedIdentities: details.whitelistedIdentities
  };
}

export interface B2BReviewRequestUpdate {
  requestGuid: string,
  saNumber: string,
  businessJustification: string
}

export interface B2BAppReviewRequest {
  requestGuid: string,
  saNumber: string,
  businessJustification: string
}
export interface CardData {
    expanded: boolean;
    title: string;
    description: string;
    APIs: string[];
}

export interface RowData {
    roleName: string;
    description: string;
}

export const rows: RowData[] = [
    { roleName: 'Application Developer', description: 'Enables to create an app or project on VPVP and use already existing services. ' },
    { roleName: 'Service Developer', description: 'Enables to onboard new microservice or add more APIs to existing service. ' },
    { roleName: 'PCSF Developer', description: 'Enables access to PCSF admin portal for onboarding ' },
    { roleName: 'Super Developer', description: 'Only for internal Innovation Developers. ' },
];

export const cardDataInitial: CardData[] = [
    {
        expanded: false,
        title: 'Visa platform',
        description: 'VPVP has integration with Visa Developer Platform, Cyber source APIs, AI workbench platform, Data Platform.  ',
        APIs: ['Visa Developer Platform',
            'Cyber source APIs',
            'AI workbench platform',
            'Data Platform'
        ]
    },
    {
        expanded: false,
        title: 'Microservices',
        description: 'VPVP has set of reusable microservices Cybersource Payments, Tokenization, Card management which can be used as plug and play for any usecase. ',
        APIs: ['Cybersource Payments',
            'Tokenization',
            'Card Services'
        ]
    },
    {
        expanded: false,
        title: '3rd party services',
        description: 'VPVP has integration with 3rd party services like Twilio, Marqeta for SMS related capabilities and digital issuance capabilities. ',
        APIs: ['Twillio',
            'Marqeta'
        ]
    },
    {
        expanded: false,
        title: 'Enterprise capabilities',
        description: 'VPVP has integration with B2C-IAM platform, Kafka, Hazel cast, Rate limiter which can be easily integrated for new service development. ',
        APIs: ['B2C IAM platform',
            'Kafka',
            'Hazel cast',
            'Rate limiter'
        ]
    }
];
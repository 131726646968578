import { Divider, Grid, Theme, Typography, Dialog } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton, Button, Box, useMediaQuery, useTheme } from "@mui/material";
import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { string } from "yup";
import { ExportToJsonButton } from "../../../../components/buttons/export-to-json-button";
import { logger } from "../../../../services/logger.service";
import { buttonStyles } from "../../../../styles/button.styles";
import ProductDialog from "../product-dialog/product-dialog";
import { useProductPreviewStyles } from "../product-view/product-preview.styles";
import { ProductPreviewDetails } from "./product-preview-details";
import { ProductPreviewHosts } from "./product-preview-hosts";
import { ProductPreviewHeaders } from "./product-preview-headers";
import { ProductPreviewEndpoints } from "./product-preview-endpoints";
import { ProductCreate } from "../../model/product-create";
import { jsonProductService } from "../../services/json.service";
import { B2C2ReviewRequestUpdate } from "../../model/product-update";
import { createB2C2ReviewRequestAsync } from "../../services/network-services.products";
import { useB2C2ApprovalRequests } from "../../hooks/use-b2c2-approval-requests";
import { useB2C2ApprovalRequest } from "../../hooks/use-b2c2-approval-request";
import { ProductPreviewB2C2BArgs } from "./product-preview-b2c2b-args";

import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { Helmet } from 'react-helmet';

export interface ProductPreviewProps {
  product?: ProductCreate | null;
  productActive?: ProductCreate | null;
  isEdit?: boolean;
  editDetailsNav?: () => void;
  editB2C2BArgsNav?: () => void;
  editOperationsNav?: () => void;
  editHostsNav?: () => void;
  editHeadersNav?: () => void;
  editConfigsNav?: () => void;
  isProductOwner?: boolean;
}

export const ProductPreview: React.FC<ProductPreviewProps> = ({
  product,
  productActive,
  isProductOwner,
  isEdit = false,
  editDetailsNav,
  editB2C2BArgsNav,
  editOperationsNav,
  editHostsNav,
  editConfigsNav,
  editHeadersNav,
}) => {
  const styles = useProductPreviewStyles();
  const [dialog, setDialog] = React.useState(0);
  const navigate = useNavigate();
  const btnStyles = buttonStyles();
  var alignmentValue = "PENDING";
  var requestId = "";
  if (product !== undefined && product !== null) {
    requestId = product.requestId;
  } else if (productActive !== undefined && productActive !== null) {
    requestId = productActive.requestId;
    alignmentValue = "ACTIVE";
  }
  const [alignment, setAlignment] = React.useState(alignmentValue);
  const { refreshApprovals } = useB2C2ApprovalRequests();
  const { b2c2ApprovalRequest } = useB2C2ApprovalRequest(requestId);

  const getJsonContentToExport = useCallback(
    () => (product ? jsonProductService.exportToJson(product) : "{}"),
    [product]
  );

  const handleDialog = useCallback((index: number) => {
    setDialog(index);
  }, []);

  const handleCancel = useCallback(() => {
    navigate("/b2c/products");
  }, [navigate]);

  const b2c2RviewRequestUpdate = useMemo<B2C2ReviewRequestUpdate>(
    () => ({
      requestId: "",
      saNumber: "",
      businessJustification: "",
    }),
    []
  );

  const handleSubmitReview = useCallback(
    async (sanumber: string, businessJustification: string) => {
      if (!b2c2RviewRequestUpdate) return;
      if (!!product) {
        b2c2RviewRequestUpdate.businessJustification = businessJustification;
        b2c2RviewRequestUpdate.saNumber = sanumber;
        b2c2RviewRequestUpdate.requestId = product.requestId;
        const success = await createB2C2ReviewRequestAsync(
          product.productId,
          b2c2RviewRequestUpdate
        );
        if (success) {
          refreshApprovals();
          navigate("/b2c/products/" + product?.productId);
        } else {
          logger.error(
            "Unable to show approvals page",
            "Update Approval Request unsuccessful"
          );
        }
      }
      navigate("/b2c/products");
    },
    [
      product,
      b2c2RviewRequestUpdate,
      createB2C2ReviewRequestAsync,
      handleDialog,
      navigate,
    ]
  );

  const handleEditComplete = useCallback(() => {
    navigate("/b2c/products/" + product?.productId);
  }, [navigate]);

  const handleChangeProductPreview = (
    event: any,
    newAlignment: React.SetStateAction<string>
  ) => {
    if (newAlignment !== null) setAlignment(newAlignment);
  };

  const handleApprovalDialog = useCallback(
    async () => handleDialog(1),
    [handleDialog]
  );

  const handleSubmitButton = () => {
    if (
      alignment == "ACTIVE" ||
      typeof product == "undefined" ||
      b2c2ApprovalRequest?.status !== "OPEN" ||
      !isProductOwner
    ) {
      return true;
    } else {
      return false;
    }
  };
  const theme: Theme = useTheme();
  const setToggle = handleSubmitButton();
  const media: boolean = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Helmet>
            <title>Product Details | Visa Prototype Validation Platform</title>
          </Helmet>
          <TypographyHighLight headerTitle={<span className={styles.title}>Product Details</span>} />
          {dialog ? (
            <ProductDialog
              handleDialog={handleDialog}
              handleCancel={handleCancel}
              handleSubmitReview={handleSubmitReview}
              handleEditComplete={handleEditComplete}
              dialog={dialog}
              prefilledSA={"SLE-SA-"}
              handleSubmit={function (): Promise<void> {
                throw new Error("Function not implemented.");
              }}
            />
          ) : (
            <div />
          )}
      <Grid container direction={media ? "column" : "row"} justifyContent="space-between">
        <Grid item>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChangeProductPreview}
            >
              <ToggleButton
                id="PENDING"
                value="PENDING"
                disabled={product == undefined || product == null}
              >
                PENDING
              </ToggleButton>
              <ToggleButton
                id="ACTIVE"
                value="ACTIVE"
                disabled={productActive == undefined || productActive == null}
              >
                ACTIVE
              </ToggleButton>
            </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Button
              type="submit"
              variant="contained"
              onClick={handleApprovalDialog}
              className={clsx(btnStyles.btnBlue2x)}
              disabled={setToggle}
            >
              Submit for Review
            </Button>
            <ExportToJsonButton
              filename="product.json"
              getJsonContent={getJsonContentToExport}
              mode="textIcon"
            />
          </Grid>
        </Grid>
      </Grid>
 
      <br />
      <br />
      <Divider className={styles.dividerB2C} />

      {alignment === "ACTIVE" ? (
        <>
          {productActive !== undefined ? (
            <div>
              <ProductPreviewDetails details={productActive} />
              <Divider className={styles.dividerB2C} />
              <div>
                {isEdit ? (
                  <Button
                    onClick={editB2C2BArgsNav}
                    variant="outlined"
                    color="secondary"
                    className={styles.editButton}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                ) : (
                  <div />
                )}
                <Typography className={styles.title} component={"h2"}>B2C2B Args</Typography>
                <ProductPreviewB2C2BArgs
                  productB2C2BArgs={productActive?.productB2C2BArgs}
                />
                <Divider className={styles.dividerB2C} />
              </div>

              <div>
                {isEdit ? (
                  <Button
                    onClick={editHostsNav}
                    variant="outlined"
                    color="secondary"
                    className={styles.editButton}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                ) : (
                  <div />
                )}
                <Typography className={styles.title} component={"h2"}>Host URL</Typography>
                <ProductPreviewHosts hosts={productActive?.productHosts} />
                <Divider className={styles.dividerB2C} />
              </div>

              <div>
                {isEdit ? (
                  <Button
                    onClick={editOperationsNav}
                    variant="outlined"
                    color="secondary"
                    className={styles.editButton}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                ) : (
                  <div />
                )}
                <Typography className={styles.title} component={"h2"}>Endpoints</Typography>
                <ProductPreviewEndpoints
                  endpoints={productActive?.productEndpoints}
                />
                <Divider className={styles.dividerB2C} />
              </div>

              <div>
                {isEdit ? (
                  <Button
                    onClick={editHeadersNav}
                    variant="outlined"
                    color="secondary"
                    className={styles.editButton}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                ) : (
                  <div />
                )}
                <Typography className={styles.title} component={"h2"}>
                  Allowed headers
                </Typography>
                <ProductPreviewHeaders
                  headers={productActive?.productHeaders}
                />
                <Divider className={styles.dividerB2C} />
              </div>
            </div>
          ) : (
            <div>
              Active version is not available for this product
              <Divider className={styles.divider2x} />
            </div>
          )}
        </>
      ) : (
        <>
          {product !== undefined ? (
            <div>
              <ProductPreviewDetails details={product} />
              <Divider className={styles.dividerB2C} />
              <div>
                {isEdit ? (
                  <Button
                    onClick={editB2C2BArgsNav}
                    variant="outlined"
                    color="secondary"
                    className={styles.editButton}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                ) : (
                  <div />
                )}
                <Typography className={styles.title} component={"h2"}>B2C2B Args</Typography>
                <ProductPreviewB2C2BArgs
                  productB2C2BArgs={product?.productB2C2BArgs}
                />
                <Divider className={styles.dividerB2C} />
              </div>

              <div>
                {isEdit ? (
                  <Button
                    onClick={editHostsNav}
                    variant="outlined"
                    color="secondary"
                    className={styles.editButton}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                ) : (
                  <div />
                )}
                <Typography className={styles.title} component={"h2"}>Host URL</Typography>
                <ProductPreviewHosts hosts={product?.productHosts} />
                <Divider className={styles.dividerB2C} />
              </div>

              <div>
                {isEdit ? (
                  <Button
                    onClick={editOperationsNav}
                    variant="outlined"
                    color="secondary"
                    className={styles.editButton}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                ) : (
                  <div />
                )}
                <Typography className={styles.title} component={"h2"}>Endpoints</Typography>
                <ProductPreviewEndpoints
                  endpoints={product?.productEndpoints}
                />
                <Divider className={styles.dividerB2C} />
              </div>

              <div>
                {isEdit ? (
                  <Button
                    onClick={editHeadersNav}
                    variant="outlined"
                    color="secondary"
                    className={styles.editButton}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                ) : (
                  <div />
                )}
                <Typography className={styles.title} component={"h2"}>
                  Allowed headers
                </Typography>
                <ProductPreviewHeaders headers={product?.productHeaders} />
                <Divider className={styles.dividerB2C} />
              </div>
            </div>
          ) : (
            <div>
              Pending version is not available for this product
              <Divider className={styles.divider2x} />
            </div>
          )}
        </>
      )}
    </Grid>
  );
};

import { config } from '../../../config';
import { networkSrv } from '../../../services/network.service';
import { B2BApprovalRequest, B2BServiceReviewRequest } from '../model/service';
import { B2BServiceCreate } from '../model/service-create';
import { B2BService } from './../model/service';
import { B2BApprovalRequestUpdate, B2BReviewRequestUpdate, B2BServiceUpdate } from './../model/service-update';
import { B2BApprovalRequestRS, B2BApprovalRequestUpdateRQ, B2BReviewRequestRS, B2BReviewRequestUpdateRQ, B2BServiceRQ, B2BServiceRS, B2BServiceUpdateRQ } from './dtos';
import { mapApprovalRequestUpdate, mapB2BApprovalRequest, mapB2BReviewRequest, mapB2BService, mapB2BServiceCreate, mapB2BServiceUpdate, mapReviewRequestUpdate } from './model-maps';

export async function getB2BServicesAsync(): Promise<B2BService[]> {
  const b2bServicesRS = await networkSrv.getAsync<B2BServiceRS[]>(`${config.urls.b2bServices}?expand=true&pending=false`);
  return b2bServicesRS.map(mapB2BService);
}

export async function getB2BServicesPendingAsync(): Promise<B2BService[]> {
  const b2bServicesRS = await networkSrv.getAsync<B2BServiceRS[]>(`${config.urls.b2bServices}?pending=true&expand=true`);
  return b2bServicesRS.map(mapB2BService);
}

export async function getB2BServicesExpandTrueAsync(): Promise<B2BService[]> {
  const b2bServicesRS = await networkSrv.getAsync<B2BServiceRS[]>(`${config.urls.b2bServices}?expand=true&pending=false`);
  return b2bServicesRS.map(mapB2BService);
}

export async function getB2BServicesPendingExpandTrueAsync(): Promise<B2BService[]> {
  const b2bServicesRS = await networkSrv.getAsync<B2BServiceRS[]>(`${config.urls.b2bServices}?pending=true&expand=true`);
  return b2bServicesRS.map(mapB2BService);
}

export async function createB2BServiceAsync(b2bService: B2BServiceCreate): Promise<B2BService> {
  const b2bServiceRQ = mapB2BServiceCreate(b2bService);
  Object.keys(b2bServiceRQ).forEach((k) => (k!=='auto_dc' && !(b2bServiceRQ as any)[k] && (b2bServiceRQ as any)[k] !== undefined) && delete (b2bServiceRQ as any)[k]);
  const b2bServiceRS = await networkSrv.postAsync<B2BServiceRQ, B2BServiceRS>(config.urls.b2bServices, b2bServiceRQ);
  return mapB2BService(b2bServiceRS);
}

export function deleteB2BServiceAsync(serviceGuid: string|undefined): Promise<void> {
  return networkSrv.deleteAsync(`${config.urls.b2bServices}/${serviceGuid}`);
}

export async function updateB2BServiceAsync(serviceGuid: string, b2bService: B2BServiceUpdate): Promise<B2BService> {
  const b2bServiceRQ = mapB2BServiceUpdate(b2bService);
  const b2bServiceRS = await networkSrv.patchAsync<Partial<B2BServiceUpdateRQ>, B2BServiceRS>(`${config.urls.b2bServices}/${serviceGuid}`, b2bServiceRQ);
  return mapB2BService(b2bServiceRS);
}

export async function getB2BApprovalRequestsAsync(): Promise<B2BApprovalRequest[]> {
  const b2bApprovalRequestsRS = await networkSrv.getAsync<B2BApprovalRequestRS[]>(`${config.urls.b2bApprovalRequests}?expand=true`);
  return b2bApprovalRequestsRS.map(mapB2BApprovalRequest);
}

export async function updateB2BApprovalRequestAsync(requestGuid: string, b2bApprovalRequestUpdate: B2BApprovalRequestUpdate): Promise<B2BApprovalRequest> {
  const b2bApprovalRequestRQ = mapApprovalRequestUpdate(b2bApprovalRequestUpdate);
  const b2bApprovalRequestRS = await networkSrv.patchAsync<B2BApprovalRequestUpdateRQ, B2BApprovalRequestRS>(`${config.urls.b2bApprovalRequests}/${requestGuid}`, b2bApprovalRequestRQ);
  return mapB2BApprovalRequest(b2bApprovalRequestRS);
}

export async function createB2BReviewRequestAsync(serviceGuid: string, b2bReviewRequestUpdate: B2BReviewRequestUpdate): Promise<B2BServiceReviewRequest> {
  const b2bReviewRequestRQ = mapReviewRequestUpdate(b2bReviewRequestUpdate);
  const b2bReviewRequestRS = await networkSrv.postAsync<B2BReviewRequestUpdateRQ, B2BReviewRequestRS>(`${config.urls.b2bServices}/${serviceGuid}/review`, b2bReviewRequestRQ);
  return mapB2BReviewRequest(b2bReviewRequestRS);
}

export const networkServicesSrv = {
  getB2BServicesAsync,
  createB2BServiceAsync,
  deleteB2BServiceAsync,
  updateB2BServiceAsync,
  getB2BApprovalRequestsAsync,
  updateB2BApprovalRequestAsync,
  getB2BServicesPendingAsync,
  createB2BReviewRequestAsync,
  getB2BServicesExpandTrueAsync,
  getB2BServicesPendingExpandTrueAsync
}

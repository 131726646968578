import { Table } from "@tanstack/table-core"
import React, { ChangeEvent, useState } from "react"
import Select from '@visa/vds/select';
import { vdsStyleOverrides } from "../../../../../../../vds-overrides.styles";
import { Row } from "../../../../app-edit/b2c2-app-edit-headers";
import { ProductHeader } from "../../../../../../products/model/product";

export const B2cHeaderGuidSelectCell: React.FC<Table<Row>> = (table) => {
    const vdsStyles = vdsStyleOverrides();
    const [headerGuidError, setHeaderGuidError] = useState<string>("");
    const options: ProductHeader[] = table.options.meta?.data || [];

    return <Select
        name="Header_GUID"
        label="Header GUID *"
        value={table.options.meta?.dirtyRow?.headerId}
        className={vdsStyles.selectFullWidth}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            table.options?.meta?.setEditCelldata("", e.target.value, "headerId");
            setHeaderGuidError(table.options?.meta?.isIndividualCellValid("headerId", e.target.value) || "")
        }}
        onBlur={(e: ChangeEvent<HTMLSelectElement>) => {
            setHeaderGuidError(table.options?.meta?.isIndividualCellValid("headerId", e.target.value) || "")
        }}
        showErrorText={!!headerGuidError}
        errorText={headerGuidError}
        invalid={!!headerGuidError}
        aria-required
    >
        <option value="" disabled defaultChecked>Select Header</option>
        {options.sort().map(b2c2Header => (
            <option key={b2c2Header.headerId} value={b2c2Header.headerId}>{b2c2Header.headerId}</option>
        ))}
    </Select>
}
import { IconButton, SvgIcon } from '@material-ui/core';
import React, { useCallback } from 'react';
import { ReactComponent as Icon } from './icon-help.svg';
import { useNavigate } from 'react-router-dom';
import { DarkBlueTooltip } from '../../../../components/tooltips/tooltips.styles';

export const HelpMenu: React.FC = () => {
    const navigate = useNavigate();
    const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        navigate('/help');
    }, [navigate]);

    return (
        <div>
            <DarkBlueTooltip placement="bottom" title="Help">
            <IconButton
                aria-label="Help"
                role="link"
                aria-controls="user-menu"
                onClick={handleMenu}
                color="inherit"
            >
                <SvgIcon>
                    <Icon />
                </SvgIcon>
            </IconButton>
            </DarkBlueTooltip>
        </div>
    );
};
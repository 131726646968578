import React from 'react';
import { useRoutes } from 'react-router-dom';
import { B2BAppList } from './views/app-list/b2c2-app-list';
import { B2C2AppWizard } from './views/app-wizard/b2c2-app-wizard';
import { AddNew } from '../../views/add-new/add-new';
import { B2C2AppApprovalRequests } from '../products/views/approvals/b2c2-app-approval-requests';
import { B2C2AppView } from './views/app-view/b2c2-app-view';
import { B2C2AppEdit } from './views/app-edit/b2c2-app-edit';

const routes: any[] = [
  { path: '/', element: <B2BAppList /> },
  { path: 'new', element: <B2C2AppWizard /> },
  { path: 'add',
    element: <AddNew
        title='Add a new B2C app'
        isApp={true}
        wizardNavLink='/b2c/apps/new'
        jsonType='b2cApp'
    /> },
  { path: ':applicationGuid', element: <B2C2AppView /> },
  { path: ':applicationGuid/edit', element: <B2C2AppEdit /> },
  { path: ':appId/approvals', element: <B2C2AppApprovalRequests /> },
  { path: '*', redirectTo: '/404' },
];

export const AppsRouter: React.FC = () => useRoutes(routes);

import { config } from '../../../config';
import { networkSrv } from '../../../services/network.service';
import { User } from '../model/user';
import { UserRS } from './dtos';
import { mapUser } from './model-maps';

export async function getUsersAsync(): Promise<User[]> {
  const usersRS = await networkSrv.getAsync<UserRS[]>(config.urls.user);
  return usersRS.map(mapUser);
}

export const networkUserSrv = {
  getUsersAsync,
}

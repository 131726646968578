import { Table } from "@tanstack/table-core"
import React, { ChangeEvent, useState } from "react"
import { Row } from "../../../../app-edit/b2c2-app-edit-identities"
import Select from '@visa/vds/select';
import { vdsStyleOverrides } from "../../../../../../../vds-overrides.styles";

export const TempTypeSelect: React.FC<Table<Row>> = (table) => {
    const [tempTypeError, setTempTypeError] = useState<string>("");
    const vdsStyles = vdsStyleOverrides();
    const TEMPLATE_TYPES: string[] = [
        'RESET_PASSWORD_SUCCESS',
        'CHANGE_PASSWORD_SUCCESS',
        'VERIFY_OTP',
        'RESET_PASSWORD_OTP',
        'CREATE_ACCOUNT',
        'CREATE_ACCOUNT_EXISTING',
        'DELETE_ACCOUNT'
    ];
    const templateTypesMaps = TEMPLATE_TYPES.reduce<Record<string, string>>(
        (acc, templateType) => ({ ...acc, [templateType]: templateType }),
        {}
    );

    return <Select
        name="temple_typeid"
        label="Template Type *"
        value={table.options.meta?.dirtyRow?.templateType}
        className={vdsStyles.selectFullWidth}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            table.options?.meta?.setEditCelldata("", e.target.value, "templateType");
            table.options?.meta?.setEditCelldata("",null, "commType");
            table.options?.meta?.setEditCelldata("",'',"expiryMinutes");
            table.options?.meta?.setEditCelldata("",'',"content");
            setTempTypeError(table.options?.meta?.isIndividualCellValid("templateType", e.target.value) || "")
        }}
        onBlur={(e: ChangeEvent<HTMLSelectElement>) => {
            setTempTypeError(table.options?.meta?.isIndividualCellValid("templateType", e.target.value) || "")
        }}
        showErrorText={!!tempTypeError}
        errorText={tempTypeError}
        invalid={!!tempTypeError}
        aria-required
        autoFocus
    >
        <option value="" disabled defaultChecked>Select Template Type</option>
        {Object.keys(templateTypesMaps).map(key => (
            <option key={key} value={key}>{templateTypesMaps[key]}</option>
        ))}
    </Select>
}
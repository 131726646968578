import React from 'react';
import { Grid, Card, CardContent, Container, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export const ProductDetailsPlaceHolder: React.FC = () => {
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Container maxWidth="sm">
                <Box pt={2}><Skeleton variant="rect" animation="wave" height={30} width="60%"/></Box>
                <Box pt={2}><Skeleton variant="rect" animation="wave" height={30} width="50%"/></Box>
                <Box pt={2}><Skeleton variant="rect" animation="wave" height={30} width="30%"/></Box>
                <Box pt={2}><Skeleton variant="rect" animation="wave" height={30}/></Box>
                <Box pt={2}><Skeleton variant="rect" animation="wave" height={30}/></Box>
              </Container>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

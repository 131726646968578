import { config } from "../../../config";
import { networkSrv } from "../../../services/network.service";
import { B2C2ApprovalRequest, B2C2ProductReviewRequest, Product, ProductB2C2BArgs, ProductDetails } from "../model/product";
import { ProductCreate } from "../model/product-create";
import { B2C2ApprovalRequestUpdate, B2C2ReviewRequestUpdate, ProductUpdate } from "../model/product-update";
import { B2C2ApprovalRequestRS, B2C2ApprovalRequestUpdateRQ, B2C2ReviewRequestRS, B2C2ReviewRequestUpdateRQ, ProductB2C2BArgsRQ, ProductDetailsRQ, ProductRQ, ProductRS } from "./dtos";
import { mapB2C2ApprovalRequest, mapB2C2ApprovalRequestUpdate, mapB2C2ReviewRequest, mapB2C2ReviewRequestUpdate, mapProduct, mapProductB2C2BArgs, mapProductCreate, mapProductDetails } from "./model-maps";

export async function getProductsAsync(): Promise<Product[]> {
  const productRS = await networkSrv.getAsync<ProductRS[]>(`${config.urls.products}?expand=true`);
  return productRS.map(mapProduct);
}

export async function getPendingProductsAsync(): Promise<Product[]> {
  const productRS = await networkSrv.getAsync<ProductRS[]>(`${config.urls.products}?expand=true&pending=true`);
  return productRS.map(mapProduct);
}

export async function createProductAsync(product: ProductCreate): Promise<Product> {
  if (product.description === '') {
    delete product.description;
  }
  if (product.documentationUrl === '') {
    delete product.documentationUrl;
  }
  if(!product.productHeaders){
    product.productHeaders=[]
  }
  const productRQ = mapProductCreate(product);
  const productRS = await networkSrv.postAsync<ProductRQ, ProductRS>(config.urls.products, productRQ);
  return mapProduct(productRS);
}

export function deleteProductAsync(id: string): Promise<void> {
  return networkSrv.deleteAsync(`${config.urls.products}/${id}`);
}

export async function updateProductDetailsAsync(id: string, productDetails: Partial<ProductDetails>): Promise<Product> {
  const serviceDetailsRQ = mapProductDetails(productDetails);
  const productRS = await networkSrv.patchAsync<Partial<ProductDetailsRQ>, ProductRS>(`${config.urls.products}/${id}`, serviceDetailsRQ);
  return mapProduct(productRS);
}

export async function updateProductB2C2BAsync(id: string, productB2C2BArgs: ProductB2C2BArgs): Promise<Product> {
  const productB2C2BArgsRQ = { "b2c2bArgs": mapProductB2C2BArgs(productB2C2BArgs) };
  const productRS = await networkSrv.patchAsync<Partial<ProductRQ>, ProductRS>(`${config.urls.products}/${id}`, productB2C2BArgsRQ);
  return mapProduct(productRS);
}

export async function getB2C2ApprovalRequestsAsync(): Promise<B2C2ApprovalRequest[]> {
  const b2c2ApprovalRequestsRS = await networkSrv.getAsync<B2C2ApprovalRequestRS[]>(`${config.urls.b2cApprovalRequests}?expand=true`);
  return b2c2ApprovalRequestsRS.map(mapB2C2ApprovalRequest);
}

export async function updateB2C2ApprovalRequestAsync(requestGuid: string, b2c2ApprovalRequestUpdate: B2C2ApprovalRequestUpdate): Promise<B2C2ApprovalRequest> {
  const b2c2ApprovalRequestRQ = mapB2C2ApprovalRequestUpdate(b2c2ApprovalRequestUpdate);
  const b2c2ApprovalRequestRS = await networkSrv.patchAsync<B2C2ApprovalRequestUpdateRQ, B2C2ApprovalRequestRS>(`${config.urls.b2cApprovalRequests}/${requestGuid}`, b2c2ApprovalRequestRQ);
  return mapB2C2ApprovalRequest(b2c2ApprovalRequestRS);
}

export async function createB2C2ReviewRequestAsync(productId: string, b2c2ReviewRequestUpdate: B2C2ReviewRequestUpdate): Promise<B2C2ProductReviewRequest> {
  const b2c2ReviewRequestRQ = mapB2C2ReviewRequestUpdate(b2c2ReviewRequestUpdate);
  const b2bReviewRequestRS = await networkSrv.postAsync<B2C2ReviewRequestUpdateRQ, B2C2ReviewRequestRS>(`${config.urls.products}/${productId}/review`, b2c2ReviewRequestRQ);
  return mapB2C2ReviewRequest(b2bReviewRequestRS);
}


export const networkServicesProducts = {
  getProductsAsync,
  getPendingProductsAsync,
  createProductAsync,
  deleteProductAsync,
  updateProductDetailsAsync,
  updateProductB2C2BAsync,
  getB2C2ApprovalRequestsAsync,
  createB2C2ReviewRequestAsync,
  updateB2C2ApprovalRequestAsync
}
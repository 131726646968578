import React, { useContext, useRef } from 'react';
import { Card, CardContent, CardHeader, Grid, Link, List, ListItemText, Typography } from '@material-ui/core';
import { helpStyles } from './help.styles';
import { config } from '../../config';
import { Helmet } from 'react-helmet';
import { ListItem } from '@mui/material';
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../state/skip-to-main-store';

export const Help: React.FC = () => {
    const styles = helpStyles();
    const ref = useRef<HTMLHeadingElement>(null);
    const context: LandmarkContextType = useContext(LandmarkContext);
    useLandmarkHook(ref, context[1]);
    useHighlightH1(ref);
    interface IQuestionInfo {
        question: string;
        url: string;
    }
    const questionInfo: Array<IQuestionInfo> = [
        {
            question: "What are the names and descriptions of all the admin roles?",
            url: config.externalUrls.visaWikiAdminRoles
        }, {
            question: "How do I request additional admin access?",
            url: config.externalUrls.visaWikiRequestRole
        }, {
            question: "How do I create a new app or a new service?",
            url: config.externalUrls.visaWikiCreateApp
        }, {
            question: "Where can I see information about all the services?",
            url: config.externalUrls.visaWikiAccessAppsAndServices
        }
    ]
    return (
        <main>
            <Helmet>
                <title>Help | Visa Prototype Validation Platform </title>
            </Helmet>
            <Grid container spacing={2} className={styles.hero} direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Grid>
                    <Card>
                        <CardHeader
                            title="Help"
                            className={styles.title}
                            titleTypographyProps={{ component: "h1", innerRef: ref, tabIndex: -1 }}
                            subheader="Answers to common questions"
                            subheaderTypographyProps={{ component: "h2" }} />
                        <CardContent>
                            <List component="ul" dense={false}>
                                {
                                    questionInfo.map((e: IQuestionInfo, index: number) =>
                                        <ListItem dense={true} key={index}>
                                            <ListItemText>
                                                <Typography className={styles.heading} component="div" >
                                                    <Link className={styles.link} href={e.url} target="_blank" aria-label={e.question + "(opens a new tab)"}>{e.question}</Link>
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    )
                                }
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </main>
    );
};
import { Table } from "@tanstack/table-core"
import React, { ChangeEvent, useState } from "react"
import { Row } from "../../../../app-edit/b2c2-app-edit-identities"
import Select from '@visa/vds/select';
import { vdsStyleOverrides } from "../../../../../../../vds-overrides.styles";

export const CommTypeSelect: React.FC<Table<Row>> = (table) => {
    const [commTypeError, setCommTypeError] = useState<string>("");
    const vdsStyles = vdsStyleOverrides();
    const COMM_TYPE: string[] = [
        'OTP',
        'LINK',
        'NONE'
    ];
    
    const commTypesMaps = ((table.options.meta?.dirtyRow?.templateType === 'VERIFY_OTP' || table.options.meta?.dirtyRow?.templateType === 'RESET_PASSWORD_OTP')
                            ? (COMM_TYPE.filter(COMM_TYPE => COMM_TYPE === 'OTP')): (COMM_TYPE.filter(COMM_TYPE => COMM_TYPE === 'NONE'))).reduce<Record<string, string>>(
        (acc, commType) => ({ ...acc, [commType]: commType }),
        {}
    );

    return <Select
        name="comm_typeid"
        label="Comm Type"
        value={table.options.meta?.dirtyRow?.commType || ""}
        className={vdsStyles.selectFullWidth}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            table.options?.meta?.setEditCelldata("", e.target.value, "commType");
            setCommTypeError(table.options?.meta?.isIndividualCellValid("commType", e.target.value) || "")
        }}
        onBlur={(e: ChangeEvent<HTMLSelectElement>) => {
            setCommTypeError(table.options?.meta?.isIndividualCellValid("commType", e.target.value) || "")
        }}
        showErrorText={!!commTypeError}
        errorText={commTypeError}
        invalid={!!commTypeError}
        aria-required
    >
        <option value="" disabled defaultChecked>Select Comm Type</option>
        {Object.keys(commTypesMaps).map(key => (
            <option key={key} value={key}>{commTypesMaps[key]}</option>
        ))}
    </Select>
}
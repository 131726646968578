
import { CellContext } from "@tanstack/react-table"
import React from "react"
import { B2C2ApplicationB2C2BArgsMle } from '../../../../../model/application';
import { MaskedCell } from '../../../../app-edit/b2c2-masked-cell';
import { MleServerCertSelectEdit } from "./mle-server-cert-select-edit";

export const MleServerCertKeyCell: React.FC<CellContext<B2C2ApplicationB2C2BArgsMle, any>> = (context) => {
    if (context.table.options.meta?.currentEditingRowId[0] !== context.row.id) {
        return <MaskedCell value={context.getValue()}></MaskedCell>
    }
 
   return <MleServerCertSelectEdit  {...context.table}/>

}

import { B2C2Application } from '../model/application';
import { useB2C2Applications } from './use-b2c2-applications';

export interface UseB2C2ApplicationResult {
  b2c2Application: B2C2Application | undefined | null;
  b2c2PendingApplication: B2C2Application | undefined | null;
  loading: boolean;
  b2c2ActiveApplication:B2C2Application | undefined | null;
}

export function useB2C2Application(applicationGuid: string|undefined): UseB2C2ApplicationResult {
  const { loading, b2c2Applications } = useB2C2Applications();

  let b2c2Application: B2C2Application | undefined | null = null;
  let b2c2PendingApplication: B2C2Application | undefined | null = null;
  let b2c2ActiveApplication: B2C2Application | undefined | null = null;

  if (b2c2Applications) {
    b2c2Application = b2c2Applications.find(app => app.applicationGuid === applicationGuid && (app.status == 'ACTIVE'|| app.status == 'PENDING'));
    b2c2PendingApplication = b2c2Applications.find(app => app.applicationGuid === applicationGuid && app.status == 'PENDING');
    b2c2ActiveApplication =b2c2Applications.find(app =>app.applicationGuid === applicationGuid && app.status =='ACTIVE');
  }

  return {
    b2c2Application,
    b2c2PendingApplication,
    loading,
    b2c2ActiveApplication
  };
}
import { Table } from "@tanstack/table-core";
import React, { ChangeEvent } from "react";
import Tr from '@visa/vds/tr';
import Td from '@visa/vds/td';
import { TableFormAddCancelAction } from "../../table-cells/b2c2-apps/table-form-add-cancel";
import Checkbox from '@visa/vds/checkbox';
import ScreenReader from '@visa/vds/screen-reader';
import { AddHeaderNameCall } from "../../table-cells/b2c2-product/headers/add-header-name-cell";
import { HeaderTypeCell } from "../../table-cells/b2c2-product/headers/add-header-type-cell";
import { AddHeaderDefaultValueCall } from "../../table-cells/b2c2-product/headers/add-header-default-value-cell";
import { Row } from "../../../../../products/views/product-edit/product-edit-headers";

export const AddHeaderRow: React.FC<Table<Row>> = (table) => {
    return <Tr >
        <Td><AddHeaderNameCall {...table}/></Td>
        <Td><HeaderTypeCell {...table}/></Td>
        <Td>
            <Checkbox
                name={`add_header_checkbox_indeterminate`}
                label={<ScreenReader tag={"span"}>{table.options.meta?.dirtyRow?.isRequired ? `Select ${table.options.meta?.dirtyRow.name}` : `Unselect ${table.options.meta?.dirtyRow?.name}`}</ScreenReader>}
                id={`add_header_checkbox_indeterminate`}
                checked={table.options.meta?.dirtyRow?.isRequired }
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    table.options?.meta?.setEditCelldata("new", e.target.checked ? true : false, "isRequired")
                }}
            ></Checkbox>
        </Td>
        <Td><AddHeaderDefaultValueCall {...table}/></Td>
        <Td><TableFormAddCancelAction {...table as Table<any>}/></Td>
    </Tr>
}

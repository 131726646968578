import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { ProductEndpointUpdate } from "../../../../../../products/model/product-update";

export const EndpointNameCell: React.FC<Table<ProductEndpointUpdate>> = (table) => {
    const [endpointName, setEndpointName] = useState<string>("");
    return <Input
        style={{ height: "unset" }}
        errorText={endpointName}
        label="Endpoint name *"
        name={"endpoint_name"}
        value={table.options.meta?.dirtyRow?.name || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setEndpointName(table.options.meta?.isIndividualCellValid("name", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "name");
        }}
        onBlur={()=>setEndpointName(table.options?.meta?.isIndividualCellValid("name",table.options.meta.dirtyRow?.name) || "")}
        aria-required
        autoFocus
        showErrorText={!!endpointName}
        invalid={!!endpointName}
    ></Input>
}
import { Reducer } from 'react';
import { Action } from '../../../state/actions';
import { Application, B2BApplication } from '../model/application';
import { ApplicationApproval } from '../model/application-approval';
import { B2BProvisioningContracts } from '../model/provisioning';
import { appsTypes } from './apps-actions';
import { CredentialDetail } from '../../appsNew/model/credential-detail';

export interface ApprovalsState {
  list: ApplicationApproval[] | null;
  loading: boolean;
  error: string | null;
}

export interface AppsState {
  applications: Application[] | null;
  application: Application | null;
  approvals: Record<number, ApprovalsState>;
  loading: boolean;
  error: string | null;
}

export interface ProvisioningState {
  b2bProvisioningContracts: B2BProvisioningContracts[];
  loading: boolean;
  error: string | null;
}

export const initialProvisioningState: ProvisioningState = {
  b2bProvisioningContracts: [],
  error: null,
  loading: false,
}

export interface CredentialDetailState {
  credentialDetails: CredentialDetail[] | null;
  loading: boolean;
  error: string | null;
}

export const initialCredentialDetailState: CredentialDetailState = {
  credentialDetails: null,
  error: null,
  loading: false,
}

export interface B2BAppsState {
  b2bApplications: B2BApplication[];
  loading: boolean;
  error: string | null;
}

export const initialAppsState: AppsState = {
  applications: null,
  application: null,
  approvals: {},
  error: null,
  loading: false,
}

export const initialB2BAppsState: B2BAppsState = {
  b2bApplications: [],
  error: null,
  loading: false,
}

export const appsReducer: Reducer<AppsState, Action> = (state: AppsState, action: Action): AppsState => {
  switch (action.type) {
    case appsTypes.loadApplications: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case appsTypes.loadApplicationsSuccess: {
      const applications = action.payload as Application[];
      return {
        ...state,
        loading: false,
        error: null,
        applications,
      };
    }
    case appsTypes.loadApplicationsError: {
      const error = action.payload as string;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case appsTypes.addApplication: {
      if (!state.applications) {
        return state;
      }
      const application = action.payload as Application;
      return {
        ...state,
        applications: [
          ...state.applications ? state.applications : [],
          application,
        ],
      };
    }
    case appsTypes.updateApplication: {
      if (!state.applications) {
        return state;
      }
      const application = action.payload as Application;
      return {
        ...state,
        applications: [
          ...state.applications ? state.applications.map(app => app.id === application.id ? application : app) : [application],
        ],
      };
    }
    case appsTypes.deleteApplication: {
      if (!state.applications) {
        return state;
      }
      const id = action.payload as number;
      return {
        ...state,
        applications: [
          ...state.applications ? state.applications.filter(app => app.id !== id) : [],
        ],
      };
    }

    case appsTypes.loadApplicationById: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    
    case appsTypes.loadApplicationByIdSuccess: {
      const application = action.payload as Application;
      return {
        ...state,
        loading: false,
        error: null,
        application,
      };
    }

    case appsTypes.loadApplicationApprovals: {
      const appId = action.payload as number;
      return {
        ...state,
        approvals: {
          ...state.approvals,
          [appId]: {
            ...state.approvals[appId],
            loading: true,
            error: null,
          },
        },
      };
    }
    case appsTypes.loadApplicationApprovalsSuccess: {
      const { appId, approvals } = action.payload as { appId: number, approvals: ApplicationApproval[] };
      return {
        ...state,
        approvals: {
          ...state.approvals,
          [appId]: {
            list: approvals,
            loading: false,
            error: null,
          },
        },
      };
    }
    case appsTypes.loadApplicationApprovalsError: {
      const { appId, error } = action.payload as { appId: number, error: string };
      return {
        ...state,
        approvals: {
          ...state.approvals,
          [appId]: {
            ...state.approvals[appId],
            loading: false,
            error,
          },
        },
      };
    }
    case appsTypes.updateApplicationApproval: {
      const { appId, approval } = action.payload as { appId: number, approval: ApplicationApproval };
      const oldApproval = state.approvals[appId];
      const approvals = oldApproval && oldApproval.list
        ? oldApproval.list.map(item => item.id !== approval.id ? item : approval)
        : [approval];
      return {
        ...state,
        approvals: {
          ...state.approvals,
          [appId]: {
            list: approvals,
            loading: false,
            error: null,
          },
        },
      };
    }

    default:
      return state;
  }
}



export const b2bAppsReducer: Reducer<B2BAppsState, Action> = (state: B2BAppsState, action: Action): B2BAppsState => {
  switch (action.type) {
    case appsTypes.loadB2BApplications: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case appsTypes.loadB2BApplicationsSuccess: {
      const b2bApplications = action.payload as B2BApplication[];
      return {
        ...state,
        loading: false,
        error: null,
        b2bApplications,
      };
    }
    case appsTypes.loadB2BApplicationsError: {
      const error = action.payload as string;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case appsTypes.addB2BApplication: {
      if (!state.b2bApplications) {
        return state;
      }
      const b2bApplication = action.payload as B2BApplication;
      return {
        ...state,
        b2bApplications: [
          ...state.b2bApplications ? state.b2bApplications : [],
          b2bApplication,
        ],
      };
    }
    case appsTypes.updateB2BApplication: {
      if (!state.b2bApplications) {
        return state;
      }
      const b2bApplication = action.payload as B2BApplication;
      return {
        ...state,
        b2bApplications: [
          ...state.b2bApplications ? state.b2bApplications.map(b2bApp => b2bApp.applicationGuid === b2bApplication.applicationGuid ? b2bApplication : b2bApp) : [b2bApplication],
        ],
      };
    }
    case appsTypes.deleteB2BApplication: {
      if (!state.b2bApplications) {
        return state;
      }
      const applicationGuid = action.payload as string;
      return {
        ...state,
        b2bApplications: [
          ...state.b2bApplications ? state.b2bApplications.filter(b2bApp => b2bApp.applicationGuid !== applicationGuid) : [],
        ],
      };
    }

    default:
      return state;
  }
}



  export const provisioningReducer: Reducer<ProvisioningState, Action> = (state: ProvisioningState, action: Action): ProvisioningState => {
    switch (action.type) {
      case appsTypes.loadB2BProvisioning: {
        return {
          ...state,
          loading: true,
          error: null,
        };
      }
      case appsTypes.loadB2BProvisioningSuccess: {
        const b2bProvisioningContracts = action.payload as B2BProvisioningContracts[];
        return {
          ...state,
          loading: false,
          error: null,
          b2bProvisioningContracts
        };
      }
      case appsTypes.loadApplicationsError: {
        const error = action.payload as string;
        return {
          ...state,
          loading: false,
          error,
        };
      }

  
      default:
        return state;
    }
  }

  export const credentialDetailReducer: Reducer<CredentialDetailState, Action> = (state: CredentialDetailState, action: Action): CredentialDetailState => {
    switch (action.type) {
      case appsTypes.loadCredentials: {
        return {
          ...state,
          loading: true,
          error: null,
        };
      }
      case appsTypes.loadCredentialsSuccess: {
        const credentialDetails = action.payload as CredentialDetail[];
        return {
          ...state,
          loading: false,
          error: null,
          credentialDetails
        };
      }
      case appsTypes.deleteApplication: {
        if (!state.credentialDetails) {
          return state;
        }
        const keyId = action.payload as string;
        return {
          ...state,
          credentialDetails: [
            ...state.credentialDetails ? state.credentialDetails.filter(credential => credential.keyId !== keyId) : [],
          ],
        };
      }
      default:
        return state;
    }
  }


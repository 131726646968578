import { makeStyles } from '@material-ui/core/styles';

export const useFormsStyles = makeStyles(theme => ({
  formControl25: {
    margin: 0,
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: '20%',
    },    
  },
  formControl30: {
    margin: 0,
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: '30%',
    },    
  },
  formControl50: {
    margin: 0,
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: '50%',
    },    
  },
  formControl60: {
    margin: 0,
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: '60%',
    },    
  },
  formControl70: {
    margin: 0,
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: '70%',
    },    
  },
  formControl80: {
    margin: 0,
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: '80%',
    },    
  },
  formControlFull: {
    margin: 0,
    minWidth: '100% !important',
  },
  marginTop: {
    marginTop: `${theme.spacing(2)}px !important`,
  },
  marginBottom: {
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  marginSmTop: {
    [theme.breakpoints.down('sm')]: {
      marginTop: `${theme.spacing(2)}px !important`,
    },    
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  marginMdLeft: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
    },    
  },
  h3: {
    color: '#2A2E69'
  },
  label: {
    color: '#616188',
    fontSize: 14,
    width: 'fit-content'
  },
    buttonRoot: {
        padding: theme.spacing(4, 1, 4, 1)
    },
  deleteBtn: {
    marginRight: theme.spacing(5)
  },
    instructionBox: {
        height: '40px',
        marginBottom: '40px',
        width: "100%"
    },
    instructionText: {
        padding: theme.spacing(1, 0, 1, 0)
    },

    regionHelperText: {
        color: '#f44336',
        marginLeft: '0',
        marginTop: theme.spacing(2)
    }
}));

import React, { ChangeEvent, useState } from "react"
import Select from '@visa/vds/select';
import { Table } from "@tanstack/react-table";
import { Row } from "../../../../app-edit/b2c2-app-edit-b2c2b-mle";

const MLE_STATUS: string[] = [
    'ACTIVE',
    'INACTIVE',
];

export const AddMleStatusCell: React.FC<Table<Row>> = (table) => {
    const [mlaStatusError, setMlaStatusError] = useState<string>("");
    
    const mleStatusMap = MLE_STATUS.reduce<Record<string, string>>(
        (acc, mleStatus) => ({ ...acc, [mleStatus]: mleStatus }),
        {}
    );
    return <Select id={"mle_status"}
        label="MLE Status"
        fullWidth
        value={table.options.meta?.dirtyRow?.mleStatus || ''}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setMlaStatusError(table.options.meta?.isIndividualCellValid("mleStatus", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "mleStatus");
        }}
        showErrorText={!!mlaStatusError}
        errorText={mlaStatusError}
        invalid={!!mlaStatusError}
        autoFocus={!!table.options.meta?.dirtyRow?.mleStatus}
    >
        <option value="" disabled> Select Mle Status</option>
        {Object.entries(mleStatusMap).map(([key, value]) => <option key={key} value={value}>{key}</option>)}
    </Select>
}
import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { useBlockingLoading } from '../../hooks/use-blocking-loading';
import { useLoading } from '../../hooks/use-loading';
import { useNotifications } from '../../hooks/use-notifications';
import { useIsAuthenticated } from '../../lib/oidc-client-react';
import { BlockingLoading } from '../blocking-loading/blocking-loading';
import { NotificationBar } from '../notifications/notification-bar';
import { NotificationSnackbar } from '../notifications/notification-snackbar';
import Moment from 'moment';

export const AppGlobalComponents: React.FC = () => {
  const { notificationBar, notificationSnackbar } = useNotifications();
  const { loading } = useLoading();
  const { loading: blockLoading } = useBlockingLoading();
  const isAuthenticated = useIsAuthenticated();
    Moment.locale('en');

  return (
    <>
      {isAuthenticated && loading && <LinearProgress color="secondary" aria-label='Loading bar' aria-live="polite"/>}
      {isAuthenticated && notificationBar && <NotificationBar message={notificationBar.message} variant={notificationBar.variant} />}
      {isAuthenticated && notificationSnackbar && <NotificationSnackbar message={notificationSnackbar.message} variant={notificationSnackbar.variant} />}
      <BlockingLoading open={blockLoading} />
    </>
  );
};

import { Identity } from '../../../model/entities';
import { MultiDataType, MultiType } from '../../../model/multi-type';
import { ApplicationConsumerStatus, ApplicationType, B2C2ApplicationHeaders, B2C2ApplicationB2C2BArgs } from './application';
import { B2C2ApplicationConfigUpdate } from './application-update';

export interface ApplicationConsumerCreate {
  userguid: string;
  username: string;
  userState: ApplicationConsumerStatus;
}

export interface ApplicationRoleCreate {
  name: string;
  approvedEmailIds: string[];
  operations: number[];
  consumerUsers: ApplicationConsumerCreate[];
}

export interface ApplicationConfigBase {
  key: string;
  dataType: MultiDataType;
}

export interface B2C2ApplicationConfigBase {
  configKey: string;
  dataType?: MultiDataType;
}

export interface ApplicationConfigCreate extends ApplicationConfigBase {
  value: MultiType;
}

export interface B2C2ApplicationConfigCreate extends B2C2ApplicationConfigBase {
  configValue: MultiType;
}

export interface ApplicationServiceConfigCreate {
  serviceConfigId: number;
  value: MultiType;
}

export const appConfigValues = [{key: 1,value: 'true',}, {key: 0,value: 'false',}];

export type ApplicationCommunicationType = 'LINK' | 'OTP' | 'NONE'| null;
export const ApplicationCommunicationTypes = ['LINK', 'OTP', 'NONE'];

export type ApplicationTemplateType =  'RESET_PASSWORD_SUCCESS'|
'CHANGE_PASSWORD_SUCCESS'|
'VERIFY_OTP'|
'RESET_PASSWORD_OTP'|
'CREATE_ACCOUNT'|
'CREATE_ACCOUNT_EXISTING'|
'DELETE_ACCOUNT' | ''
// 'ACTIVATE' | 'ACTIVATE_SUCCESS' | 'ALREADY_ACTIVATED'
// | 'RESET_PASSWORD_SUCCESS'
// | 'CHANGE_PASSWORD_SUCCESS'
// | 'PASSWORD_EXPIRY'
// | 'ACCOUNT_DEACTIVATION'
// | 'ACTIVATE_OTP' 
// | 'RESET_PASSWORD_OTP' |''
export const ApplicationTemplateTypes = [ 'RESET_PASSWORD_SUCCESS',
'CHANGE_PASSWORD_SUCCESS',
'VERIFY_OTP',
'RESET_PASSWORD_OTP',
'CREATE_ACCOUNT',
'CREATE_ACCOUNT_EXISTING',
'DELETE_ACCOUNT'];

export interface ApplicationCommunicationTemplateCreate {
  type: ApplicationCommunicationType;
  expiryMinutes: number;
  content: string;
}

export interface B2C2ApplicationCommunicationTemplateCreate extends Identity{
  templateType: ApplicationTemplateType;
  commType: ApplicationCommunicationType
  expiryMinutes: number;
  content: string;
}

export interface ApplicationCommunicationTemplatesCreate {
  accountActivation: ApplicationCommunicationTemplateCreate;
  accountActivationSuccess: ApplicationCommunicationTemplateCreate;
  passwordExpiry: ApplicationCommunicationTemplateCreate;
  resetPassword: ApplicationCommunicationTemplateCreate;
  accountDeactivation: ApplicationCommunicationTemplateCreate;
  forgotUsername: ApplicationCommunicationTemplateCreate;
  changePassword: ApplicationCommunicationTemplateCreate;
}

export interface ApplicationCommunicationCreate {
  fromEmailAddress: string;
  fromDisplayName: string;
  templates: ApplicationCommunicationTemplatesCreate;
}

export interface ApplicationDetailsCreate {
  name: string;
  description: string;
  type: ApplicationType;
  owners: string[];
}

export interface ApplicationCreate extends ApplicationDetailsCreate {
  roles: ApplicationRoleCreate[];
  serviceConfigs?: ApplicationServiceConfigCreate[];
  appConfigs?: B2C2ApplicationConfigCreate[];
  communication?: ApplicationCommunicationCreate;
}

export interface B2C2ApplicationCreate extends B2C2ApplicationDetailsCreate {
  allowedEndpoints: string[];
  appProductHeaders?: B2C2ApplicationHeaders[];
  owners?: string[];
  whitelistedIdentities?: string[];
  fromId: string;
  fromDisplayName: string;
  configs?: B2C2ApplicationConfigUpdate[];
  templates?: B2C2ApplicationCommunicationTemplateCreate[];
  b2c2bArgs?: B2C2ApplicationB2C2BArgs;
}

export interface B2C2ApplicationDetailsCreate {
  name: string;
  description?: string;
  namespace?: string;
  supportGdl: string;
}

export interface B2BApplicationOwnerCreate {
  applicationOwnerGuid: string;
  subjectName: string;
}

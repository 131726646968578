import React from 'react';
import {useRoutes} from 'react-router-dom';
import {NotFoundErrorPage} from '../../components/errors/not-found-error-page';
import {AuthCallback} from './views/callback';
import {Login} from './views/login/login';
import {Logout} from './views/logout';

const routes: any[] = [
  { path: 'login', element: <Login /> },
  { path: 'logout', element: <Logout /> },
  { path: 'callback', element: <AuthCallback /> },

  { path: '*', element: <NotFoundErrorPage /> },
];

export const AuthRouter: React.FC = () => useRoutes(routes);

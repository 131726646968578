import { useEffect } from 'react';
import { ERR_INVALID_OAUTH2_AUTHENTICATION } from '../../../features/apps/constants';
import { retrySSOSrv } from '../../../services/retry-sso.service';
import { BackendErrorDetailDto } from '../../oidc-client/model/network.dto';
import { getUserManager } from '../service/user-service';
import { actions } from '../state/actions';
import { useAuthStore } from '../state/store';
import { useLogin } from './use-login.hook';

export interface LoginCallbackResult {
  loading: boolean;
  authenticated: boolean;
  error: Error | null;
}

export function useLoginCallback(): LoginCallbackResult {
  const [state, dispatch] = useAuthStore();
  const { login } = useLogin();

  useEffect(() => {
    const loginCallbackAsync = async () => {
      dispatch(actions.startAuthentication());
      try {
        const user = await getUserManager().signinRedirectCallback();
        dispatch(actions.authenticationSuccess(user));
      } catch (e:any) {
        const errorDetails = e.cause?.details;
        let isSsoFailed = errorDetails?.some(
          (detail: BackendErrorDetailDto) =>
            detail.subcode &&
            detail.subcode === ERR_INVALID_OAUTH2_AUTHENTICATION.subcode &&
            detail.reason &&
            detail.reason === ERR_INVALID_OAUTH2_AUTHENTICATION.reason
        );
        let noOfRetries = retrySSOSrv.getNoOfRetries();
        if (isSsoFailed && noOfRetries > 1) {
          retrySSOSrv.setNoOfRetries(noOfRetries - 1);
          login();
        }
        const message = e.message ? e.message : "Authentication error";
        dispatch(actions.authenticationError(new Error(message)));
      }
    }

    loginCallbackAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: state.authenticating,
    authenticated: !!state.user,
    error: state.error,
  };
}

//import { Button } from '@material-ui/core';
import { Table } from "@tanstack/table-core"
import { Button } from '@material-ui/core';
import { EditComponentProps } from 'material-table';
import React, { KeyboardEvent, ChangeEvent, useCallback, useRef, useState } from 'react';
import { useNotifications } from '../../../../../../../hooks/use-notifications';
import { useCredentialsFileUpload } from '../../../../../../appsNew/hooks/use-b2c2-file-upload';
import { Row } from "../../../../app-edit/b2c2-app-edit-b2c2b-mle";
import { B2C2CredentialDetails } from '../../../../app-edit/b2c2-credential-details';

export const MleServerCertSelectEdit: React.FC<Table<Row>> = (table) => {

    const { pushNotification } = useNotifications();
    const [currentFile, setCurrentFile] = useState<File>();
    const { uploadFileAsync } = useCredentialsFileUpload();
    const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>();
    const [isOpen, setIsOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleCloseB2C2CredentialDetails = useCallback(() => setIsOpen(false), []);

    const handleChange = useCallback(async (event: ChangeEvent<any>) => {
        const { files } = event.target;
        const selectedFiles = files as FileList;
        setCurrentFile(selectedFiles?.[0]);
        let certId;
        if (!!selectedFiles?.[0]) {
            const success = await uploadFileAsync(selectedFiles?.[0]);
            if (success) {
                if (typeof success === 'string') {
                    setIsUploadSuccess(false);
                    setIsOpen(true);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                    return;
                }
                setIsUploadSuccess(true);
                certId = success.keyId;
                table.options?.meta?.setEditCelldata("", certId, "mleServerCertId");
            } else {
                setCurrentFile(undefined);
                setIsUploadSuccess(false);
                pushNotification({ message: 'Unable to fetch server cert id', type: 'snackbar', variant: 'error' });
            }
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        
    }, []);

    const handleUpload = (event:KeyboardEvent<HTMLLabelElement>)=> {
        if(event.key === "Enter" || event.key === " " ){
            fileInputRef?.current?.click();
        }
      };
    
    return (
        <>
            {isOpen && <B2C2CredentialDetails
                onClose={handleCloseB2C2CredentialDetails}
            />}
                 <label htmlFor="btn-upload">
                <Button
                    variant="contained"
                    component="label" onKeyUp={handleUpload}
                >
                    Upload File
                    <input
                        type="file"
                        ref={fileInputRef}
                        hidden
                        onChange={handleChange}
                    />
                </Button>
            </label>
            <div className="file-name">
                {isUploadSuccess && currentFile ? currentFile.name : null}
                {' '}
                {table.options.meta?.dirtyRow?.mleServerCertId}
            </div>
        </>
    );
}

import { useEffect, useState } from 'react';
import { getUserManager } from '../service/user-service';
import { actions } from '../state/actions';
import { useAuthDispatch } from '../state/store';

export interface UseLogoutResult {
  loggedOut: boolean;
}

export function useLogout(): UseLogoutResult {
  const dispatch = useAuthDispatch();
  const [loggedOut, setLoggedOut] = useState<boolean>(false);

  useEffect(() => {
    const logoutAsync = async () => {
      dispatch(actions.logout());
      try {
        await getUserManager().signoutRedirect();
      } catch {
      } finally {
        setLoggedOut(true);
      }
    }

    logoutAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loggedOut,
  };
}

//import { Checkbox, ListItemText, Grid, Input, InputLabel, MenuItem, FormControl, NativeSelect, Select, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { ChangeEvent, useState, useEffect } from "react";
import Listbox from '@visa/vds/listbox';
import ListboxContainer from '@visa/vds/listbox-container';
import ListboxItem from '@visa/vds/listbox-item';
import Multiselect from '@visa/vds/multiselect-collapsible-listbox';
import Checkbox from '@visa/vds/checkbox';
import FormControl from '@visa/vds/form-control';
import { SearchBar } from "../common-component/search-bar";

export interface IB2C2ConsumerAprovalFilter {
    dataEntries: any[];
    multiReqStatus: string[];
    setMultiReqStatus: Function;
    search: string;
    setSearch: Function;
}
export interface item {
    label: string;
    value: string;
}

export interface filterData {
    selected: string[];
    items: item[]
}
export const B2C2ConsumerAprovalFilter: React.FC<IB2C2ConsumerAprovalFilter> = ({ dataEntries, setMultiReqStatus, setSearch, search, multiReqStatus }) => {
    const theme = useTheme();
    const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));
    const [datas, setDatas] = useState<filterData>({
        items: [],
        selected: []
    });
    useEffect(() => {
        let items: item[] = Array.from(new Set(dataEntries?.map(data => data.status))).map((entry, index) =>
        ({
            label: entry,
            value: entry
        })
        )
        setDatas({
            items: items,
            selected: [`${multiReqStatus}`]
        })
    }, [dataEntries]);

    const handleOnSelect = (e: any, { selected }: { selected: string[] }) => {
        setDatas({
            ...datas,
            selected
        });
        setMultiReqStatus(selected);
    }

    return <Grid
        container
        style={{ backgroundColor: "#F2F4F8" }}
        justifyContent={mediaCheck ? "space-between" : "flex-start"}
        alignItems={"center"}
        rowGap={2}
        padding={1}
    >
        <Grid item xs={12} lg={6} padding={1}>
            <Typography component={"h2"} variant='h5'>
                Total requests: {dataEntries.length}
            </Typography>
        </Grid>
        <Grid item xs={12} lg={3} padding={1}>
            <FormControl variant="standard">
                <Multiselect
                    selectedListItems={datas.selected}
                    items={datas.items}
                    label="Status Filter"
                >
                    <ListboxContainer
                        name="listbox-container-status"
                        onSelect={handleOnSelect}
                        selectedListItems={datas.selected}
                    >
                        <Listbox multiselect>
                            {
                                datas.items.map((item, k) => (
                                    <ListboxItem key={k} listItemValue={item.value}>
                                        <Checkbox label={item.label} tabIndex="-1" />
                                    </ListboxItem>
                                ))
                            }
                        </Listbox>
                    </ListboxContainer>
                </Multiselect>
            </FormControl>
        </Grid>
        <Grid item xs={12} lg={3} padding={1}>
            <SearchBar label=" Search B2C Consumer" search={search} setSearch={setSearch}></SearchBar>
        </Grid>
    </Grid>
}

function setValue<T>(key: string, value: T): void {
  if (!value) {
    return;
  }
  localStorage.setItem(key, JSON.stringify(value));
}

function getValue<T>(key: string): T | null {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value) as T;
  }
  return null;
}

function clear(): void {
  localStorage.clear();
}

function toInt(value: string) {
  return parseInt(value);
}

export const localStorageSrv = {
  setValue,
  getValue,
  clear,
  toInt,
}

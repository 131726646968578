import { useBlockingLoading } from '../../../hooks/use-blocking-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { useDispatch } from '../../../state/app-store';
import { ProductCreate } from '../model/product-create';
import { networkServicesProducts } from '../services/network-services.products';
import { productActions } from '../state/product-actions';

export interface UseProductCreateResult {
  loading: boolean;
  createProductAsync: (service: ProductCreate) => Promise<createProductResponse>;
}

type createProductResponse = {
  status: boolean,
  productId: string
}

export function useProductCreate(): UseProductCreateResult {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useBlockingLoading();
  const { pushNotification } = useNotifications();

  const createProductAsync = async (product: ProductCreate): Promise<createProductResponse> => {
    startLoading();
    try {
      const prd = await networkServicesProducts.createProductAsync(product);
      dispatch(productActions.addProduct(prd));
      const val:createProductResponse ={
        status: true,
        productId : prd.productId
      }
      return val;
    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      pushNotification({
        message: `Create product failed with '${error}'. Please, try again later.`,
        variant: 'error',
        type: 'bar',
      });
      const val:createProductResponse ={
        status: false,
        productId : ''
      }
      return val;
    } finally {
      stopLoading();
    }
  };

  return {
    loading,
    createProductAsync,
  };
}

import { localStorageSrv } from './local-storage.service';
import { config } from '../config';

function saveLocation(location: string): void {
  localStorageSrv.setValue('redirectTo', location);
}

function getLocation(): string {
  const location = localStorageSrv.getValue<string>('redirectTo') || config.paths.home;
  localStorage.removeItem('redirectTo');
  return location;
}

export const redirectUrlSrv = {
  saveLocation,
  getLocation,
}

import { Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { useAppPreviewStyles } from './app-preview.styles';

export const B2BAppPreviewDetailsPlaceholder: React.FC = () => {
    const styles = useAppPreviewStyles();
    return (
        <Typography component="div" className={styles.details}>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12} lg={2}><b>App name</b></Grid>
                <Grid item xs={12} lg={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
            </Grid>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12} lg={2}><b>Description</b></Grid>
                <Grid item xs={12} lg={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
            </Grid>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12} lg={2}><b>Status</b></Grid>
                <Grid item xs={12} lg={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
            </Grid>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12} lg={2}><b>App Guid</b></Grid>
                <Grid item xs={12} lg={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
            </Grid>
        </Typography>
    );
};

import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const vdsStyleOverrides = makeStyles((theme:Theme) => ({
    "selectFullWidth":{
        maxWidth: "unset",
        minWidth: "unset",
        width: "100%",
    },
    "searchFullWidth":{
        "& > .vds-input":{
            maxWidth: "unset",
            minWidth: "unset",
            width: "100%",
        }
    },
    "inputFullWidth":{
            maxWidth: "unset",
            minWidth: "unset",
            width: "100%",
            "& > .vds-input-bar > .vds-input-input > .vds-input--input-text":{
                height: "unset"
            }
    },
    "focusBorder": {
        "&:focus" :{
            border: "2px #1434cb dashed"
        }
    }
}));
import { Chip, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { B2C2Application } from '../../model/application';
import { B2BAppPreviewDetailsPlaceholder } from './b2b-app-preview-details-placeholder';
import { useAppPreviewStyles } from './app-preview.styles';
import { InfoTooltip, Placement } from '../../../../components/tooltips/tooltips';
import { appEditStyles } from '../app-edit/app-edit.styles';

export interface B2BAppPreviewDetailsProps {
  details?: B2C2Application | null;
}


export const B2BAppPreviewDetails: React.FC<B2BAppPreviewDetailsProps> = ({ details}) => {
  const styles = useAppPreviewStyles();
  const editStyles = appEditStyles();
  if (!details) {
    return (
      <B2BAppPreviewDetailsPlaceholder />
    );
  }

  return (
    <div className={styles.details}>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} lg={2}><b>App name</b></Grid>
        <Grid item xs={12} lg={10}>{details.name}</Grid>
      </Grid>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} lg={2}><b>App Guid</b></Grid>
        <Grid item xs={12} lg={10}>
          <InfoTooltip placement={Placement.rightStart}
            content={'This is onboarded as client id on VDP Project'}>
            <span tabIndex={0}>{details.applicationGuid}</span>
          </InfoTooltip>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} lg={2}><b>Description</b></Grid>
        <Grid item xs={12} lg={10}>{details.description}</Grid>
      </Grid>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} lg={2}><b>Status</b></Grid>
        <Grid item xs={12} lg={10}>{details.status}</Grid>
      </Grid>
      <Grid container direction={'row'} spacing={3}>
        <Grid item xs={12} lg={2}><b>Owners</b></Grid>
        <Grid item xs={12} lg={10}>
          {details.owners?.map((item, index) => (
            <Chip key={index} className={editStyles.chipStyleRB} variant="outlined" label={item} />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

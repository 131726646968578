export enum UserRole {
  None = 0,
  ApplicationDeveloper = 1,
  ServiceDeveloper = 3,
  PcsfDeveloper = 4,
  B2BApplicationDeveloper = 8,
  B2BServiceDeveloper = 16,
  SuperDeveloper = 63,
  GDLUPPB2BApprovers = 64,
}

export const allUserRoles: UserRole[] = [
  UserRole.ApplicationDeveloper,
  UserRole.ServiceDeveloper,
  UserRole.PcsfDeveloper,
  UserRole.B2BApplicationDeveloper,
  UserRole.B2BServiceDeveloper,
  UserRole.SuperDeveloper,
  UserRole.GDLUPPB2BApprovers,
];

export const userRolesMap: Record<UserRole, string> = {
  [UserRole.None]: 'None',
  [UserRole.ApplicationDeveloper]: 'App Developer',
  [UserRole.ServiceDeveloper]: 'Service Developer',
  [UserRole.PcsfDeveloper]: 'PCFS Developer',
  [UserRole.B2BApplicationDeveloper]: 'B2B App Developer',
  [UserRole.B2BServiceDeveloper]: 'B2B Service Developer',
  [UserRole.SuperDeveloper]: 'Super Developer',
  [UserRole.GDLUPPB2BApprovers]: 'GDL - UPPB2BApprovers',
};

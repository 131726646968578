import React, { ChangeEvent, useState } from "react"
import Select from "@visa/vds/select"
import { Table } from "@tanstack/react-table";
import { ProductHostUpdate } from "../../../../../../products/model/product-update";
import { vdsStyleOverrides } from "../../../../../../../vds-overrides.styles";

export const B2CHostTypeEditCell: React.FC<Table<ProductHostUpdate>> = (table) => {
    const data = table.options.meta?.data?.hostTypeOptions || {};
    const vdsStyles = vdsStyleOverrides();
    const [hostTypeError, setHostTypeError] = useState<string>("");
    return <Select
        name="HostType"
        label="Host Type *"
        fullWidth
        value={table.options.meta?.dirtyRow?.dataCenter || ''}
        aria-required
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            table.options?.meta?.setEditCelldata("", e.target.value, "dataCenter");
            setHostTypeError(table.options?.meta?.isIndividualCellValid("dataCenter",e.target.value) || "");
        }}
        onBlur={()=>setHostTypeError(table.options?.meta?.isIndividualCellValid("dataCenter",table.options.meta.dirtyRow?.dataCenter) || "")}
        autoFocus
        showErrorText={!!hostTypeError}
        errorText={hostTypeError}
        invalid={!!hostTypeError}
        className={vdsStyles.selectFullWidth}
    >
        <option value="" disabled>Select Host Type</option>
        {Object.entries(data).map(([key, value]) => <option key={key} value={value as string}>{key}</option>)}
    </Select>
}
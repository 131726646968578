import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const helpStyles = makeStyles((theme:Theme) => ({
    hero: {
        [theme.breakpoints.down("lg")]:{
            padding: theme.spacing(2, 5, 5, 5),
      
          },
        padding: theme.spacing(8, 20, 20, 8),
        width: '100%',
        flexBasis: '100%'
    },

    heading: {
        fontSize: theme.typography.pxToRem(16),
        flexBasis: '100%',
        flexShrink: 0,
        lineHeight: theme.typography.pxToRem(24),
        color: '#616188',
    },
    title: {
         color: '#2A2E69',
         fontFamily: "Open Sans",

        '& .MuiCardHeader-title': {
            fontSize: theme.typography.pxToRem(26),
            paddingBottom: '33px',
        },
        '& .MuiCardHeader-subheader': {
            fontSize: theme.typography.pxToRem(20),
            fontWeight: 600,
            letterSpacing: theme.typography.pxToRem(-0.5),
        }
    },
    link:{
        position: "relative",
        " &::after":{
            position: "absolute",
            right: "-1.5rem",
            background: "url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtElEQVR4nO3WMQrCMBTG8f8lGnqBjoLXCNLzuDrp/ezgbrsUegA7SER4BSm1JvASh+aDBykh/HhpAoGcreUAdIBTrhawa3AbAZ3qvga7yPU124W14zI8T97qvx+uPfCcXY0hBfzOMeReasIG6FPDBmhkrk8Fmw+0ke+T/HNV+PEDnbLThEeglnEBXGXBDSgDoGDYenQaBdbu1BsulDv1hi8LqJUDF/UhUAFn6VwbdUq7l0NQXjnq/HgLbaytAAAAAElFTkSuQmCC\")",
            content: "''",
            width: "1rem",
            height: "1rem",
            backgroundSize: "cover"
        }
    }

}));
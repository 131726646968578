import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const skipToStyles = makeStyles((theme: Theme) => ({
    skipButton: {
        padding: 0,
        height:0,
        overflow:'hidden',
        borderRadius: 0,
        width:"100%",
        backgroundColor: '#003EA9',
        border: 0,
        color: "white",
        cursor: "pointer",
        textAlign: "center",
        fontWeight: "bold",
        transitionDuration: "10s",
        zIndex: 10000000000,
        display: "block",
        margin: 0,
        "&:focus": {
            padding:theme.spacing(1.5),
            overflow: 'visible',
            color: 'white',
            backgroundColor: "#003EA9",
            height: "unset",
            border: "2px #003EA9 solid",
            fontSize: '18px',
            width: "100%",
        },
    },
}));

import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';

export const dashboardStyles = makeStyles((theme: Theme) => ({
  root: {

  },
  content: {
    margin: theme.spacing(4, 4, 4, 4)
  }
}));

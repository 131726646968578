import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const getStartedStyles = makeStyles((theme:Theme) => ({
  hero: {
    padding: theme.spacing(8, 20, 20, 20),
    [theme.breakpoints.down("md")]:{
      padding: theme.spacing(2, 5, 5, 5),

    },
    flex: 1
  },
  headCell: {
    padding: 0,
    margin: 0,
    border:0
  },
  cardStyle: {
    borderRadius: 0,
    marginBottom: '30px'
  },
  expansionPanel: {
  boxShadow: 'none',
    border: 'hidden'
  },
  expandBtn: {
    textTransform:'none',
    padding: 0,
    fontWeight: 'bold'
  },
  description: {
    fontSize: '20px'
  },
  signInBtn: {
    padding: '0',
    fontSize: '20px',
    textTransform:'none',
    color:'#2A2E69',
    textDecoration: 'underline',
    cursor: "pointer",
    fontWeight:"bold",
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: '#fafafa',
    }
  },
  header1: {
    marginTop: '60px',
    padding: theme.spacing(1, 0, 1, 0),
    color:'#2A2E69'
  },
  btnRequestAccessBtm: {
    width:'220px',
    height:'auto',
    marginTop: theme.spacing(3)
  },
  btnRequestAccessTop: {
    width:'220px',
    height:'auto'
  },
  cardItemDescription: {
    padding: '8px 0px 10px 0'
  },
  signInText: {
    paddingTop: '20px',
    float: 'right'
  },
  floatRight: {
    float: 'right'
  },
  logobarMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0'
  },
  signinMenu:{
    padding: '0',
    margin: '0',
    paddingTop:'32px'
  },
  newTabIcon:{
    position: "relative",
    " &::after":{
        backdropFilter:"brightness(0) invert(1)",
        position: "absolute",
        right: "-1.5rem",
        background: "url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtElEQVR4nO3WMQrCMBTG8f8lGnqBjoLXCNLzuDrp/ezgbrsUegA7SER4BSm1JvASh+aDBykh/HhpAoGcreUAdIBTrhawa3AbAZ3qvga7yPU124W14zI8T97qvx+uPfCcXY0hBfzOMeReasIG6FPDBmhkrk8Fmw+0ke+T/HNV+PEDnbLThEeglnEBXGXBDSgDoGDYenQaBdbu1BsulDv1hi8LqJUDF/UhUAFn6VwbdUq7l0NQXjnq/HgLbaytAAAAAElFTkSuQmCC\")",
        content: "''",
        width: "1rem",
        height: "1rem",
        backgroundSize: "cover"
    }
}
}));

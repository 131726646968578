import React,{ useMemo } from 'react';
import { ProductEndpointCreate } from '../../model/product-create';
import { ColumnDef,getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../../../appsNew/views/table-abstraction/table-core';

export interface ProductPreviewEndpointsProps {
    endpoints?: ProductEndpointCreate[];
}

export const ProductPreviewEndpoints: React.FC<ProductPreviewEndpointsProps> = ({ endpoints }) => {

    const columns: ColumnDef<ProductEndpointCreate, any>[] = useMemo(() => [
        { 
          header: 'Endpoint name', 
          accessorKey: 'name' 
        },
        { 
          header: 'Http method', 
          accessorKey: 'methodType'
        },
        { 
          header: 'Internal path', 
          accessorKey: 'internalPath',
        },
        { 
          header: 'External path', 
          accessorKey: 'externalPath' 
        },
        { 
          header: 'B2C MLE Type', 
          accessorKey: 'b2cMleType' 
        },
        { 
          header: 'B2B MLE Type', 
          accessorKey: 'b2bMleType' 
        },
      ], []);
      const rows = useMemo<ProductEndpointCreate[]>(() => endpoints ? endpoints.map(endpoint => ({ ...endpoint })) : [], [endpoints]);
      const tableDatas = useReactTable({
        columns: columns,
        data: rows,
        getCoreRowModel: getCoreRowModel(),
        enableSorting: false
      });
    return (
        <>
            <TableCore caption='Endpoints' table={tableDatas}></TableCore>
        </>

    );
};

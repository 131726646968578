import { Reducer } from 'react';
import { Action } from '../../../state/actions';
import { B2C2ApprovalRequest, Product } from '../model/product';
import { productTypes } from './product-actions';


export interface ProductsState {
  products: Product[];
  approvals: Record<string, B2C2ApprovalRequestsState>;
  loading: boolean;
  error: string | null;
}

export interface B2C2ApprovalRequestsState {
  b2c2ApprovalRequests: B2C2ApprovalRequest[];
  loading: boolean;
  error: string | null;
}


export const initialProductsState: ProductsState = {
  products: [],
  approvals: {},
  error: null,
  loading: false,
}

export const initialB2C2ApprovalRequestsState: B2C2ApprovalRequestsState = {
  b2c2ApprovalRequests: [],
  error: null,
  loading: false,
}

export const productsReducer: Reducer<ProductsState, Action> = (state: ProductsState, action: Action): ProductsState => {
  switch (action.type) {
    case productTypes.loadProducts: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case productTypes.loadProductsSuccess: {
      const products = action.payload as Product[];
      return {
        ...state,
        loading: false,
        error: null,
        products,
      };
    }
    case productTypes.loadProductsError: {
      const error = action.payload as string;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case productTypes.addProduct: {
      if (!state.products) {
        return state;
      }
      const products = action.payload as Product;
      return {
        ...state,
        products: [
          ...state.products ? state.products : [],
          products,
        ],
      };
    }
    case productTypes.updateProduct: {
      if (!state.products) {
        return state;
      }
      const products = action.payload as Product;
      return {
        ...state,
        products: [
          ...state.products ? state.products.map(srv => srv.productId === products.productId ? products : srv) : [products],
        ],
      };
    }
    case productTypes.deleteProduct: {
      if (!state.products) {
        return state;
      }
      const productId = action.payload as string;
      return {
        ...state,
        products: [
          ...state.products ? state.products.filter(product => product.productId !== productId) : [],
        ],
      };
    }
    default:
      return state;
  }
}

export const b2c2ApprovalRequestsReducer: Reducer<B2C2ApprovalRequestsState, Action> = (state: B2C2ApprovalRequestsState, action: Action): B2C2ApprovalRequestsState => {
  switch (action.type) {
    case productTypes.loadB2C2ApprovalRequests: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case productTypes.loadB2C2ApprovalRequestsSuccess: {
      const b2c2ApprovalRequests = action.payload as B2C2ApprovalRequest[];
      return {
        ...state,
        loading: false,
        error: null,
        b2c2ApprovalRequests,
      };
    }
    case productTypes.loadB2C2ApprovalRequestsError: {
      const error = action.payload as string;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case productTypes.updateB2C2ApprovalRequest: {
      if (!state.b2c2ApprovalRequests) {
        return state;
      }
      const b2c2ApprovalRequest = action.payload as B2C2ApprovalRequest;
      return {
        ...state,
        b2c2ApprovalRequests: [
          ...state.b2c2ApprovalRequests ? state.b2c2ApprovalRequests.map(request => request.requestId === b2c2ApprovalRequest.requestId ? b2c2ApprovalRequest : request) : [b2c2ApprovalRequest],
        ],
        loading: false,
        error: null,
      };
    }
    
    default:
      return state;
  }
}
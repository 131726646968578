import {Box, Card, CardContent, CardHeader, Divider, Grid, Typography} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { useProfileStyles } from './profile.styles';

export const ProfilePlaceholder: React.FC = () => {
  const styles = useProfileStyles();

  return (
  <div className={styles.root}>
    <Grid container spacing={2}>
      <Grid item lg={12} md={8} sm={12} xs={12}>
        <Card>
          <CardHeader title="Profile" className={styles.title} />
          <CardContent>
            <Typography component="div">
              <Grid container spacing={1}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box className={styles.itemTitle} m={1}>Name</Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box className={styles.itemDetail} m={1}><Skeleton variant="rect" animation="wave" height={30} /></Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box className={styles.itemTitle} m={1}>Username</Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box className={styles.itemDetail} m={1}><Skeleton variant="rect" animation="wave" height={30} /></Box>
                </Grid>
                <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                  <Box className={styles.itemTitle} m={1}>Email</Box>
                </Grid>
                <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                  <Box className={styles.itemDetail} m={1}><Skeleton variant="rect" animation="wave" height={30} /></Box>
                </Grid>
                <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                  <Box className={styles.itemTitle} m={1}>Phone Number</Box>
                </Grid>
                <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                  <Box className={styles.itemDetail} m={1}><Skeleton variant="rect" animation="wave" height={30} /></Box>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
          <Divider className={styles.divider}/>
          <CardContent>
            <Typography component="div">
              <Grid container spacing={1}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box className={styles.itemTitle} m={1}>Your admin roles</Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box className={styles.itemDetail} m={1}>
                    <Skeleton variant="rect" animation="wave" height={30} />
                  </Box>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
          <Divider className={styles.divider}/>
          <CardContent>
            <Typography component="div">
              <Grid container spacing={1}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box className={styles.itemTitle} m={1}>Apps you own</Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box className={styles.itemDetail} m={1}>
                    <Skeleton variant="rect" animation="wave" height={30} />
                  </Box>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </div>
  );
}

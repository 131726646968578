
import { CellContext } from "@tanstack/react-table"
import React from "react"
import { B2C2ApplicationB2C2BArgsAuthN } from '../../../../../model/application';
import { WsiExternalIdEdit } from "./wsi-externalId-edit";

export const AddWsiExternalIdCell: React.FC<CellContext<B2C2ApplicationB2C2BArgsAuthN, any>> = (context) => {

    const host = window.location.host;
    const splits = host.split("-");
    let isWSIExternalIdEditable = false;
    if (splits.length > 2) {
        if (splits[2] === "qapenb.oce" || splits[2] === "qaintb.oce") {
            isWSIExternalIdEditable = true;
        }
    }

    if (context.table.options.meta?.currentEditingRowId[0] !== context.row.id) {
        if (!isWSIExternalIdEditable || !context.getValue()) {
            return <div>N/A</div>;
        }
        return <>{context.getValue()}</>;
    }

    return <WsiExternalIdEdit {...context.table}/>
}

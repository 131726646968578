import { actionCreator } from '../../../state/actions';
import { B2BServiceReviewRequest } from '../../services/model/service';
import { Application, B2BApplication } from '../model/application';
import { ApplicationApproval } from '../model/application-approval';
import { CredentialDetail } from '../../appsNew/model/credential-detail';
import { B2BProvisioningContracts } from '../model/provisioning';

const types = {
  loadApplications: 'apps/load_applications',
  loadApplicationById: 'apps/load_application_by_id',
  loadB2BApplications: 'apps/load_b2b_applications',
  loadApplicationsSuccess: 'apps/load_applications_success',
  loadApplicationByIdSuccess: 'apps/load_application_by_id_success',
  loadB2BApplicationsSuccess: 'apps/load_b2b_applications_success',
  loadApplicationsError: 'apps/load_applications_error',
  loadB2BApplicationsError: 'apps/load_b2b_applications_error',
  addApplication: 'apps/add_application',
  addB2BApplication: 'apps/add_b2b_application',
  updateApplication: 'apps/update_application',
  updateB2BApplication: 'apps/update_b2b_application',
  deleteApplication: 'apps/delete_application',
  deleteB2BApplication: 'apps/delete_b2b_application',
  loadApplicationApprovals: 'apps/load_application_approvals',
  loadApplicationApprovalsSuccess: 'apps/load_application_approvals_success',
  loadApplicationApprovalsError: 'apps/load_application_approvals_error',
  updateApplicationApproval: 'apps/update_application_approval',
  b2BreviewRequest: 'apps/b2b_review_request',
  loadB2BProvisioning: 'apps/load_b2b_provisioning',
  loadB2BProvisioningSuccess: 'apps/load_b2b_provisioning_success',
  loadCredentials: 'apps/load_credentials',
  loadCredentialsSuccess: 'apps/load_credentials_success',
  deleteCredential: 'apps/delete_credential',
};

const loadApplications = () => actionCreator(types.loadApplications);
const loadApplicationById = (id: number) => actionCreator(types.loadApplicationById);
const loadB2BApplications = () => actionCreator(types.loadB2BApplications);
const loadApplicationsSuccess = (applications: Application[]) => actionCreator(types.loadApplicationsSuccess, applications);
const loadB2BProvisioning = () => actionCreator(types.loadB2BProvisioning);
const loadApplicationByIdSuccess = (application: Application) => actionCreator(types.loadApplicationByIdSuccess, application);
const loadB2BApplicationsSuccess = (applications: B2BApplication[]) => actionCreator(types.loadB2BApplicationsSuccess, applications);
const loadB2BProvisioningSuccess = (provisioningContracts: B2BProvisioningContracts[]) => actionCreator(types.loadB2BProvisioningSuccess, provisioningContracts);
const loadApplicationsError = (error: string) => actionCreator(types.loadApplicationsError, error);
const loadB2BApplicationsError = (error: string) => actionCreator(types.loadB2BApplicationsError, error);
const addApplication = (application: Application) => actionCreator(types.addApplication, application);
const addB2BApplication = (application: B2BApplication) => actionCreator(types.addB2BApplication, application);
const updateApplication = (application: Application) => actionCreator(types.updateApplication, application);
const updateB2BApplication = (application: B2BApplication) => actionCreator(types.updateB2BApplication, application);
const deleteApplication = (id: number) => actionCreator(types.deleteApplication, id);
const deleteB2BApplication = (applicationGuid: string) => actionCreator(types.deleteB2BApplication, applicationGuid);
const loadApplicationApprovals = (appId: number) => actionCreator(types.loadApplicationApprovals, appId);
const loadApplicationApprovalsSuccess = (payload: { appId: number, approvals: ApplicationApproval[] }) => actionCreator(types.loadApplicationApprovalsSuccess, payload);
const loadApplicationApprovalsError = (payload: { appId: number, error: string }) => actionCreator(types.loadApplicationApprovalsError, payload);
const updateApplicationApproval = (payload: { appId: number, approval: ApplicationApproval }) => actionCreator(types.updateApplicationApproval, payload);
const b2BReviewRequest = (approvalRequest: B2BServiceReviewRequest) => actionCreator(types.b2BreviewRequest, approvalRequest);
const loadCredentials = () => actionCreator(types.loadCredentials);
const loadCredentialsSuccess = (credentials: CredentialDetail[]) => actionCreator(types.loadCredentialsSuccess, credentials);
const deleteCredential = (credentialId: string) => actionCreator(types.deleteCredential, credentialId);

export const appsActions = {
  loadApplications,
  loadApplicationById,
  loadB2BApplications,
  loadApplicationsSuccess,
  loadApplicationByIdSuccess,
  loadB2BApplicationsSuccess,
  loadApplicationsError,
  loadB2BApplicationsError,
  addApplication,
  addB2BApplication,
  updateApplication,
  updateB2BApplication,
  deleteApplication,
  deleteB2BApplication,
  loadApplicationApprovals,
  loadApplicationApprovalsSuccess,
  loadApplicationApprovalsError,
  updateApplicationApproval,
  b2BReviewRequest,
  loadB2BProvisioning,
  loadB2BProvisioningSuccess,
  loadCredentials,
  loadCredentialsSuccess,
  deleteCredential
};

export const appsTypes = types;

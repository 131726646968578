import { useMemo } from 'react';
import { useUserProfile } from '../../auth/hooks/use-user-profile.hook';
import { useB2C2Application } from './use-b2c2-application';

export function useB2C2IsApplicationOwner(appId: string): boolean | undefined {
  const { userProfile } = useUserProfile();
  const { b2c2Application,b2c2PendingApplication } = useB2C2Application(appId);

  const isApplicationOwner = useMemo<boolean | undefined>(() => {
    if (!b2c2Application || !userProfile) {
      return undefined;
    }
    return !!b2c2Application.owners.some(owner => owner === userProfile.username);
  }, [b2c2Application, userProfile]);

  return isApplicationOwner;
}

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useTableCoreStyles = makeStyles((theme: Theme) => ({
    tableHeader: {
        "&:focus": {
            outline: "1px dashed #132482",
            outlineOffset: "-4px"
        },
        display: "table-cell",
        padding: theme.spacing(2),
        fontSize: theme.typography.fontSize,
        textAlign: "left",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        verticalAlign: "inherit",
        color: "rgba(0, 0, 0, 0.87)",
        lineHeight: "1.5rem",
        minWidth: "200px"
    },
    tableData: {
        display: "table-cell",
        padding: theme.spacing(2),
        fontSize: theme.typography.fontSize,
        textAlign: "left",
        lineHeight: "1.43",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        letterSpacing: "0.01071em",
        verticalAlign: "inherit",
        fontWeight: 400
    },
    tableRow: {
        display: "table-row",
        outline: 0,
        verticalAlign: "middle",
    },
    tableBody:{
        display: "table-row-group"
    },
}));


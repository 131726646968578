import { arrayEquals } from './array-equals.service';

export function objectDiff<T extends Object>(a: T, b: T | null | undefined): Partial<T> {
  if (!b) {
    return a;
  }

  const aMap = new Map(Object.entries(a));
  const bMap = new Map(Object.entries(b));

  const resultObject = Object.keys(a)
    .filter(key => Array.isArray(aMap.get(key)) && Array.isArray(bMap.get(key))
      ? !arrayEquals(aMap.get(key), bMap.get(key))
      : aMap.get(key) !== bMap.get(key)
    )
    .reduce((result, key) => ({
      ...result,
      [key]: aMap.get(key)
    }), {});

  return resultObject as Partial<T>;
}

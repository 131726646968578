import React from 'react';
import { Table } from '@tanstack/react-table';
import Pagination from '@visa/vds/pagination';
import ButtonIcon from '@visa/vds/button-icon';
import PaginationControl from '@visa/vds/pagination-control';
import Icon from '@visa/vds/icon';
import PaginationPage from '@visa/vds/pagination-page';
import Select from "@visa/vds/select";
import { vdsStyleOverrides } from '../../../../vds-overrides.styles';

export interface ITableCore<T> {
  table: Table<T>;
  label: string;
}

export const TablePagination: React.FC<ITableCore<any>> = ({ table, label }) => {
  const vdsStyles = vdsStyleOverrides();
  return <Pagination ariaLabel={"Pagination for "+label} style={{ backgroundColor: "#F2F4F8", display: "flex", alignItems: "center" }} key={label}>
    {!!table.getSelectedRowModel().rows.length && <PaginationControl style={{ display: "flex", alignItems: "center", color: "black", marginRight: "20px" }}>
      {table.getSelectedRowModel().rows.length + " rows selected"}
    </PaginationControl>}
    <PaginationControl >
      <Select
        label="Row Per Page"
        onChange={(e: { target: { value: any; }; }) => table.setPageSize(Number(e.target.value))}
        className={vdsStyles.selectFullWidth}
      >
        {[5, 10, 15, 20].map(e => <option value={e} key={label + e}>{e}</option>)}
      </Select>
    </PaginationControl>
    <PaginationControl style={{ display: "flex", alignItems: "center", color: "black" }}>
      Showing {(table.getState().pagination.pageIndex) * (table.getState().pagination.pageSize) + 1} to {Math.min((table.getState().pagination.pageIndex + 1) * (table.getState().pagination.pageSize), table.getCoreRowModel().rows.length)}
    </PaginationControl>
    <PaginationControl style={{ display: "flex", alignItems: "center" }}  >
      <ButtonIcon ariaLabel="first page" iconType="light-tiny" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
        <Icon name="arrow-start" resolution="tiny" />
      </ButtonIcon>
    </PaginationControl>
    <PaginationControl style={{ display: "flex", alignItems: "center" }}>
      <ButtonIcon ariaLabel="previous page" iconType="light-tiny" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
        <Icon name="arrow-previous" resolution="tiny" />
      </ButtonIcon>
    </PaginationControl>
    <PaginationPage style={{ display: "flex", alignItems: "center", color: "black" }}>
      <div aria-current="page" aria-label={"page " + table.getState().pagination.pageIndex + 1}>{table.getState().pagination.pageIndex + 1}</div>
    </PaginationPage>
    <PaginationControl style={{ display: "flex", alignItems: "center" }}>
      <ButtonIcon ariaLabel="next page" iconType="light-tiny" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
        <Icon name="arrow-next" resolution="tiny" />
      </ButtonIcon>
    </PaginationControl>
    <PaginationControl style={{ display: "flex", alignItems: "center" }}>
      <ButtonIcon ariaLabel="last page" iconType="light-tiny" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
        <Icon name="arrow-end" resolution="tiny" />
      </ButtonIcon>
    </PaginationControl>
  </Pagination>;
}
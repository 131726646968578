import { makeStyles, Theme } from '@material-ui/core/styles';

export const importFromJsonStyles = makeStyles((theme: Theme) => ({
    "vdsDialogBoxCard":{
        "& > .vds-dialog-card":{
            maxWidth: "unset",
            width: "50vw"
        },
        "& > .vds-dialog-card > .vds-dialog-card--body":{
            padding: 10
        }
    },
    dropZone: {
        width: '100%',
        height: '60vh',
        border: '1px solid #B4B4C6',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    container: {
        width: '50vw',
        maxWidth: '100%'
    },
    root: {
        width: '100%'
    },
    btn: {
        borderRadius: 0,
        borderWidth: 2,
        color: '#fff',
        backgroundColor: '#003EA9',
        textTransform: 'none',
        fontSize: '18px',
        '&:hover': {
            color: '#003EA9',
            borderColor: '#003EA9'
        },
        height: 'auto',
        padding: '0px 5px'
    },
    dialogActionsSpacing: {
        marginRight: '20px'
    },
    fileNameBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
},
    fileNameBarItem: {
        paddingRight: '4px'
    },
    fileCloseBtn: {
        marginLeft: 'auto',
        fill: '#15195A'
    },
    fileIconBad: {
        fill: 'red'
    },
    fileIconGood: {
        fill: 'navy'
    },
}));

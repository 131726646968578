import { Grid, IconButton, useTheme } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Moment from 'moment';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { buttonStyles } from "../../../../styles/button.styles";
import { useProductApprovalPreviewStyles } from '../approvals/b2c2-product-approval-preview.styles';
import { applicationApprovalStatuses } from '../../../appsNew/model/application-approval';
import { useB2C2OwnedApplications } from '../../../appsNew/hooks/use-b2c2-owned-applications';
import { B2C2Application, B2C2ApplicationConsumer } from '../../../appsNew/model/application';
import { CancelSharp, CheckCircle } from '@material-ui/icons';
import AppDialog, { AppDialogStates } from '../../../appsNew/views/app-dialog/app-dialog';
import { networkAppsNewSrv } from '../../../appsNew/services/network-apps.service';
import { Helmet } from 'react-helmet';
import { TableCore } from '../../../appsNew/views/table-abstraction/table-core';
import { TablePagination } from '../../../appsNew/views/table-abstraction/table-pagination';
import { B2C2ConsumerAprovalFilter } from '../../../appsNew/views/table-abstraction/filter-implementation/b2c2-consumer-approval-filter';
import { ColumnDef, SortingState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';


export const B2C2AppApprovalRequests: React.FC = () => {
  let { appId } = useParams();
  const styles = useProductApprovalPreviewStyles();
  const { ownedApps, loading } = useB2C2OwnedApplications();
  const navigate = useNavigate();
  const btnStyles = buttonStyles();
  const theme = useTheme();
  const selectedApp: B2C2Application | any = ownedApps?.filter((app) => {
    return app.applicationGuid === appId
  })[0];
  const [dialog, setDialog] = React.useState<AppDialogStates>(AppDialogStates.Close);
  const handleDialog = useCallback((dialogState: AppDialogStates) => {
    console.log("handleDialog");
    setDialog(dialogState);
  }, []);
  const [isApproveFlow, setIsApproveFlow] = React.useState(true);
  const [activeConsumerId, setActiveConsumerId] = React.useState('');
  const [activeAppId, setActiveAppId] = React.useState('');

  let consumerRequests = selectedApp?.consumerRequests.map((consumer: any) => {
    return Object.assign({}, consumer, {
      requestStatus: consumer?.status
    })
  }).sort((a: B2C2ApplicationConsumer, b: B2C2ApplicationConsumer) => (a.status > b.status) ? -1 : (a.status > b.status) ? 1 : 0)

  let consumerRequestsSort = selectedApp?.consumerRequests.sort((a: B2C2ApplicationConsumer, b: B2C2ApplicationConsumer) => (a.status > b.status) ? -1 : (a.status > b.status) ? 1 : 0)

  useEffect(() => {
    if (dialog === AppDialogStates.Success) {

    }
  }, [dialog]);

  const handleApproveClick = useCallback(
    (appId: string, requestId: string) => {
      console.log("handleApproveClick");
      setIsApproveFlow(true);
      setActiveConsumerId(requestId);
      setActiveAppId(appId);
      handleDialog(AppDialogStates.ConfirmApproval);
    },
    [appId, navigate]
  );

  const handleCancelClick = useCallback(
    (appId: string, requestId: string) => {
      setIsApproveFlow(false);
      setActiveConsumerId(requestId);
      setActiveAppId(appId);
      handleDialog(AppDialogStates.ConfirmApproval);
    },
    [appId, navigate]
  );

  const handleSubmit = useCallback(async () => {
    handleDialog(AppDialogStates.Close);
    if (isApproveFlow) {
      const success = await networkAppsNewSrv.createB2C2AppConsumerRequestAsync(activeAppId, activeConsumerId, {
        status: 'APPROVED'
      });
      if (success) {
        handleDialog(AppDialogStates.Success);
      }
    } else {
      const success = await networkAppsNewSrv.createB2C2AppConsumerRequestAsync(activeAppId, activeConsumerId, {
        status: 'DENIED'
      });
      if (success) {
        handleDialog(AppDialogStates.Success);
      }
    }
  }, [handleDialog, isApproveFlow, activeAppId, activeConsumerId]);


  const handleBack = useCallback(() => {
    navigate(`/b2c/products/requests`);
  }, [navigate]);


  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState<string>("");
  const [filtredData, setFilteredData] = useState([] as any);
  const [multiReqStatus, setMultiReqStatus] = useState<string[]>(['PENDING']);

  useEffect(() => {
    let fdata = consumerRequestsSort?.filter((obj: any) => multiReqStatus.indexOf(obj.status) !== -1)
    setFilteredData(fdata);
  }, [multiReqStatus, consumerRequestsSort]);


  const columns = useMemo<ColumnDef<B2C2ApplicationConsumer, any>[]>(() => ([
    {
      header: 'Request Id',
      accessorKey: 'requestId',
    },
    {
      header: 'Consumer ID',
      accessorKey: 'subjectId',
    },
    {
      header: 'Username',
      accessorKey: 'identity',
    },
    {
      header: 'Date modified',
      accessorKey: 'lastModifiedAt',
      type: 'date',
      cell: (e) => <>{Moment(e.getValue()).format('MMM D YYYY')}</>,
    },
    {
      header: 'Status',
      accessorKey: 'status',
    },
    {
      header: 'Action',
      accessorKey: 'requestId',
      enableSorting: false,
      cell: ({ row: { original: { status, appId, requestId } } }) => {
        if (status === 'PENDING') {
          return (
            <>
              <IconButton onClick={() => handleApproveClick(appId, requestId)} aria-label={`Click to approve the consumer request details for request ID ${requestId}`} role="link">
                <CheckCircle style={{ color: 'green' }} />
              </IconButton>
              <IconButton onClick={() => handleCancelClick(appId, requestId)} aria-label={`Click to cancel the consumer request details for request ID ${requestId}`} role="link">
                <CancelSharp style={{ color: 'red' }} />
              </IconButton>
            </>
          )
        } else {
          return <div style={{ padding: '10px' }}>&nbsp;</div>;
        }
      },
    }
  ]), [handleApproveClick, handleCancelClick]);
  const table = useReactTable({
    columns: columns,
    data: filtredData,
    debugTable: false,
    manualPagination: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    state: {
      sorting,
      globalFilter: search
    },
    initialState: {
      pagination: {
        pageSize: 5,
        pageIndex: 0
      },
    },
  });
  return (
    <main>
      {dialog !== AppDialogStates.Close && (
        <AppDialog
          handleDialog={handleDialog}
          handleSubmit={handleSubmit}
          navLinkOnSuccess="/b2c/apps"
          dialog={dialog}
        />
      )}
      <Helmet>
        <title>B2C Consumer Requests | Visa Prototype Validation Platform</title>
      </Helmet>
      <TypographyHighLight variant='h5' headerTitle={<div style={{ color: 'black', marginBottom: theme.spacing(4) }}>B2C Consumer Requests</div>} />
      <B2C2ConsumerAprovalFilter
        multiReqStatus={multiReqStatus}
        dataEntries={consumerRequestsSort}
        search={search}
        setSearch={setSearch}
        setMultiReqStatus={setMultiReqStatus} />
      <TableCore table={table} caption='B2C Consumer Requests'></TableCore>
      <TablePagination label='B2C Consumer Requests controls' table={table}></TablePagination>
      <>
        <Divider className={styles.dividerB2BApproval} />
        <Grid container direction={'row'} spacing={3} justifyContent='space-between'>

          <Button variant="contained" onClick={handleBack} className={btnStyles.btnBlue} aria-label='Back to admin request approvals' role="link">Back</Button>

        </Grid>
      </>
    </main>
  );
};

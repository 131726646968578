import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { AppDialogStyles } from './app-dialog.styles';
import OneButtonDialog from '../../../../views/dialog/one-button-dialog';
import SuccessIcon from './icon-success.svg';
import { DialogStyles } from '../../../../views/dialog/dialog.styles';
import Box from '@mui/material/Box';
import { FormHelperText, TextField } from '@mui/material';
import { JUSTIFICATION_VALIDATION_REGEX, SLE_SA_VALIDATION_REGEX } from '../../../apps/constants';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';

export enum AppDialogStates {
    Close = 0,
    ConfirmChanges = 1,
    Success = 2,
    QuitEditing = 3,
    CancelChanges = 4,
    SubmitReview = 5,
    ConfirmApproval = 6,
}

export interface AppDialogProps {
    handleSubmit: () => Promise<void>;
    handleSubmitReview?: (sanumber: string, businessJustification: string) => void;
    handleDialog: (dialogState: AppDialogStates) => void;
    handleCancel?: () => void;
    handleEditComplete?: () => void;
    dialog: number;
    navLinkOnSuccess: string;
}

export default function AppDialog(dialogProps: AppDialogProps) {
    const styles = AppDialogStyles();
    const dialogStyles = DialogStyles();
    const closeDialog = useCallback(() => dialogProps.handleDialog(AppDialogStates.Close), [dialogProps]);
    const openQuitDialog = useCallback(() => dialogProps.handleDialog(AppDialogStates.QuitEditing), [dialogProps]);
    const [sanum, setSanum] = useState('SLE-SA-');
    const [justification, setJustification] = useState('');
    const [errors, setErrors] = useState({
        sanum: false,
        justification: false
    });

    const handleSubmitValue = useCallback(() => {
        if (!sanum || !justification) {
            setErrors(Object.assign({}, errors, {
                sanum: !sanum ? 'Required' : false,
                justification: !justification ? 'Required' : false,
            }));
            return false;
        }
        if (!SLE_SA_VALIDATION_REGEX.test(sanum)) {
            setErrors(Object.assign({}, errors, {
                sanum: 'Invalid SA Number. Please follow this format: SLE-SA-123456',
                justification: false
            }));
            return false;
        }
        if (!JUSTIFICATION_VALIDATION_REGEX.test(justification)) {
            setErrors(Object.assign({}, errors, {
                sanum: false,
                justification: 'Invalid justification. Only characters and numbers are allowed[_=&;,.%?-/ ].'
            }));
            return false;
        }
        if (dialogProps?.handleSubmitReview) {
            dialogProps?.handleSubmitReview(sanum, justification)
        }
    }, [sanum, justification, dialogProps.handleSubmitReview]);

    return (
        <div>
            <Dialog open={dialogProps.dialog === AppDialogStates.ConfirmChanges}
                role="dialog"
                aria-modal="true"
                aria-labelledby="dialog-title"
                onClose={(event, reason) => {
                    switch (reason) {
                        case "escapeKeyDown":
                            openQuitDialog();
                            break;
                        case "backdropClick":
                            break;
                        default:
                            openQuitDialog();
                            break;
                    }
                }}>
                <DialogTitle id="dialog-title" disableTypography>
                    <TypographyHighLight headerTitle={<b>Confirm changes</b>} component={"h2"} variant='h4'></TypographyHighLight>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" className={dialogStyles.content}>
                        Saving these changes will overwrite previous version.
                    </Typography>
                </DialogContent>
                <DialogActions className={dialogStyles.dialogActions}>
                    <Button onClick={dialogProps.handleSubmit} type="submit"
                        variant="outlined"
                        className={dialogStyles.shortBtn}>
                        Confirm
                    </Button>
                    <Button onClick={openQuitDialog} type="submit"
                        variant="outlined"
                        className={dialogStyles.shortBtn}>
                        Quit
                    </Button>
                </DialogActions>
            </Dialog>
            {
                dialogProps.dialog === AppDialogStates.Success &&
                <OneButtonDialog title={'Success!'} titleIcon={SuccessIcon} btnNavigate={dialogProps?.navLinkOnSuccess}
                    btnText={'Ok'}
                    content={'Your changes were saved.'} />
            }
            <Dialog open={dialogProps.dialog === AppDialogStates.QuitEditing}
                role="dialog"
                aria-modal="true"
                aria-labelledby="dialog-title"
                onClose={(event, reason) => {
                    switch (reason) {
                        case "escapeKeyDown":
                            openQuitDialog();
                            break;
                        case "backdropClick":
                            break;
                        default:
                            openQuitDialog();
                            break;
                    }
                }}
            >
                <DialogTitle id="dialog-title" disableTypography>
                    <TypographyHighLight headerTitle={<b>Quit editing?</b>} component={"h2"} />
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" className={dialogStyles.content}>
                        Changes you made so far will not be saved.
                    </Typography>
                </DialogContent>
                <DialogActions className={dialogStyles.dialogActions}>
                    <Button onClick={dialogProps.handleCancel} type="submit"
                        variant="outlined"
                        className={dialogStyles.longBtn}>
                        Yes, I Quit
                    </Button>
                    <Button onClick={closeDialog} type="submit"
                        variant="outlined"
                        className={dialogStyles.longBtn}>
                        Keep Editing
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogProps.dialog === AppDialogStates.CancelChanges}
                role="dialog"
                aria-modal="true"
                aria-labelledby="dialog-title"
                onClose={(event, reason) => {
                    switch (reason) {
                        case "escapeKeyDown":
                            openQuitDialog();
                            break;
                        case "backdropClick":
                            break;
                        default:
                            openQuitDialog();
                            break;
                    }
                }}
            >
                <DialogTitle id="dialog-title" className={styles.dialogTitleWidth} disableTypography>
                    <TypographyHighLight headerTitle={<b>Cancel changes?</b>} component={"h2"} />
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" className={dialogStyles.content}>
                        Your changes in this page will not be saved.
                    </Typography>
                </DialogContent>
                <DialogActions className={dialogStyles.dialogActions}>
                    <Button onClick={dialogProps.handleCancel} type="submit"
                        variant="outlined"
                        className={dialogStyles.shortBtn}>
                        Yes
                    </Button>
                    <Button onClick={closeDialog} type="submit"
                        variant="outlined"
                        className={dialogStyles.shortBtn}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogProps.dialog === AppDialogStates.SubmitReview} role="dialog"
                aria-modal="true"
                aria-labelledby="dialog-title"
                onClose={(event, reason) => {
                    switch (reason) {
                        case "escapeKeyDown":
                            openQuitDialog();
                            break;
                        case "backdropClick":
                            break;
                        default:
                            openQuitDialog();
                            break;
                    }
                }}>
                <DialogTitle id="dialog-title" disableTypography>
                    <TypographyHighLight headerTitle={<b>Submit for approval</b>} component={"h2"} />
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" className={dialogStyles.content}>
                        Your changes will be posted pending approval from the site manager.
                    </Typography>

                    <div>
                        <Box
                            component="form"
                            sx={{
                                "& > :not(style)": { m: 1, width: "54ch" },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="sa-number"
                                label="SLE-SA-Number"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => setSanum(e.target.value)}
                                error={errors.sanum}
                                required
                                value={sanum}
                            />
                            <div aria-live='assertive'>
                                {errors.sanum && <FormHelperText>Error: SLE SA Number is {errors.sanum}</FormHelperText>}
                            </div>
                            <TextField
                                id="business-justification"
                                label="Business Justification"
                                multiline
                                rows={4}
                                fullWidth
                                onChange={(e) => setJustification(e.target.value)}
                                error={errors.justification}
                                required
                            />
                            <div aria-live='assertive'>
                                {errors.justification && <FormHelperText>Business Justification {errors.justification}</FormHelperText>}
                            </div>
                        </Box>
                    </div>
                </DialogContent>
                <DialogActions className={dialogStyles.dialogActions}>
                    <Button onClick={handleSubmitValue} type="submit"
                        variant="outlined"
                        className={dialogStyles.shortBtn}>
                        Submit
                    </Button>
                    <Button onClick={closeDialog} type="submit"
                        variant="outlined"
                        className={dialogStyles.shortBtn}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogProps.dialog === AppDialogStates.ConfirmApproval}
                role="dialog"
                aria-modal="true"
                aria-labelledby="dialog-title"
                onClose={(event, reason) => {
                    switch (reason) {
                        case "escapeKeyDown":
                            openQuitDialog();
                            break;
                        case "backdropClick":
                            break;
                        default:
                            openQuitDialog();
                            break;
                    }
                }}>
                <DialogTitle id="dialog-title" disableTypography>
                    <TypographyHighLight headerTitle={<b>Confirm changes</b>} component={"h2"} variant='h4'></TypographyHighLight>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" className={dialogStyles.content}>
                        Do you wish to proceed.
                    </Typography>
                </DialogContent>
                <DialogActions className={dialogStyles.dialogActions}>
                    <Button onClick={dialogProps.handleSubmit} type="submit"
                        variant="outlined"
                        className={dialogStyles.shortBtn}>
                        Confirm
                    </Button>
                    <Button onClick={closeDialog} type="submit"
                        variant="outlined"
                        className={dialogStyles.shortBtn}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
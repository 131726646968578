import { Chip, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useProductPreviewStyles } from "../product-view/product-preview.styles";
import { ProductCreate } from '../../model/product-create';
import { ProductPreviewDetailsPlaceholder } from './product-preview-details-placeholder';
import { useMediaQuery, useTheme } from '@mui/material';

export interface ProductPreviewDetailsProps {
  details?: ProductCreate | null;
}

export const ProductPreviewDetails: React.FC<ProductPreviewDetailsProps> = ({ details }) => {
  const styles = useProductPreviewStyles();
  const theme = useTheme();
  const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));
  if (!details) {
    return (
      <ProductPreviewDetailsPlaceholder />
    );
  }

  return (
    <>
      <Grid container direction={mediaCheck ? 'column' : 'row'} spacing={3}>
        <Grid item xs={12} lg={2}><b>Product name</b></Grid>
        <Grid item xs={12} lg={10}>{details.name}</Grid>
        <Grid item xs={12} lg={2}><b>Documentation URL</b></Grid>
        <Grid item xs={12} lg={10}>{details.documentationUrl}</Grid>
        <Grid item xs={12} lg={2}><b>Context Root</b></Grid>
        <Grid item xs={12} lg={10}>{details.contextRoot}</Grid>
      </Grid>
      <Grid container direction={'row'} spacing={3}>
        <Grid item xs={12} lg={2}><b>Owners</b></Grid>
        <Grid item xs={12} lg={10}>
          {details.productOwners?.map((item, index) => (
            <Chip key={index} className={styles.chipStyleRB} variant="outlined" label={item} />
          ))}</Grid>
      </Grid>
  </>
  );
};

import React, { ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { GreyTooltip } from './tooltips.styles';

export enum Placement{
    leftStart="left-start",
    rightStart="right-start",
    bottomEnd="bottom-end",
    bottom="bottom"
}

export const InfoTooltip:React.FC<{placement:Placement, content:string, children:ReactElement}> = (({placement, content, children}) =>
   <GreyTooltip children={children} placement={placement} title={
            <Grid container>
                <Grid item xs={2} sm={2}>
                    <InfoIcon/>
                </Grid>
                <Grid item xs={10} sm={10}>
                    {content}
                </Grid>
            </Grid>
    }/>);
import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Select from '@visa/vds/select';
import { httpMethods } from "../../../../../../../model/http-method";
import { ProductEndpointUpdate } from "../../../../../../products/model/product-update";

export const EndpointMethodTypeCell: React.FC<Table<ProductEndpointUpdate>> = (table) => {
    const [methodTypeError, setMethodTypeError] = useState<string>("");
    const httpMethodsMap = httpMethods.reduce<Record<string, string>>(
        (acc, httpMethod) => ({ ...acc, [httpMethod]: httpMethod }),
        {}
    );
    return <Select
        nam="method_type"
        label="Http method *"
        fullWidth
        value={table.options.meta?.dirtyRow?.methodType || ''}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setMethodTypeError(table.options.meta?.isIndividualCellValid("methodType", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "methodType");
        }}
        onBlur={()=>setMethodTypeError(table.options?.meta?.isIndividualCellValid("methodType",table.options.meta.dirtyRow?.methodType) || "")}

        showErrorText={!!methodTypeError}
        errorText={methodTypeError}
        invalid={!!methodTypeError}
    >
        <option value="" disabled> Select Http Method</option>
        {Object.entries(httpMethodsMap).map(([key, value]) => <option key={key} value={value}>{key}</option>)}
    </Select>
}
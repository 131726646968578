import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {drawerWidth} from '../app-drawer/app-drawer.styles';

export const appHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    emptyBar: {
      paddingLeft: theme.spacing(10),
    },
    appBar: {
      backgroundColor:'#1A1F71 !important',
      color: '#fff !important',
      paddingLeft: theme.spacing(10),
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      marginLeft: theme.spacing(3),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
  }),
);

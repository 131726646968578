import { Grid, IconButton, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { B2C2ApplicationB2C2BArgs } from "../../model/application";
import { B2C2ApplicationB2C2BArgsMle } from '../../model/application';
import { B2C2ApplicationB2C2BArgsAuthN } from '../../model/application';
import { useAppPreviewStyles } from './app-preview.styles';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { B2C2AppFileDownload } from './b2c2-app-file-download';
import { X_PAY_TOKEN } from '../../constants';
import { TWO_WAY_SSL } from '../../constants';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../table-abstraction/table-core';

export interface B2C2AppPreviewB2C2BArgsProps {
  b2c2bArgs: B2C2ApplicationB2C2BArgs | undefined;
  isApplicationOwner: boolean,
}

interface AppDialogStates {
  authnId: string,
  sharedSecret: boolean,
  apiKey: boolean,
  basicAuthUserId: boolean,
  basicAuthPassword: boolean,
};

export const B2C2AppPreviewB2C2BArgs: React.FC<B2C2AppPreviewB2C2BArgsProps> = ({ b2c2bArgs, isApplicationOwner }) => {
  const styles = useAppPreviewStyles();

  const initalAuthnState = b2c2bArgs?.authN.map((auth) => {
    return { authnId: auth.authnId, sharedSecret: false, apiKey: false, basicAuthUserId: false, basicAuthPassword: false };
  });
  const [authnState, setAuthnState] = React.useState<AppDialogStates[]>(!!initalAuthnState ? initalAuthnState : []);

  const handleHideSecret = (authnId: string, field: string) => {
    if (!!authnState) {
      return setAuthnState(authnState.map((authn: AppDialogStates) => (authn.authnId === authnId ? { ...authn, [field]: !authn[field as keyof AppDialogStates] } : authn)));
    }
  }


  const mleColumns = useMemo<ColumnDef<B2C2ApplicationB2C2BArgsMle>[]>(() => ([
    {
      header: 'MLE Key Id',
      accessorKey: 'mleKeyId',
    },
    {
      header: 'MLE Status',
      accessorKey: 'mleStatus',
    },
    {
      header: 'MLE Client Key Id',
      cell: ({ row: { original: { mleClientKeyId } } }) => (
        <div>
          {mleClientKeyId}
          <B2C2AppFileDownload filename="mleClientKey.pem" credentialId={mleClientKeyId} isDownloadAllowed={isApplicationOwner} />
        </div>
      ),
    },
    {
      header: 'MLE Server Cert Id',
      cell: ({ row: { original: { mleServerCertId } } }) => (
        <div>
          {mleServerCertId}
          <B2C2AppFileDownload filename="mleClientKey.pem" credentialId={mleServerCertId} isDownloadAllowed={isApplicationOwner} />
        </div>
      ),
    }
  ]), []);
  const mleRows = useMemo<B2C2ApplicationB2C2BArgsMle[]>(() => b2c2bArgs?.mle ? b2c2bArgs.mle.map(mle => ({ ...mle })) : [], [b2c2bArgs]);
  const mleTableDatas = useReactTable({
    columns: mleColumns,
    data: mleRows,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false
  });

  const authColumns = useMemo<ColumnDef<B2C2ApplicationB2C2BArgsAuthN>[]>(() => ([
    {
      header: 'Type',
      accessorKey: 'type',
    },
    {
      header: 'Shared Secret',
      cell: ({ row: { original: { type, attr, authnId } } }) => (
        <>
          {attr.sharedSecret ?
            <>
              {authnState.filter((s) => s.authnId === authnId)[0]?.sharedSecret ? attr.sharedSecret : "********"}
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleHideSecret(authnId, "sharedSecret")}
              >
                {authnState.filter((s) => s.authnId === authnId)[0].sharedSecret ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </>
            : (type === X_PAY_TOKEN ? <>********</> : <>N/A</>)
          }
        </>
      ),
    },
    {
      header: 'API Key',
      cell: ({ row: { original: { type, attr, authnId } } }) => (
        <>
          {attr.apiKey ?
            <>
              {authnState.filter((s) => s.authnId === authnId)[0]?.apiKey ? attr.apiKey : "********"}
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleHideSecret(authnId, "apiKey")}
              >
                {authnState.filter((s) => s.authnId === authnId)[0].apiKey ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </>
            : (type === X_PAY_TOKEN ? <>********</> : <>N/A</>)
          }
        </>
      ),
    },
    {
      header: 'Client Key Id',
      cell: ({ row: { original: { type, attr } } }) => (
        <>
          {attr.clientKeyId ? <>
            {attr.clientKeyId}
            <B2C2AppFileDownload filename="clientKey.pem" credentialId={attr.clientKeyId} isDownloadAllowed={isApplicationOwner} /> </>
            : (type === TWO_WAY_SSL ? <>********</> : <>N/A</>)
          }
        </>
      ),
    },
    {
      header: 'Server Cert Id',
      cell: ({ row: { original: { type, attr } } }) => (
        <>
          {attr.serverCertId ?
            <>{attr.serverCertId}
              <B2C2AppFileDownload filename="serverCert.pem" credentialId={attr.serverCertId} isDownloadAllowed={isApplicationOwner} /> </>
            : (type === TWO_WAY_SSL ? <>********</> : <>N/A</>)
          }
        </>
      ),
    },
    {
      header: 'Basic Auth User Id',
      cell: ({ row: { original: { type, attr, authnId } } }) => (
        <>
          {attr.basicAuthUserId ?
            <>
              {authnState.filter((s) => s.authnId === authnId)[0]?.basicAuthUserId ? attr.basicAuthUserId : "********"}
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleHideSecret(authnId, "basicAuthUserId")}
              >
                {authnState.filter((s) => s.authnId === authnId)[0].basicAuthUserId ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </>
            : (type === TWO_WAY_SSL ? <>********</> : <>N/A</>)
          }
        </>
      ),
    },
    {
      header: 'Basic Auth Password',
      cell: ({ row: { original: { type, attr, authnId } } }) => (
        <>
          {attr.basicAuthPassword ?
            <>
              {authnState.filter((s) => s.authnId === authnId)[0]?.basicAuthPassword ? attr.basicAuthPassword : "********"}
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleHideSecret(authnId, "basicAuthPassword")}
              >
                {authnState.filter((s) => s.authnId === authnId)[0].basicAuthPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </>
            : (type === TWO_WAY_SSL ? <>********</> : <>N/A</>)
          }
        </>
      ),
    },
    {
      header: 'WSI External Id',
      cell: ({ row: { original: { attr } } }) => (
        <>
          {attr?.wsiExternalId ? attr.wsiExternalId : <>N/A</>}
        </>
      ),
    }
  ]), [authnState]);
  const authRows = useMemo<B2C2ApplicationB2C2BArgsAuthN[]>(() => b2c2bArgs?.authN ? b2c2bArgs.authN.map(authN => ({ ...authN })) : [], [b2c2bArgs]);
  const authTableDatas = useReactTable({
    columns: authColumns,
    data: authRows,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false
  });
  return (
    <div>
      <Typography component="div" className={styles.details}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} lg={2}><b>VDP App Id</b></Grid>
          <Grid item xs={12} lg={10}>{b2c2bArgs?.vdpAppId}</Grid>
        </Grid>
      </Typography>
      {!!b2c2bArgs ? <>
        <TableCore caption='MLEs' table={mleTableDatas} />
        <TableCore caption='AuthN : Auth type in column 1 and details in the next 7 columns' table={authTableDatas} />
      </>
        : <></>}
    </div>
  );
};

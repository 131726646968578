import { config } from '../../../config';
import { networkSrv } from '../../../services/network.service';
import { ProductEndpoints } from '../model/product';
import { ProductEndpointCreate } from '../model/product-create';
import { ProductEndpointRQ, ProductEndpointsRQ, ProductEndpointsRS } from './dtos';
import { mapProductEndpoints, mapProductEndpointsCreate, mapProductEndpointsUdpate } from './model-maps';

export async function addProductEndpointsAsync(productId: string, endpoints: ProductEndpointCreate[]): Promise<ProductEndpoints[]> {
  const endpointsRQ = {"productEndpoints":endpoints.map(mapProductEndpointsCreate)};
  const endpointsRS = await networkSrv.postAsync<ProductEndpointRQ, ProductEndpointsRS[]>(`${config.urls.products}/${productId}/endpoints`, endpointsRQ);
  return endpointsRS.map(mapProductEndpoints);
}

export function deleteProductEndpointAsync(productId: string, endpoint: Partial<ProductEndpoints>): Promise<void> {
  const endpointId = endpoint.endpointId;
  return networkSrv.deleteAsync(`${config.urls.products}/${productId}/endpoints/${endpointId}`);
}

export function updateProductEndpointsAsync(productId: string, endpoint: Partial<ProductEndpoints>): Promise<void> {
  const endpointId = endpoint.endpointId;
  const productEndpointRQ = mapProductEndpointsUdpate(endpoint);
  return networkSrv.patchAsync<Partial<ProductEndpointsRQ>, void>(`${config.urls.products}/${productId}/endpoints/${endpointId}`, productEndpointRQ);
}

export const networkProductEndpointsSrv = {
  addProductEndpointsAsync,
  deleteProductEndpointAsync,
  updateProductEndpointsAsync,
}

import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { ChangeEvent, MutableRefObject, useRef } from "react";
import { Table } from "@tanstack/react-table";
import { useNavigate } from "react-router";
import { Product } from "../../../../products/model/product";
import Select from '@visa/vds/select';
import ButtonText from '@visa/vds/button-text';
import { SearchBar } from "../common-component/search-bar";
import { vdsStyleOverrides } from "../../../../../vds-overrides.styles";
export interface IB2C2productListFilter {
    table: Table<any>;
    b2c2Products: Product[];
    ownedProducts: Product[];
    dataOption: DataOptionsType;
    changeDataOptions: Function;
    search: string;
    setSearch: Function;
}
export type DataOptionsType = 'all' | 'my';
export const B2C2ProductListFilter: React.FC<IB2C2productListFilter> = ({ b2c2Products, ownedProducts, dataOption, table, changeDataOptions, search, setSearch }) => {
    const theme = useTheme();
    const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));
    const navigate = useNavigate();
    const vdsStyles = vdsStyleOverrides();
    return <Grid container style={{ backgroundColor: "#F2F4F8" }} justifyContent={mediaCheck ? "space-between" : "flex-start"} alignItems={"center"} rowGap={2} padding={1} >
        <Grid item xs={12} lg={5}>
            <Typography variant="h5" component={"h2"} style={{ fontWeight: 500, margin: "8px" }}>
                Total Products: {b2c2Products.length}
            </Typography>
            <Typography variant="h5" component={"h2"} style={{ fontWeight: 500, margin: "8px" }}>
                My Products: {ownedProducts.length}
            </Typography>
        </Grid>
        <Grid item xs={12} lg={7}>
            <Grid container alignItems={"center"} justifyContent={!mediaCheck ? "flex-end" : undefined} rowGap={2} padding={1} columnGap={2}>
                <Grid item xs={12} lg={4}>
                <Select
                        name="product_Filter"
                        label="Products Filter"
                            value={dataOption}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                if (e.target.value === "all") {
                                    changeDataOptions('all')
                                } else {
                                    changeDataOptions('my')
                                }
                            }}
                        defaultValue={"my"}
                        className={vdsStyles.selectFullWidth}
                        >
                        <option value="all">All Products</option>
                        <option value="my">My Products</option>
                    </Select>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <SearchBar label="Search B2C Product" search={search} setSearch={setSearch}></SearchBar>
                </Grid>
                <Grid item xs={12} lg={3}>
                <ButtonText id="list-add" type="submit"
                    variant="outlined"
                    role="button"
                    isFullWidth
                    onClick={() => {
                        navigate('add', {
                            relative: 'path'
                        });
                    }}
                >
                    Add  Product
                </ButtonText>
                </Grid>
            </Grid>
        </Grid>
    </Grid>;
}
import { IconButton, useTheme } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Moment from 'moment';
import { useB2C2OwnedApplications } from '../../../appsNew/hooks/use-b2c2-owned-applications';
import { B2C2Application } from '../../../appsNew/model/application';
import { TableCore } from '../../../appsNew/views/table-abstraction/table-core';
import { TablePagination } from '../../../appsNew/views/table-abstraction/table-pagination';
import { B2C2ConsumerRequestFilter } from '../../../appsNew/views/table-abstraction/filter-implementation/b2c2-consumer-request-filter';
import { Helmet } from 'react-helmet';
import { ColumnDef, SortingState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';

export const B2C2ConsumerRequest: React.FC = () => {
  const { ownedApps, loading } = useB2C2OwnedApplications();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleAppClick = useCallback(
    (id: string) => navigate(`/b2c/apps/${id}/approvals`),
    [navigate]
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState<string>("");
  const columns = useMemo<ColumnDef<B2C2Application, any>[]>(() => ([
    {
      header: 'App name',
      accessorKey: 'name',
    },
    {
      header: 'App Id',
      accessorKey: 'applicationGuid',
    },
    {
      header: 'Description',
      accessorKey: 'description',
    },
    {
      header: 'Date modified',
      accessorKey: 'lastModifiedAt',
      type: 'datetime',
      cell: (e) => <>{Moment(e.getValue()).format('MMM D YYYY')}</>,
    },
    {
      header: 'Action',
      accessorKey: 'applicationGuid',
      enableSorting: false,
      cell: (e) => {
        return <IconButton
          id={e.getValue()}
          onClick={() => handleAppClick(e.getValue())}
          aria-label={`Click for consumer request details for application GUID ${e.getValue()}`}
          role="link"><VisibilityOutlinedIcon fontSize="small"
            color="primary" /></IconButton>
      },
    },
  ]), [handleAppClick]);
  const table = useReactTable({
    columns: columns,
    data: ownedApps,
    debugTable: true,
    manualPagination: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    state: {
      sorting,
      globalFilter: search
    },
    initialState: {
      pagination: {
        pageSize: 5,
        pageIndex: 0
      },
    },
  });
  return (

    <main>
      <Helmet>
        <title>B2C Consumer Requests | Visa Prototype Validation Platform</title>
      </Helmet>
      <TypographyHighLight variant='h5' headerTitle={<div style={{ color: 'black', marginBottom: theme.spacing(4) }}>B2C Consumer Requests</div>} />
      <B2C2ConsumerRequestFilter
        dataEntries={ownedApps}
        search={search}
        setSearch={setSearch}
      />
      <TableCore table={table} caption='B2C Consumer Requests'></TableCore>
      <TablePagination label='B2C Consumer Requests controls' table={table}></TablePagination>
    </main>
  );
};

import React, { useCallback, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { DialogActions, DialogContent } from '@material-ui/core';
import { DialogTitle } from '../../../../views/dialog/dialog-title';
import { buttonStyles } from '../../../../styles/button.styles';
import clsx from 'clsx';
import { ProductDialogStyles } from './product-dialog.styles';
import Box from '@mui/material/Box';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { JUSTIFICATION_VALIDATION_REGEX, SA_VALIDATION_REGEX, SLE_SA_VALIDATION_REGEX } from '../../../apps/constants';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';

export interface ProductDialogProps {
  handleSubmit?: () => Promise<void>;
  handleSubmitReview?: (sanumber: string, businessJustification: string) => void;
  handleDialog: (index: number) => void;
  handleCancel?: () => void;
  handleEditComplete?: () => void;
  dialog: number;
  message?: string;
  prefilledSA?: string;
  dialogTitle?: string;
}

export default function ProductDialog(dialogProps: ProductDialogProps) {
  const styles = ProductDialogStyles();
  const btnStyles = buttonStyles();
  const closeDialog = useCallback(() => dialogProps.handleDialog(0), [dialogProps]);
  const openQuitDialog = useCallback(() => dialogProps.handleDialog(3), [dialogProps]);
  const [justification, setJustification] = useState('');
  const { prefilledSA = '' } = dialogProps;
  const [sanum, setSanum] = useState(prefilledSA);
  const [errors, setErrors] = useState({
    sanum: false,
    justification: false
  });

  const [changeJustification, setChangeJustification] = useState('');
  const [changeRequestNumber, setChangeRequestNumber] = useState('');
  const [changeDetailsError, setChangeDetailsError] = useState({
    changeRequestNumber: false,
    changeJustification: false
  });

  function handleB2C2SubmitValue() {
    if (!sanum || !justification) {
      setErrors(Object.assign({}, errors, {
        sanum: !sanum ? 'Required' : false,
        justification: !justification ? 'Required' : false,
      }));
      return false;
    }
    if (!SLE_SA_VALIDATION_REGEX.test(sanum)) {
      setErrors(Object.assign({}, errors, {
        sanum: 'Invalid SA Number. Please follow this format: SLE-SA-123456',
        justification: false
      }));
      return false;
    }
    if (!JUSTIFICATION_VALIDATION_REGEX.test(justification)) {
      setErrors(Object.assign({}, errors, {
        sanum: false,
        justification: 'Invalid justification. Only characters and numbers are allowed[_=&;,.%?-/ ].'
      }));
      return false;
    }
    if (dialogProps?.handleSubmitReview) {
      dialogProps!.handleSubmitReview(sanum, justification);
      //window.location.reload();
    } else {
      if (dialogProps?.handleSubmit) {
        dialogProps!.handleSubmit();
      }
    }
  }

  function handleSubmitValue() {
    if (!sanum || !justification) {
      setErrors(Object.assign({}, errors, {
        sanum: !sanum ? 'Required' : false,
        justification: !justification ? 'Required' : false,
      }));
      return false;
    }
    if (!SA_VALIDATION_REGEX.test(sanum)) {
      setErrors(Object.assign({}, errors, {
        sanum: 'Invalid SA Number. Please follow this format: SA-123456',
        justification: false
      }));
      return false;
    }
    if (!JUSTIFICATION_VALIDATION_REGEX.test(justification)) {
      setErrors(Object.assign({}, errors, {
        sanum: false,
        justification: 'Invalid justification. Only characters and numbers are allowed[_=&;,.%?-/ ].'
      }));
      return false;
    }
    if (dialogProps?.handleSubmitReview) {
      dialogProps!.handleSubmitReview(sanum, justification);
      //window.location.reload();
    } else {
      if (dialogProps?.handleSubmit) {
        dialogProps!.handleSubmit();
      }
    }
  }

  const handleChangeDetailsSubmitValue = useCallback(async () => {
    if (!changeRequestNumber ||
      changeRequestNumber.length < 3 ||
      !(changeRequestNumber.substring(0, 3).toLowerCase() === 'chg') ||
      !changeJustification) {
      setChangeDetailsError(Object.assign({}, changeDetailsError, {
        changeRequestNumber: !changeRequestNumber ? 'Required' : (changeRequestNumber.length < 3 ||
          !(changeRequestNumber.substring(0, 3).toLowerCase() === 'chg')) ?
          'Change Request Number must start with CHG' : false,
        changeJustification: !changeJustification ? 'Required' : false,
      }));
      return false;
    }
    closeDialog();
  }, [changeRequestNumber, changeJustification, changeDetailsError]);

  return (
    <div>
      {/* <Dialog open={dialogProps.dialog === 1}>
                <DialogTitle id="dialog-title">
                    <b>Request changes</b>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" className={styles.content}>
                        Your changes will be posted pending approval from the site manager.
                    </Typography>
                </DialogContent>
                <DialogActions className={styles.dialogActions}>
                    <Button autoFocus onClick={dialogProps.handleSubmit} type="submit"
                            variant="outlined"
                            className={clsx(btnStyles.btnBlue, styles.longBtn)}>
                        Request Change
                    </Button>
                    <Button autoFocus onClick={openQuitDialog} type="submit"
                            variant="outlined"
                            className={clsx(btnStyles.btnBlue, styles.shortBtn)}>
                        Quit
                    </Button>
                </DialogActions>
            </Dialog> */}

      <Dialog open={dialogProps.dialog === 1} role="dialog" aria-modal="true" aria-labelledby="dialog-title" disableEscapeKeyDown={false}>
        <DialogTitle id="dialog-title">
          <b>Submit for approval</b>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" className={styles.content}>
            Your changes will be posted pending approval from the site
            manager
          </Typography>
          <div>
            {" "}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "54ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                {" "}
                <TextField
                  onChange={(e) => setSanum(e.target.value)}
                  id="sa-number"
                  label="SLE-SA-Number"
                  variant="outlined"
                  fullWidth
                  required
                  error={errors.sanum}
                  value={sanum}
                />
                {errors.sanum && <FormHelperText>{errors.sanum}</FormHelperText>}
              </div>
              <div>
                {" "}
                <TextField
                  onChange={(e) => setJustification(e.target.value)}
                  id="business-justification"
                  label="Business Justification"
                  multiline
                  rows={4}
                  fullWidth
                  required
                  error={errors.justification}
                />
                {errors.justification && <FormHelperText>{errors.justification}</FormHelperText>}
              </div>
            </Box>
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            autoFocus
            onClick={handleB2C2SubmitValue}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={closeDialog}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/*      <Dialog open={dialogProps.dialog === 2}>
          <DialogTitle id="dialog-title">
            <b>We received your edit request</b>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" className={styles.content}>
              A notification will be sent with regards to your edit request.
            </Typography>
          </DialogContent>
          <DialogActions className={styles.dialogActions}>
            <Button
              autoFocus
              type="submit"
              onClick={dialogProps.handleEditComplete}
              variant="outlined"
              className={clsx(btnStyles.btnBlue, styles.shortBtn)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog> */}

      <Dialog role="dialog" aria-modal="true" aria-labelledby="dialog-title" open={dialogProps.dialog === 2}>
        <DialogTitle id="dialog-title">
          <b>Your changes are saved successfully</b>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" className={styles.content}>
            You will be redirected to preview page
          </Typography>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            autoFocus
            type="submit"
            onClick={dialogProps.handleEditComplete}
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog role="dialog" aria-modal="true" aria-labelledby="dialog-title" open={dialogProps.dialog === 3}>
        <DialogTitle id="dialog-title">
          <b>Quit editing?</b>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" className={styles.content}>
            Changes you made so far will not be saved.
          </Typography>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            autoFocus
            onClick={dialogProps.handleCancel}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.longBtn)}
          >
            Yes, I Quit
          </Button>
          <Button
            autoFocus
            onClick={closeDialog}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.longBtn)}
          >
            Keep Editing
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog role="dialog" aria-modal="true" aria-labelledby="dialog-title" open={dialogProps.dialog === 4}>
        <DialogTitle id="dialog-title" className={styles.dialogTitleWidth}>
          <b>Cancel changes?</b>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" className={styles.content}>
            Your changes in this page will not be saved.
          </Typography>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            autoFocus
            onClick={dialogProps.handleCancel}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Yes
          </Button>
          <Button
            autoFocus
            onClick={closeDialog}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog role="dialog" aria-modal="true" aria-labelledby="dialog-title" open={dialogProps.dialog === 5} disableBackdropClick onEscapeKeyDown={closeDialog}>
        <DialogTitle id="dialog-title">
          {
            dialogProps?.dialogTitle ? <b>{dialogProps?.dialogTitle}</b> : <b>Service Approval</b>
          }
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" className={styles.content}>
            By clicking the OK button, you're   {dialogProps.message}  approval.
          </Typography>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            onClick={dialogProps.handleSubmit}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            OK
          </Button>
          <Button
            onClick={closeDialog}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog role="dialog" aria-modal="true" aria-labelledby="dialog-title" open={dialogProps.dialog === 6}>
        <DialogTitle id="dialog-title">
          <b>Submit for approval</b>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" className={styles.content}>
            Your changes will be posted pending approval from the site
            manager.
          </Typography>
        </DialogContent>
        {<DialogActions className={styles.dialogActions}>
          <Button
            autoFocus
            onClick={handleSubmitValue}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={openQuitDialog}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Quit
          </Button>
        </DialogActions>}
      </Dialog>

      <Dialog role="dialog" aria-modal="true" aria-labelledby="dialog-title" open={dialogProps.dialog === 7}>
        <DialogTitle id="dialog-title">
          <b>Host's Change Details</b>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" className={styles.content}>
            Service host's change details are tracked for auditing purposes
          </Typography>
          <div>
            {" "}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "54ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                {" "}
                <TextField
                  onChange={(e) => setChangeRequestNumber(e.target.value)}
                  value={changeRequestNumber}
                  id="changeRequestNumber"
                  name="changeRequestNumber"
                  label="Change Request Number"
                  variant="outlined"
                  fullWidth
                  required
                  error={changeDetailsError.changeRequestNumber}
                />
                {changeDetailsError.changeRequestNumber && <FormHelperText>{changeDetailsError.changeRequestNumber}</FormHelperText>}
              </div>
              <div>
                {" "}
                <TextField
                  onChange={(e) => setChangeJustification(e.target.value)}
                  value={changeJustification}
                  id="changeJustification"
                  name="changeJustification"
                  label="Change Justification"
                  multiline
                  rows={4}
                  fullWidth
                  required
                  error={!changeJustification && changeDetailsError.changeJustification}
                />
                {changeDetailsError.changeJustification && <FormHelperText>{changeDetailsError.changeJustification}</FormHelperText>}
              </div>
            </Box>
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            autoFocus
            onClick={handleChangeDetailsSubmitValue}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={closeDialog}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog role="dialog" aria-modal="true" aria-labelledby="dialog-title" open={dialogProps.dialog === 8} onEscapeKeyDown={closeDialog}>
        <DialogTitle id="dialog-title" disableTypography>
          <TypographyHighLight headerTitle={<b>Note:</b>} component={"h2"}></TypographyHighLight>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" className={styles.content}>
            Ensure firewall is opened from b2b-gateway to your host.
            <br />
            <b>If Auto DC is enabled: </b>
            Ensure firewall is also opened from b2b-onboarding to your host.
          </Typography>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            onClick={closeDialog}
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog role="dialog" aria-modal="true" aria-labelledby="dialog-title" open={dialogProps.dialog === 9} disableBackdropClick>
        <DialogTitle id="dialog-title" disableTypography >
          <TypographyHighLight headerTitle='Submit for approval' component={"h2"} />
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" className={styles.content}>
            Your changes will be posted pending approval from the site
            manager
          </Typography>
          <div>
            {" "}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "54ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                {" "}
                <TextField
                  onChange={(e) => setSanum(e.target.value)}
                  id="sa-number"
                  label="SA-Number"
                  variant="outlined"
                  fullWidth
                  required
                  error={errors.sanum}
                  value={sanum}
                />
                {errors.sanum && <FormHelperText>{errors.sanum}</FormHelperText>}
              </div>
              <div>
                <FormControl>

                  <FormLabel htmlFor='business-justification' required>Business Justification</FormLabel>
                  <textarea
                    cols={50}
                    style={{ resize: "none", width: "100%" }}
                    onChange={(e) => setJustification(e.target.value)}
                    id="business-justification"
                    rows={4}
                    required
                    aria-invalid={errors.justification}
                  />
                  {errors.justification && <FormHelperText>{errors.justification}</FormHelperText>}
                </FormControl>
              </div>
            </Box>
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            autoFocus
            onClick={handleSubmitValue}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={closeDialog}
            type="submit"
            variant="outlined"
            className={clsx(btnStyles.btnBlue, styles.shortBtn)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Button, Container, Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import React, { RefObject, useCallback, useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorsStyles } from './errors.styles';
import { Helmet } from 'react-helmet';
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../state/skip-to-main-store';
import { TypographyHighLight } from '../../components/app-header-highlight/typographyHighlight';

export const NotFoundErrorPage: React.FC = () => {
  const styles = useErrorsStyles();
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleGoHome = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  const ref: RefObject<HTMLHeadingElement> = useRef<HTMLHeadingElement>(null);
  const context: LandmarkContextType = useContext(LandmarkContext);
  useLandmarkHook(ref, context[1]);
  useHighlightH1(ref);
  return (
  <> 
  <Helmet>
    <title>Not Found | Visa Prototype Validation Platform </title>
  </Helmet>
  <main>
    <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center" className={styles.root}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Container maxWidth="sm">
        <TypographyHighLight headerTitle="Oops!" variant='h5' />
          <h2>404 Not Found</h2>
          <div>Sorry, an error has occured. Requested page not found!</div>
          <div className={styles.marginTop}>
            <Button variant="contained" color="primary" onClick={handleGoBack}>
              <ArrowBackIcon /> Go back
            </Button>
            <Button variant="contained" color="primary" className={styles.marginLeft} onClick={handleGoHome}>
              <HomeIcon /> Take me home
          </Button>
          </div>
        </Container>
      </Grid>
    </Grid>
    </main></>
  );
}

import { useBlockingLoading } from '../../../hooks/use-blocking-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { useDispatch } from '../../../state/app-store';
import { B2C2ApplicationCreate } from '../model/application-create';
import { networkAppsNewSrv } from '../services/network-apps.service';
import { appsActions } from '../state/apps-actions';

export interface UseB2C2ApplicationCreateResult {
  loading: boolean;
  createB2C2ApplicationAsync: (b2c2Application: B2C2ApplicationCreate) => Promise<createApplicationResponse>;
}

type createApplicationResponse = {
  status: boolean,
  applicationGuid?: string
}

export function useB2C2ApplicationCreate(): UseB2C2ApplicationCreateResult {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useBlockingLoading();
  const { pushNotification } = useNotifications();

  const createB2C2ApplicationAsync = async (b2c2Application: B2C2ApplicationCreate): Promise<createApplicationResponse> => {
    startLoading();
    try {

      //Additional validations
      let appConfigs = b2c2Application.configs || [];
      let mgtAppConfig = appConfigs.find((config : any) => config.configKey === 'vp2_user_mgmt');
      const { templates, ... applicationWithoutTemplates } = b2c2Application;
      const app = await networkAppsNewSrv.createB2C2ApplicationAsync(((mgtAppConfig && !mgtAppConfig.configValue) ? applicationWithoutTemplates : b2c2Application));

      dispatch(appsActions.addB2C2Application(app));
      const val:createApplicationResponse ={
        status: true,
        applicationGuid : app.applicationGuid
      }
      return val;

    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      pushNotification({
        message: `Create application failed with '${error}'. Please, try again later.`,
        variant: 'error',
        type: 'bar',
      });
      const val:createApplicationResponse ={
        status: false,
        applicationGuid : ' '
      }
      return val;
    } finally {
      stopLoading();
    }
  };

  return {
    loading,
    createB2C2ApplicationAsync,
  };
}


import React, { useMemo } from 'react';
import { B2C2ApplicationHeaders } from "../../model/application";
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../table-abstraction/table-core';

export interface B2C2AppPreviewHeadersProps {
  headers: B2C2ApplicationHeaders[] | undefined;
}

export const B2C2AppPreviewHeaders: React.FC<B2C2AppPreviewHeadersProps> = ({ headers }) => {
  
  const columns: ColumnDef<B2C2ApplicationHeaders, any>[] = useMemo(() => [
    {
        header: 'Header Id',
        accessorKey: 'headerId'
    },
    {
        header: 'Application Header Id',
        accessorKey: 'appHeaderId'
    },
    {
        header: 'Header Value',
        accessorKey: 'headerValue'
    }
], []);
const rows = useMemo<B2C2ApplicationHeaders[]>(() => headers ? headers.map(header => ({ ...header })) : [], [headers]);
const tableDatas = useReactTable({
    columns: columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false
});

if(!headers) { return null;}

  return (<>
    <TableCore caption='Allowed Headers' table={tableDatas}></TableCore>
</>
  );
};

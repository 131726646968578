import { Grid, Typography } from '@material-ui/core';
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { B2BOperation, B2BService } from '../../../services/model/service';
import { appEditStyles, hostTheme } from './app-edit.styles';
import { GreyTooltip } from '../../../../components/tooltips/tooltips.styles';
import { useProducts } from '../../../products/hooks/use-products';
import { Product } from '../../../products/model/product';
import { Helmet } from 'react-helmet';
import { FormHelperText } from '@mui/material';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { ColumnDef, RowSelectionState, SortingState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../table-abstraction/table-core';
import { TablePagination } from '../table-abstraction/table-pagination';
import Checkbox from '@visa/vds/checkbox';
import ScreenReader from '@visa/vds/screen-reader';
import { HeadingSearchFilter } from '../table-abstraction/filter-implementation/heading-search-filter';

interface Row {
    id: string;
    endpointId: string;
    name: string;
    internalPath: string
    externalPath: string;
    methodType: string;
    tableData?: any;
    product?: string;
    status: string;
}

export interface B2C2AppEditAllowedEndpointssProps {
    endpointIds: string[] | undefined;
    onEndpointChange: (operationGuids: string[]) => void;
    isCreate?: boolean;
}

export const B2C2AppEditAllowedEndpoints: React.FC<B2C2AppEditAllowedEndpointssProps> = ({ endpointIds, onEndpointChange, isCreate = true }) => {
    const { products, loading } = useProducts();
    const styles = appEditStyles();
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [searchAvailableEndpoints, setSearchAvailableEndpoints] = useState<string>("");
  const [searchSelectedEndpoints, setSearchSelectedEndpoints] = useState<string>("");
  const [sortingAvailableEndpoints, setSortingAvailableEndpoints] = useState<SortingState>([]);
  const [sortingSelectedEndpoints, setSortingSelectedEndpoints] = useState<SortingState>([]);
  const firstTimeRendered: MutableRefObject<boolean> = useRef<boolean>(true);
    const productsMap = useMemo<ReadonlyMap<string, Product>>(() => {
        return (
            !products
                ? new Map<string, Product>()
                : products.reduce<Map<string, Product>>(
                    (result, products) => result.set(products.productId, products),
                    new Map<string, Product>()
                )
        )
    }, [products]);

    const rows = useMemo<Row[]>(() => (
        products ? products.reduce<Row[]>((result: Row[], product: Product) => ([
            ...result,
            ...product.productEndpoints.map(op => {
                const prd = productsMap.get(op.productId);
                return {
                    id: op.endpointId,
                    endpointId: op.endpointId,
                    name: op.name,
                    internalPath: op.internalPath,
                    externalPath: op.externalPath,
                    methodType: op.methodType,
                    status: op.status,
                    product: !prd ? '' : prd.name
                }
            }).filter((op) => op.status === 'ACTIVE'),
        ]), []) : []
    ), [productsMap, products]);

  const columnsVDS: ColumnDef<Row, any>[] = useMemo(() => [
    { 
      header: 'Endpoint Name', 
      accessorKey: 'name' 
    },
    { 
      header: 'Endpoint Id', 
      accessorKey: 'endpointId', 
      id: 'endpointId' 
    },
    { 
      header: 'Method type', 
      accessorKey: 'methodType',
      meta: {
        styles: {
          minWidth: "120px"
        }
      },
    },
    { 
      header: 'Internal Path', 
      accessorKey: 'internalPath' 
    },
    { 
      header: 'External Path', 
      accessorKey: 'externalPath' 
    },
    { 
      header: 'Product', 
      accessorKey: 'product' 
    },
  ], []);
  const columnsVDSAvailable: ColumnDef<Row, any>[] = useMemo(() => {
    return [{
      id:"checkbox",
      meta: {
        styles: {
          minWidth: "unset"
        }
      },
      enableSorting: false,
      header: (e) => <Checkbox
        name="header_checkbox_indeterminate"
        label={<ScreenReader tag={"span"}>{e.table.getIsSomeRowsSelected() ? "Select all" : "Unselect All"}</ScreenReader>}
        id="header_checkbox_indeterminate"
        checked={e.table.getIsAllRowsSelected()}
        aria-checked={e.table.getIsAllRowsSelected() ? "true" : "false"}
        indeterminate={e.table.getIsSomeRowsSelected()}
        onChange={e.table.getToggleAllRowsSelectedHandler()}
      ></Checkbox>,
      accessorKey: "endpointId",
      cell: (e) => <Checkbox
        name={`${e.getValue()}_checkbox_indeterminate`}
        label={<ScreenReader tag={"span"}>{e.table.getIsSomeRowsSelected() ? `Select ${e.row.original.name}` : `Unselect ${e.row.original.name}`}</ScreenReader>}
        id={`${e.getValue()}_checkbox_indeterminate`}
        checked={e.row.getIsSelected()}
        onChange={e.row.getToggleSelectedHandler()}
      ></Checkbox>
    }, ...columnsVDS]

  }, [columnsVDS])
  const availableOperationsTableHook = useReactTable({
    columns: columnsVDSAvailable,
    data: rows,
    manualPagination: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSortingAvailableEndpoints,
    getRowId: (e) => e.endpointId,
    enableMultiRowSelection: true,
    enableSorting: true,
    state: {
      sorting: sortingAvailableEndpoints,
      globalFilter: searchAvailableEndpoints,
      rowSelection: rowSelection
    },
    onRowSelectionChange: setRowSelection,
    initialState: {
      pagination: {
        pageSize: 5,
        pageIndex: 0
      },
      rowSelection
    },
  });
  
// dont change dependency array as the it is going in an infinite loop. The issue will be solved once we migrate to react form hooks in future.
useEffect(() => {
  const initialSelectedGuidrecords: Record<string, boolean> = {}
  endpointIds?.forEach(element => initialSelectedGuidrecords[element] = true);
  setRowSelection(initialSelectedGuidrecords);
}, []);
// dont change dependency array as the it is going in an infinite loop. The issue will be solved once we migrate to react form hooks in future.
useEffect(() => {
  onEndpointChange(Object.keys(rowSelection));
}, [rowSelection]);

const selectedRows: Row[] = useMemo(() => {
  if (!!!rows) {
    return [];
  }
  return rows.filter(e => new Set<string>(Object.keys(rowSelection)).has(e.endpointId))
}, [rowSelection, rows])

const selectedOperationsTableHook = useReactTable({
  columns: columnsVDS,
  data: selectedRows,
  manualPagination: false,
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  getSortedRowModel: getSortedRowModel(),
  onSortingChange: setSortingSelectedEndpoints,
  enableSorting: true,
  state: {
    sorting: sortingSelectedEndpoints,
    globalFilter: searchSelectedEndpoints
  },
  initialState: {
    pagination: {
      pageSize: 5,
      pageIndex: 0
    },
  },
});

  const isAnyRowSelected = useMemo<boolean>(() => {
    if (rows.length === 0) {
      return true;
    }
    if (firstTimeRendered.current) {
      firstTimeRendered.current = false;
      return true;
    }
    return !!Object.keys(rowSelection).length
  }, [rowSelection, rows.length])
  return (<>
            <Helmet>
                <title>
                    {isCreate ? "Allowed Endpoints (2 of 6) | Add new B2C Application | Visa Prototype Validation Platform" : `Allowed Endpoints (2 of 6) | Edit B2C Application | Visa Prototype Validation Platform`}
                </title>
            </Helmet>
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TypographyHighLight headerTitle={isCreate ? "Allowed Endpoints (2 of 6) | Add new B2C Application" : `Allowed Endpoints (2 of 6) | Edit B2C Application`} variant='h5' />
        <Typography color="primary" className={styles.instruction}>
          Add at least one endpoint to your app
        </Typography>
        <HeadingSearchFilter heading='Available Endpoints' search={searchAvailableEndpoints} setSearch={setSearchAvailableEndpoints}></HeadingSearchFilter>
        <TableCore caption='Available Endpoint' table={availableOperationsTableHook}></TableCore>
        <TablePagination label='Available endpoints controls' table={availableOperationsTableHook} ></TablePagination>
            <br />
        <HeadingSearchFilter heading='Selected Endpoints' search={searchSelectedEndpoints} setSearch={setSearchSelectedEndpoints}></HeadingSearchFilter>
        <TableCore table={selectedOperationsTableHook} caption="Selected Endpoints"></TableCore>
        <TablePagination label='Selected endpoints controls' table={selectedOperationsTableHook} ></TablePagination>
      </Grid>
    </Grid>
  </>
    );
};
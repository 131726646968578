
import {
  B2C2ApprovalRequest,
  B2C2ProductReviewRequest,
  Product,
  ProductB2C2BArgs,
  ProductDetails,
  ProductEndpoints,
  ProductHeader,
  ProductHost,
} from "../model/product";
import {
  ProductB2C2BArgsCreate,
  ProductCreate,
  ProductEndpointCreate,
  ProductHeaderCreate,
  ProductHostCreate,
} from "../model/product-create";
import {
  B2C2ApprovalRequestUpdate,
  B2C2ReviewRequestUpdate,
  ProductEndpointUpdate,
  ProductHeaderUpdate,
  ProductHostUpdate,
} from "../model/product-update";
import {
  B2C2ApprovalRequestRS,
  B2C2ApprovalRequestUpdateRQ,
  B2C2ReviewRequestRS,
  B2C2ReviewRequestUpdateRQ,
  ProductB2C2BArgsRQ,
  ProductB2C2BArgsRS,
  ProductDetailsRQ,
  ProductEndpointsRQ,
  ProductEndpointsRS,
  ProductHeaderRQ,
  ProductHeaderRS,
  ProductHostRQ,
  ProductHostRS,
  ProductRQ,
  ProductRS,
} from "./dtos";

export function mapProduct(dto: ProductRS): Product {
  const model: Product = {
    id: dto.id,
    name: dto.name,
    description: dto.description,
    documentationUrl: dto.documentationUrl,
    contextRoot: dto.contextRoot,
    statusType: dto.statusType,
    createdBy: dto.createdBy,
    createdAt: dto.createdAt,
    lastModifiedAt: dto.lastModifiedAt,
    productId: dto.productId,
    requestId: dto.requestId,
    productHosts: dto.productHosts ? dto.productHosts.map(mapProductHost) : [],
    productEndpoints: dto.productEndpoints
      ? dto.productEndpoints.map(mapProductEndpoints)
      : [],
    productHeaders: dto.productHeaders
      ? dto.productHeaders.map(mapProductHeaders)
      : [],
    productOwners: dto.productOwners ? dto.productOwners : [],
    productB2C2BArgs: mapProductB2C2BArgsFromRS(dto.b2c2bArgs),
  };
  return model;
}

export function mapProductRQ(dto: ProductRQ): ProductCreate {
  const model: ProductCreate = {
    name: dto.name,
    description: dto.description,
    documentationUrl: dto.documentationUrl,
    contextRoot: dto.contextRoot,
    statusType: dto.statusType,
    productHosts: dto.productHosts
      ? dto.productHosts.map(mapProductHostRQ)
      : [],
    productEndpoints: dto.productEndpoints
      ? dto.productEndpoints.map(mapProductEndpointsRQ)
      : [],
    productHeaders: dto.productHeaders
      ? dto.productHeaders.map(mapProductHeadersRQ)
      : [],
    productOwners: dto.productOwners ? dto.productOwners : [],
    productId: dto.productId,
    requestId: dto.requestId,
    productB2C2BArgs: dto.b2c2bArgs ? mapProductB2C2BArgs(dto.b2c2bArgs) : undefined,
  };
  return model;
}

export function mapProductB2C2BArgs(
  dto: ProductB2C2BArgsRQ
): ProductB2C2BArgsCreate {
  const model: ProductB2C2BArgsCreate = {
    useVp2Connector: dto.useVp2Connector,
    business: dto.business,
  };
  return model;
}

export function mapProductB2C2BArgsFromRS(
  dto: ProductB2C2BArgsRS | undefined
): ProductB2C2BArgs {
  const model: ProductB2C2BArgsCreate = {
    useVp2Connector: dto?.useVp2Connector,
    business: dto?.business,
  };
  return model;
}

export function mapProductHost(dto: ProductHostRS): ProductHost {
  const model: ProductHost = {
    id: dto.id,
    url: dto.url,
    dataCenter: dto.dataCenter,
    productId: dto.productId,
    hostId: dto.hostId,
    createdBy: dto.createdBy,
    createdAt: dto.createdAt,
    lastModifiedAt: dto.lastModifiedAt,
  };
  return model;
}

export function mapProductEndpoints(dto: ProductEndpointsRS): ProductEndpoints {
  const model: ProductEndpoints = {
    id: dto.id,
    name: dto.name,
    description: dto.description,
    internalPath: dto.internalPath,
    externalPath: dto.externalPath,
    methodType: dto.methodType,
    endpointId: dto.endpointId,
    productId: dto.productId,
    status: dto.status,
    version: dto.version,
    createdBy: dto.createdBy,
    createdAt: dto.createdAt,
    lastModifiedAt: dto.lastModifiedAt,
    b2cMleType: dto.b2cMleType,
    b2bMleType: dto.b2bMleType,
  };

  return model;
}

export function mapProductHeaders(dto: ProductHeaderRS): ProductHeader {
  const model: ProductHeader = {
    id: dto.id,
    name: dto.name,
    dataType: dto.dataType,
    isRequired: dto.isRequired,
    defaultValue: dto.defaultValue,
    headerId: dto.headerId,
    productId: dto.productId,
    createdBy: dto.createdBy,
    createdAt: dto.createdAt,
    lastModifiedAt: dto.lastModifiedAt,
  };
  return model;
}

export function mapProductHostRQ(dto: ProductHostRQ): ProductHostCreate {
  const model: ProductHostCreate = {
    url: dto.url,
    dataCenter: dto.dataCenter,
    hostId: dto.hostId,
  };
  return model;
}

export function mapProductEndpointsRQ(
  dto: ProductEndpointsRQ
): ProductEndpointCreate {
  const model: ProductEndpointCreate = {
    name: dto.name,
    description: dto.description,
    internalPath: dto.internalPath,
    externalPath: dto.externalPath,
    methodType: dto.methodType,
    status: dto.status,
    version: dto.version,
    b2cMleType: dto.b2cMleType,
    b2bMleType: dto.b2bMleType,
  };

  return model;
}

export function mapProductHeadersRQ(dto: ProductHeaderRQ): ProductHeaderCreate {
  const model: ProductHeaderCreate = {
    name: dto.name,
    dataType: dto.dataType,
    isRequired: dto.isRequired,
    defaultValue: dto.defaultValue,
  };
  return model;
}

export function mapProductCreate(model: ProductCreate): ProductRQ {
  const dto: ProductRQ = {
    name: model.name,
    contextRoot: model.contextRoot,
    documentationUrl: model.documentationUrl,
    productHosts: model.productHosts.map(mapProductHostCreate),
    productEndpoints: model.productEndpoints.map(mapProductEndpointsCreate),
    productHeaders: model.productHeaders
      ? model.productHeaders.map(mapProductHeadersCreate)
      : undefined,
    productOwners: model.productOwners ? model.productOwners : undefined,
    productId: model.productId,
    requestId: model.requestId,
    b2c2bArgs: model.productB2C2BArgs ? mapProductB2C2BArgs(model.productB2C2BArgs) : undefined,
  };
  return dto;
}

export function mapProductHostCreate(dto: ProductHostCreate): ProductHostRQ {
  const model: ProductHostRQ = {
    url: dto.url,
    dataCenter: dto.dataCenter,
    hostId: dto.hostId,
  };
  return model;
}

export function mapProductHostUpdate(
  model: Partial<ProductHostUpdate>
): Partial<ProductHostRQ> {
  const dto: Partial<ProductHostRQ> = {
    url: model.url,
    dataCenter: model.dataCenter,
  };
  return dto;
}

export function mapProductEndpointsCreate(
  dto: ProductEndpointCreate
): ProductEndpointsRQ {
  const model: ProductEndpointsRQ = {
    name: dto.name,
    description: dto.description,
    internalPath: dto.internalPath,
    externalPath: dto.externalPath,
    methodType: dto.methodType,
    status: dto.status,
    version: dto.version,
    b2cMleType: dto.b2cMleType,
    b2bMleType: dto.b2bMleType,
  };

  return model;
}

export function mapProductEndpointsUdpate(
  model: Partial<ProductEndpointUpdate>
): Partial<ProductEndpointsRQ> {
  const dto: Partial<ProductEndpointsRQ> = {
    name: model.name,
    methodType: model.methodType,
    internalPath: model.internalPath,
    externalPath: model.externalPath,
    b2cMleType: model.b2cMleType,
    b2bMleType: model.b2bMleType,
  };
  return dto;
}

export function mapProductHeadersCreate(
  dto: ProductHeaderCreate
): ProductHeaderRQ {
  const model: ProductHeaderRQ = {
    name: dto.name,
    dataType: dto.dataType,
    isRequired: dto.isRequired,
    defaultValue: dto.defaultValue,
  };
  return model;
}

export function mapProductHeaderUpdate(
  model: Partial<ProductHeaderUpdate>
): Partial<ProductHeaderRQ> {
  const dto: Partial<ProductHeaderRQ> = {
    name: model.name,
    dataType: model.dataType,
    isRequired: model.isRequired,
    defaultValue: model.defaultValue,
  };
  return dto;
}

export function mapProductDetails(
  model: Partial<ProductDetails>
): Partial<ProductDetailsRQ> {
  const dto: Partial<ProductDetailsRQ> = {
    name: model.name,
    contextRoot: model.contextRoot,
    documentation_url: model.documentationUrl,
    productOwners: model.productOwners,
    description: model.description,
  };
  return dto;
}

export function mapB2C2ApprovalRequest(
  dto: B2C2ApprovalRequestRS
): B2C2ApprovalRequest {
  const model: B2C2ApprovalRequest = {
    createdBy: dto.createdBy,
    createdAt: dto.createdAt,
    lastModifiedBy: dto.lastModifiedBy,
    lastModifiedAt: dto.lastModifiedAt,
    reviewerSubjectName: dto.reviewer_subject_name,
    saNumber: dto.sa_number,
    businessJustification: dto.business_justification,
    sendBackMessage: dto.sent_back_message,
    requestId: dto.requestId,
    requestor: dto.requestor,
    requestType: dto.requestType,
    status: dto.status,
    adminRequestEntity: {
      name: dto.adminRequestEntity?.name,
      description: dto.adminRequestEntity?.description,
      productId: dto.adminRequestEntity?.productId,
      requestId: dto.adminRequestEntity?.requestId,
      appId: dto.adminRequestEntity?.appId,
      status: dto.adminRequestEntity?.status,
      supportGdl: dto.adminRequestEntity?.supportGdl,
      namespace: dto.adminRequestEntity?.namespace,
      tenancyId: dto.adminRequestEntity?.tenancyId,
      allowedEndpoints: dto.adminRequestEntity?.allowedEndpoints,
      owners: dto.adminRequestEntity?.owners,
      createdBy: dto.adminRequestEntity?.createdBy,
      createdAt: dto.adminRequestEntity?.createdAt,
      lastModifiedBy: dto.adminRequestEntity?.lastModifiedBy,
      lastModifiedAt: dto.adminRequestEntity?.lastModifiedAt,
    },
  };
  return model;
}

export function mapB2C2ApprovalRequestUpdate(
  model: B2C2ApprovalRequestUpdate
): B2C2ApprovalRequestUpdateRQ {
  const dto: B2C2ApprovalRequestUpdateRQ = {
    sa_number: model.saNumber || "",
    business_justification: model.businessJustification || "",
    status: model.status,
    sendBackMsg: model.sendBackMsg || "",
  };

  if (!model.sendBackMsg) {
    delete dto.sendBackMsg;
  }
  return dto;
}

export function mapB2C2ReviewRequestUpdate(
  model: B2C2ReviewRequestUpdate
): B2C2ReviewRequestUpdateRQ {
  const dto: B2C2ReviewRequestUpdateRQ = {
    saNumber: model.saNumber,
    businessJustification: model.businessJustification,
    requestId: model.requestId,
  };
  return dto;
}

export function mapB2C2ReviewRequest(
  dto: B2C2ReviewRequestRS
): B2C2ProductReviewRequest {
  const model: B2C2ProductReviewRequest = {
    saNumber: dto.saNumber,
    businessJustification: dto.requestId,
    requestId: dto.requestId,
    requestStatus: dto.requestStatus,
  };
  return model;
}

import { Table } from "@tanstack/table-core"
import React, { useState } from "react"
import Tr from '@visa/vds/tr';
import Td from '@visa/vds/td';
import { TableFormAddCancelAction } from "../../table-cells/b2c2-apps/table-form-add-cancel";
import { Row } from "../../../../views/app-edit/b2c2-app-edit-identities"
import { TempTypeSelect } from "../../table-cells/b2c2-apps/app-identities/template-type-select";
import { CommTypeSelect } from "../../table-cells/b2c2-apps/app-identities/comm-type-select";
import { B2C2AppsTemplateExpiryCell } from "../../table-cells/b2c2-apps/app-identities/header-Expiry-Cell";
import { B2C2AppsTemplateContentCell } from "../../table-cells/b2c2-apps/app-identities/header-Content-Cell";
import { TableFormAddCancelCustomizationsAction } from "../../table-cells/b2c2-apps/table-form-add-cancel-customizations";

export const B2C2AddAppIdentites: React.FC<Table<Row>> = (table) => {
    return <Tr >
        <Td>
           <TempTypeSelect {...table}/>
        </Td>
        <Td>
            <CommTypeSelect {...table}/>
        </Td>
        <Td>
            <B2C2AppsTemplateExpiryCell {...table}/>
        </Td>
        <Td>
            <B2C2AppsTemplateContentCell {...table}/>
        </Td>
        <Td>
            <TableFormAddCancelCustomizationsAction {...table as Table<any>}></TableFormAddCancelCustomizationsAction>
        </Td>
    </Tr>
}
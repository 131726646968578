export interface Action {
  type: string;
  payload?: any;
}

export function actionCreator<T>(type: string, payload?: T): Action {
  return {
    type,
    payload,
  };
}

export const types = {
  reset: 'reset',
}

const reset = () => actionCreator(types.reset);

export const actions = {
  reset,
}

export interface ILandmarkState {
  isSkipToRequired: boolean,
  onSkipFocusElement?: React.RefObject<HTMLElement> | null
};

export interface IAction<T,S> {
  type: T,
  payload: S
}
export interface IActionType<T> {
  type: T,
}
export interface ISkipToLoginState{
  isSkipToRequired: boolean,
  onSkipFocusElement?: React.RefObject<HTMLElement> | null
}
import React, { ElementType, ReactNode, RefObject, useContext, useRef } from "react";
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../state/skip-to-main-store';
import { Variant } from "@mui/material/styles/createTypography";
import { Typography } from "@mui/material";
import { vdsStyleOverrides } from "../../vds-overrides.styles";

export interface TypographyH1Interface {
    headerTitle: string| ReactNode;
    variant?: Variant | 'inherit';
    component?: ElementType<any>;
}
export const TypographyHighLight: React.FC<TypographyH1Interface> = ({ headerTitle, variant, component = 'h1'}) => {
    const sty = vdsStyleOverrides();
    const ref: RefObject<HTMLHeadingElement> = useRef<HTMLHeadingElement>(null);
    const context: LandmarkContextType = useContext(LandmarkContext);
    useLandmarkHook(ref, context[1]);
    useHighlightH1(ref);
    return <Typography className={sty.focusBorder}component={component} tabIndex={-1} ref={ref} variant={variant} children={headerTitle}/>;
}
import Moment from 'moment';
import React, { RefObject, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useB2C2ApprovalRequests } from '../../hooks/use-b2c2-approval-requests';
import { IconButton, useTheme } from '@material-ui/core';
import { CancelRounded, CheckCircleRounded, ErrorRounded, FindInPageOutlined, InsertDriveFileOutlined, PauseCircleFilledRounded } from '@material-ui/icons/';
import { Helmet } from 'react-helmet';
import { ColumnDef, RowModel, SortingState, Table, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { B2C2ApprovalRequest } from '../../model/product';
import { TableCore } from '../../../appsNew/views/table-abstraction/table-core';
import { TablePagination } from '../../../appsNew/views/table-abstraction/table-pagination';
import { B2C2RequestAprovalFilter } from '../../../appsNew/views/table-abstraction/filter-implementation/b2c2-request-approval-filter';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import Icon from '@visa/vds/icon';
import ButtonIcon from '@visa/vds/button-icon';

export const B2C2ProductApprovals: React.FC = () => {
  const { b2c2ApprovalRequests, loading } = useB2C2ApprovalRequests();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleB2BServiceApprovalClick = useCallback(
    (id: string) => navigate(`/b2c/products/approvals/${id}`),
    [navigate]
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState<string>("");
  const [type, setType] = useState<string>("0");
  const [requestor, setRequestor] = useState<string>("0");
  const [status, setStatus] = useState<string>("0");
  const filteredEntries: B2C2ApprovalRequest[] = useMemo(() => {
    if (type === "0" && requestor === "0" && status === "0") {
      return b2c2ApprovalRequests;
    }
    return b2c2ApprovalRequests.filter(e => {
      if (type === "0" || e.requestType === type) {
        return true;
      }
      return false;
    })
      .filter((e) => {
        if (requestor === "0" || e.requestor === requestor) {
          return true;
        }
        return false;
      })
      .filter(e => {
        if (status === "0" || e.status === status) {
          return true;
        }
        return false;
      })
  }, [type, requestor, status, b2c2ApprovalRequests])
  const columns = useMemo<ColumnDef<B2C2ApprovalRequest, any>[]>(() => ([
    {
      header: 'Request Guid',
      accessorKey: 'requestId',
    },
    {
      header: 'Type',
      accessorKey: 'requestType',
    },
    {
      header: 'Date modified',
      accessorKey: 'lastModifiedAt',
      type: 'datetime',
      cell: (e) => <>{Moment(e.getValue()).format('MMM D YYYY')}</>,
    },
    {
      header: 'Requestor',
      accessorKey: 'requestor',
    },
    {
      header: 'Request Status',
      accessorKey: 'status',
      cell: e => {
        switch (e.getValue()) {
          case "APPROVED":
            return <span>{e.getValue()} <span style={{}}><CheckCircleRounded style={{ color: 'green', fontSize: '15px' }} /> </span></span>
          case "IN_REVIEW":
            return <span>{e.getValue()} <span style={{}}><PauseCircleFilledRounded style={{ color: 'navy', fontSize: '15px' }} /> </span></span>
          case "OPEN":
            return <span>{e.getValue()} <span style={{}}><ErrorRounded style={{ color: 'orange', fontSize: '15px' }} /> </span></span>
          case "DENIED":
            return <span>{e.getValue()}<span style={{}}><CancelRounded style={{ color: 'red', fontSize: '15px' }} /> </span></span>
          default:
            return <p>{e.getValue()}</p>
        }
      },
    },
    {
      header: 'Action',
      accessorKey: 'requestId',
      enableSorting: false,
      cell: (e) => {
        return  <ButtonIcon ariaLabel={`Approval details for request ID ${e.getValue()}`} iconType="light-tiny" onClick={() => handleB2BServiceApprovalClick(e.getValue())} role="link" >
        <Icon name="document" resolution="tiny" />
    </ButtonIcon>
      },
    },
  ]), [handleB2BServiceApprovalClick]);
  const table = useReactTable({
    columns: columns,
    data: filteredEntries,
    debugTable: true,
    manualPagination: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    state: {
      sorting,
      globalFilter: search
    },
    initialState: {
      pagination: {
        pageSize: 5,
        pageIndex: 0
      },
    },
  });
  return (
    <main>
      <Helmet>
        <title>B2C Admin Requests | Visa Prototype Validation Platform</title>
      </Helmet>
      <TypographyHighLight variant='h5' headerTitle={<div style={{ color: 'black', marginBottom: theme.spacing(4) }}>B2C Admin Requests</div>} />
      <B2C2RequestAprovalFilter
        requestor={requestor}
        status={status}
        type={type}
        dataEntries={b2c2ApprovalRequests}
        search={search}
        setRequestor={setRequestor}
        setSearch={setSearch}
        setStatus={setStatus}
        setType={setType} />

      <TableCore table={table} caption='B2C Admin Requests'></TableCore>
      <TablePagination label='B2C Admin Requests controls' table={table}></TablePagination>
    </main>
  );
}
import { Grid } from '@material-ui/core';
import React from 'react';
import { B2C2ProductsRouter } from '../../products-router';
import { productAreaStyles } from '../product-area/b2c2-products-area.styles';

export const B2C2ProductsArea: React.FC = () => {
  const styles = productAreaStyles();
  return (
    <div className={styles.root}>
          <B2C2ProductsRouter />
    </div>
  );
}

import {
  Button,
  IconButton,
  TextField,
  Tooltip
} from '@material-ui/core';
import Dialog from '@visa/vds/dialog';
import DialogCardBody from '@visa/vds/dialog-card-body';
import DialogCardHead from '@visa/vds/dialog-card-head';
import DialogCardHeadTitle from '@visa/vds/dialog-card-head-title';
import DialogCardFooter from '@visa/vds/dialog-card-footer';
import ButtonGroup from '@visa/vds/button-group';
import ButtonText from '@visa/vds/button-text';
import { ReactComponent as FileAddIcon } from './file-add.svg'
import { ReactComponent as FileIcon } from './file.svg'
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
import { importFromJsonStyles } from './import-from-json-button.styles'
import React, { Fragment, useCallback, useState } from 'react';
import Dropzone from "react-dropzone";
import clsx from "clsx";
import { useNotifications } from "../../hooks/use-notifications";
import { useNavigate } from "react-router-dom";
import { jsonB2BService } from "../../features/services/services/json.b2b.service";
import { jsonB2C2App } from '../../features/appsNew/services/json.b2b.service';
import { jsonProductService } from '../../features/products/services/json.service';
import { TypographyHighLight } from '../../components/app-header-highlight/typographyHighlight';

export interface ImportFromJsonDialogProps {
  onImport?: (content: string) => any;
  mode?: 'icon' | 'button';
  buttonStyle?: string;
  importType: string;
  wizardLink?: string;
}

export const ImportFromJsonButton: React.FC<ImportFromJsonDialogProps> = ({ onImport, mode = 'icon', buttonStyle, importType, wizardLink }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const [fileSelected, setFileSelected] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File>(new File([""], ""));
  const styles = importFromJsonStyles();
  const { pushNotification } = useNotifications();
  const navigate = useNavigate();

  const handleOpen = useCallback(() => {
    setContent('');
    setFileSelected(false);
    setUploadedFile(new File([""], ""));
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleJsonImport = useCallback(async (content: string) => {
    handleClose();

    let wizardObject: any;

    if (!!onImport) {
      wizardObject = onImport(content)
    } else {
      switch (importType) {
        case 'b2bService':
          wizardObject = await jsonB2BService.importB2BSrvFromJson(content);
          break;
        case 'b2cApp':
          wizardObject = await jsonB2C2App.importB2C2AppFromJson(content);
          break;
        case 'b2cProduct':
          wizardObject = await jsonProductService.importFromJson(content);
          break;
      }
    }

    if (!wizardObject) {
      pushNotification({ message: 'Invalid JSON content', type: 'snackbar', variant: 'error' });
      return;
    }

    if (!!wizardLink) {
      navigate(wizardLink, { state: { wizardObject: wizardObject } });
    }
  }, [handleClose, pushNotification, navigate, importType, onImport, wizardLink]);

  const handleInputChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>): void => {
    setContent(ev.target.value);
  }, [setContent]);

  const onFileSelection = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const fileReader = new FileReader();
      fileReader.onerror = () => pushNotification({ message: 'Json file reading has failed', type: 'snackbar', variant: 'error' });
      fileReader.onload = () => {
        setContent(String(fileReader.result));
        setFileSelected(true);
        setUploadedFile(file);
      };
      fileReader.readAsText(file)
    })
  }, [setContent, setFileSelected, pushNotification]);

  return (
    <div className={styles.root}>
      {mode === 'icon' ? (
        <Tooltip title="Import from JSON">
          <IconButton aria-label="Import from JSON" onClick={handleOpen}>
            <PublishIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          aria-haspopup="true"
          id="upload1"
          type="submit"
          variant="outlined"
          className={buttonStyle}
          onClick={handleOpen}>
          Upload File
        </Button>
      )}
      <Dialog open={open} role="dialog" aria-modal="true" aria-labelledby="dialog-upload-title"  className={styles.vdsDialogBoxCard}>
        <DialogCardHead>
          <DialogCardHeadTitle id="dialog-upload-title">
            <TypographyHighLight headerTitle={<b> Upload JSON File</b>} component={"h2"} variant='h4'></TypographyHighLight>
          </DialogCardHeadTitle>
        </DialogCardHead>
        <DialogCardBody id="dialog-upload-content">
          <div className={styles.container}>
            {!fileSelected ?
              (
                <Dropzone
                  onDrop={onFileSelection}
                  accept="application/json"
                  maxFiles={1}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
                    <div className={clsx(styles.dropZone)} {...getRootProps()}>
                      <input id="fileDrag" {...getInputProps()} />
                      {!isDragActive && <Fragment><FileAddIcon /><label htmlFor="fileDrag">Drag file here or browse</label></Fragment>}
                      {isDragActive && isDragAccept && <Fragment><FileAddIcon className={styles.fileIconGood} /><p>Drag file here</p></Fragment>}
                      {isDragReject && <Fragment><FileAddIcon className={styles.fileIconBad} /><p>Only .json files are accepted</p></Fragment>}
                    </div>
                  )}
                </Dropzone>
              ) : (
                <Fragment>
                  <div className={styles.fileNameBar}>
                    <FileIcon className={styles.fileNameBarItem} />
                    <p className={styles.fileNameBarItem}>{uploadedFile.name}</p>
                    <IconButton aria-label="Close file" onClick={handleOpen} className={styles.fileCloseBtn}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <TextField
                    multiline
                    fullWidth
                    rows="25"
                    variant="outlined"
                    value={content}
                    onChange={handleInputChange}
                  /></Fragment>)
            }
          </div>
        </DialogCardBody>
        <DialogCardFooter direction="right">
        <ButtonGroup direction="right">
            <ButtonText colorScheme="primary" onClick={() => handleJsonImport(content)}>
              Upload
            </ButtonText>
            <ButtonText colorScheme="secondary" onClick={handleClose}>
              Cancel
            </ButtonText>
          </ButtonGroup>
        </DialogCardFooter>
      </Dialog>
    </div>
  );
};



import React from 'react';
import { DialogTitleStyles } from './dialog.styles';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { TypographyHighLight } from '../../components/app-header-highlight/typographyHighlight';

export const DialogTitle = DialogTitleStyles((props: any) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
            <TypographyHighLight headerTitle={children} component={"h2"} variant="h6"/>
            {onClose && (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            )}
        </MuiDialogTitle>
    );
});
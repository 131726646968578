import { Reducer } from 'react';
import { Action } from '../../../state/actions';
import { User } from '../../oidc-client/model/user';
import { actionTypes } from './actions';

export interface UserState {
  user: User | null;
  authenticating: boolean;
  error: Error | null;
  loggedOut: boolean;
}

export const initialUserState: UserState = {
  user: null,
  authenticating: false,
  error: null,
  loggedOut: false,
}

export const reducer: Reducer<UserState, Action> = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case actionTypes.startAuthentication:
      return {
        ...state,
        user: null,
        authenticating: true,
        error: null,
      };
    case actionTypes.authenticationSuccess:
      const user = action.payload as User;
      return {
        ...state,
        user,
        authenticating: false,
        error: null,
        loggedOut: false,
      };
    case actionTypes.authenticationError:
      const error = action.payload as Error;
      return {
        ...state,
        authenticating: false,
        error,
      };
    case actionTypes.logout:
      return {
        ...state,
        user: null,
        authenticating: false,
        error: null,
        loggedOut: true,
      };

    default:
      return state;
  }
}

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useMemo } from 'react';
import { B2C2Application } from '../../model/application';
import { Typography } from '@mui/material';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../table-abstraction/table-core';
import { B2C2ApplicationCommunicationTemplateCreate } from '../../model/application-create';

export interface B2C2AppPreviewAppCustomProps {
    appCustomConfig?: B2C2Application;
}


export const B2C2AppPreviewAppCustom: React.FC<B2C2AppPreviewAppCustomProps> = ({ appCustomConfig }) => {

    const columns: ColumnDef<B2C2ApplicationCommunicationTemplateCreate, any>[] = useMemo(() => [
        {
            header: 'Template Type',
            accessorKey: 'templateType'
        },
        {
            header: 'Content',
            accessorKey: 'content'
        },
        {
            header: 'Comm Type',
            accessorKey: 'commType'
        },
        {
            header: 'Expiry Min',
            accessorKey: 'expiryMinutes',
        }
    ], []);
    const rows = useMemo<B2C2ApplicationCommunicationTemplateCreate[]>(() => appCustomConfig?.templates ? appCustomConfig?.templates.map(templates => ({ ...templates })) : [], [appCustomConfig]);
    const tableDatas = useReactTable({
        columns: columns,
        data: rows,
        getCoreRowModel: getCoreRowModel(),
        enableSorting: false
    });

    return (
        <>
            <Typography component={"h3"}><b>Whitelisted Identities</b></Typography>
            <div style={{ marginTop: '1.5rem' }}>
            {appCustomConfig?.whitelistedIdentities.join(', ')}
            </div>
            {appCustomConfig?.templates &&
             <TableCore caption='Customizations' table={tableDatas}></TableCore>
            }
        </>
    );
};
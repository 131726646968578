import { Button, IconButton, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useCallback } from 'react';
import { useCredentialsFileDownload } from '../../hooks/use-b2c2-file-download';
import { useNotifications } from '../../../../hooks/use-notifications';

export interface B2C2AppFileDownloadProps {
  credentialId: string;
  filename: string;
  isDownloadAllowed: boolean 
}

export const B2C2AppFileDownload: React.FC<B2C2AppFileDownloadProps> = ({ credentialId, filename, isDownloadAllowed }) => {

  const { pushNotification } = useNotifications();
  const { downloadFileAsync } = useCredentialsFileDownload();
  
  const handleDownload = useCallback(async () => {
    const success = await downloadFileAsync(credentialId);
    if (success) {
      const blob = new Blob([success], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = filename;
      link.href = url;
      link.click();
    } else {
      pushNotification({ message: 'Unable to download file', type: 'snackbar', variant: 'error' });
    }
  }, [filename, credentialId]);
  return (
    isDownloadAllowed?
    <div>
      <Tooltip title="Download Credential">
        <IconButton aria-label="Download" onClick={() => handleDownload()}>
          <GetAppIcon />
        </IconButton>
      </Tooltip>
    </div>: null
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { relative } from 'path';

export const appWizardStyles = makeStyles(theme => ({
  instructions: {
    color: '#1A1F71',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  smallTitle: {
    color: "#616188",
    fontSize: "16px",
    fontWeight: 400,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
  divider:{
    variant:"fullWidth",
    height:"7px",
    margin: "30px 0",
    backgroundColor:'#F2F4F8'
  },
  backBtn: {
    marginLeft: theme.spacing(5),
    height: "64px",
    width: "150px",
    marginTop: "50px"
  },
  nextBtn: {
    height: "64px",
    width: "150px",
    marginTop: "50px"
  },
  cancelBtn: {
    padding: "14px 3px 19px 1px;",
    height: "64px",
    width: "100px",
    marginTop: "50px"
  },
}));

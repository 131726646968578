import { Table } from "@tanstack/table-core"
import React from "react"
import Icon from '@visa/vds/icon';
import ButtonIcon from '@visa/vds/button-icon';

export const TableFormAddCancelAction: React.FC<Table<any>> = (table)=>{
        return <>
            <ButtonIcon ariaLabel={`Add new row`} iconType="light-tiny" 
             disabled={table.options.meta?.isValid && !table.options.meta.isValid(table)} onClick={(()=>table.options.meta?.handleAdd && table.options.meta?.handleAdd())}
               > <Icon name="add" resolution="tiny" />
            </ButtonIcon>
            <ButtonIcon ariaLabel={`Cancel the add row`} iconType="light-tiny" onClick={() => table.options?.meta?.stopAddMode && table.options?.meta?.stopAddMode()}>
                <Icon name="close" resolution="tiny" />
            </ButtonIcon></>
}
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';

export const SuccessDialogTitleStyles = withStyles((theme: Theme) => ({
    dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        margin: 0,
        padding: theme.spacing(1),
    }
}));

export const DialogStyles = makeStyles((theme: Theme) => ({
    icon: {
        width:'24px',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: '5px'
    },
    content: {
        fontSize: '14px',
        maxWidth: theme.spacing(60),
        maxHeight: theme.spacing(40)
    },
    dialogActions: {
        paddingTop: '40px'
    },
    longBtn: {
        borderRadius: 0,
        padding: theme.spacing(2, 3, 2, 3),
        borderWidth: 2,
        color: '#fff',
        backgroundColor: '#003EA9',
        textTransform: 'none',
        fontSize: '14px',
        width: '240px',
        height: '20px',
        '&:hover': {
            color: '#003EA9',
            borderColor: '#003EA9'
        },
        '&:disabled': {
            backgroundColor: 'grey',
            color: 'white'
        }
    },
    shortBtn: {
        borderRadius: 0,
        padding: theme.spacing(2, 3, 2, 3),
        borderWidth: 2,
        color: '#fff',
        backgroundColor: '#003EA9',
        textTransform: 'none',
        fontSize: '14px',
        width: '100px',
        height: '20px',
        '&:hover': {
            color: '#003EA9',
            borderColor: '#003EA9'
        },
        '&:disabled': {
            backgroundColor: 'grey',
            color: 'white'
        }
    },
}));


export const DialogTitleStyles = withStyles((theme: Theme) => ({
    dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        margin: 0,
        padding: theme.spacing(1),
    }
}));
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import createTheme from '@material-ui/core/styles/createTheme';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const useAppEditStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    smallTitle: {
      color: "#616188",
      fontSize: "16px",
      fontWeight: 400,
    },
    instruction1: {
      color: '#1A1F71',
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "28px",
      marginBottom: theme.spacing(1)
    },
    errorsHeading: {
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.error.main,
    },
    errorText: {
      color: theme.palette.error.main,
    },
    spaceLeft: {
      marginLeft: theme.spacing(2),
    },
      bigIndicator: {
          height: 4,
      },
      cancelBtn: {
          height: "64px",
          width: "100px",
          marginTop: "50px"
      },
      backBtn: {
          marginLeft: theme.spacing(5),
          height: "64px",
          width: "150px",
          marginTop: "50px"
      },
      nextBtn: {
          marginRight: theme.spacing(5),
          height: "64px",
          width: "150px",
          marginTop: "50px"
      },
      submitBtn: {
          height: "64px",
          width: "150px",
          marginTop: "50px",

      }
  }),
);

export const appEditStyles = makeStyles(theme => ({
    addBtn: {
        fontSize: '42px'
    },
    title: {
        fontSize: '20px'
    },
    expandText: {
      color: 'grey',
        fontSize: '14px'
    },
    expandemailText: {
        color: '#727272',
          fontSize: '14px'
      },
    instruction: {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
        marginBottom: "30px"
    },
    tableBackgroundColor: {
        backgroundColor: '#F2F4F8'
    },
    instruction1: {
      color: '#1A1F71',
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "28px",
      marginBottom: theme.spacing(1)
    },
    instruction2: {
        fontSize: "16px",
        fontWeight: 500,
        marginBottom: theme.spacing(3)
    },
    smallTitle: {
        color: "#616188",
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: "10px"
    },
    chipContainerGrid: {
        border: '1px solid grey'
    },
    commContainerGrid: {
        border: '1px solid grey',
        width: '280px'
    },
    chipItemGrid: {
        padding: '5px'
    },
    chipStyle: {
        margin:'4px',
        wordWrap: 'break-word'
    },
    chipStyleRB: {
        margin: theme.spacing(0, 0.4, 0.4, 0),
        wordWrap: 'break-word'
    },
    operationsTitle: {
        padding: theme.spacing(2),
        backgroundColor: '#F2F4F8',
        height: '50px',
        borderRadius: theme.spacing(0.5, 0.5, 0, 0)
    },
    dataGridParentB2B: {
        height: 400,
        width: '100%',
    },
    dataGridParentB2C: {
        height: 400,
        width: 800
    },
    marginBtm: {
        marginBottom: theme.spacing(3),
    },
}));

export const searchStyle: CSSProperties = {
    border: '1px solid grey',
    height: '42px',
    fontSize: '20px',
    color: '#616188'
};
export const backgroundWidth: CSSProperties = {
    backgroundColor: '#F2F4F8',
    maxWidth: '90vw', 
    overflow: 'auto'
};
export const backgroundGrey: CSSProperties = {
    backgroundColor: '#F2F4F8'
};
export const hostTheme = createTheme({
    overrides: {
        MuiTabs: {
            root: {
                marginBottom: "70px"
            },
            flexContainer: {
                display: "flex",
                backgroundColor: "#F2F4F8",
                justifyContent: "center"
            },
        },
        MuiTableRow: {
            root: {
                background: "white",
            },
        },
        MuiTableSortLabel: {
            root: {
                fontSize: "14px",
                fontWeight: 600

            }
        },
        MuiTab: {
            root: {
                boxSizing: "content-box",
                minHeight: "36px"
            },
            textColorPrimary: {
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "16px",
                primary: "003EA9",
                MuiSelected: {
                    color: "#003EA9",
                }
            },
        },
    },
});
import { User } from '../../oidc-client';

export interface Action {
  type: string;
  payload?: any;
}

export function actionCreator<T>(type: string, payload?: T): Action {
  return {
    type,
    payload,
  };
}

const types = {
  startAuthentication: 'oidc-client-react/start-authentication',
  authenticationSuccess: 'oidc-client-react/authentication-success',
  authenticationError: 'oidc-client-react/authentication-error',
  logout: 'oidc-client-react/logout',
}

const startAuthentication = () => actionCreator(types.startAuthentication);
const authenticationSuccess = (user: User) => actionCreator(types.authenticationSuccess, user);
const authenticationError = (error: Error) => actionCreator(types.authenticationError, error);
const logout = () => actionCreator(types.logout);

export const actions = {
  startAuthentication,
  authenticationSuccess,
  authenticationError,
  logout
}

export const actionTypes = types;

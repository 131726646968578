import {useMemo} from 'react';
import {useUserProfile} from '../../auth/hooks/use-user-profile.hook';
import {B2C2Application} from '../model/application';
import { useB2C2Applications } from './use-b2c2-applications';

export interface UseB2C2OwnedApplicationsResult {
    b2c2Applications: B2C2Application[];
    ownedApps: B2C2Application[];
    loading: boolean;
}

export function useB2C2OwnedApplications(): UseB2C2OwnedApplicationsResult {
    const { userProfile } = useUserProfile();
    const { loading, b2c2Applications } = useB2C2Applications();

    const username = userProfile?.username || '';

    const ownedApps = useMemo(
        () => b2c2Applications
        ? b2c2Applications.filter(app => app.owners.some((owner) => owner === username))
            : []
        , [b2c2Applications, username]
    );

    return {
        b2c2Applications,
        ownedApps,
        loading,
    };
}
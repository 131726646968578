import { useEffect } from 'react';
import { useIsAuthenticated } from '../lib/oidc-client-react';
import { GlobalNotification } from '../model/global-notification';
import { globalActions } from '../state/global-actions';
import { useGlobalStore } from '../state/global-store';

export interface UseNotificationsResult {
  notificationBar: GlobalNotification | null;
  notificationSnackbar: GlobalNotification | null;
  pushNotification: (notification: GlobalNotification) => void;
}

export function useNotifications(): UseNotificationsResult {
  const [state, dispatch] = useGlobalStore();
  const isAuthenticated = useIsAuthenticated();

  const notification = state.notifications.length === 0 ? null : state.notifications[0];

  useEffect(() => {
    if (notification) {
      if (isAuthenticated) {
        setTimeout(() => {
          dispatch(globalActions.popNotification(notification));
        }, 5000);
      } else {
        dispatch(globalActions.popNotification(notification));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  const pushNotification = (notification: GlobalNotification) => {
    if (isAuthenticated) {
      dispatch(globalActions.pushNotification(notification));
    }
  }

  return {
    notificationBar: notification !== null && notification.type === 'bar' ? notification : null,
    notificationSnackbar: notification !== null && notification.type === 'snackbar' ? notification : null,
    pushNotification,
  };
}

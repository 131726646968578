import React from 'react';
import { Navigate, Route, RouteProps, useLocation } from 'react-router-dom';
import { config } from '../../config';
import { useIsAuthenticated } from '../../lib/oidc-client-react';
import { useHasPermission, UserPermission } from './hooks/use-has-permission.hook';
import { UserRole } from './model/user-role';

export type ProtectedRouteProps = RouteProps & {
  requiredRole?: UserRole;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredRole = UserRole.None, children }) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const permissions = useHasPermission(requiredRole ? requiredRole : UserRole.None);

  return !isAuthenticated ? (
    <Navigate to={config.paths.login} replace={true} state={location} />
  ) : permissions === UserPermission.Denied ? (
    <Navigate to="/401" replace={true} />
  ) : permissions === UserPermission.Pending || permissions === UserPermission.Unknown ? (
    <></>
  ) : (
    <>{children}</>
  );
}


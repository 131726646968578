import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { Row } from "../../../../app-edit/b2c2-app-edit-b2c2b-auth";
import { TWO_WAY_SSL, X_PAY_TOKEN } from "../../../../../constants";
import { ClientKeySelectEdit } from "./client-key-select-edit";

export const ClientKeyIdEdit: React.FC<Table<Row>> = (table) => {
    
    if (table.options.meta?.dirtyRow?.type === TWO_WAY_SSL) {
        return <ClientKeySelectEdit {...table}/>
    } else if (!table.options.meta?.dirtyRow?.type) {
        return <></>;
    } else {
        return <div>N/A</div>;
    }

}
import React from 'react';
import { AppsRouter } from '../../apps-router';
import { appsAreaStyles } from './apps-area.styles';

export const B2C2AppsArea: React.FC = () => {
  const styles = appsAreaStyles();

  return (
    <div className={styles.root}>
          <AppsRouter />
    </div>
  );
}


import React, { useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { useAuthStore } from './lib/oidc-client-react/state/store';
import { getUserManager, useRefreshToken } from './lib/oidc-client-react';
import { actions } from './lib/oidc-client-react/state/actions'

export default function IdleTimerContainer() {
  const [state, dispatch] = useAuthStore();
  const timeout = 900000
  const [remaining, setRemaining] = useState(timeout)
  const [elapsed, setElapsed] = useState(0)
  const [lastActive, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false)

  const handleOnActive = () => setIsIdle(false)
  const { loading, authenticated } = useRefreshToken();

  const [isPromptDisplayed, setDisplayprompt]: [boolean, Function] = useState(false);
  const handleOnIdle = () => {
    setDisplayprompt(false);
    navigate('/auth/logout');
  }
  const handleOnPrompt = () => setDisplayprompt(true);
  const navigate = useNavigate();
  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime,
  } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    onPrompt: handleOnPrompt,
    promptTimeout: 30000
  })

  const handleReset = () => {
    reset();
    setDisplayprompt(false);
  }

  useEffect(() => {

    let lastActiveTime = getLastActiveTime() || +new Date();
    setRemaining(getRemainingTime());
    if (typeof lastActiveTime !== null) {
      setLastActive(+lastActiveTime)
    }

    setElapsed(getElapsedTime())

    setInterval(() => {
      let lastActiveTime = getLastActiveTime() || +new Date();
      setRemaining(getRemainingTime())
      if (typeof lastActiveTime !== null) {
        setLastActive(+lastActiveTime)
      }
      setElapsed(getElapsedTime())
    }, 1000)

    if (isIdle === true) {

      navigate('/auth/logout');
    }


    const refreshTokenAsync = async () => {
      try {
        const user = await getUserManager().signinSilent();
        dispatch(actions.authenticationSuccess(user));
      } catch {
      } finally {
        //setLoading(false);
      }
    }


    // if user active on every 14 minutes


    setInterval(() => {

      if (isIdle === false)
        refreshTokenAsync();

    }, 840000)

  }, [isIdle])

  return <Dialog open={isPromptDisplayed}>
    <DialogTitle>Idle Session Timeout</DialogTitle>
    <DialogContent>
      <DialogContentText >
        The portal is about to logout in {Math.floor(remaining / 1000)} seconds due to prolonged inactivity.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleReset()} color="primary">
        Reset Timer
      </Button>
    </DialogActions>
  </Dialog>
}

import { CellContext } from "@tanstack/react-table"
import React from "react"

export const EditCellWrapper: React.FC<{context:CellContext<any, any>, element: React.ReactElement<any, string | React.JSXElementConstructor<any>>}> = ({context,element}) => {
    if (context.table.options.meta?.currentEditingRowId[0] !== context.row.id) {
        if(typeof(context.getValue()) === 'boolean'){
            return <>{String(context.getValue())}</>;
        }
        return <>{context.getValue()}</>;
    }
    return element;
}
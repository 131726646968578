import { Dispatch } from 'react';
import { useStore } from '../../../state/app-store';
import { UsersState } from './users-reducer';
import { Action } from '../../../state/actions';

export const useUsersStore = () => {
  const store = useStore();
  const userStore: [UsersState, Dispatch<Action>] = [store[0].users, store[1]];
  return userStore;
}

export const useUsersState = () => useUsersStore()[0];

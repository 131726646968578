import { Table } from "@tanstack/table-core"
import React, { ChangeEvent } from "react"
import { Row } from "../../../../app-edit/b2c2-app-edit-identities"
import Input from '@visa/vds/input';
import { vdsStyleOverrides } from "../../../../../../../vds-overrides.styles";

export const B2C2AppsTemplateContentCell: React.FC<Table<Row>> = (table) => {
  const vdsStyles = vdsStyleOverrides();
  return <Input
    style={{ height: "unset" }}
    className={vdsStyles.inputFullWidth}
    label="Content"
    value={table.options.meta?.dirtyRow?.content || ''}
    onChange={(e: ChangeEvent<HTMLInputElement>) => {
      table.options?.meta?.setEditCelldata("", e.target.value, "content");
    }}
  ></Input>
}
import React, { ChangeEvent, MutableRefObject, useRef } from "react";
import ButtonIcon from '@visa/vds/button-icon';
import Icon from '@visa/vds/icon';
import Input from '@visa/vds/input';
import InputOption from '@visa/vds/input-option';
import Search from '@visa/vds/search';
import { vdsStyleOverrides } from "../../../../../vds-overrides.styles";

export const SearchBar: React.FC<{ search: string, setSearch: Function, label: string}> = ({ search, setSearch, label }) => {
    const inputReference: MutableRefObject<null | HTMLInputElement> = useRef(null);
    const vdsStyles = vdsStyleOverrides();
    return <Search aria-label={`${label} box`} className={vdsStyles.searchFullWidth}>
        <Input
            label={`${label}`}
            contentBeforeInput={
                <InputOption>
                    <Icon name="search" resolution="low" />
                </InputOption>
            }
            aria-label={label}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            name="searchBar"
            placeholder={label}
            type="search"
            useNativePlaceholder
            value={search}
            ref={inputReference}
            autoFocus={false}
        >
            {search.length ? (
                <InputOption>
                    <ButtonIcon
                        ariaLabel="clear"
                        iconType="light-tiny"
                        onClick={() => {
                            inputReference?.current?.focus();
                            setTimeout(() => {
                                setSearch("");
                            });
                        }}
                    >
                        <Icon name="clear" resolution="tiny" />
                    </ButtonIcon>
                </InputOption>
            ) : undefined}
        </Input>
    </Search>



}
import { useGlobalStore } from '../state/global-store';
import { globalActions } from '../state/global-actions';

export interface UseLoadingResult {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

export function useLoading(): UseLoadingResult {
  const [state, dispatch] = useGlobalStore();

  return {
    loading: !!state.loading,
    startLoading: () => dispatch(globalActions.startLoading()),
    stopLoading: () => dispatch(globalActions.stopLoading()),
  };
}

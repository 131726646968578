import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { DialogStyles, SuccessDialogTitleStyles } from './dialog.styles';
import { DialogActions, DialogContent } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { TypographyHighLight } from '../../components/app-header-highlight/typographyHighlight';

export interface OneButtonDialogProps {
    title: string;
    titleIcon?: string | null;
    content: string;
    btnText: string;
    btnNavigate: string;
}

export default function OneButtonDialog(props: OneButtonDialogProps) {
    const styles = DialogStyles();
    const navigate = useNavigate();
    const handleFinish = useCallback(() => {
        navigate(props.btnNavigate);
    }, [props, navigate, props.btnNavigate]);

    const DialogTitle = SuccessDialogTitleStyles((props: any) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other} >
                <Typography component={"div"}>{children}</Typography>
            </MuiDialogTitle>
        );
    });

    return (
        <div>
            <Dialog open={true} aria-modal
                role="dialog"
                aria-labelledby="dialog-title"
                onClose={(event, reason) => {
                    switch (reason) {
                        case "escapeKeyDown":
                            handleFinish();
                            break;
                        case "backdropClick":
                            break;
                        default:
                            handleFinish();
                            break;
                    }
                }}>
                <DialogTitle id="dialog-title"  >
                    {props.titleIcon == null ? <div /> : <img className={styles.icon} alt='icon' src={props.titleIcon} />}
                    <TypographyHighLight headerTitle={props.title} variant='h6' component={"h2"}></TypographyHighLight>
                </DialogTitle>
                <DialogContent >
                    <Typography variant="body1">
                        {props.content}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFinish} type="submit"
                        variant="outlined"
                        className={styles.longBtn}>
                        {props.btnText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
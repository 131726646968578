import { useEffect } from 'react';
import { useLoading } from '../../../hooks/use-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { B2C2ApprovalRequest } from '../model/product';
import { networkServicesProducts } from '../services/network-services.products';
import { productActions } from '../state/product-actions';
import { useB2C2ApprovalRequestsStore } from '../state/product-selectors';

export interface UseB2BApprovalsResult {
  b2c2ApprovalRequests: B2C2ApprovalRequest[];
  loading: boolean;
  refreshApprovals: ()=>void;
}

export function useB2C2ApprovalRequests(): UseB2BApprovalsResult {
  const [state, dispatch] = useB2C2ApprovalRequestsStore();
  const { startLoading, stopLoading } = useLoading();
  const { pushNotification } = useNotifications();

  const loadB2C2ApprovalsAsync = async () => {
    startLoading();
    dispatch(productActions.loadB2C2ApprovalRequests());
    try {
      const b2bApprovalRequests = await networkServicesProducts.getB2C2ApprovalRequestsAsync();
      dispatch(productActions.loadB2C2ApprovalRequestsSuccess(b2bApprovalRequests));
    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      dispatch(productActions.loadB2C2ApprovalRequestsError(error));
      pushNotification({
        message: `Load B2C Approval Requests failed with '${error}'`,
        variant: 'error',
        type: 'bar',
      });
    } finally {
      stopLoading();
    }
  }

  useEffect(() => {
    if (state.b2c2ApprovalRequests.length === 0 && !state.loading && !state.error) {
      loadB2C2ApprovalsAsync();
    }
  }, [state.b2c2ApprovalRequests, state.loading, state.error, dispatch, pushNotification, startLoading, stopLoading]);

  const refreshApprovals = () => {
    loadB2C2ApprovalsAsync();
  }
  return {
    b2c2ApprovalRequests: state.b2c2ApprovalRequests,
    loading: state.loading,
    refreshApprovals: refreshApprovals
  };
}
import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import React, { RefObject, useContext, useRef } from 'react';
import { ServerError } from '../../../../components/errors/server-error';
import { useUserProfile } from '../../hooks/use-user-profile.hook';
import { allUserRoles, userRolesMap } from '../../model/user-role';
import { ProfilePlaceholder } from './profile-placeholder';
import { useProfileStyles } from './profile.styles';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../../../state/skip-to-main-store';

export const Profile: React.FC = () => {
  const styles = useProfileStyles();
  const { userProfile: profile, loading } = useUserProfile();
  const mainHighlightRef:RefObject<HTMLHeadingElement> = useRef(null);
  const context:LandmarkContextType = useContext(LandmarkContext);
  useLandmarkHook(mainHighlightRef,context[1]);
  useHighlightH1(mainHighlightRef);

  if (loading) {
    return <ProfilePlaceholder />;
  }
  if (!profile) {
    return <ServerError />
  }

  const userRoles = allUserRoles.filter(role => profile.roles.has(role) &&  profile.roles.get(role));
  return (
    <main>
      <Helmet>
        <title>Profile | Visa Prototype Validation Platform </title>
      </Helmet>
    <div className={styles.root}>
      <Grid container spacing={2}>
        <Grid item  xs={12}>
          <Card>
            <CardHeader title="Profile" className={styles.title} component='h1' tabIndex={-1} innerRef={mainHighlightRef}/>
            <CardContent>
              <Typography component="div">
                <Grid container spacing={1}>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box className={styles.itemTitle} m={1} component={"b"}>Name</Box>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box className={styles.itemDetail} m={1}>{profile.displayName}</Box>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box/>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box className={styles.itemTitle} m={1} component={"b"}>Username</Box>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box className={styles.itemDetail} m={1}>{profile.username}</Box>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box/>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box className={styles.itemTitle} m={1} component={"b"}>Email</Box>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box className={styles.itemDetail} m={1}>{profile.email || 'Not Available'}</Box>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box/>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box className={styles.itemTitle} m={1} component={"b"}>Phone Number</Box>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box className={styles.itemDetail} m={1}>{profile.phone || 'Not Available'}</Box>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
            <Divider className={styles.divider}/>
            <CardContent>
              <Typography component="div">
                <Grid container spacing={3}>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box className={styles.itemTitle} m={1} component={"b"}>Your admin roles</Box>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Box className={styles.itemDetail} m={1}>
                      {userRoles.length > 0 ? userRoles.map(role => (
                        <Typography key={role} className={styles.itemDetail}>
                          {userRolesMap[role]}
                        </Typography>
                      )) : (
                          <>{'Not Available'}</>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                    <Box className={styles.helpBox}>
                      <Typography className={styles.itemDetail} component="p">
                        View the admin role list and/or request additional access on the <Link rel="noopener" to="/help">Help page</Link>.
                      </Typography>
                    </Box>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
    </main>
  );
};

import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { Row } from "../../../../app-edit/b2c2-app-edit-b2c2b-auth";
import { TWO_WAY_SSL } from "../../../../../constants";
import { b2c2ApplicationB2C2BArgsAttrSchema } from "../../../../../model/b2c2-application-validations";
import { ValidationError } from "yup";

export const BasicAuthPasswordEdit: React.FC<Table<Row>> = (table) => {
    const [basicAuthPasswordError, setBasicAuthPasswordError] = useState<string>("");

    if (table.options.meta?.dirtyRow?.type === TWO_WAY_SSL) {
        return <Input
            style={{ height: "unset" }}
            errorText={basicAuthPasswordError}
            label="Basic Auth Password *"
            name={"basicAuthPassword"}
            type={'password'}
            value={table.options.meta?.dirtyRow?.attr?.basicAuthPassword || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                let attribute = {...table.options?.meta?.dirtyRow?.attr,"basicAuthPassword": e.target.value}
                try{
                    b2c2ApplicationB2C2BArgsAttrSchema.validateSyncAt("basicAuthPassword", {"basicAuthPassword": e.target.value})
                    setBasicAuthPasswordError("");
                }catch (e) {
                    setBasicAuthPasswordError((e as ValidationError).message);
                  }
                table.options?.meta?.setEditCelldata("", attribute, "attr");
            }}
            showErrorText={!!basicAuthPasswordError}
            invalid={!!basicAuthPasswordError}
        ></Input>
    } else if (!table.options.meta?.dirtyRow?.type) {
        return <></>;
    } else {
        return <div>N/A</div>;
    }
}
import { Divider, Drawer, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useHasPermission, UserPermission } from '../../features/auth/hooks/use-has-permission.hook';
import { UserRole } from '../../features/auth/model/user-role';
import { useIsAuthenticated } from '../../lib/oidc-client-react';
import { BlueTooltip } from '../tooltips/tooltips.styles';
import { appDrawerStyles } from './app-drawer.styles';
import { ReactComponent as DashboardIcon } from './dashboard.svg';
import { ReactComponent as ServiceApprovalIcon } from './serviceApproval.svg';
import { ReactComponent as SettingsIcon } from './settings.svg';
import { ReactComponent as AppsIcon } from './view-grid.svg';
import { Link } from 'react-router-dom';
export interface B2BAppDrawerProps {
    open: boolean;
    onToggle: () => void;
}

export const B2BAppDrawer: React.FC<B2BAppDrawerProps> = ({ open, onToggle }) => {
    const styles = appDrawerStyles();
    const b2bAppDeveloper = useHasPermission(UserRole.B2BApplicationDeveloper) === UserPermission.Granted;
    const b2bServiceDeveloper = useHasPermission(UserRole.B2BServiceDeveloper) === UserPermission.Granted;
    const GDLUPPB2BApprovers = useHasPermission(UserRole.GDLUPPB2BApprovers) === UserPermission.Granted;
    const isAuthenticated = useIsAuthenticated();
    if (!isAuthenticated) {
        return null;
    }

    return (
        <Drawer role="navigation"
            PaperProps={{
                style: {
                    zIndex: 400
                }
            }}
            aria-label='B2B Drawer'
            variant="permanent"
            className={clsx(styles.drawer, {
                [styles.drawerOpen]: open,
                [styles.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [styles.drawerOpen]: open,
                    [styles.drawerClose]: !open,
                }),
            }}
            open={open}
        >
            <Divider />
            <div style={{ marginTop: "100px" }}>
                <BlueTooltip title="B2B dashboard" placement="right">
                    <Link to={"/b2b"}>
                        <ListItem className={styles.listItem} component={"span"}>
                            <ListItemIcon>
                                <DashboardIcon className={styles.dashIcon} />
                            </ListItemIcon>
                            <ListItemText primary="B2B Dashboard" />
                        </ListItem>
                    </Link>
                </BlueTooltip>
                {b2bAppDeveloper && (
                    <BlueTooltip title="B2B apps" placement="right">
                        <Link to={"/b2b/apps"}>
                            <ListItem className={styles.listItem} component={"span"}>
                                <ListItemIcon>
                                    <AppsIcon className={styles.dashIcon} />
                                </ListItemIcon>
                                <ListItemText primary="B2B Apps" />
                            </ListItem>
                        </Link>
                    </BlueTooltip>
                )}
                {b2bServiceDeveloper && (
                    <BlueTooltip title="B2B services" placement="right">
                        <Link to={"/b2b/services"}>
                            <ListItem className={styles.listItem} component={"span"}>
                                <ListItemIcon>
                                    <SettingsIcon className={styles.dashIcon} />
                                </ListItemIcon>
                                <ListItemText primary="B2B Services" />
                            </ListItem>
                        </Link>
                    </BlueTooltip>
                )}
                {(GDLUPPB2BApprovers || b2bServiceDeveloper) && (
                    <BlueTooltip title="B2B approvals" placement="right">
                        <Link to={"/b2b/services/approvals"}>
                            <ListItem className={styles.listItem} component={"span"}>
                                <ListItemIcon>
                                    <ServiceApprovalIcon className={styles.dashIcon} />
                                </ListItemIcon>
                                <ListItemText primary="B2B Approvals" />
                            </ListItem>
                        </Link>
                    </BlueTooltip>
                )}
            </div>
            <Divider />
        </Drawer>
    );
};
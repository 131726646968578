
import { CellContext } from "@tanstack/react-table"
import React from "react"
import { B2C2ApplicationB2C2BArgsAuthN } from '../../../../../model/application';
import { ServerCertIdEdit } from "./server-certId-edit";

export const AddServerCertIdCell: React.FC<CellContext<B2C2ApplicationB2C2BArgsAuthN, any>> = (context) => {
    if (context.table.options.meta?.currentEditingRowId[0] !== context.row.id) {
        return !!context.getValue() ?
                    <>{context.getValue()}</> : <div>N/A</div>
    }
 
    return <ServerCertIdEdit {...context.table}/>

}


import { createTheme, Theme } from '@material-ui/core';

const appTheme: Theme = createTheme({
  props:{
    MuiFormHelperText:{
      style:{
        "color":"#800808"
      }
    },
  },
  breakpoints: {
    values: {
      //all are default values only mentioned them for clarity except 1200 which is changed to 1280 for VGAR changes.
      xs: 0, sm: 600, md: 900, lg: 1280, xl: 1536
    }
  },
  palette: {
    primary: {
      main: process.env.NODE_ENV === 'production' ? '#1A1F71' : '#1A1F71',
    },
    secondary: {
      main: '#f7b600',
    },
    text: {
      secondary: '#1A1F71',
    },
    background: {
      default: "#ffffff"
    }
  },
});

export default appTheme;
import { actionCreator } from './actions';
import { GlobalNotification } from '../model/global-notification';

const types = {
  startLoading: 'global/start_loading',
  stopLoading: 'global/stop_loading',
  startBlockingLoading: 'global/start_blocking_loading',
  stopBlockingLoading: 'global/stop_blocking_loading',
  pushNotification: 'global/push_notification',
  popNotification: 'global/pop_notification',
}

const startLoading = () => actionCreator(types.startLoading);
const stopLoading = () => actionCreator(types.stopLoading);
const startBlockingLoading = () => actionCreator(types.startBlockingLoading);
const stopBlockingLoading = () => actionCreator(types.stopBlockingLoading);
const pushNotification = (notification: GlobalNotification) => actionCreator(types.pushNotification, notification);
const popNotification = (notification: GlobalNotification) => actionCreator(types.popNotification, notification);

export const globalActions = {
  startLoading,
  stopLoading,
  startBlockingLoading,
  stopBlockingLoading,
  pushNotification,
  popNotification,
}

export const globalTypes = types;

import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { ProductEndpointUpdate } from "../../../../../../products/model/product-update";

export const EndpointExternalPathCell: React.FC<Table<ProductEndpointUpdate>> = (table) => {
    const [externalPathError, setExternalPathError] = useState<string>("");
    return <Input
        style={{ height: "unset" }}
        errorText={externalPathError}
        label="External Path *"
        name={"external_path"}
        value={table.options.meta?.dirtyRow?.externalPath || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setExternalPathError(table.options.meta?.isIndividualCellValid("externalPath", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "externalPath");
        }}
        onBlur={()=>setExternalPathError(table.options?.meta?.isIndividualCellValid("externalPath",table.options.meta.dirtyRow?.externalPath) || "")}
        showErrorText={!!externalPathError}
        invalid={!!externalPathError}
    ></Input>
}
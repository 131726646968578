import { Table } from "@tanstack/table-core"
import React, { ChangeEvent } from "react"
import { Row } from "../../../../app-edit/b2c2-app-edit-headers"
import Input from '@visa/vds/input';

export const B2C2AppsHeaderValueCell: React.FC<Table<Row>> = (table: Table<Row>) => {
  return <Input
    style={{ height: "unset" }}
    fullWidth
    label="Value"
    value={table.options.meta?.dirtyRow?.value || ''}
    onChange={(e: ChangeEvent<HTMLInputElement>) => {
      table.options?.meta?.setEditCelldata("", e.target.value, "value");
    }}
    autoFocus={!!table.options.meta?.dirtyRow?.value}
  ></Input>
}
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExportToJsonButton } from '../../../../components/buttons/export-to-json-button';
import { logger } from '../../../../services/logger.service';
import { buttonStyles } from '../../../../styles/button.styles';
import { useB2C2ApprovalRequest } from '../../../products/hooks/use-b2c2-approval-request';
import { B2C2ReviewRequestUpdate } from '../../../products/model/product-update';
import { B2C2AppReviewRequestUpdate } from '../../hooks/use-b2c2-app-review-request';
import { B2C2Application } from '../../model/application';
import { B2C2ApplicationUpdate, B2BReviewRequestUpdate } from '../../model/application-update';
import { jsonB2C2App } from '../../services/json.b2b.service';
import AppDialog, { AppDialogStates } from '../app-dialog/app-dialog';
import { useAppPreviewStyles } from './app-preview.styles';
import { B2BAppPreviewDetails } from './b2b-app-preview-details';
import { B2C2AppPreviewAllowedEndpoints } from './b2c2-app-preview-allowed-endpoints';
import { B2C2AppPreviewAppConfigs } from './b2c2-app-preview-config';
import { B2C2AppPreviewAppCustom } from './b2c2-app-preview-custom';
import { B2C2AppPreviewHeaders } from "./b2c2-app-preview-headers";
import { useB2C2ApprovalRequests } from '../../../products/hooks/use-b2c2-approval-requests';
import { B2C2AppPreviewB2C2BArgs } from './b2c2-app-preview-b2c2b-args';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';

export interface B2C2AppPreviewProps {
  b2c2Application?: B2C2Application | null;
  b2c2AppUpdate?: B2C2ApplicationUpdate | null;
  extraDetails?: Record<string, string>;
  b2c2PendingApplications?: B2C2Application | null;
  alignment?: string;
  setAlignment?: any;
  isApplicationOwner: boolean | undefined;
}

export const B2C2AppPreview: React.FC<B2C2AppPreviewProps> = ({ b2c2Application, b2c2PendingApplications, b2c2AppUpdate, alignment = "PENDING", setAlignment, isApplicationOwner }) => {
  const styles = useAppPreviewStyles();
  const btnStyles = buttonStyles();
  const navigate = useNavigate();
  const { createB2C2AppReviewRequestAsync } = B2C2AppReviewRequestUpdate();
  const getJsonContentToExport = useCallback(
    () => b2c2AppUpdate ? jsonB2C2App.exportB2C2AppToJson(b2c2AppUpdate) : '{}',
    [b2c2AppUpdate]
  );
  const { refreshApprovals } = useB2C2ApprovalRequests();
  var alignmentValue = 'PENDING';
  var requestGuid = '';
  if (b2c2PendingApplications !== undefined && b2c2PendingApplications !== null) {
    alignmentValue = 'PENDING';
    requestGuid = b2c2PendingApplications.requestGuid;
  } else if (b2c2Application !== undefined && b2c2Application !== null) {
    alignmentValue = 'ACTIVE';
    requestGuid = b2c2Application.requestGuid;
  }

  const [activeService, setActiveService] = React.useState(false);
  const handleChange = (event: any, newAlignment: React.SetStateAction<string>) => {
    if (newAlignment !== null)
      setAlignment(newAlignment);
  };
  const { b2c2ApprovalRequest } = useB2C2ApprovalRequest(requestGuid);
  const [dialog, setDialog] = React.useState(0);
  const handleDialog = useCallback((index: number) => {
    setDialog(index);
  }, []);

  const handleSubmitButton = () => {
    if (alignment == "ACTIVE" || typeof b2c2PendingApplications == "undefined" || b2c2ApprovalRequest?.status !== "OPEN" || !isApplicationOwner) {
      return true;
    } else {
      return false;
    }
  };
  const setToggle = handleSubmitButton();

  const b2c2ReviewRequestUpdate = useMemo<B2C2ReviewRequestUpdate>(
    () => ({
      requestId: "",
      saNumber: "",
      businessJustification: "",
    }), []
  );

  const handleCancel = useCallback(() => {
    navigate('/b2c/apps');
  }, [navigate]);

  const handleSubmitReview = useCallback(async (sanumber: string, businessJustification: string) => {
    if (!b2c2ReviewRequestUpdate)
      return;
    if (!!b2c2PendingApplications) {
      b2c2ReviewRequestUpdate.businessJustification = businessJustification;
      b2c2ReviewRequestUpdate.saNumber = sanumber;
      b2c2ReviewRequestUpdate.requestId = b2c2PendingApplications.requestGuid;
      const success = await (createB2C2AppReviewRequestAsync(b2c2PendingApplications.applicationGuid, b2c2ReviewRequestUpdate));
      if (success) {
        refreshApprovals();
        navigate('/b2c/apps/');
      } else {
        logger.error('Unable to show approvals page', "Update Approval Request unsuccessful");
      }
    }

    navigate('/b2c/apps');
  }, [b2c2PendingApplications, b2c2ReviewRequestUpdate, handleDialog, createB2C2AppReviewRequestAsync, navigate]);

  /*   const handleSubmit = useCallback(() => {
      navigate('/b2b/services');
  }, [navigate]); */

  const handleEditComplete = useCallback(() => {
    navigate('/b2c/apps')
    //window.location.reload();
  }, [navigate]);

  const handleApprovalDialog = useCallback(async () => handleDialog(AppDialogStates.SubmitReview), [handleDialog]);

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={styles.root}>
      <div>
        <TypographyHighLight headerTitle={<Typography component="div" className={styles.title}>App
          Details</Typography>} />
        <br />
        <Grid container direction='row' justifyContent='space-between'>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}>
            <ToggleButton id="pending-a" value="PENDING" disabled={(b2c2PendingApplications === undefined && b2c2PendingApplications === null)} >PENDING</ToggleButton>
            <ToggleButton id="active-a" value="ACTIVE" disabled={(b2c2AppUpdate === undefined && b2c2AppUpdate === null)} >ACTIVE</ToggleButton>
          </ToggleButtonGroup>
          <Button
            type="submit"
            variant="contained"
            onClick={handleApprovalDialog}
            className={clsx(btnStyles.btnBlue2x)}
            disabled={setToggle}
          >
            Submit for Review
          </Button>
        </Grid>
        <Divider className={styles.dividerB2C} />
        {(alignment === "ACTIVE") ?
          <>
          { b2c2Application!==null?(<>
            <Box display="flex" justifyContent="flex-end"><ExportToJsonButton filename="b2c2Application.json"
              getJsonContent={getJsonContentToExport}
              mode="textIcon" /></Box>
            <B2BAppPreviewDetails details={b2c2Application}  />
            <Divider className={styles.dividerB2C} />
              <Typography className={styles.title} component={"h2"}>Allowed
                Endpoints</Typography>
              <br />
              <B2C2AppPreviewAllowedEndpoints allowedEndpoints={b2c2Application?.allowedEndpoints}/>
              <Divider className={styles.dividerB2C} />
              <Typography className={styles.title} component={"h2"}>Allowed
                Headers</Typography>
              <br />
              <B2C2AppPreviewHeaders headers={b2c2Application?.appProductHeaders} />
              <Divider className={styles.dividerB2C} />

              <Typography className={styles.title} component={"h2"}> App Configs</Typography>
              <br />
              <B2C2AppPreviewAppConfigs appConfigs={b2c2Application?.configs}/>
              <Divider className={styles.dividerB2C} />
           
              <Typography className={styles.title} component={"h2"}> Customizations</Typography>
              <br />
              <B2C2AppPreviewAppCustom appCustomConfig={b2c2Application}/>
              <Divider className={styles.dividerB2C} />
  	      <Typography className={styles.title} component={"h2"}> B2C2B Args</Typography>
              <br />
              <B2C2AppPreviewB2C2BArgs b2c2bArgs={b2c2Application?.b2c2bArgs} isApplicationOwner={!!isApplicationOwner} />
              <Divider className={styles.dividerB2C} />
	  </>):
           (<> 
             Application does not contain any active version 
             <Divider className={styles.divider2x} />
          </>)}
            </>
          :
          <>
          {b2c2PendingApplications!==null?<div>
            <div>
              <Box display="flex" justifyContent="flex-end"><ExportToJsonButton filename="b2c2Application.json"
                getJsonContent={getJsonContentToExport}
                mode="textIcon" /></Box>
              <B2BAppPreviewDetails details={b2c2Application} />
              <Divider className={styles.dividerB2C} />
              <div>
                <Typography className={styles.title} component={"h2"}>Allowed
                  Endpoints</Typography>
                <br />
                <B2C2AppPreviewAllowedEndpoints allowedEndpoints={b2c2PendingApplications?.allowedEndpoints}/>
                <Divider className={styles.dividerB2C} />
              </div>
              <div>
                <Typography className={styles.title} component={"h2"}>Allowed
                  Headers</Typography>
                <br />
                <B2C2AppPreviewHeaders headers={b2c2PendingApplications?.appProductHeaders} />
                <Divider className={styles.dividerB2C} />
              </div>
              <div>
                <Typography className={styles.title} component={"h2"}> App Configs</Typography>
                <br />
                <B2C2AppPreviewAppConfigs appConfigs={b2c2PendingApplications?.configs}/>
                <Divider className={styles.dividerB2C} />
              </div>
              <div>
                <Typography className={styles.title} component={"h2"}> Customizations</Typography>
                <br />
                <B2C2AppPreviewAppCustom appCustomConfig={b2c2PendingApplications}/>
                <Divider className={styles.dividerB2C} />
              </div>
              <div>
                <Typography className={styles.title} component={"h2"}> B2C2B Args</Typography>
                <br />
                <B2C2AppPreviewB2C2BArgs b2c2bArgs={b2c2PendingApplications?.b2c2bArgs} isApplicationOwner={!!isApplicationOwner} />
              </div>
            </div>
          </div>
            :<div>
              Application does not contain any pending version
              <Divider className={styles.divider2x} />
          </div>}
          </>}
      {dialog ? <AppDialog handleDialog={handleDialog} handleCancel={handleCancel} handleSubmitReview={handleSubmitReview}
        handleEditComplete={handleEditComplete} dialog={dialog} handleSubmit={function (): Promise<void> {
          throw new Error('Function not implemented.');
        }} navLinkOnSuccess={''} /> : <div/>}
      </div>
    </Grid>
  );
};
import { Table } from "@tanstack/table-core"
import React, { useState } from "react"
import Tr from '@visa/vds/tr';
import Td from '@visa/vds/td';
import { FormControl, FormHelperText, Input, InputLabel, NativeSelect } from "@mui/material";
import { ProductHeader } from '../../../../../products/model/product';
import { TableFormAddCancelAction } from "../../table-cells/b2c2-apps/table-form-add-cancel";
import { FormLabel } from "@material-ui/core";
import { Row } from "../../../../../appsNew/views/app-edit/b2c2-app-edit-b2c2b-mle";
import { AddMleKeyCall } from "../../table-cells/b2c2-apps/app-b2c2b/add-mle-key-cell";
import { AddMleStatusCell } from "../../table-cells/b2c2-apps/app-b2c2b/add-mle-status-cell";
import { MleServerCertSelectEdit } from "../../table-cells/b2c2-apps/app-b2c2b/mle-server-cert-select-edit";
import { MleClientKeySelectEdit } from "../../table-cells/b2c2-apps/app-b2c2b/mle-client-key-select-edit";

export const B2C2BAppsMle: React.FC<Table<Row>> = (table) => {
    return <Tr >
    <Td><AddMleKeyCall {...table}/></Td>
    <Td><AddMleStatusCell {...table}/></Td>
    <Td><MleClientKeySelectEdit {...table}/></Td>
    <Td><MleServerCertSelectEdit {...table}/></Td>
    <Td><TableFormAddCancelAction {...table as Table<any>}/></Td> 
</Tr>
}
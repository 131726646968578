import { useBlockingLoading } from '../../../hooks/use-blocking-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { networkServicesSrv } from '../services/network-services.service';
import { servicesActions } from '../state/services-actions';
import { useDispatch } from '../../../state/app-store';

export interface UseB2BServiceDeleteResult {
  loading: boolean;
  deleteB2BServiceAsync: (serviceGuid: string|undefined) => Promise<boolean>;
}

export function useB2BServiceDelete(): UseB2BServiceDeleteResult {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useBlockingLoading();
  const { pushNotification } = useNotifications();

  const deleteB2BServiceAsync = async (serviceGuid: string|undefined): Promise<boolean> => {
    startLoading();
    try {
      await networkServicesSrv.deleteB2BServiceAsync(serviceGuid);
      dispatch(servicesActions.deleteB2BService(serviceGuid));
      return true;
    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      pushNotification({
        message: `Delete service failed with '${error}'. Please, try again later.`,
        variant: 'error',
        type: 'bar',
      });
      return false;
    } finally {
      stopLoading();
    }
  };

  return {
    loading,
    deleteB2BServiceAsync,
  };
}

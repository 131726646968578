import {
  Button,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { statusListCardStyles } from './list-card.styles';
import { buttonStyles } from "../../../../styles/button.styles";
import Moment from "moment";
import Icon from '@visa/vds/icon';
import ButtonIcon from '@visa/vds/button-icon';
import { Grid, Typography } from '@mui/material';
import Select from '@visa/vds/select';
import ButtonText from '@visa/vds/button-text';
import { vdsStyleOverrides } from '../../../../vds-overrides.styles';
import ScreenReader from '@visa/vds/screen-reader';


export type Status = 'Active' | 'Stopped' | 'Disabled' | string;

export interface AppEntry {
  applicationGuid: string,
  name: string;
  description: string;
  status: Status;
  dateModified: string;
  action: string;
}

export interface Headers {
  name?: string;
  applicationGuid?: string,
  description?: string;
  dateModified?: string;
  action?: string;
  status?: string;
}

export interface ActionItem {
  title: string;
  action: () => void;
}

export interface StatusListCardProps {
  title: string;
  entries: AppEntry[];
  ownedEntries: AppEntry[];
  topAction?: ActionItem;
  bottomAction?: ActionItem;
  onEntryClick?: (applicationGuid: string) => void;
  className?: string;
  headers?: Headers;
  isB2B: boolean;
}

export const B2BAppStatusListCard: React.FC<StatusListCardProps> = ({ title, entries, ownedEntries, topAction, bottomAction, onEntryClick, className, headers, isB2B, ...rest }) => {
  const styles = statusListCardStyles();
  const vdsStyles= vdsStyleOverrides();
  const [displayAll, setDisplayAll] = useState(false);
  const handleChange = (event: any) => {
    setDisplayAll(event.target.value === 'all');
  };
  const heads: Headers = {
    name: 'App name',
    applicationGuid: 'Application Guid',
    description: 'Description',
    dateModified: 'Date Modified',
    action: 'Action',
    status: 'Status',
    ...headers,
  };
  return (
    <Card>
      <Grid container className={styles.cardHeader} direction={"row"} alignItems={"center"} rowGap={2}>
        <Grid item xs={12} lg={8}>
          <Typography variant='h5' component={"h2"}>{title}</Typography>
        </Grid>
        {topAction && <Grid item xs={12} lg={4}>
          <Grid container rowGap={2} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} lg={8}>
              <Select
                name="Application_filter"
                label="Application filter"
                onChange={handleChange}
                defaultValue={"my"}
                className={vdsStyles.selectFullWidth}
              >
                <option value="all">All apps</option>
                <option value="my">My apps</option>
              </Select>
            </Grid>
            <Grid item xs={12} lg={4} justifyContent={"center"} alignItems={"center"}>
              <ButtonText colorScheme="primary" onClick={topAction.action} isFullWidth>
                {topAction.title}
              </ButtonText>
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
      <CardContent className={styles.content}>
        <TableContainer style={{ minWidth: "100%", width: "80vw", overflowX: "scroll" }} tabIndex={0}>
          <Table style={{
            captionSide: "top"
          }}>
            <ScreenReader tag="caption">{isB2B ? 'B2B Applications' : 'B2C Applications : application name in column 1 and details in the next 4 columns.actions for each application are available in column 6'}</ScreenReader>
            <TableHead>
              <TableRow>
                <TableCell component={"th"} scope='col' style={{ minWidth: "200px" }}><b> {heads.name}</b></TableCell>
                <TableCell component={"th"} scope='col' style={{ minWidth: "400px" }}><b>{heads.applicationGuid}</b></TableCell>
                <TableCell component={"th"} scope='col' style={{ minWidth: "490px" }}><b>{heads.description}</b></TableCell>
                <TableCell component={"th"} scope='col' style={{ minWidth: "200px" }}><b>{heads.dateModified}</b></TableCell>
                <TableCell component={"th"} scope='col' style={{ minWidth: "200px" }}><b>{heads.status}</b></TableCell>
                <TableCell component={"th"} scope='col' style={{ minWidth: "100px" }}><b>{heads.action}</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(displayAll ? entries : ownedEntries).map((entry) => (
                <TableRow hover key={entry.applicationGuid}>
                  <TableCell>{entry.name}</TableCell>
                  <TableCell>{entry.applicationGuid}</TableCell>
                  <TableCell>{entry.description}</TableCell>
                  <TableCell>{Moment(entry.dateModified).format('MMM D YYYY')}</TableCell>
                  <TableCell>{entry.status}</TableCell>

                  <TableCell className={clsx(onEntryClick && styles.pointer)}>
                    <ButtonIcon ariaLabel={"View Details for application " + entry.name} iconType="light-tiny" onClick={onEntryClick ? () => onEntryClick(entry.applicationGuid) : undefined} role="link" >
                      <Icon name="password-show" resolution="tiny" />
                    </ButtonIcon>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={styles.actions}>
        {bottomAction && (
          <ButtonText
            colorScheme="secondary"
            onClick={bottomAction.action}>
            {bottomAction.title}
          </ButtonText>
        )}
      </CardActions>
    </Card>
  );
};

import { STOP_SSO_RETRIES } from "../features/apps/constants";
import { localStorageSrv } from "./local-storage.service";

function setNoOfRetries(noOfRetries: number): void {
  localStorageSrv.setValue("noOfRetries", noOfRetries);
}

function getNoOfRetries(): number {
  const noOfRetries =
    localStorageSrv.getValue<number>("noOfRetries") || STOP_SSO_RETRIES;
  return noOfRetries;
}

export const retrySSOSrv = {
  setNoOfRetries,
  getNoOfRetries,
};

import { Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { useProductPreviewStyles } from "../product-view/product-preview.styles";

export const ProductPreviewB2C2BArgsPlaceholder: React.FC = () => {
    const styles = useProductPreviewStyles();
    return (
        <Typography component="div" className={styles.details}>
          <Grid container direction={'row'} spacing={3}>
            <Grid item xs={2}><b>Use VP2 Connector</b></Grid>
            <Grid item xs={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
          </Grid>
          <Grid container direction={'row'} spacing={3}>
            <Grid item xs={2}><b>Business</b></Grid>
            <Grid item xs={10}><Skeleton variant="rect" animation="wave" height={30}/></Grid>
          </Grid>
      </Typography>
    );
};

import {
  B2C2ApprovalEntity,
  B2C2Entity,
  Identity,
} from "../../../model/entities";
import { MleType } from "../../../model/mle-types";
import { ProductDetailsUpdate } from "./product-update";

export type ServiceHostDataCenter = "OCE" | "OCC" | "OCIB" | "OCIM" | "";
export const serviceHostDataCenters = ["OCE", "OCC", "OCIB", "OCIM"];

export type RateLimitType =
  | "OVERALL_THROTTLE"
  | "OVERALL_QUOTA"
  | "THROTTLE_PER_CLIENT"
  | "QUOTA_PER_CLIENT";
export const rateLimitTypes = [
  "OVERALL_THROTTLE",
  "OVERALL_QUOTA",
  "THROTTLE_PER_CLIENT",
  "QUOTA_PER_CLIENT",
];

export type ApprovalRequestStatus = "PENDING" | "APPROVED" | "DENIED";
export const approvalRequestStatus = ["PENDING", "APPROVED", "DENIED"];

export interface Product extends B2C2Entity, Identity {
  name: string;
  description: string;
  documentationUrl: string;
  contextRoot: string;
  statusType: string;
  productEndpoints: ProductEndpoints[];
  productHosts: ProductHost[];
  productOwners: string[];
  productHeaders: ProductHeader[];
  productId: string;
  requestId: string;
  isEditable?: boolean;
  productB2C2BArgs?: ProductB2C2BArgs;
}

export interface ProductB2C2BArgs {
  useVp2Connector?: boolean;
  business?: string;
}

export interface ProductDetails extends ProductDetailsUpdate {}

export interface ProductEndpoints extends B2C2Entity, Identity {
  name: string;
  description: string;
  internalPath: string;
  externalPath: string;
  methodType: string;
  endpointId: string;
  productId: string;
  status: string;
  version: string;
  b2cMleType: MleType;
  b2bMleType: MleType;
}

export interface ProductHost extends B2C2Entity, Identity {
  url: string;
  dataCenter: ServiceHostDataCenter;
  productId: string;
  hostId: string;
}

export interface ProductHeader extends B2C2Entity, Identity {
  name: string;
  dataType: string;
  isRequired: boolean;
  defaultValue: string;
  headerId: string;
  productId: string;
}

export function cloneProductDetails(details: ProductDetails): ProductDetails {
  return {
    id: details.id,
    contextRoot: details.contextRoot,
    name: details.name,
    documentationUrl: details.documentationUrl,
    description: details.description,
    productId: details.productId,
    requestId: details.requestId,
  };
}

export interface B2C2ApprovalRequest extends B2C2Entity {
  reviewerSubjectName?: string;
  saNumber?: string;
  businessJustification?: string;
  requestId: string;
  requestor: string;
  requestType: string;
  status: string;
  adminRequestEntity?: AdminRequestEntity;
  sendBackMessage?: string;
}

export interface AdminRequestEntity extends B2C2ApprovalEntity {
  name?: string;
  description?: string;
  context_root?: string;
  documentation_url?: string;
  productId?: string;
  requestId?: string;
  appId?: string;
  status?: string;
  supportGdl?: string;
  namespace?: string;
  tenancyId?: string;
  allowedEndpoints?: ProductEndpoints[];
  owners?: string[];
}

export interface B2C2ProductReviewRequest {
  businessJustification: string;
  saNumber: string;
  requestId: string;
  requestStatus: string;
}

import { getUserManager } from '../service/user-service';

export interface UseLoginResult {
  login: () => void;
}

export function useLogin(): UseLoginResult {
  return {
    login: () => getUserManager().signinRedirect()
  }
}

import { Divider, Drawer, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AddToQueue, AssignmentTurnedIn } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { useHasPermission, UserPermission } from '../../features/auth/hooks/use-has-permission.hook';
import { UserRole } from '../../features/auth/model/user-role';
import { useIsAuthenticated } from '../../lib/oidc-client-react';
import { BlueTooltip } from '../tooltips/tooltips.styles';
import { appDrawerStyles } from './app-drawer.styles';
import { ReactComponent as AppApprovalIcon } from './appApproval.svg';
import { ReactComponent as DashboardIcon } from './dashboard.svg';
import { ReactComponent as AppsIcon } from './view-grid.svg';
import { Link } from 'react-router-dom';
export interface AppDrawerProps {
    open: boolean;
    onToggle: () => void;

}

export const AppDrawer: React.FC<AppDrawerProps> = ({ open, onToggle }) => {
    const styles = appDrawerStyles();
    const appDeveloper = useHasPermission(UserRole.ApplicationDeveloper) === UserPermission.Granted;
    const serviceDeveloper = useHasPermission(UserRole.ServiceDeveloper) === UserPermission.Granted;
    const isAuthenticated = useIsAuthenticated();
    if (!isAuthenticated) {
        return null;
    }

    return (
        <Drawer role="navigation"
            PaperProps={{
                style: {
                    zIndex: 400
                }
            }}
            aria-label='B2C Drawer'
            variant="permanent"
            className={clsx(styles.drawer, {
                [styles.drawerOpen]: open,
                [styles.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [styles.drawerOpen]: open,
                    [styles.drawerClose]: !open,
                }),
            }}
            open={open}
        >
            <Divider />
            <div style={{ marginTop: "100px" }}>
                <BlueTooltip title={"B2C dashboard"} placement="right">
                    <Link to={ "/b2c"}>
                        <ListItem className={styles.listItem} component={"span"}>
                            <ListItemIcon>
                                <DashboardIcon className={styles.dashIcon} />
                            </ListItemIcon>
                            <ListItemText primary={ "B2C Dashboard"} />
                        </ListItem>
                    </Link>
                </BlueTooltip>
                {appDeveloper && (
                    <BlueTooltip title={ "B2C apps"} placement="right">
                        <Link to={"/b2c/apps"}>
                            <ListItem className={styles.listItem} component={"span"}>
                                <ListItemIcon>
                                    <AppsIcon className={styles.dashIcon} />
                                </ListItemIcon>
                                <ListItemText primary={"B2C Apps"} />
                            </ListItem>
                        </Link>
                    </BlueTooltip>
                )}
                {serviceDeveloper && (
                    <BlueTooltip title={"B2C products"} placement="right">
                        <Link to={"/b2c/products"}>
                            <ListItem className={styles.listItem} component={"span"}>
                                <ListItemIcon>
                                    <AddToQueue className={styles.dashIcon} />

                                </ListItemIcon>
                                <ListItemText primary={ "B2C Products"} />
                            </ListItem>
                        </Link>
                    </BlueTooltip>
                )}
          

                    <BlueTooltip title="Admin requests" placement="right">
                        <Link to={"/b2c/products/approvals"}>
                            <ListItem className={styles.listItem} component={"span"}>
                                <ListItemIcon>
                                    <AssignmentTurnedIn className={styles.dashIcon} />
                                </ListItemIcon>
                                <ListItemText primary="Admin Requests" />
                            </ListItem>
                        </Link>
                    </BlueTooltip>

                
                    <BlueTooltip title="Consumer requests" placement="right">
                        <Link to={"/b2c/products/requests"}>
                            <ListItem className={styles.listItem} component={"span"}>
                                <ListItemIcon>
                                    <AppApprovalIcon className={styles.dashIcon} />
                                </ListItemIcon>
                                <ListItemText primary="Consumer Requests" />
                            </ListItem>
                        </Link>
                    </BlueTooltip>
            </div>
            <Divider />
        </Drawer>
    );
};
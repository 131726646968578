import React, { RefObject, useEffect } from "react";
import { Dispatch, Reducer, ReducerAction, ReducerState, createContext, useReducer } from "react";
import { IAction, ISkipToLoginState } from "./actions";

const enum ActionEnum {
  SET_SKIP_TO_LOGIN, REMOVE_SKIP_TO_LOGIN
}

export type SkipToLoginContextType= [ReducerState<Reducer<ISkipToLoginState, IAction<ActionEnum,ISkipToLoginState>>>, Dispatch<ReducerAction<Reducer<ISkipToLoginState, IAction<ActionEnum,ISkipToLoginState>>>>];

const reducer: Reducer<ISkipToLoginState, IAction<ActionEnum,ISkipToLoginState>> = (state: ISkipToLoginState, action: IAction<ActionEnum,ISkipToLoginState>): ISkipToLoginState => {
  switch (action.type) {
    case ActionEnum.SET_SKIP_TO_LOGIN:
      return {
        ...state,
        isSkipToRequired: true,
        onSkipFocusElement: action.payload.onSkipFocusElement
      }
    case ActionEnum.REMOVE_SKIP_TO_LOGIN:
      return {
        isSkipToRequired: false
      }
    default:
      return state;
  }
}

const initialState: ISkipToLoginState = {
  isSkipToRequired: false
}


const defaultValue: SkipToLoginContextType = [
  initialState, () => { }
];
export const SkipToLoginContext = createContext(defaultValue);

export const AppSkipToLoginProvider:React.FC<{ children: React.ReactNode }> = ({ children }: { children: React.ReactNode }) => (
  
  <SkipToLoginContext.Provider
    value={useReducer(reducer, initialState)}
    children={children}
  />
);

export const useSkipToLoginHook= (ref:RefObject<HTMLElement>, dispatch:Dispatch<ReducerAction<Reducer<ISkipToLoginState, IAction<ActionEnum,ISkipToLoginState>>>>)=>useEffect(()=>{
  dispatch({
    type: ActionEnum.SET_SKIP_TO_LOGIN,
    payload:{
      isSkipToRequired: true,
      onSkipFocusElement: ref,
    }

  });

  return ()=>{
    dispatch({
      type: ActionEnum.REMOVE_SKIP_TO_LOGIN,
      payload:{
        isSkipToRequired: false,
        onSkipFocusElement: null,
      }
  
    });
  }
 },[ref.current])
export interface IdT {
  id: number;
}

export function arrayDiff<T extends IdT>(a: T[], b: T[] | null | undefined): T[] {
  if (!b || b.length === 0) {
    return a;
  }
  return a.filter(aItem => !b.some(bItem => aItem.id === bItem.id));
}

import { config } from '../../../config';
import { networkSrv } from '../../../services/network.service';
import { CredentialDetail } from '../../appsNew/model/credential-detail';
import { B2C2ApplicationB2C2BKeyId } from '../model/application';

export async function uploadFileAsync(file: File): Promise<B2C2ApplicationB2C2BKeyId> {
  const credentialRS = await networkSrv.postFileAsync<File, B2C2ApplicationB2C2BKeyId>(config.urls.b2cCredentials, file);
  return credentialRS;
}

export async function downloadFileAsync(id: string): Promise<string> {
  const credentialFileRS = await networkSrv.getFileAsync<string, string>(`${config.urls.b2cCredentials}/${id}`);
  return credentialFileRS;
}

export async function getCredentialDetails(): Promise<CredentialDetail[]> {
    const b2bApplicationsRS = await networkSrv.getAsync<CredentialDetail[]>(`${config.urls.b2cCredentials}`);
    return b2bApplicationsRS;
  }

export async function deleteCredential(credentialId: string): Promise<void> {
  return networkSrv.deleteAsync(`${config.urls.b2cCredentials}/${credentialId}`);
}

export const networkFileUploadSrv = {
  uploadFileAsync,
  downloadFileAsync,
  getCredentialDetails,
  deleteCredential
}
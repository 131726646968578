import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const homeStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  cardImage:{
    height:'175px',
    width:'auto',
    float:'right'
  },
  cardHasAccess: {
    borderRadius: 0,
    backgroundColor: '#F2F4F8',
    color: '#616188',
    margin: theme.spacing(1, 0, 1, 0)
  },
  cardNoAccess: {
    borderRadius: 0,
    color: '#616188',
    margin: theme.spacing(1, 0, 1, 0)
  },
  cardColumn: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]:{
      padding: 0 
    }
  },
  cardName:{
    height:'30px',
    color: '#2A2E69'
  },
  cardColumnWithMargin: {
    padding: theme.spacing(9, 6, 6, 6),
    [theme.breakpoints.down("sm")]:{
      padding: 0 
    }
  },
  btnRequestAccess: {
    width: '220px',
    height: 'auto'
  },
  heading:{
    padding: "32px 0"
  }
}));

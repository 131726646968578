import { useEffect } from 'react';
import { useLoading } from '../../../hooks/use-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { networkFileUploadSrv } from '../services/network-file-upload.service';
import { appsActions } from '../../apps/state/apps-actions';
import { CredentialDetail } from '../model/credential-detail';
import { useCredentialDetailsStore } from '../../apps/state/apps-selectors';

export interface UseCredentialsResult {
  credentialDetails: CredentialDetail[] | null;
  loading: boolean;
}

export function useCredentialsDetails(): UseCredentialsResult {
  const [state, dispatch] = useCredentialDetailsStore();
  const { startLoading, stopLoading } = useLoading();
  const { pushNotification } = useNotifications();

  useEffect(() => {
    const loadCredentialsAsync = async () => {
      startLoading();
      dispatch(appsActions.loadCredentials());
      try {
        const credentialDetails = await networkFileUploadSrv.getCredentialDetails();
        const allCredentials = [...credentialDetails];
        dispatch(appsActions.loadCredentialsSuccess(allCredentials));
      } catch (e:any) {
        const error = e.message ? e.message as string : 'Response error';
        pushNotification({
          message: `Load Credentials failed with '${error}'`,
          variant: 'error',
          type: 'bar',
        });
      } finally {
        stopLoading();
      }
    }
    
    loadCredentialsAsync();
  }, []);

  return {
    credentialDetails: state.credentialDetails,
    loading: state.loading,
  };
}
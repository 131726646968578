import { Divider, Grid, Typography } from '@material-ui/core';
import React, { RefObject, useContext, useRef } from 'react';
import { useProductApprovalPreviewStyles } from '../approvals/b2c2-product-approval-preview.styles';
import { B2C2ApprovalRequest } from '../../model/product';
import { B2C2ApprovalRequestDetails } from './b2c2-product-approval-details';

import { B2C2EditRequestStatus } from "./b2c2-product-edit-request";
import { Helmet } from 'react-helmet';
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../../../state/skip-to-main-store';

export interface B2C2ProductApprovalPreviewProps {
  b2c2ApprovalRequest?: B2C2ApprovalRequest | null;
}

export const B2C2ProductApprovalPreview: React.FC<B2C2ProductApprovalPreviewProps> = ({ b2c2ApprovalRequest }) => {
  const styles = useProductApprovalPreviewStyles();

  const mainHighlightRef: RefObject<HTMLHeadingElement> = useRef(null);
  const context: LandmarkContextType = useContext(LandmarkContext);
  useLandmarkHook(mainHighlightRef, context[1]);
  useHighlightH1(mainHighlightRef);
  return (
    <main>
      <Helmet>
        <title>Request Details : {`${!!b2c2ApprovalRequest?.adminRequestEntity?.name ? b2c2ApprovalRequest?.adminRequestEntity?.name : ""}`}</title>
      </Helmet>
      <Typography className={styles.title} tabIndex={-1} component={"h1"} ref={mainHighlightRef}>Request Details : {b2c2ApprovalRequest?.adminRequestEntity?.name}</Typography>
      <Divider className={styles.dividerB2BApproval} />

      <B2C2ApprovalRequestDetails approvalRequest={b2c2ApprovalRequest} />
      <Divider className={styles.dividerB2BApproval} />
      <>
        <B2C2EditRequestStatus approvalRequest={b2c2ApprovalRequest} />
      </>
    </main>
  );
};

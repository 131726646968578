import React, { RefObject, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOwnedProducts } from '../../hooks/use-owned-products';
import { useTheme } from '@material-ui/core';
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../../../state/skip-to-main-store';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { ColumnDef, SortingState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { DataOptionsType } from '../../../appsNew/views/table-abstraction/filter-implementation/b2c2-product-list-filter';
import { TableCore } from '../../../appsNew/views/table-abstraction/table-core';
import { Product } from '../../model/product';
import { TablePagination } from '../../../appsNew/views/table-abstraction/table-pagination';
import { B2C2ProductListFilter } from '../../../appsNew/views/table-abstraction/filter-implementation/b2c2-product-list-filter';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import Icon from '@visa/vds/icon';
import ButtonIcon from '@visa/vds/button-icon';
export const ProductList: React.FC = () => {
    const { products, ownedProducts, loading } = useOwnedProducts();
    const navigate = useNavigate();
    const theme = useTheme();
    const handleProductClick = useCallback(
        (id: string) => navigate(`/b2c/products/${id}`),
        [navigate]
    );
    const [search, setSearch] = useState<string>("");
    const [sorting, setSorting] = useState<SortingState>([]);
    const [dataOptions, setDataOptions] = useState<DataOptionsType>("my")
    const columns: ColumnDef<Product, any>[] = useMemo(() => ([
        {
            header: 'Product name',
            accessorKey: 'name',
        },
        {
            header: 'Product Id',
            accessorKey: 'productId',
        },
        {
            header: 'Documentation URL',
            accessorKey: 'documentationUrl',
        },
        {
            header: 'Date modified',
            accessorKey: 'lastModifiedAt',
            type: 'datetime',
            cell: (e) => (moment(e.row.original.lastModifiedAt).format('MMM D YYYY'))
        },
        {
            header: 'Status',
            accessorKey: 'statusType',
            type: 'string',
        },
        {
            header: 'Action',
            accessorKey: 'Action',
            type: 'string',
            enableSorting: false,
            cell: ({ row: { original: { productId, name } } }) => (
                    <ButtonIcon ariaLabel={"View Details for service " + name} iconType="light-tiny" onClick={() => handleProductClick(productId)} role="link" >
                        <Icon name="password-show" resolution="tiny" />
                    </ButtonIcon>
                    ),
        }
    ]), [handleProductClick]);
const dataSelection: Product[] = useMemo(() => {
        if (loading) {
            return [];
        }
        if (dataOptions === 'all') {
            return products;
        }
        return ownedProducts;
    }, [products, dataOptions, loading, ownedProducts]);
    const b2c2TableHook = useReactTable({
        columns: columns,
        data: dataSelection,
        debugTable: true,
        manualPagination: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        enableSorting: true,
        state: {
            sorting,
            globalFilter: search
        },
        initialState: {
            pagination: {
                pageSize: 5,
                pageIndex: 0
            },
        },
    });
    return (
        <main>
            <Helmet>
                <title>B2C Products | Visa Prototype Validation Platform</title>
            </Helmet>
            <TypographyHighLight variant='h5' headerTitle={ <div style={{ color: 'black', marginBottom: theme.spacing(4) }}>B2C Products</div> }/>
	     <B2C2ProductListFilter
                b2c2Products={products}
                ownedProducts={ownedProducts}
                changeDataOptions={setDataOptions}
                dataOption={dataOptions}
                table={b2c2TableHook}
                search={search}
                setSearch={setSearch} />
            <TableCore table={b2c2TableHook} caption={"All B2C Products"} />
            <TablePagination table={b2c2TableHook} label='B2C Products Controls' />
        </main>
    );
};
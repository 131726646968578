import {makeStyles} from '@material-ui/core/styles';

export const logoStyles = makeStyles(theme => ({
appLogo: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
},
visaLogo: {
    height: '28px',
        width: 'auto',
        padding: '0px 17px 4px 0px'
},
vpvpLogo: {
    height: '24px',
        width: 'auto'
},
title: {
    flexGrow: 1,
  }
}));

import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { appHeaderStyles } from './app-header.styles';
import { CombinedLogo, LogoColorTheme } from '../logos/CombinedLogo';
import { SkipToMainContent } from '../skipToComponent/skipToMainContent';
import { SkipToLogin } from '../skipToComponent/skipToLogin';

export const EmptyHeader: React.FC = () => {
  const styles = appHeaderStyles();

  return (
    <header className={styles.root}>
      <SkipToMainContent />
      <SkipToLogin />
      <AppBar position="relative" className={styles.appBar}>
        <Toolbar className={styles.emptyBar}>
          <CombinedLogo logoColor={LogoColorTheme.WHITE}></CombinedLogo>
        </Toolbar>
      </AppBar>
    </header>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import vpvpLogoImage from './VPVPLogo.png';
import { logoStyles } from './logo.styles';
import visaLogoImage from './visaVbmBlue.png';
import visaLogoWhite from './logo-white.png';
import { useMediaQuery, useTheme } from '@material-ui/core';

export enum LogoColorTheme {
    WHITE = "WHITE", BLUE = "BLUE"
};
export interface CombinedLogoProps {
    logoColor: LogoColorTheme
}
export const CombinedLogo: React.FC<CombinedLogoProps> = ({ logoColor }) => {
    const visaStyles = logoStyles();
    const theme = useTheme();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('sm'));
  
    switch (logoColor) {
        case LogoColorTheme.WHITE: return (
            <div className={[visaStyles.appLogo, visaStyles.title].join(" ")}>
                <Link rel="noopener" to={'/'}>
                    <img src={visaLogoWhite} alt="Visa_White" className={visaStyles.visaLogo} />
                    {matchesMediaQuery?<img src={vpvpLogoImage} alt="Prototype Validation Platform Logo" className={visaStyles.vpvpLogo} style={{ filter: "brightness(0) invert(1)" }} />:<></>}
                </Link>
            </div>);
        case LogoColorTheme.BLUE: return (
            <div className={visaStyles.appLogo}>
                <Link rel="noopener" to={'/'}>
                    <img src={visaLogoImage} alt="Visa_Blue" className={visaStyles.visaLogo} />
                    {matchesMediaQuery?<img src={vpvpLogoImage} alt="Prototype Validation Platform Logo" className={visaStyles.vpvpLogo} />:<></>}
                </Link>
            </div>)
    }
};

import { useBlockingLoading } from '../../../hooks/use-blocking-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { useDispatch } from '../../../state/app-store';
import { appsActions } from '../../apps/state/apps-actions';
import { networkFileUploadSrv } from '../services/network-file-upload.service';

export interface UseCredentialDeleteResult {
  loading: boolean;
  deleteCredentialAsync: (keyId: string) => Promise<boolean>;
}

export function useCredentialDelete(): UseCredentialDeleteResult {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useBlockingLoading();
  const { pushNotification } = useNotifications();

  const deleteCredentialAsync = async (keyId: string): Promise<boolean> => {
    startLoading();
    try {
      await networkFileUploadSrv.deleteCredential(keyId);
      dispatch(appsActions.deleteCredential(keyId));
      return true;
    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      pushNotification({
        message: `Delete application failed with '${error}'. Please, try again later.`,
        variant: 'error',
        type: 'bar',
      });
      return false;
    } finally {
      stopLoading();
    }
  };

  return {
    loading,
    deleteCredentialAsync,
  };
}

import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { Row } from "../../../../../../products/views/product-edit/product-edit-headers";

export const AddHeaderNameCall: React.FC<Table<Row>> = (table) => {
    const [headerNameError, setHeaderNameError] = useState<string>("");
    return <Input
        style={{ height: "unset" }}
        errorText={headerNameError}
        label="Header Name *"
        name={"header_name"}
        value={table.options.meta?.dirtyRow?.name || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setHeaderNameError(table.options.meta?.isIndividualCellValid("name", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "name");
        }}
        autoFocus
        showErrorText={!!headerNameError}
        invalid={!!headerNameError}
    ></Input>
}
import React, { useMemo } from 'react';
import { ProductHeaderCreate } from "../../model/product-create";
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../../../appsNew/views/table-abstraction/table-core';


export interface ProductPreviewHeadersProps {
  headers?: ProductHeaderCreate[];
}

export const ProductPreviewHeaders: React.FC<ProductPreviewHeadersProps> = ({ headers }) => {

  const columns: ColumnDef<ProductHeaderCreate, any>[] = useMemo(() => [
    {
      header: 'Header Name',
      accessorKey: 'name'
    },
    {
      header: 'Header Type',
      accessorKey: 'dataType'
    },
    {
      header: 'Required',
      accessorKey: 'isRequired',
    },
    {
      header: 'Header Value',
      accessorKey: 'defaultValue'
    },

  ], []);
  const rows = useMemo<ProductHeaderCreate[]>(() => headers ? headers.map(header => ({ ...header })) : [], [headers]);
  const tableDatas = useReactTable({
    columns: columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false
  });
  return (
    <>
      <TableCore caption='Allowed Headers' table={tableDatas}></TableCore>
    </>
  );
};

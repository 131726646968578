import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useB2C2ApprovalRequest } from '../../hooks/use-b2c2-approval-request';
import { B2C2ProductApprovalPreview } from './b2c2-product-approval-preview';

export const B2C2ApprovalRequestView: React.FC = () => {
  const navigate = useNavigate();
  const { requestGuid } = useParams();
  const { b2c2ApprovalRequest } = useB2C2ApprovalRequest(requestGuid);
  useEffect(() => {
    if (b2c2ApprovalRequest === undefined) {
      navigate('/404', { replace: true });
    }
  }, [b2c2ApprovalRequest, navigate]);

  return (
          <B2C2ProductApprovalPreview b2c2ApprovalRequest={b2c2ApprovalRequest} />
  );
};

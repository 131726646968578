import { Button, IconButton, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useCallback } from 'react';

export interface ImportFromJsonDialogProps {
  getJsonContent: () => string;
  filename: string;
  mode?: 'icon' | 'button' | 'textIcon';
}

export const ExportToJsonButton: React.FC<ImportFromJsonDialogProps> = ({ getJsonContent, filename, mode = 'icon' }) => {
  const handleExport = useCallback(() => {
    const jsonData = getJsonContent();
    if (!jsonData) {
      return;
    }
    const blob = new Blob([jsonData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = filename;
    link.href = url;
    link.click();
  }, [filename, getJsonContent]);

  return (
    <div>
      {
        mode === 'icon' ? (
        <Tooltip title="Export to JSON">
          <IconButton id="export1" aria-label="Export to JSON" onClick={handleExport}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
            ) :
            mode === 'button' ? (
          <Button id="export2" variant="outlined" color="primary" onClick={handleExport}>
            Export to JSON
          </Button>
            ) : (
                <Tooltip title="Export to JSON">
                  <IconButton id="export3" aria-label="Export to JSON" onClick={handleExport} color="primary"
                              style={{fontSize: '14px'}}>
                    <GetAppIcon/> Export JSON file
                  </IconButton>
                </Tooltip>
            )}
    </div>
  );
};

import { MultiDataType, MultiType } from '../../../model/multi-type';
import { mapServiceConfiguration } from '../../services/services/model-maps';
import {
  ApplicationCommunication,
  ApplicationCommunicationTemplate,
  ApplicationConfig,
  ApplicationConsumer,
  ApplicationRole,
  ApplicationRoleOperation,
  ApplicationServiceConfig,
  B2C2ApplicationHeaders,
  B2BApplicationOutboundEvent,
  B2BApplicationOwner,
  B2C2Application,
  B2C2ApplicationAllowedEndpoints
} from '../model/application';
import { ApplicationApproval, ApplicationApprovalUpdate, ConsumerRequestUpdate } from '../model/application-approval';
import { ApplicationCommunicationCreate, ApplicationCommunicationTemplateCreate, ApplicationConfigCreate, ApplicationCreate, ApplicationRoleCreate, ApplicationServiceConfigCreate, B2C2ApplicationCommunicationTemplateCreate, B2C2ApplicationConfigCreate, B2C2ApplicationCreate } from '../model/application-create';
import { ApplicationConfigUpdate, ApplicationRoleUpdate, ApplicationServiceConfigUpdate, B2C2ApplicationUpdate, B2BAppReviewRequest, B2C2ApplicationConfigUpdate } from '../model/application-update';
import {
  ApplicationApprovalRequestRQ,
  ApplicationApprovalRequestRS,
  ApplicationCommunicationRQ,
  ApplicationCommunicationRS,
  ApplicationCommunicationTemplateRQ,
  ApplicationCommunicationTemplateRS,
  ApplicationConfigRQ,
  ApplicationConfigRS,
  ApplicationConsumerRS,
  ApplicationDetailsRQ,
  ApplicationRoleOperationRS,
  ApplicationRoleRQ,
  ApplicationRoleRS,
  ApplicationRQ,
  ApplicationRS,
  ApplicationServiceConfigRQ,
  ApplicationServiceConfigRS,
  B2C2ApplicationAllowedEndpointRS, B2BApplicationHeaderRQ,
  B2BApplicationHeadersRS,
  B2BApplicationOutboundEventRQ,
  B2BApplicationOutboundEventRS,
  B2BApplicationOwnerRS,
  B2BApplicationRQ,
  B2BApplicationRS,
  B2BApplicationUpdateRQ,
  B2BAppReviewRequestRS,
  B2C2ApplicationHeadersRS,
  B2C2ApplicationRQ,
  B2C2ApplicationRS,
  B2C2ApplicationUpdateRQ,
  ConsumerRequestRQ
} from './dtos';

/*
  Map Application Create/Update methods
*/

export function mapApplicationCreate(model: ApplicationCreate): ApplicationRQ {
  const dto: ApplicationRQ = {
    name: model.name,
    description: model.description,
    app_type: model.type,
    app_owners: model.owners,
    app_roles: model.roles.map(mapApplicationRoleCreate),
    app_configs: model.appConfigs ? model.appConfigs.map(mapApplicationConfigCreate) : [],
    app_service_configs: model.serviceConfigs ? model.serviceConfigs.map(mapApplicationServiceConfigCreate) : [],
    app_communication: mapApplicationCommunicationCreate(model.communication),
  };
  return dto;
}

export function mapApplicationRoleCreate(model: ApplicationRoleCreate): ApplicationRoleRQ {
  const dto: ApplicationRoleRQ = {
    name: model.name,
    operations: model.operations,
    approved_email_ids: model.approvedEmailIds,
  };
  return dto;
}

export function mapApplicationRoleUpdate(model: Partial<ApplicationRoleUpdate>): Partial<ApplicationRoleRQ> {
  const dto: Partial<ApplicationRoleRQ> = {
    name: model.name,
    operations: model.operations,
    approved_email_ids: model.approvedEmailIds,
  };
  return dto;
}

export function mapApplicationServiceConfigCreate(model: ApplicationServiceConfigCreate): ApplicationServiceConfigRQ {
  const dto: ApplicationServiceConfigRQ = {
    service_config_id: model.serviceConfigId,
    value: model.value
  };
  return dto;
}

export function mapApplicationServiceConfigUpdate(model: Partial<ApplicationServiceConfigUpdate>): Partial<ApplicationServiceConfigRQ> {
  const dto: Partial<ApplicationServiceConfigRQ> = {
    service_config_id: model.serviceConfigId,
    value: model.value
  };
  return dto;
}

export function mapApplicationConfigCreate(model: B2C2ApplicationConfigCreate): ApplicationConfigRQ {
  const dto: ApplicationConfigRQ = {
    configKey: model.configKey,
    configValue: model.configValue,
  };
  return dto;
}

export function mapApplicationConfigUpdate(model: Partial<ApplicationConfigUpdate>): Partial<ApplicationConfigRQ> {
  const dto: Partial<ApplicationConfigRQ> = {
    configKey: model.key,
    configValue: model.value,
  };
  return dto;
}

export function mapApplicationCommunicationTemplateCreate(model: ApplicationCommunicationTemplateCreate): ApplicationCommunicationTemplateRQ {
  const dto: ApplicationCommunicationTemplateRQ = {
    comm_type: model.type,
    expiry_minutes: model.expiryMinutes,
    content: model.content,
  };
  return dto;
}

export function mapApplicationCommunicationCreate(model?: ApplicationCommunicationCreate): ApplicationCommunicationRQ | undefined {
  const dto: ApplicationCommunicationRQ | undefined = !model ? undefined : {
    from_email_address: model.fromEmailAddress,
    from_display_name: model.fromDisplayName,
    templates: {
      account_activation: mapApplicationCommunicationTemplateCreate(model.templates.accountActivation),
      account_activation_success: mapApplicationCommunicationTemplateCreate(model.templates.accountActivationSuccess),
      password_expiry: mapApplicationCommunicationTemplateCreate(model.templates.passwordExpiry),
      reset_password: mapApplicationCommunicationTemplateCreate(model.templates.resetPassword),
      account_deactivation: mapApplicationCommunicationTemplateCreate(model.templates.accountDeactivation),
      forgot_username: mapApplicationCommunicationTemplateCreate(model.templates.forgotUsername),
      change_password: mapApplicationCommunicationTemplateCreate(model.templates.changePassword),
    },
  };
  return dto;
}

/*
  Map ApplicationRQ methods
*/

export function mapApplicationRQ(dto: ApplicationRQ): ApplicationCreate {
  const model: ApplicationCreate = {
    name: dto.name,
    description: dto.description,
    type: dto.app_type,
    owners: dto.app_owners,
    roles: dto.app_roles ? dto.app_roles.map(mapApplicationRoleRQ) : [],
    serviceConfigs: dto.app_service_configs ? dto.app_service_configs.map(mapApplicationServiceConfigRQ) : [],
    appConfigs: dto.app_configs ? dto.app_configs.map(mapApplicationConfigRQ) : [],
    communication: mapApplicationCommunicationRQ(dto.app_communication),
  };
  return model;
}

export function mapApplicationRoleRQ(dto: ApplicationRoleRQ): ApplicationRoleCreate {
  const model: ApplicationRoleCreate = {
    name: dto.name,
    operations: dto.operations,
    consumerUsers: [], // TODO: parse consumers
    approvedEmailIds: dto.approved_email_ids,
  };
  return model;
}

export function mapApplicationConfigRQ(dto: ApplicationConfigRQ): B2C2ApplicationConfigCreate {
  const model: B2C2ApplicationConfigCreate = {
    configKey: dto.configKey,
    dataType: getDataType(dto.configValue),
    configValue: dto.configValue,
  };
  return model;
}

export function mapApplicationServiceConfigRQ(dto: ApplicationServiceConfigRQ): ApplicationServiceConfigCreate {
  const model: ApplicationServiceConfigCreate = {
    value: dto.value,
    serviceConfigId: Number(dto.service_config_id),
  };
  return model;
}

export function mapApplicationCommunicationTemplateRQ(dto: ApplicationCommunicationTemplateRQ): ApplicationCommunicationTemplateCreate {
  const model: ApplicationCommunicationTemplateCreate = {
    type: dto.comm_type,
    expiryMinutes: dto.expiry_minutes,
    content: dto.content,
  };
  return model;
}

export function mapApplicationCommunicationRQ(dto?: ApplicationCommunicationRQ): ApplicationCommunicationCreate | undefined {
  if (!dto)
    return undefined;

  const model: ApplicationCommunicationCreate = {
    fromEmailAddress: dto.from_email_address,
    fromDisplayName: dto.from_display_name,
    templates: {
      accountActivation: mapApplicationCommunicationTemplateRQ(dto.templates.account_activation),
      accountActivationSuccess: mapApplicationCommunicationTemplateRQ(dto.templates.account_activation_success),
      passwordExpiry: mapApplicationCommunicationTemplateRQ(dto.templates.password_expiry),
      resetPassword: mapApplicationCommunicationTemplateRQ(dto.templates.reset_password),
      accountDeactivation: mapApplicationCommunicationTemplateRQ(dto.templates.account_deactivation),
      forgotUsername: mapApplicationCommunicationTemplateRQ(dto.templates.forgot_username),
      changePassword: mapApplicationCommunicationTemplateRQ(dto.templates.change_password),
    },
  };
  return model;
}

/*
  Map Application methods
*/


export function mapApplicationConfig(dto: ApplicationConfigRS): ApplicationConfig {
  const model: ApplicationConfig = {
    id: dto.id,
    key: dto.configKey,
    dataType: getDataType(dto.configValue),
    value: dto.configValue,
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    modifiedAt: dto.modified_at,
  };
  return model;
}

function getDataType(value: MultiType): MultiDataType {
  if (typeof value === 'string') {
    return 'string';
  } else if (typeof value === 'number') {
    return 'number';
  } else if (typeof value === 'boolean') {
    return 'boolean';
  } else if (Array.isArray(value)) {
    const isNumArray = (value as any[]).every((item: string | number) => typeof item === 'number');
    if (isNumArray) {
      return 'number[]';
    } else {
      return 'string[]';
    }
  } else {
    return 'string';
  }
}

export function mapApplicationServiceConfig(dto: ApplicationServiceConfigRS): ApplicationServiceConfig {
  const model: ApplicationServiceConfig = {
    id: dto.id,
    value: dto.value,
    serviceConfig: mapServiceConfiguration(dto.service_config),
    applicationId: dto.application_id,
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    modifiedAt: dto.modified_at,
  };
  return model;
}

export function mapApplicationCommunicationTemplate(dto: ApplicationCommunicationTemplateRS): ApplicationCommunicationTemplate {
  const model: ApplicationCommunicationTemplate = {
    id: dto.id,
    type: dto.comm_type,
    expiryMinutes: dto.expiry_minutes,
    content: dto.content,
    appCommId: dto.app_comm_id,
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    modifiedAt: dto.modified_at,
  };
  return model;
}

export function mapApplicationCommunication(dto?: ApplicationCommunicationRS): ApplicationCommunication | undefined {
  if (!dto)
    return undefined;

  const model: ApplicationCommunication = {
    fromEmailAddress: dto.from_email_address,
    fromDisplayName: dto.from_display_name,
    templates: {
      accountActivation: mapApplicationCommunicationTemplate(dto.templates.account_activation),
      accountActivationSuccess: mapApplicationCommunicationTemplate(dto.templates.account_activation_success),
      passwordExpiry: mapApplicationCommunicationTemplate(dto.templates.password_expiry),
      resetPassword: mapApplicationCommunicationTemplate(dto.templates.reset_password),
      accountDeactivation: mapApplicationCommunicationTemplate(dto.templates.account_deactivation),
      forgotUsername: mapApplicationCommunicationTemplate(dto.templates.forgot_username),
      changePassword: mapApplicationCommunicationTemplate(dto.templates.change_password),
    },
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    modifiedAt: dto.modified_at,
  };
  return model;
}


export function mapApplicationApprovalUpdate(model: ConsumerRequestUpdate): ConsumerRequestRQ {
  const dto: ConsumerRequestRQ = {
    status: model.status,
  };
  return dto;
}

/*
  Map B2C 2.0 Application methods
*/

export function mapApplicationConsumerApproval(model: ConsumerRequestUpdate): ConsumerRequestRQ {
  const dto: ConsumerRequestRQ = {
    status: model.status,
  };
  return dto;
}

export function mapB2C2Application(dto: B2C2ApplicationRS): B2C2Application {
   const model: B2C2Application = {
   name: dto.name,
   description: dto.description,
   namespace: dto.namespace,
   applicationGuid: dto.appId,
   status: dto.status,
   supportGdl: dto.supportGdl,
   appProductHeaders: dto.appProductHeaders ? dto.appProductHeaders.map(mapB2BApplicationAllowedHeaders) : [],
   owners: dto.owners,
   createdBy: dto.createdBy,
   createdAt: dto.createdAt,
   lastModifiedBy: dto.lastModifiedBy,
   lastModifiedAt: dto.lastModifiedAt,
   requestGuid:dto.requestId,
   whitelistedIdentities: dto.whitelistedIdentities,
   fromId: dto.fromId,
   fromDisplayName: dto.fromDisplayName,
   configs: dto.configs ? dto.configs.map(mapB2C2B2C2ApplicationConfigUpdate) : [],
   templates: dto.templates ? dto.templates.map(mapB2C2ApplicationCommunicationTemplateCreate) : [],
   allowedEndpoints: dto.allowedEndpoints,
   applicationEndpointsnMap: dto.allowedEndpoints ? dto.allowedEndpoints.map(mapB2C2ApplicationAllowedEndpoint) : [],
   consumerRequests: dto.consumerRequests || [],
   b2c2bArgs: dto.b2c2bArgs,
    };
   return model;
  }
  
  export function mapB2C2B2C2ApplicationConfigUpdate(dto: B2C2ApplicationConfigUpdate,index:number): B2C2ApplicationConfigUpdate {
    const model: B2C2ApplicationConfigUpdate = {
      id: index,
      configValue: dto.configValue,
      configKey: dto.configKey,
    };
   return model;
  }

  export function mapB2C2ApplicationCommunicationTemplateCreate(dto: B2C2ApplicationCommunicationTemplateCreate,index:number): B2C2ApplicationCommunicationTemplateCreate {
    const model: B2C2ApplicationCommunicationTemplateCreate = {
   id:index,
   templateType: dto.templateType,
   commType: dto.commType,
   expiryMinutes: dto.expiryMinutes,
   content: dto.content,
   };
   return model;
  }


export function mapB2BApplicationAllowedHeaders(dto: B2C2ApplicationHeadersRS): B2C2ApplicationHeaders {
  const model: B2C2ApplicationHeaders = {
    headerId: dto.headerId,
    headerValue: dto.headerValue,
    appHeaderId: dto.appHeaderId
  };
  return model;
}

export function mapB2BApplicationOutboundEventsRS(dto: B2BApplicationOutboundEventRS): B2BApplicationOutboundEvent {
  const model: B2BApplicationOutboundEvent = {
    eventGuid: dto.event_guid,
    applicationEventGuid: dto.application_event_guid,
    applicationGuid: dto.application_guid,
    eventType: dto.event_type,
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
  };
  return model;
}

export function mapB2C2ApplicationAllowedEndpoint(dto: B2C2ApplicationAllowedEndpointRS): B2C2ApplicationAllowedEndpoints {
  const model: B2C2ApplicationAllowedEndpoints = {
    appEndpointId: dto.appEndpointId,
    externalPath: dto.externalPath,
    internalPath: dto.internalPath,
    methodType: dto.methodType,
    created_by: dto.created_by,
    last_modified_by: dto.last_modified_by,
    created_at: dto.created_at,
    last_modified_at: dto.last_modified_at,
    appId: dto.appId,
    productId: dto.productId,
    endpointName: dto.endpointName,
    endpointId: dto.endpointId,
    status: dto.status
  };
  return model;
}

export function mapB2BApplicationOwner(dto: B2BApplicationOwnerRS): B2BApplicationOwner {
  const model: B2BApplicationOwner = {
    applicationOwnerGuid: dto.application_owner_guid,
    subjectName: dto.subject_name,
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
  };
  return model;
}

export function mapB2C2ApplicationCreate(model: B2C2ApplicationCreate): B2C2ApplicationRQ {
  const dto: B2C2ApplicationRQ = {
    name: model.name,
    description: model.description,
    namespace: model.namespace,
    supportGdl: model.supportGdl,
    allowedEndpoints: model.allowedEndpoints,
    appProductHeaders: model.appProductHeaders,
    owners: model.owners,
    configs: model.configs,
    templates: model.templates,
    fromId: model.fromId,
    fromDisplayName: model.fromDisplayName,
    whitelistedIdentities: model.whitelistedIdentities,
    b2c2bArgs: model.b2c2bArgs,
  };
  return dto;
}

export function mapB2BApplicationHeaders(model: B2C2ApplicationHeaders): B2BApplicationHeaderRQ {
  const dto: B2BApplicationHeaderRQ = {
    service_header_guid: model.headerId,
    header_value: model.headerValue,
    appHeaderId: model.appHeaderId
  };
  return dto;
}

export function mapB2BApplicationHeaderRQ(model: B2BApplicationHeaderRQ): B2C2ApplicationHeaders {
  const dto: B2C2ApplicationHeaders = {
    headerId: model.service_header_guid,
    headerValue: model.header_value,
    appHeaderId: model.appHeaderId
  };
  return dto;
}

export function mapB2C2ApplicationUpdate(model: B2C2ApplicationUpdate): B2C2ApplicationUpdateRQ {
  const dto: B2C2ApplicationUpdateRQ = {
    name: model.name,
    description: model.description,
    allowedEndpoints: model.allowedEndpoints,
    supportGdl: model.supportGdl,
    owners: model.owners,
    appProductHeaders: model.appProductHeaders,
    configs: model.configs,
    templates: model.templates,
    b2c2bArgs: model.b2c2bArgs,
    whitelistedIdentities: model.whitelistedIdentities
  };
  return dto;
}


/*
  Map B2B ApplicationRQ methods
*/

export function mapB2C2ApplicationRQ(dto: B2C2ApplicationRQ): B2C2ApplicationCreate {
  const model: B2C2ApplicationCreate = {
    name: dto.name,
    description: dto.description ? dto.description : '',
    supportGdl: dto.supportGdl,
    allowedEndpoints: dto.allowedEndpoints,
    owners: dto.owners ? dto.owners : [],
    fromId: dto.fromId,
    fromDisplayName: dto.fromDisplayName,
    configs: dto.configs,
    whitelistedIdentities: dto.whitelistedIdentities,
    templates: dto.templates,
    namespace: dto.namespace,
    b2c2bArgs: dto.b2c2bArgs
  }
  return model;
}

export function mapB2BAppReviewRequestUpdate(model: B2BAppReviewRequest): B2BAppReviewRequestRS {
  const dto: B2BAppReviewRequestRS = {
    sa_number: model.saNumber,
    business_justification: model.businessJustification,
    request_guid: model.requestGuid,
    request_status: ''
  };
  return dto;
}

export function mapB2BReviewResponse(dto :B2BAppReviewRequestRS):B2BAppReviewRequest {
  const model: B2BAppReviewRequest = {
    saNumber: dto.sa_number,
    businessJustification:dto.business_justification,
    requestGuid: dto.request_guid,
  };
  return model;
}
import React, { useMemo } from 'react';
import { B2C2ApplicationConfigCreate } from '../../model/application-create';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../table-abstraction/table-core';

export interface B2C2AppPreviewAppConfigProps {
    appConfigs?: B2C2ApplicationConfigCreate[];
}


export const B2C2AppPreviewAppConfigs: React.FC<B2C2AppPreviewAppConfigProps> = ({ appConfigs }) => {

    const columns: ColumnDef<B2C2ApplicationConfigCreate, any>[] = useMemo(() => [
        {
            header: 'Config Key',
            accessorKey: 'configKey'
        },
        {
            header: 'Config Value',
            accessorKey: 'configValue'
        }
    ], []);
    const rows = useMemo<B2C2ApplicationConfigCreate[]>(() => appConfigs ? appConfigs.map(appConfig => ({ ...appConfig })) : [], [appConfigs]);
    const tableDatas = useReactTable({
        columns: columns,
        data: rows,
        getCoreRowModel: getCoreRowModel(),
        enableSorting: false
    });

    return (<>
        <TableCore caption='App Configs' table={tableDatas}></TableCore>
    </>
    );
};
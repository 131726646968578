import React, { useMemo } from 'react';
import { ProductHostCreate } from '../../model/product-create';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../../../appsNew/views/table-abstraction/table-core';

export interface ProductPreviewHostsProps {
    hosts?: ProductHostCreate[];
}

export const ProductPreviewHosts: React.FC<ProductPreviewHostsProps> = ({ hosts }) => {

    const columns: ColumnDef<ProductHostCreate, any>[] = useMemo(() => [
        {
            header: 'Data center',
            accessorKey: 'dataCenter'
        },
        {
            header: 'URL location',
            accessorKey: 'url'
        }

    ], []);
    const rows = useMemo<ProductHostCreate[]>(() => hosts ? hosts.map(host => ({ ...host })) : [], [hosts]);
    const tableDatas = useReactTable({
        columns: columns,
        data: rows,
        getCoreRowModel: getCoreRowModel(),
        enableSorting: false
    });
    return (
        <>
            <TableCore caption='Host URL' table={tableDatas}></TableCore>
        </>
    );
};

import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const buttonStyles = makeStyles((theme:Theme) => ({
    btnBlue: {
        borderRadius: 0,
        padding: theme.spacing(2, 3, 2, 3),
        borderWidth: 2,
        color: '#fff',
        backgroundColor: '#003EA9',
        width: '175px',
        height: 'auto',
        textTransform: 'none',
        fontSize: '20px',
        '&:hover': {
            color: '#003EA9',
            borderColor: '#003EA9'
        },
        '&:disabled': {
            backgroundColor: 'grey',
            color: 'white'
        }
    },
    btnBlue2x: {
        borderRadius: 0,
        padding: theme.spacing(2, 1, 2, 1),
        borderWidth: 2,
        color: '#fff',
        backgroundColor: '#003EA9',
        width: '190px',
        height: 'auto',
        textTransform: 'none',
        fontSize: '20px',
        marginTop: "50px",
        '&:hover': {
            color: '#003EA9',
            borderColor: '#003EA9'
        },
        '&:disabled': {
            backgroundColor: 'grey',
            color: 'white'
        }
    },
    btnBlue3x: {
        borderRadius: 0,
        padding: theme.spacing(2, 1, 2, 1),
        borderWidth: 2,
        color: '#fff',
        backgroundColor: '#003EA9',
        width: '210px',
        height: 'auto',
        textTransform: 'none',
        fontSize: '20px',
        '&:hover': {
            color: '#003EA9',
            borderColor: '#003EA9'
        },
        '&:disabled': {
            backgroundColor: 'grey',
            color: 'white'
        }
    },
    btnWhite: {
        borderRadius: 0,
        padding: theme.spacing(2, 3, 2, 3),
        borderWidth: 2,
        width: '175px',
        height: 'auto',
        backgroundColor: '#fff',
        color: '#003EA9',
        borderColor: '#003EA9',
        textTransform: 'none',
        fontSize: '20px',
        '&:disabled': {
            backgroundColor: 'grey',
            color: 'white'
        }
    },
    btnBlueWithRightMargin: {
        borderRadius: 0,
        padding: theme.spacing(2, 3, 2, 3),
        borderWidth: 2,
        color: '#fff',
        backgroundColor: '#003EA9',
        width: '175px',
        height: 'auto',
        textTransform: 'none',
        fontSize: '20px',
        marginRight:'10px',
        '&:hover': {
            color: '#003EA9',
            borderColor: '#003EA9'
        },
        '&:disabled': {
            backgroundColor: 'grey',
            color: 'white'
        }
    },
}));
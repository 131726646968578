import { Grid, Typography } from "@mui/material";
import { Table } from "@tanstack/react-table";
import React from "react";
import ButtonText from '@visa/vds/button-text';
import { SearchBar } from "../common-component/search-bar";
export interface IHeadingSearchFilter {
    heading: string;
    search: string;
    setSearch: Function;
    addButtonFunction: Function;
    table: Table<any>
}

export const HeaderSearchAddButton: React.FC<IHeadingSearchFilter> = ({ heading, addButtonFunction, search, setSearch, table }) => {
    const isvp2enabled=table.options.meta?.data?.isUsingVp2 || false;
    return <Grid sx={(theme) => ({
        padding: theme.spacing(2),
        backgroundColor: '#F2F4F8',
        borderRadius: theme.spacing(0.5, 0.5, 0, 0)
    })}
        container
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        rowGap={2}
    >
        <Grid item xs={12} lg={6}>
            <Typography variant="h2" style={{ fontSize: '20px' }}>{heading}</Typography>
        </Grid>
        {!isvp2enabled?
        <Grid item xs={12} lg={6}>
            <Grid container direction="row" alignItems={"center"} columnGap={2} rowGap={2}>
                <Grid item xs={12} lg={9}>
                    <SearchBar label={`Search Box for ${heading}`} search={search} setSearch={setSearch}/>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <ButtonText
                        type="submit"
                        variant="outlined"
                        onClick={() => addButtonFunction()}
                        disabled={!!table?.options.meta?.currentEditingRowId.length}
                        isFullWidth
                    >
                        Add Row
                    </ButtonText>
                </Grid>
            </Grid>
        </Grid>
        :<></>
    }
    </Grid>
}
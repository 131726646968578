import React, { RefObject, useContext, useRef } from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { addNewStyles } from './add-new.styles';
import createNewImage from './CardCreateNew.png';
import uploadJsonImage from './CardUploadJson.png';
import RemoveIcon from '@material-ui/icons/Remove';
import { ImportFromJsonButton } from '../../components/buttons/import-from-json-button'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Theme, useTheme } from '@mui/material';
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../state/skip-to-main-store';

export interface AddNewProps {
    title: string;
    isApp: boolean;
    wizardNavLink: string;
    jsonType: string;
}

export const AddNew: React.FC<AddNewProps> = ({ title, isApp, wizardNavLink, jsonType, ...rest }) => {
    const styles = addNewStyles();
    const navigate = useNavigate();
    const theme: Theme = useTheme();
    const mediaQueryCheck: boolean = useMediaQuery(theme.breakpoints.down("xs"));
    const ref: RefObject<HTMLHeadingElement> = useRef<HTMLHeadingElement>(null);
    const context: LandmarkContextType = useContext(LandmarkContext);
    useLandmarkHook(ref, context[1]);
    useHighlightH1(ref);
    return (
        <main className={styles.root}>
            <Helmet>
                <title>{title} | Visa Prototype Validation Platform</title>
            </Helmet>
            <Typography variant="h5" component={"h1"} color="primary" gutterBottom ref={ref} tabIndex={-1}>
                {title}
            </Typography>
            <Grid component="div" container className={styles.cardContainer} direction={mediaQueryCheck ? "column" : "row"} justifyContent='space-evenly'>
                <Grid component="div" item  xs={12} sm={4} md={3} lg={3}>
                    <Card className={styles.cardRoot} tabIndex={-1}>
                            <CardMedia
                                aria-hidden
                                className={styles.media}
                                image={createNewImage}
                                title="Create New Image"
                            />
                            <CardContent>
                                <Typography gutterBottom color="primary" variant="h5" component="h2" >
                                    Onboard Wizard
                                </Typography>
                                <RemoveIcon fontSize="large" />
                                <Typography variant="body2" component="div">
                                   <p> Follow our guided process to create {isApp ? 'an app' : 'a service'} and utilize our tooltips to learn more.</p>
                                </Typography>
                            </CardContent>
                        <CardActions className={styles.content}>
                            <Button
                                role="link"
                                type="submit"
                                id="add-new-launch"
                                variant="outlined"
                                className={styles.cardBtn}
                                onClick={() => navigate(wizardNavLink)}>
                                Launch Wizard
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid component="div" item xs={12} sm={4} md={3} lg={3}>
                    <Card className={styles.cardRoot}>
                            <CardMedia
                                aria-hidden
                                className={styles.media}
                                image={uploadJsonImage}
                                title="Upload Json Image"
                            />
                            <CardContent>
                                <Typography gutterBottom color="primary" variant="h5" component="h2">
                                    Upload JSON File
                                </Typography>
                                <RemoveIcon fontSize="large" />
                                <Typography variant="body2" component="div">
                                    <p>Upload your JSON file embedded with the appropriate information to create your {isApp ? 'app' : 'service'}.</p>
                                </Typography>
                            </CardContent>

                        <CardActions className={styles.content}>
                            <ImportFromJsonButton
                                mode='button'
                                buttonStyle={styles.cardBtn}
                                importType={jsonType}
                                wizardLink={wizardNavLink} />
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </main>
    );
};
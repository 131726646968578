import { useBlockingLoading } from '../../../hooks/use-blocking-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { networkAppsNewSrv } from '../services/network-apps.service';
import { appsActions } from '../state/apps-actions';
import { useDispatch } from '../../../state/app-store';

export interface UseB2C2ApplicationDeleteResult {
  loading: boolean;
  deleteB2C2ApplicationAsync: (applicationGuid: string) => Promise<boolean>;
}

export function useB2C2ApplicationDelete(): UseB2C2ApplicationDeleteResult {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useBlockingLoading();
  const { pushNotification } = useNotifications();

  const deleteB2C2ApplicationAsync = async (applicationGuid: string): Promise<boolean> => {
    startLoading();
    try {
      await networkAppsNewSrv.deleteB2C2ApplicationAsync(applicationGuid);
      dispatch(appsActions.deleteB2C2Application(applicationGuid));
      return true;
    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      pushNotification({
        message: `Delete application failed with '${error}'. Please, try again later.`,
        variant: 'error',
        type: 'bar',
      });
      return false;
    } finally {
      stopLoading();
    }
  };

  return {
    loading,
    deleteB2C2ApplicationAsync,
  };
}

import { logger } from './logger.service';
import { getGlobalConfig } from './global-config.service';
import { getUserManager } from '../lib/oidc-client-react';

interface ResponseError {
  code: string;
  status: string;
  message: string;
  errors: string[];
  service_name: string;
  errorResponse: string;
}

function getAsync<RS>(url: string): Promise<RS> {
  return sendAsync(url, 'GET');
}

function getFileAsync<RQ, RS>(url: string): Promise<RS> {
  return fetchFileAsync(url, 'GET');
}

function postAsync<RQ, RS>(url: string, body?: RQ): Promise<RS> {
  return sendAsync(url, 'POST', body);
}

function postFileAsync<RQ, RS>(url: string, file: File): Promise<RS> {
  return sendFileAsync(url, 'POST', file);
}

function putAsync<RQ, RS>(url: string, body?: RQ): Promise<RS> {
  return sendAsync(url, 'PUT', body);
}

function patchAsync<RQ, RS>(url: string, body?: RQ): Promise<RS> {
  return sendAsync(url, 'PATCH', body);
}

function deleteAsync(url: string): Promise<void> {
  return sendAsync(url, 'DELETE');
}

async function sendAsync<RQ, RS>(url: string, method: string, body?: RQ): Promise<RS> {
  const headers: HeadersInit = {
    'Accept': 'application/json',
  };
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase())) {
    if (!!body) {
      headers['Content-Type'] = 'application/json';
    }
    
    const config = getGlobalConfig();
    if (!!config && !!config.csrf) {
      headers['csrf-token'] = config.csrf;
    }
  }

  try {
    const userManager = getUserManager();
    let user = await userManager.getUser();
    if (user.expired) {
      user = await userManager.signinSilent();
    }
    if (user.access_token && !user.expired) {
      headers['x-access-token'] = user.access_token;
    }
  } catch (ex) { 
    logger.error(`[network.service] SendAsync ERROR. Url: ${url}`, ex);
  }

  const request: RequestInit = {
    method,
    headers,
    credentials: 'same-origin',
    body: body ? JSON.stringify(body) : undefined,
  };
  const response = await fetch(url, request);


  let jsonData: any = null;
  try {
    jsonData = await response.json();
  } catch { }

  if (!response.ok) {
    if (jsonData === null && !response.headers.has("x-correlation-id")) {
      logger.error('Admin Service is down', { url, method, responseStatus: response.status });
      throw Error('Connection Timeout. Admin Service is down');
    }
    const errorData = jsonData ? jsonData as ResponseError : null;
    const xCorId = response.headers.has("x-correlation-id") ? ', [x-correlation-id: ' + response.headers.get("x-correlation-id") + ']' : ', [x-correlation-id: ]';
    logger.error('Server response error', { url, method, responseStatus: response.status, errorData });
    throw Error(errorData && errorData.message ? errorData.message + xCorId : response.statusText + xCorId);
  }

  const data = jsonData ? jsonData as RS : null;
  if (!data) {
    return {} as RS;
  }
  return data;
}

async function fetchFileAsync<RQ, RS>(url: string, method: string): Promise<RS> {
  const headers: HeadersInit = {};
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase())) {
    
  const config = getGlobalConfig();
    if (!!config && !!config.csrf) {
      headers['CSRF-Token'] = config.csrf;
    }
  }

  try {
    const userManager = getUserManager();
    let user = await userManager.getUser();
    if (user.expired) {
      user = await userManager.signinSilent();
    }
    if (user.access_token && !user.expired) {
      headers['x-access-token'] = user.access_token;
    }
  } catch (ex) { 
    logger.error(`[network.service] SendAsync ERROR. Url: ${url}`, ex);
  }

  const request: RequestInit = {
    method,
    headers,
    credentials: 'same-origin',
  };
  const response = await fetch(url, request);

  let textData: any = null;
  try {
    textData = await response.text();
  } catch { }

  if (!response.ok) {
    if (textData === null && !response.headers.has("x-correlation-id")) {
      logger.error('Admin Service is down', { url, method, responseStatus: response.status });
      throw Error('Connection Timeout. Admin Service is down');
    }
    const errorData = textData ? textData as ResponseError : null;
    const xCorId = response.headers.has("x-correlation-id") ? ', [x-correlation-id: ' + response.headers.get("x-correlation-id") + ']' : ', [x-correlation-id: ]';
    logger.error('Server response error', { url, method, responseStatus: response.status, errorData });
    throw Error(errorData && errorData.message ? errorData.message + xCorId : response.statusText + xCorId);
  }

  const data = textData ? textData as RS : null;
  if (!data) {
    return {} as RS;
  }
  return data;
}

async function sendFileAsync<RQ, RS>(url: string, method: string, file: File): Promise<RS> {
  const headers: HeadersInit = {};
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase())) {

  const config = getGlobalConfig();
    if (!!config && !!config.csrf) {
      headers['CSRF-Token'] = config.csrf;
    }
  }

  try {
    const userManager = getUserManager();
    let user = await userManager.getUser();
    if (user.expired) {
      user = await userManager.signinSilent();
    }
    if (user.access_token && !user.expired) {
      headers['x-access-token'] = user.access_token;
    }
  } catch (ex) { 
    logger.error(`[network.service] SendAsync ERROR. Url: ${url}`, ex);
  }

  const formData = new FormData();
  formData.append("file", file);

  const request: RequestInit = {
    method,
    headers,
    credentials: 'same-origin',
    body: formData,
  };
  const response = await fetch(url, request);


  let jsonData: any = null;
  try {
    jsonData = await response.json();
  } catch { }

  if (!response.ok) {
    if (response.status === 429) {
      logger.error('Unable to upload cert/key as maximum limit of upload by user reached', { url, method, responseStatus: response.status });
      const error = new Error('Too Many request. Unable to upload cert/key as maximum limit of upload by user reached');
      error.message = 'Too Many request. Unable to upload cert/key as maximum limit of upload by user reached';
      throw error;
    }
    if (jsonData === null && !response.headers.has("x-correlation-id")) {
      logger.error('Admin Service is down', { url, method, responseStatus: response.status });
      throw Error('Connection Timeout. Admin Service is down');
    }
    const errorData = jsonData ? jsonData as ResponseError : null;
    const xCorId = response.headers.has("x-correlation-id") ? ', [x-correlation-id: ' + response.headers.get("x-correlation-id") + ']' : ', [x-correlation-id: ]';
    logger.error('Server response error', { url, method, responseStatus: response.status, errorData });
    throw Error(errorData && errorData.message ? errorData.message + xCorId : response.statusText + xCorId);
  }

  const data = jsonData ? jsonData as RS : null;
  if (!data) {
    return {} as RS;
  }
  return data;
}

export const networkSrv = {
  getAsync,
  postAsync,
  getFileAsync,
  postFileAsync,
  putAsync,
  patchAsync,
  deleteAsync,
};

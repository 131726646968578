import { Reducer } from 'react';
import { Action } from '../../../state/actions';
import { usersTypes } from './users-actions';
import { User } from '../model/user';

export interface UsersState {
  users: User[] | null;
  loading: boolean;
  error: string | null;
}

export const initialUserState: UsersState = {
  users: null,
  error: null,
  loading: false,
}

export const usersReducer: Reducer<UsersState, Action> = (state: UsersState, action: Action): UsersState => {
  switch (action.type) {
    case usersTypes.loadUsers: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case usersTypes.loadUsersSuccess: {
      const users = action.payload as User[];
      return {
        ...state,
        loading: false,
        users,
      };
    }
    case usersTypes.loadUsersError: {
      const error = action.payload as string;
      return {
        ...state,
        loading: false,
        error,
      };
    }

    default:
      return state;
  }
}

import { useEffect } from 'react';
import { useLoading } from '../../../hooks/use-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { Product } from '../model/product';
import { networkServicesProducts } from '../services/network-services.products';
import { productActions } from '../state/product-actions';
import { useProductsStore } from '../state/product-selectors';

export interface UseProductsResult {
  products: Product[];
  loading: boolean;
}

export function useProducts(): UseProductsResult {
  const [state, dispatch] = useProductsStore();
  const { startLoading, stopLoading } = useLoading();
  const { pushNotification } = useNotifications();

  useEffect(() => {
    const loadProductsAsync = async () => {
      startLoading();
      dispatch(productActions.loadProducts());
      try {
        const products = await networkServicesProducts.getProductsAsync();
        const pendingProducts = await networkServicesProducts.getPendingProductsAsync();

        const allProducts =[...products, ...pendingProducts];

        dispatch(productActions.loadProductsSuccess(allProducts));
      } catch (e:any) {
        const error = e.message ? e.message as string : 'Response error';
        dispatch(productActions.loadProductsError(error));
        pushNotification({
          message: `Load Products failed with '${error}'`,
          variant: 'error',
          type: 'bar',
        });
      } finally {
        stopLoading();
      }
    }
    if (!state.loading && !state.error && state.products.length === 0) {
      loadProductsAsync();
    }
  }, [state.products, state.loading, state.error, dispatch, pushNotification, startLoading, stopLoading]);

  return {
    products: state.products,
    loading: state.loading,
  };
}
import { CellContext } from "@tanstack/react-table"
import React, { ChangeEvent } from "react"
import { Row } from "../../../../../../products/views/product-edit/product-edit-headers";
import Checkbox from '@visa/vds/checkbox';
import ScreenReader from '@visa/vds/screen-reader';

export const AddHeaderRequiredCell: React.FC<CellContext<Row, any>> = (context) => {

    return <Checkbox
        name={`${context.getValue()}_checkbox_indeterminate`}
        label={<ScreenReader tag={"span"}>{context.table.getIsSomeRowsSelected() ? `Select ${context.row.original.name}` : `Unselect ${context.row.original.name}`}</ScreenReader>}
        id={`${context.getValue()}_checkbox_indeterminate`}
        checked={context.table.options.meta?.dirtyRow?.isRequired}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
            context.table.options?.meta?.setEditCelldata(context.row.id, e.target.checked ? true : false, "isRequired")
        }}
    ></Checkbox>
}
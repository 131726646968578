import React from 'react';
import clsx from 'clsx';
import { notificationBarStyles } from './notification-bar.styles';
import { NotificationProps } from './notification-props';
import { notificationVariantIcon } from './notification-variant-icon';

export const NotificationBar: React.FC<NotificationProps> = ({ message, variant }) => {
  const styles = notificationBarStyles();
  const Icon = notificationVariantIcon[variant];
  const backgroundColor = styles[variant];

  return (
    <div className={clsx(styles.root, backgroundColor)} aria-live='assertive'>
      <Icon className={clsx(styles.icon, styles.iconVariant)} />
      {message}
    </div>
  );
}

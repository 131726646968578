import { makeStyles } from "@material-ui/core";

export const dialogStyles = makeStyles((theme)=>({
    "vdsDialogBoxCard":{
        "& > .vds-dialog-card":{
            maxWidth: "unset",
            width: "50vw"
        },
        "& > .vds-dialog-card > .vds-dialog-card--body":{
            padding: 10
        }
    },
    "vdsDialogBoxCardForCred":{
        "& > .vds-dialog-card":{
            maxWidth: "unset",
            width: "80vw"
        },
        "& > .vds-dialog-card > .vds-dialog-card--body":{
            padding: 10
        }
    },
    instruction: {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
        marginBottom: "30px"
    },
    instruction1: {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
        marginBottom: theme.spacing(1)
    },
    instruction2: {
        fontSize: "16px",
        fontWeight: 500,
        marginBottom: theme.spacing(3)
    },
    root: {
        padding: '5px',
        border: '1px solid grey',
        backgroundColor: '#F2F4F8'
    },
    addBtn: {
        fontSize: '42px'
    },
    title: {
        fontSize: '20px'
    },
    textBackground: {
        backgroundColor: "white"
    },
    actions: {
      marginTop: theme.spacing(1),
    },
    button: {
      marginRight: theme.spacing(2),
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
}));
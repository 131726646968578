import { B2C2ApprovalRequest } from '../model/product';
import { useB2C2ApprovalRequests } from './use-b2c2-approval-requests';

export interface UseB2C2ApprovalRequestResult {
  b2c2ApprovalRequest: B2C2ApprovalRequest | undefined | null;
  loading: boolean;
}
export function useB2C2ApprovalRequest(requestGuid: any): UseB2C2ApprovalRequestResult {
  const { loading, b2c2ApprovalRequests } = useB2C2ApprovalRequests();

  let b2c2ApprovalRequest: B2C2ApprovalRequest | undefined | null = null;

  if (b2c2ApprovalRequests) {
    b2c2ApprovalRequest = b2c2ApprovalRequests.find(record => record.requestId === requestGuid);
  }

  return {
    b2c2ApprovalRequest,
    loading,
  };
}

import { makeStyles, Theme } from '@material-ui/core/styles';

export const ProductDialogStyles = makeStyles((theme: Theme) => ({
    dialogSlide: {
        marginLeft: '15px',
        flex: 1
    },
    content: {
        fontSize: '14px',
        maxWidth: theme.spacing(60),
        maxHeight: theme.spacing(40)
    },
    dialogActions: {
        paddingTop: '40px'
    },
    longBtn: {
        fontSize: '14px',
        width: '240px',
        height: '20px'
    },
    shortBtn: {
        fontSize: '14px',
        width: '100px',
        height: '20px'
    },
    dialogTitleWidth: {
        width: '500px'
    }
}));
import { User } from '../../../lib/oidc-client';
import { useUser } from '../../../lib/oidc-client-react';
import { UserProfile } from '../model/user-profile';
import { UserRole } from '../model/user-role';

export interface UseUserProfile {
  userProfile: UserProfile | null;
  loading: boolean;
}

export function useUserProfile(): UseUserProfile {
  const { user, loading } = useUser();
  const userProfile = user ? getUserProfile(user) : null;
  return {
    userProfile,
    loading,
  };
}

function getUserProfile(user: User): UserProfile {
  return {
    username: user.profile.username,
    firstName: user.profile.first_name,
    lastName: user.profile.last_name,
    email: user.profile.email_id || `${user.profile.username}@visa.com`,
    phone: user.profile.phone_number,
    displayName: user.profile.display_name || `${user.profile.first_name} ${user.profile.last_name}`,
    roles: mapRoles(user.scopes),
    ownedAppIds: user.profile.owned_app_ids,
  };
}

/* IMPORTANT : Setting a role to false means user has privileges equivalent to that role but doesn't actually possess the role.
        So roles mapped as false won't be displayed in profile */
function mapRoles(roles: string[]): Map<UserRole, boolean> {
  let roleMap = new Map<UserRole, boolean>();
  roleMap.set(UserRole.None, true);
  if (roles.includes('INNOV_SUPER_DEVELOPER')) {
      roleMap.set(UserRole.SuperDeveloper, true);
      roleMap.set(UserRole.ServiceDeveloper, false);
      roleMap.set(UserRole.ApplicationDeveloper, false);
  }
  if (roles.includes('INNOV_SERVICE_DEVELOPER')) {
      roleMap.set(UserRole.ServiceDeveloper, true);
      roleMap.set(UserRole.ApplicationDeveloper, false);
  }
  if (roles.includes('INNOV_APPLICATION_DEVELOPER')) {
    roleMap.set(UserRole.ApplicationDeveloper, true);
  }
  if (roles.includes('INNOV_PCSF_DEVELOPER')) {
    roleMap.set(UserRole.PcsfDeveloper, true);
  }
  if (roles.includes('INNOV_B2B_APPLICATION_DEVELOPER')) {
    roleMap.set(UserRole.B2BApplicationDeveloper, true);
  }
  if (roles.includes('INNOV_B2B_SERVICE_DEVELOPER')) {
    roleMap.set(UserRole.B2BServiceDeveloper, true);
  }
  if (roles.includes('GDL - UPPB2BApprovers')) {
    roleMap.set(UserRole.GDLUPPB2BApprovers, true);
  }
  return roleMap;
}

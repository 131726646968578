import React, { RefObject, useContext, useRef } from 'react';
import { Button, Card, CardContent, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { homeStyles } from './home.styles';
import { useHasPermission, UserPermission } from '../../features/auth/hooks/use-has-permission.hook';
import { UserRole } from '../../features/auth/model/user-role';
import b2bImage from './B2BImage.png';
import b2cImage from './B2CImage.png';
import { config } from '../../config';
import { buttonStyles } from '../../styles/button.styles';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../state/skip-to-main-store';
import { useNavigate } from "react-router-dom";


export const Home: React.FC = () => {
  const theme = useTheme();
  const matchesMediaCheck = useMediaQuery(theme.breakpoints.up("md"));
  const styles = homeStyles();
  const btnStyles = buttonStyles();
  const b2bAppDeveloper = useHasPermission(UserRole.B2BApplicationDeveloper) === UserPermission.Granted;
  const b2bServiceDeveloper = useHasPermission(UserRole.B2BServiceDeveloper) === UserPermission.Granted;
  const GDLUPPB2BApprovers = useHasPermission(UserRole.GDLUPPB2BApprovers) === UserPermission.Granted;
  const appDeveloper = useHasPermission(UserRole.ApplicationDeveloper) === UserPermission.Granted;
  const serviceDeveloper = useHasPermission(UserRole.ServiceDeveloper) === UserPermission.Granted;
  const navigate = useNavigate();
  const cardData = [
    {
      name: 'B2C', 
      image: b2cImage, 
      description: 'The B2C gateway solution for VPVP leverages B2C-IAM platform for user authentication. Fine-grained API authorization and pilot API endpoint routing are managed by VPVP. This B2C gateway also provides consumer management, card management , CyberSource payments capabilities. ',
      APIs: 'User Management APIs, Card Management APIs, Cybersource Payments, Digital Issuance APIs etc', 
      hasAccess: (appDeveloper || serviceDeveloper), 
      link: '/b2c'
    }
  ];
  const mainHighlightRef:RefObject<HTMLHeadingElement> = useRef(null);
  const context:LandmarkContextType = useContext(LandmarkContext);
  useLandmarkHook(mainHighlightRef,context[1]);
  useHighlightH1(mainHighlightRef);
  return (
    <main>
      <Helmet>
        <title>Choose the Portal | Visa Prototype Validation Platform </title>
      </Helmet>
      <div className={styles.root}>
        <Grid component="div" container spacing={4} >
          <Typography variant="h6" component="h1" className={styles.heading} innerRef={mainHighlightRef} tabIndex={-1}>
            Choose the Portal
          </Typography>
          {
            cardData.map((x: any, index: number) =>
              <Card key={x.name} className={x.hasAccess ? styles.cardHasAccess : styles.cardNoAccess} variant="elevation">
                <CardContent component="div">
                  <Grid component="div" container>
                    {matchesMediaCheck?<Grid component="div" item md={2} className={styles.cardColumn}>
                      <img src={x.image} alt="" className={styles.cardImage} aria-hidden={true} />
                    </Grid>:<></>}
                    <Grid component="div" item xs={12} md={4} className={styles.cardColumn}>
                      <div className={styles.cardName}>
                        <Typography component="h2" variant="h6" ><b>{x.name}</b></Typography>
                      </div>
                      <Typography component="p" variant="body1">
                        {x.description}
                      </Typography>
                    </Grid>
                    <Grid component="div" item md={3} xs={12} className={styles.cardColumnWithMargin}>
                      <Typography component="p" variant="body1"><b>Example APIs:</b></Typography>
                      <Typography component="h3" variant="body1">
                        {x.APIs}
                      </Typography>
                    </Grid>
                    <Grid component="div" item md={3} xs={12} className={styles.cardColumnWithMargin} style={{
                      display: "block"
                    }}>
                      {
                        x.hasAccess ?
                          <Button type="submit" variant="outlined" onClick={()=>navigate(x.link)} className={clsx(btnStyles.btnBlue, styles.btnRequestAccess)} role="link" >
                            {"Enter " + x.name}
                          </Button> :
                          <Button type="submit" variant="outlined" target="_blank" href={config.externalUrls.visaWikiRequestRole} className={clsx(btnStyles.btnWhite, styles.btnRequestAccess)} aria-label={`Request Access for ${x.name} portal (opens a new tab)`}>
                            {"Request Access"}
                          </Button>
                      }
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )
          }
        </Grid>
      </div>
    </main>
  );
};

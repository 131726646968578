import { useBlockingLoading } from "../../../hooks/use-blocking-loading";
import { useNotifications } from "../../../hooks/use-notifications";
import { logger } from "../../../services/logger.service";
import { objectDiff } from "../../../services/object-diff.service";
import { useDispatch } from "../../../state/app-store";
import { B2C2Application } from "../model/application";
import { B2C2ApplicationUpdate, cloneB2C2ApplicationUpdate } from "../model/application-update";
import { networkAppsNewSrv } from "../services/network-apps.service";
import { networkAppsHeaderSrv } from "../services/network-app-product.service";
import { appsActions } from "../state/apps-actions";


export interface UseB2C2ApplicationUpdateResult {
  loading: boolean;
  updateB2C2ApplicationAsync: (
    oldB2C2Application: B2C2Application,
    newB2C2Application: B2C2ApplicationUpdate
  ) => Promise<boolean>;
}


export function useB2C2ApplicationUpdate(): UseB2C2ApplicationUpdateResult {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useBlockingLoading();
  const { pushNotification } = useNotifications();

  const updateB2C2ApplicationAsync = async (
    oldB2C2Application: B2C2Application,
    newB2C2Application: B2C2ApplicationUpdate
  ): Promise<boolean> => {
    startLoading();
    try {
      const applicationGuid = oldB2C2Application.applicationGuid;

      const oldList = oldB2C2Application.appProductHeaders;
      const newList = newB2C2Application.appProductHeaders || [];
      const deletedItems = oldList.filter(({ headerId: oldHeaderId }) => !newList.some(({ headerId: newHeaderId }) => newHeaderId === oldHeaderId));
      if(deletedItems.length>0){
        await Promise.all(deletedItems.map(item => networkAppsHeaderSrv.deleteB2C2ApplicationHeaderAsync(applicationGuid, item)));
      }

      const newB2C2ApplicationClone = cloneB2C2ApplicationUpdate(newB2C2Application);

      const appChangeRest = await networkAppsNewSrv.updateB2C2ApplicationAsync(
            applicationGuid,
            newB2C2ApplicationClone
          );
          dispatch(appsActions.updateB2C2Application(appChangeRest));
      pushNotification({
        message: `Update application succeeded.`,
        variant: "success",
        type: "bar",
      });
      return true;
    } catch (e:any) {
      const error = e.message ? (e.message as string) : "Response error";
      logger.error(error, e);
      pushNotification({
        message: `Update application failed with '${error}'. Please, try again later.`,
        variant: "error",
        type: "bar",
      });
      return false;
    } finally {
      stopLoading();
    }
  };

  return {
    loading,
    updateB2C2ApplicationAsync,
  };
}
import { Grid, ThemeProvider, Typography } from '@material-ui/core';
import MaterialTable, { Column } from 'material-table';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { ValidationError } from 'yup';
import { DataTableSelect } from '../../../../components/data-table-fields/data-table-select';
import { DataTableTextInput } from '../../../../components/data-table-fields/data-table-text-input';
import { FormErrors } from '../../../../hooks/use-form.hook';
import { backgroundGrey, hostTheme, productEditStyles } from '../product-edit/product-edit.styles';
import { useNotifications } from '../../../../hooks/use-notifications';
import { ProductHostUpdate, ProductUpdate } from '../../model/product-update';
import { productHostCreateSchema } from '../../model/product-validations';
import { serviceHostDataCenters } from '../../model/product';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { Helmet } from 'react-helmet';
import { EditCellWrapper } from '../../../appsNew/views/table-abstraction/table-cells/cell-wrappers/EditCellWrappers';
import { B2CHostTypeEditCell } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/host/host-type-edit-cell';
import { B2CUrlLocationEditCell } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/host/url-location-edit-cell';
import { B2CAddHostsRow } from '../../../appsNew/views/table-abstraction/table-rows/b2c2-product/add-host-row';
import { ColumnDef, SortingState, Table, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../../../appsNew/views/table-abstraction/table-core';
import { TableFormEditDeleteAction } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-apps/table-form-edit-delete-action';
import { HeaderSearchAddButton } from '../../../appsNew/views/table-abstraction/filter-implementation/header-search-addButton';
type Row = ProductHostUpdate & { tableData?: any };

const dataCentersMap = serviceHostDataCenters.reduce<Record<string, string>>(
  (acc, dataCenter) => ({ ...acc, [dataCenter]: dataCenter }),
  {}
);

export interface ProductEditHostsProps {
  productHosts: ProductHostUpdate[];
  useVp2Connector: boolean | undefined;
  onChange: (productHosts: ProductHostUpdate[]) => void;
  isCreate?: boolean | false;
}

export const ProductEditHosts: React.FC<ProductEditHostsProps> = ({ productHosts, onChange, isCreate, useVp2Connector }) => {
  const styles = productEditStyles();
  const [errors, setErrors] = useState<FormErrors>({});
  const { pushNotification } = useNotifications();

  const note = `Note: At least one host type should be configured and active.`;


  const rows = useMemo<Row[]>(() => productHosts.map(host => ({ ...host })), [productHosts]);

  const columns: ColumnDef<ProductHostUpdate>[] = useMemo(() => (
    useVp2Connector ? [
      {
        header: 'Host Type*',
        accessorKey: 'dataCenter',
        cell: (context) => <EditCellWrapper context={context} element={<B2CHostTypeEditCell {...context.table} />} />
      },
      {
        header: 'URL location*',
        accessorKey: 'url',
        cell: (context) => <EditCellWrapper context={context} element={<B2CUrlLocationEditCell {...context.table} />} />
      }
    ] : [
      {
      header: 'Host Type*',
      accessorKey: 'dataCenter',
      cell: (context) => <EditCellWrapper context={context} element={<B2CHostTypeEditCell {...context.table} />} />
    },
    {
      header: 'URL location*',
      accessorKey: 'url',
      cell: (context) => <EditCellWrapper context={context} element={<B2CUrlLocationEditCell {...context.table} />} />
    },
    {
      header: "Action",
      accessorKey: "dataCenter",
      enableSorting: false,
      cell: TableFormEditDeleteAction,
      meta: {
        styles: {
          minWidth:"200px"
        }
      }
    }
  ]), [useVp2Connector]);

  const [currentEditingRow, setCurrentEditingRow] = useState<string[]>([]);
  const [dirtyRow, setDirtyRow] = useState<ProductHostUpdate | null>(() => null);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [sort, setSort] = useState<SortingState>([]);

  const [search, setSearch] = useState<string>(""); 
  const table: Table<ProductHostUpdate> = useReactTable({
    columns: columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (e) => `${e.id}`,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSort,
    state: {
      globalFilter: search,
      sorting: sort,
    },
    meta: {
      data: {
        isUsingVp2 : useVp2Connector,
        hostTypeOptions: dataCentersMap
      },
      updateEditingRowState: (id: string) => {
        setCurrentEditingRow([id]);
        setDirtyRow(() => ({ ...rows.filter(e => `${e.id}` === id)[0] }));
      },
      currentEditingRowId: currentEditingRow,
      revertingToStaticState: () => {
        setCurrentEditingRow([]);
        setDirtyRow(null);
      },
      setEditCelldata: (id, newData, columnid) => {
        setDirtyRow((oldState) => {
          if (!oldState) {
            return oldState;
          }
          return {
            ...oldState,
            [columnid]: newData
          }
        }
        );
      },
      dirtyRow: dirtyRow,
      isAdd: isAdd,
      addRowNode: B2CAddHostsRow,
      startAddMode: () => {
        setCurrentEditingRow(["new"]);
        setDirtyRow({
          dataCenter: "",
          url: "",
          id: 0,
          hostId:""
        });
        setIsAdd(true);
      }, stopAddMode: () => {
        setCurrentEditingRow([]);
        setDirtyRow(null);
        setIsAdd(false);
      },
      isValid: (table: Table<any>) => {
        return productHostCreateSchema.isValidSync(table.options.meta?.dirtyRow)
      },
      isIndividualCellValid: (columnName: string, newData: any) => {
        try {
          productHostCreateSchema.validateSyncAt(columnName, { [columnName]: newData });
          return "";
        } catch (e) {
          return (e as ValidationError).message;
        }
      },
      handleAdd: () => {
        if (!dirtyRow) {
          return;
        }
        const minId = productHosts.reduce((min, host) => Math.min(host.id, min), 0);
    onChange([
      ...productHosts,
      { ...dirtyRow, id: minId - 1 },
    ]);
        setCurrentEditingRow([]);
        setDirtyRow(null);
        setIsAdd(false);
      },
      handleUpdate: (id: string) => {
        onChange(productHosts.map(host => `${host.id}` !== id ? host : { ...host, ...dirtyRow }));
        setCurrentEditingRow([]);
        setDirtyRow(null);
      },
      deleteRow: (id: string) => {
        onChange(productHosts.filter(host => `${host.id}` !== id));
      }
    }
  }
  );

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Typography color="primary" className={styles.instruction1}>
        Specify at least one host URL for your product
      </Typography>
      <Typography className={styles.instruction2}><em>Note: At least one OCE host required</em></Typography>
      <HeaderSearchAddButton table={table} addButtonFunction={table.options.meta?.startAddMode ? table.options.meta?.startAddMode : () => { }} heading='Host Urls' search={search} setSearch={setSearch}></HeaderSearchAddButton>
      <TableCore caption='Host Urls' table={table}></TableCore>
    </Grid>
  );
};

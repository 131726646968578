import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { NotAuthorizedErrorPage } from './components/errors/not-authorized-error-page';
import { NotFoundErrorPage } from './components/errors/not-found-error-page';
import { AuthRouter } from './features/auth/auth-router';
import { UserRole } from './features/auth/model/user-role';
import { ProtectedRoute } from './features/auth/protected-route';
import { Profile } from './features/auth/views/profile/profile';
import { B2C2ProductsArea } from './features/products/views/product-area/b2c2-products-area';
import { Dashboard } from './views/dashboard/dashboard';
import { GetStarted } from "./views/get-started/get-started";
import { Health } from './views/health/health';
import { Help } from "./views/help/help";
import { Home } from './views/home/home';
import { B2C2AppsArea } from './features/appsNew/views/apps-area/apps-area';

export const AppRouter: React.FC = () => (
  <Routes>
    <Route path="auth/*" element={<AuthRouter />} />

    <Route path="get-started" element={<GetStarted/> } />
    <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
    <Route path="help" element={<ProtectedRoute><Help /></ProtectedRoute>} />
    <Route path="b2c" element={<ProtectedRoute><Dashboard isb2b={false}/></ProtectedRoute>} />
    <Route path="health" element={<ProtectedRoute><Health /></ProtectedRoute>} />
    <Route path="profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
    <Route path="b2c/products/*" element={<ProtectedRoute requiredRole={UserRole.ServiceDeveloper}><B2C2ProductsArea /></ProtectedRoute>} />
    <Route path="b2c/apps/*" element={<ProtectedRoute requiredRole={UserRole.ApplicationDeveloper}><B2C2AppsArea /></ProtectedRoute>} />
    <Route path="401" element={<NotAuthorizedErrorPage />} />
    <Route path="404" element={<NotFoundErrorPage />} />
    <Route path="*" element={<Navigate to="/404"/>} />
  </Routes>
);

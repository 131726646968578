import React, { createContext, useContext, useReducer, Reducer, ReducerState, Dispatch, ReducerAction } from 'react';
import { AppState, initialAppState, appReducer } from './app-reducer';
import { Action } from './actions';

const defaultValue: [ReducerState<Reducer<AppState, Action>>, Dispatch<ReducerAction<Reducer<AppState, Action>>>] = [
  initialAppState, () => {}
];
export const StateContext = createContext(defaultValue);

interface StoreProviderProps {
  reducer: Reducer<AppState, Action>;
  initialState: AppState;
  children: React.ReactNode;
}

const StoreProvider = ({ reducer, initialState, children }: StoreProviderProps) => (
  <StateContext.Provider
    value={useReducer(reducer, initialState)}
    children={children}
  />
);

export const AppStoreProvider = ({ children }: { children: React.ReactNode }) => (
  <StoreProvider reducer={appReducer} initialState={initialAppState}>{children}</StoreProvider>
);

export const useStore = () => useContext(StateContext);

export const useDispatch = () => useContext(StateContext)[1];

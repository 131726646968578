import { Grid, IconButton, ThemeProvider, Typography } from '@material-ui/core';
import { AddBox, OpenInNew, Search } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { ValidationError } from 'yup';
import { DataTableSelect } from '../../../../components/data-table-fields/data-table-select';
import { DataTableTextInput } from '../../../../components/data-table-fields/data-table-text-input';
import { httpMethods } from '../../../../model/http-method';
import { B2BOperationUpdate, B2BRateLimitsUpdate, B2BRateLimitUpdate } from '../../../services/model/service-update';
import { b2bOperationUpdateSchema } from '../../../services/model/service-validations';
import { backgroundGrey, hostTheme, productEditStyles } from '../product-edit/product-edit.styles';
import { ProductEndpointUpdate } from '../../model/product-update';
import { productEndpointCreateSchema } from '../../model/product-validations';
import { mleTypes } from '../../../../model/mle-types';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { Helmet } from 'react-helmet';
import { ColumnDef, SortingState, Table, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../../../appsNew/views/table-abstraction/table-core';
import { TableFormEditDeleteAction } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-apps/table-form-edit-delete-action';
import { HeaderSearchAddButton } from '../../../appsNew/views/table-abstraction/filter-implementation/header-search-addButton';
import { EndpointNameCell } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/endpoints/endpoint-name-cell';
import { EditCellWrapper } from '../../../appsNew/views/table-abstraction/table-cells/cell-wrappers/EditCellWrappers';
import { EndpointMethodTypeCell } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/endpoints/endpoint-method-type-cell';
import { EndpointInternalPathCell } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/endpoints/endpoint-internal-path-cell';
import { EndpointExternalPathCell } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/endpoints/endpoint-external-path-cell';
import { B2cMleTypeCell } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/endpoints/endpoint-b2cmle-type-cell';
import { B2BMleTypeCell } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/endpoints/endpoint-b2bmle-type-cell';
import { B2CAddEndpointsRow } from '../../../appsNew/views/table-abstraction/table-rows/b2c2-product/add-endpoint-row';

const newRateLimits: B2BRateLimitUpdate[] = [
  {
    name: "Default Overall Throttle",
    type: "OVERALL_THROTTLE",
    numberOfCalls: "500",
    timeWindow: "300",
    numberOfClients: "50"
  },
  {
    name: "Default Overall Quota",
    type: "OVERALL_QUOTA",
    numberOfCalls: "100000",
    timeWindow: "3600",
    numberOfClients: "100"
  },
  {
    name: "Default Overall Throttle Per Client",
    type: "THROTTLE_PER_CLIENT",
    numberOfCalls: "100",
    timeWindow: "300",
    numberOfClients: "50"
  },
  {
    name: "Default Overall Quota Per Client",
    type: "QUOTA_PER_CLIENT",
    numberOfCalls: "10000",
    timeWindow: "3600",
    numberOfClients: "100"
  }
];

const emptyRateLimits: B2BRateLimitUpdate[] = [
  {
    name: "",
    type: "OVERALL_THROTTLE",
    numberOfCalls: "",
    timeWindow: "",
    numberOfClients: ""
  },
  {
    name: "",
    type: "OVERALL_QUOTA",
    numberOfCalls: "",
    timeWindow: "",
    numberOfClients: ""
  },
  {
    name: "",
    type: "THROTTLE_PER_CLIENT",
    numberOfCalls: "",
    timeWindow: "",
    numberOfClients: ""
  },
  {
    name: "",
    type: "QUOTA_PER_CLIENT",
    numberOfCalls: "",
    timeWindow: "",
    numberOfClients: ""
  }
];

export interface ProductEditEndpointsProps {
  endpoints: ProductEndpointUpdate[];
  useVp2Connector: boolean | undefined;
  onChange: (endpoints: ProductEndpointUpdate[]) => void;
  isCreate?: boolean | false;
}

type Row = ProductEndpointUpdate & { tableData?: any };

const httpMethodsMap = httpMethods.reduce<Record<string, string>>(
  (acc, httpMethod) => ({ ...acc, [httpMethod]: httpMethod }),
  {}
);

const mleTypesMap = mleTypes.reduce<Record<string, string>>(
  (acc, httpMethod) => ({ ...acc, [httpMethod]: httpMethod }),
  {}
);

export const ProductEditEndpoints: React.FC<ProductEditEndpointsProps> = ({ endpoints, onChange, isCreate,useVp2Connector }) => {
  const styles = productEditStyles();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [editRateLimits, setEditRateLimits] = useState<B2BRateLimitUpdate[] | undefined>(newRateLimits);
  const [drawerOpen, setDrawerOpen] = useState<Boolean>(false);
  const [currOpId, setCurrOpId] = useState<number>(-1);

  const handleCloseDrawer = useCallback(() => setDrawerOpen(false), []);

  const handleOpenDrawer = useCallback((rateLimits: B2BRateLimitUpdate[], opId: number | undefined) => () => {
    if (!!opId) {
      setCurrOpId(opId);
    }
    if (Array.isArray(rateLimits)) {
      setEditRateLimits(newRateLimits);
    } else {
      setEditRateLimits(rateLimits);
    }

    setDrawerOpen(true);
  }, []);

  const rows = useMemo<Row[]>(() => {
    if (endpoints !== undefined) {
      //let data = operations.filter(op => op.status == 'ACTIVE');
      return endpoints;
    } else {
      return [];
    }
  }, [endpoints]);

  const handleRequestStatusChange = useCallback(async (rateLimits: B2BRateLimitsUpdate) => {
    if (endpoints !== undefined) {
      onChange(endpoints.map(endpoint => endpoint.id === currOpId ? { ...endpoint, rateLimits: rateLimits.limits } : endpoint));
    }
    setDrawerOpen(false);
  }, [currOpId, onChange, endpoints]);


  const columns: ColumnDef<ProductEndpointUpdate>[] = useMemo(() => (
    useVp2Connector ? [
      {
        header: 'Endpoint name*',
        accessorKey: 'name',
        cell: (context) => <EditCellWrapper context={context} element={<EndpointNameCell {...context.table} />} />
      },
      {
        header: 'Http method*',
        accessorKey: 'methodType',
        cell: (context) => <EditCellWrapper context={context} element={<EndpointMethodTypeCell {...context.table} />} />
      },
      {
        header: 'Internal path*',
        accessorKey: 'internalPath',
        cell: (context) => <EditCellWrapper context={context} element={<EndpointInternalPathCell {...context.table} />} />
      },
      {
        header: 'External path*',
        accessorKey: 'externalPath',
        cell: (context) => <EditCellWrapper context={context} element={<EndpointExternalPathCell {...context.table} />} />
      },
      {
        header: 'B2C MLE Type',
        accessorKey: 'b2cMleType',
        cell: (context) => <EditCellWrapper context={context} element={<B2cMleTypeCell {...context.table} />} />
      },
      {
        header: 'B2B MLE Type',
        accessorKey: 'b2bMleType',
        cell: (context) => <EditCellWrapper context={context} element={<B2BMleTypeCell {...context.table} />} />
      },
      {
        header: "Action",
        accessorKey: "id",
        enableSorting: false,
        cell: TableFormEditDeleteAction,
        meta: {
          styles: {
            minWidth:"200px"
          }
        }
      }
    ] : [
      {
        header: 'Endpoint name*',
        accessorKey: 'name',
        cell: (context) => <EditCellWrapper context={context} element={<EndpointNameCell {...context.table} />} />
      },
      {
        header: 'Http method*',
        accessorKey: 'methodType',
        cell: (context) => <EditCellWrapper context={context} element={<EndpointMethodTypeCell {...context.table} />} />
      },
      {
        header: 'Internal path*',
        accessorKey: 'internalPath',
        cell: (context) => <EditCellWrapper context={context} element={<EndpointInternalPathCell {...context.table} />} />
      },
      {
        header: 'External path*',
        accessorKey: 'externalPath',
        cell: (context) => <EditCellWrapper context={context} element={<EndpointExternalPathCell {...context.table} />} />
      },
      {
        header: "Action",
        accessorKey: "id",
        enableSorting: false,
        cell: TableFormEditDeleteAction,
        meta: {
          styles: {
            minWidth:"200px"
          }
        }
      }
  ]), [useVp2Connector]);


  const [currentEditingRow, setCurrentEditingRow] = useState<string[]>([]);
  const [dirtyRow, setDirtyRow] = useState<ProductEndpointUpdate | null>(() => null);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [sort, setSort] = useState<SortingState>([]);

  const [search, setSearch] = useState<string>(""); 
  const table: Table<ProductEndpointUpdate> = useReactTable({
    columns: columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (e) => `${e.id}`,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSort,
    state: {
      globalFilter: search,
      sorting: sort,
    },
    meta: {
      data: {
        useVp2Connector : useVp2Connector,
        mleTypesMap: mleTypesMap
      },
      updateEditingRowState: (id: string) => {
        setCurrentEditingRow([id]);
        setDirtyRow(() => ({ ...rows.filter(e => `${e.id}` === id)[0] }));
      },
      currentEditingRowId: currentEditingRow,
      revertingToStaticState: () => {
        setCurrentEditingRow([]);
        setDirtyRow(null);
      },
      setEditCelldata: (id, newData, columnid) => {
        setDirtyRow((oldState) => {
          if (!oldState) {
            return oldState;
          }
          return {
            ...oldState,
            [columnid]: newData
          }
        }
        );
      },
      dirtyRow: dirtyRow,
      isAdd: isAdd,
      addRowNode: B2CAddEndpointsRow,
      startAddMode: () => {
        setCurrentEditingRow(["new"]);
        setDirtyRow({
          id:0,
          name: "",
          description: "",
          internalPath: "",
          externalPath: "",
          methodType: "",
          status: "",
          version: "",
          b2cMleType: "NONE",
          b2bMleType: "NONE"
        });
        setIsAdd(true);
      }, stopAddMode: () => {
        setCurrentEditingRow([]);
        setDirtyRow(null);
        setIsAdd(false);
      },
      isValid: (table: Table<any>) => {
        return productEndpointCreateSchema.isValidSync(table.options.meta?.dirtyRow)
      },
      isIndividualCellValid: (columnName: string, newData: any) => {
        try {
          productEndpointCreateSchema.validateSyncAt(columnName, { [columnName]: newData });
          return "";
        } catch (e) {
          return (e as ValidationError).message;
        }
      },
      handleAdd: () => {
        if (!dirtyRow) {
          return;
        }
          const minId = endpoints.reduce((min, endpoint) => Math.min(endpoint.id === undefined ? min : endpoint.id, min), 0);
          onChange([
            ...endpoints,
            { ...dirtyRow, id: minId - 1 },
          ]);
        setCurrentEditingRow([]);
        setDirtyRow(null);
        setIsAdd(false);
      },
      handleUpdate: (id: string) => {
        onChange(endpoints.map(endpoint => `${endpoint.id}` !== id ? endpoint : { ...endpoint, ...dirtyRow }));
        setCurrentEditingRow([]);
        setDirtyRow(null);
      },
      deleteRow: (id: string) => {
        onChange(endpoints.filter(endpoint => `${endpoint.id}` !== id));
      }
    }
  }
  );

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Helmet>
        <title>
          {
            isCreate ?
              "Endpoints | Add new product (3 of 5) | Visa Prototype Validation Platform"
              : "Endpoints | Edit product (3 of 4) | Visa Prototype Validation Platform"
          }
        </title>
      </Helmet>
      {
        isCreate ? <TypographyHighLight headerTitle="Endpoints | Add new product (3 of 5)" variant='h5'></TypographyHighLight>
          : <TypographyHighLight headerTitle="Endpoints | Edit product (3 of 4) " variant='h5'></TypographyHighLight>
      }

      <Typography color="primary" className={styles.instruction1}>
        Specify at least one endpoint for your product
      </Typography>

      <Typography className={styles.instruction2} component={"p"}><em>Note: External path must be prepended with '/innovation/contextRoot'</em></Typography>
      <HeaderSearchAddButton table={table} addButtonFunction={table.options.meta?.startAddMode ? table.options.meta?.startAddMode : () => { }} heading='Endpoints' search={search} setSearch={setSearch}></HeaderSearchAddButton>
      <TableCore caption='Endpoints' table={table}></TableCore>
    </Grid>
  );
};


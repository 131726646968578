import { config } from '../../../config';
import { networkSrv } from '../../../services/network.service';
import { Product, ProductHost } from '../model/product';
import { ProductHostCreate } from '../model/product-create';
import { ProductHostRQ, ProductHostRS, ProductHostsRQ, ProductRS } from './dtos';
import { mapProduct, mapProductHost, mapProductHostCreate, mapProductHostUpdate } from './model-maps';


export async function addProductHostsAsync(productId: string, hosts: ProductHostCreate[]): Promise<Product> {
  const hostsRQ = hosts.map(mapProductHostCreate);
  const request: ProductHostsRQ = { "productHosts": hostsRQ };
  const response = await networkSrv.postAsync<ProductHostsRQ, ProductRS>(`${config.urls.products}/${productId}/hosts`, request);
  return mapProduct(response);
}

export function deleteProductHostsAsync(productId: string, host: Partial<ProductHost>): Promise<void> {
  const hostId = host.hostId;
  return networkSrv.deleteAsync(`${config.urls.products}/${productId}/hosts/${hostId}`);
}

export function updateProductHostsAsync(productId: string, host: Partial<ProductHost>): Promise<void> {
  const hostId = host.hostId;
  const productHostRQ = mapProductHostUpdate(host);
  return networkSrv.patchAsync<Partial<ProductHostRQ>, void>(`${config.urls.products}/${productId}/hosts/${hostId}`, productHostRQ);
}

export const networkProductHostsSrv = {
  addProductHostsAsync,
  deleteProductHostsAsync,
  updateProductHostsAsync,
}
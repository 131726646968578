import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { skipToStyles } from "./skipToMainContent.style";
import { LandmarkContext } from "../../state/skip-to-main-store";

export const SkipToMainContent: React.FC = () => {
    const style = skipToStyles();
    const state = useContext(LandmarkContext)[0]
    return state.isSkipToRequired ?
                <Button
                    tabIndex={0}
                    role="link"
                    className={style.skipButton}
                    onClick={() => state.onSkipFocusElement?.current?.focus()}
                    >
                    Skip to Main Content
                </Button>
        : <></>;
}
import { Divider, Grid, Typography, Button } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { string } from "yup";
import { ExportToJsonButton } from "../../../../components/buttons/export-to-json-button";
import { logger } from "../../../../services/logger.service";
import { buttonStyles } from "../../../../styles/button.styles";
import {
  B2BReviewRequestUpdate,
} from "../../../services/model/service-update";
import { useProductPreviewStyles } from "../product-view/product-preview.styles";
import { ProductPreviewDetails } from "./product-preview-details";
import { ProductPreviewHosts } from "./product-preview-hosts";
import { ProductPreviewHeaders } from "./product-preview-headers";
import { ProductPreviewEndpoints } from "./product-preview-endpoints";
import { ProductCreate } from "../../model/product-create";
import { jsonProductService } from "../../services/json.service";
import { B2C2ReviewRequestUpdate } from "../../model/product-update";
import { createB2C2ReviewRequestAsync } from "../../services/network-services.products";
import { useB2C2ApprovalRequests } from "../../hooks/use-b2c2-approval-requests";
import { useB2C2ApprovalRequest } from "../../hooks/use-b2c2-approval-request";
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { Helmet } from 'react-helmet';
import { useMediaQuery, useTheme } from '@mui/material';
import { ProductPreviewB2C2BArgs } from "./product-preview-b2c2b-args";

export interface ProductReviewProps {
  product?: ProductCreate | null;
  productActive?: ProductCreate | null;
  isEdit?: boolean;
  editDetailsNav?: () => void;
  editOperationsNav?: () => void;
  editHostsNav?: () => void;
  editHeadersNav?: () => void;
  editConfigsNav?: () => void;
  isProductOwner?: boolean;
}

export const ProductReview: React.FC<ProductReviewProps> = ({ product, productActive, isProductOwner, isEdit = false, editDetailsNav, editOperationsNav, editHostsNav, editConfigsNav, editHeadersNav }) => {
  const styles = useProductPreviewStyles();
  const [dialog, setDialog] = React.useState(0);
  const navigate = useNavigate();
  const btnStyles = buttonStyles();
  var alignmentValue = "PENDING";
  var requestId = '';
  if (product !== undefined && product !== null) {
    requestId = product.requestId;
  } else if (productActive !== undefined && productActive !== null) {
    requestId = productActive.requestId;
    alignmentValue = "ACTIVE";
  }
  const [alignment, setAlignment] = React.useState(alignmentValue);
  const { refreshApprovals } = useB2C2ApprovalRequests();
  const { b2c2ApprovalRequest } = useB2C2ApprovalRequest(requestId);

  const getJsonContentToExport = useCallback(
    () => product ? jsonProductService.exportToJson(product) : '{}',
    [product]
  );

  const handleDialog = useCallback((index: number) => {
    setDialog(index);
  }, []);

  const handleCancel = useCallback(() => {
    navigate("/b2c/products");
  }, [navigate]);

  const b2c2RviewRequestUpdate = useMemo<B2C2ReviewRequestUpdate>(
    () => ({
      requestId: "",
      saNumber: "",
      businessJustification: "",
    }),
    []
  );

  const handleSubmitReview = useCallback(
    async (sanumber: string, businessJustification: string) => {
      if (!b2c2RviewRequestUpdate) return;
      if (!!product) {
        b2c2RviewRequestUpdate.businessJustification = businessJustification;
        b2c2RviewRequestUpdate.saNumber = sanumber;
        b2c2RviewRequestUpdate.requestId = product.requestId;
        const success = await createB2C2ReviewRequestAsync(
          product.productId,
          b2c2RviewRequestUpdate
        );
        if (success) {
          refreshApprovals();
          navigate('/b2c/products/' + product?.productId);
        } else {
          logger.error(
            "Unable to show approvals page",
            "Update Approval Request unsuccessful"
          );
        }
      }
      navigate("/b2c/products");
    },
    [
      product,
      b2c2RviewRequestUpdate,
      createB2C2ReviewRequestAsync,
      handleDialog,
      navigate,
    ]
  );

  const handleEditComplete = useCallback(() => {
    navigate('/b2c/products/' + product?.productId);
  }, [navigate]);

  const handleChangeProductPreview = (
    event: any,
    newAlignment: React.SetStateAction<string>
  ) => {
    if (newAlignment !== null) setAlignment(newAlignment);
  };

  const handleApprovalDialog = useCallback(
    async () => handleDialog(1),
    [handleDialog]
  );

  const handleSubmitButton = () => {
    if (alignment == "ACTIVE" || typeof product == "undefined" || b2c2ApprovalRequest?.status !== "OPEN" || !isProductOwner) {
      return true;
    } else {
      return false;
    }
  };
  const setToggle = handleSubmitButton();
  const theme = useTheme();
  const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Grid container>
      <Helmet>
        <title>
          Review | Add new product(5 of 5) | Visa Prototype Validation Platform
        </title>
      </Helmet>
      <TypographyHighLight headerTitle="Review | Add new product(5 of 5)" variant='h5'></TypographyHighLight>

      <Grid item style={{ width: "100%", marginTop: "16px" }}>
      <Grid container direction={mediaCheck ? 'column' : 'row'} justifyContent='space-between' >
        <Typography className={styles.title} component={"h2"}>
          Review your product details
          <br></br>
          <p className={styles.subTitle}>Please check each section of your product before you submit</p>
        </Typography>
          <Button onClick={editDetailsNav} variant="outlined" color="secondary"
            className={styles.editButton}> Edit Product Details</Button>
        </Grid>
      </Grid>

      <ProductPreviewDetails details={product} />

      <Divider className={styles.dividerB2C} />

      <Grid container>
        <Grid item style={{ width: "100%", marginTop: "16px" }}>
          <Grid container direction={mediaCheck ? 'column' : 'row'} justifyContent='space-between' >
            <Typography className={styles.title} component={"h2"}>
                B2C2B Args
            </Typography>
            <Button onClick={editHostsNav} variant="outlined" color="secondary"
              className={styles.editButton}> Edit B2C2B Args</Button>
          </Grid>
        </Grid>
      </Grid>
      <ProductPreviewB2C2BArgs productB2C2BArgs={product?.productB2C2BArgs}/>

      <Divider className={styles.dividerB2C} />

      <Grid container>
        <Grid item style={{ width: "100%", marginTop: "16px" }}>
          <Grid container direction={mediaCheck ? 'column' : 'row'} justifyContent='space-between' >
            <Typography className={styles.title} component={"h2"}>
              Host URL
            </Typography>
            <Button onClick={editHostsNav} variant="outlined" color="secondary"
              className={styles.editButton}> Edit Host URL</Button>
          </Grid>
        </Grid>
      </Grid>

      <ProductPreviewHosts hosts={product?.productHosts} />

      <Divider className={styles.dividerB2C} />

      <Grid container>
        <Grid item style={{ width: "100%", marginTop: "16px" }}>
          <Grid container direction={mediaCheck ? 'column' : 'row'} justifyContent='space-between' >
            <Typography className={styles.title} component={"h2"}>
              Endpoints
            </Typography>
            <Button onClick={editOperationsNav} variant="outlined" color="secondary"
              className={styles.editButton}> Edit Endpoints</Button>
          </Grid>
        </Grid>
      </Grid>

      <ProductPreviewEndpoints endpoints={product?.productEndpoints} />

      <Divider className={styles.dividerB2C} />

      <Grid container>
        <Grid item style={{ width: "100%", marginTop: "16px" }}>
          <Grid container direction={mediaCheck ? 'column' : 'row'} justifyContent='space-between' >
            <Typography className={styles.title} component={"h2"}>
              Allowed Headers
            </Typography>
            <Button onClick={editHeadersNav} variant="outlined" color="secondary"
              className={styles.editButton}> Edit Allowed Headers</Button>
          </Grid>
        </Grid>
      </Grid>

      <ProductPreviewHeaders headers={product?.productHeaders} />
                  
      <Divider className={styles.dividerB2C} />

    </Grid>
  );
};
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { ChangeEvent } from "react";
import Select from '@visa/vds/select';
import { SearchBar } from "../common-component/search-bar";
export interface IB2C2RequestAprovalFilter {
    dataEntries: any[];
    status: string;
    requestor: string;
    type: string;
    setStatus: Function;
    setRequestor: Function;
    setType: Function;
    search: string;
    setSearch: Function;
}
export const B2C2RequestAprovalFilter: React.FC<IB2C2RequestAprovalFilter> = ({ dataEntries, setStatus, setRequestor, setType, setSearch, requestor, search, status, type }) => {
    const theme = useTheme();
    const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));
    return <Grid
        container
        style={{ backgroundColor: "#F2F4F8" }}
        justifyContent={mediaCheck ? "space-between" : "flex-start"}
        alignItems={"center"}
        rowGap={2}
        padding={1}
    >
        <Grid item xs={12} >
            <Typography component={"h2"} variant='h5'>
                Total requests: {dataEntries.length}
            </Typography>
        </Grid>
        <Grid item xs={12} lg={3} padding={1}>
            <Select
                fullWidth
                label={"Type Filter"}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => { setType(e.target.value) }}
                value={type}
                >
                    <option value="0">All</option>
                    {Array.from(new Set(dataEntries?.map(item => item.requestType))).map((entry, index) =>
                        <option key={index} value={entry}>{entry}</option>)}
            </Select>
        </Grid>
        <Grid item xs={12} lg={3} padding={1}
        >
            <Select
                label="Requestor Filter"
                value={requestor}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setRequestor(e.target.value)}>
                    <option value="0">All</option>
                    {Array.from(new Set(dataEntries?.map(item => item.requestorSubjectName || item.requestor))).map((entry, index) =>
                        <option key={index} value={entry}>{entry}</option>)}
            </Select>
        </Grid>
        <Grid item xs={12} lg={3} padding={1}>

            <Select
                label=" Status Filter"
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setStatus(e.target.value)}
                    value={status}>
                    <option value="0">All</option>
                    {Array.from(new Set(dataEntries?.map(item => (item.requestStatus || item.status)))).map((entry, index) =>
                        <option key={index} value={entry}>{entry}</option>)}
            </Select>

        </Grid>
        <Grid item xs={12} lg={3} padding={1}>
            <SearchBar label=" Search B2C Requests" search={search} setSearch={setSearch}></SearchBar>
        </Grid>
    </Grid>
}
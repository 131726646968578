import { Identity } from '../model/entities';
import { arrayDiff } from './array-diff.service';
import { objectDiff } from './object-diff.service';

export async function updateEntityListAsync<T extends TUpdate, TUpdate extends Identity>(
  entityId: string,
  idValue: string,
  oldList: T[] | null | undefined,
  newList: TUpdate[] | null | undefined,
  addItemsAsync: (productId: string, list: TUpdate[]) => Promise<any>,
  updateItemAsync: (productId: string, item: Partial<TUpdate>) => Promise<any>,
  deleteItemAsync: (productId: string, item: Partial<TUpdate>) => Promise<void>,
): Promise<void> {
  const newItems = newList ? newList.filter(item => item.id < 0) : [];
  const updatedItems = newList ? newList.filter(item => item.id > 0) : [];
  const deletedItems = arrayDiff(oldList || [], newList);

  if (newItems.length > 0) {
    await addItemsAsync(entityId, newItems);
  }
  if (deletedItems.length > 0) {
    await Promise.all(deletedItems.map(item => deleteItemAsync(entityId, item)));
  }
  if (updatedItems.length > 0) {
    await Promise.all(updatedItems.map(item => {
      const oldItem = oldList ? oldList.find(oldItem => item.id === oldItem.id) : null;
      const partialItem = oldItem ? objectDiff<TUpdate>(item, oldItem) : item;
      const hasChanges = Object.entries(partialItem).some(([_, value]) => !!value);
      
      // if(newList && newList.length) {
      //   Object.assign(partialItem, newList[0]);
      // }

      if(hasChanges && item) {
          Object.assign(partialItem, item);
        }
      return hasChanges
        ? updateItemAsync(entityId, partialItem)
        : Promise.resolve();
    }));
  }
}

export async function addEntityListAsync<T extends TUpdate, TUpdate extends Identity>(
  entityId: string,
  oldList: T[] | null | undefined,
  newList: TUpdate[] | null | undefined,
  addItemsAsync: (productId: string, list: TUpdate[]) => Promise<any>,
): Promise<void> {
  const newItems = newList ? newList.filter(item => item.id < 0) : [];

  if (newItems.length > 0) {
    await addItemsAsync(entityId, newItems);
  }
}

export async function changeEntityListAsync<T extends TUpdate, TUpdate extends Identity>(
  entityId: number,
  oldList: T[] | null | undefined,
  newList: TUpdate[] | null | undefined,
  updateItemAsync: (applicationId: number, itemId: number, item: Partial<TUpdate>) => Promise<any>,
): Promise<void> {
  const updatedItems = newList ? newList.filter(item => item.id > 0) : [];

  if (updatedItems.length > 0) {
    await Promise.all(updatedItems.map(item => {
      const oldItem = oldList ? oldList.find(oldItem => item.id === oldItem.id) : null;
      const partialItem = oldItem ? objectDiff<TUpdate>(item, oldItem) : item;
      const hasChanges = Object.entries(partialItem).some(([_, value]) => !!value);
      return hasChanges
        ? updateItemAsync(entityId, item.id, partialItem)
        : Promise.resolve();
    }));
  }
}

export async function deleteEntityListAsync<T extends TUpdate, TUpdate extends Identity>(
  entityId: number,
  oldList: T[] | null | undefined,
  newList: TUpdate[] | null | undefined,
  deleteItemAsync: (applicationId: number, itemId: number) => Promise<void>,
): Promise<void> {
  const deletedItems = arrayDiff(oldList || [], newList);

  if (deletedItems.length > 0) {
    await Promise.all(deletedItems.map(item => deleteItemAsync(entityId, item.id)));
  }
}

import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const loginStyles = makeStyles((theme: Theme) => ({
  center: {
    padding: theme.spacing(47, 0, 0, 6),
    [theme.breakpoints.down("md")]:{
      padding: theme.spacing(1)
    }
  },
  hero: {
    margin: theme.spacing(0, 0, 0, 0),
    padding: theme.spacing(8, 0, 0, 8),
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#F2F4F8',
    backgroundSize: 'auto',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(18, 4),
    flexDirection: 'column'
  },
  heroDiv: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heroImg: {
    height: '350px',
    width: 'auto'
  },
  welcome:{
    marginTop:theme.spacing(5),
    fontWeight:200,
    color:'#2A2E69'
  },
  welcomeText: {
    padding: theme.spacing(1, 14, 2, 0),
    [theme.breakpoints.down("md")]:{
      padding: theme.spacing(1, 1, 1, 1)
    }
  },
  signInText:{
    marginBottom: theme.spacing(4),
    fontWeight:200,
    color:'#2A2E69'
  },
  form: {
    marginTop: theme.spacing(1),
  },
  flexOne: {
    flex: 1
  },
  btnGetStarted:{
    marginBottom:30
  },
  logoHeader:{
    position: "absolute",
    margin: theme.spacing(0, 0, 0, 0),
    padding: theme.spacing(8, 0, 0, 8),
  }
}));

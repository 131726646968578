import {
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { FormErrors } from '../../../../hooks/use-form.hook';
import { useUsers } from '../../../user/hooks/use-users.hook';
import { useFormsStyles } from '../../../../styles/forms.styles';
import { ProductUpdate } from '../../model/product-update';
import { FormLabel } from '@mui/material';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { Helmet } from 'react-helmet';
import Input from "@visa/vds/input";
import { vdsStyleOverrides } from '../../../../vds-overrides.styles';

export const deploymentRegions = [
  {
    region: "North America",
    code: "NA",
    dataCenters: ["OCE", "OCC"],
    allRequired: false
  },
  {
    region: "Europe",
    code: "EU",
    dataCenters: ["OCB"],
    allRequired: false
  },
  {
    region: "India",
    code: "IN",
    dataCenters: ["OCIB", "OCIM"],
    allRequired: false
  },
  {
    region: "Singapore",
    code: "SG",
    dataCenters: ["OCS"],
    allRequired: false
  },
  {
    region: "Turkey",
    code: "TR",
    dataCenters: ["OCTE", "OCTN"],
    allRequired: false
  },
  {
    region: "China",
    code: "CN",
    dataCenters: ["OCHB", "OCHS"],
    allRequired: false
  },
  {
    region: "External",
    code: "EXT",
    dataCenters: ["AWS"],
    allRequired: false
  }
];

export interface ProductEditDetailsProps {
  details: ProductUpdate;
  onInputChange: (event: React.ChangeEvent<any>) => void;
  onInputValueChange: (name: string, value: any) => void;
  errors: FormErrors;
  isCreate?: boolean | false;
}

export const ProductEditDetails: React.FC<ProductEditDetailsProps> = ({ details, onInputChange, onInputValueChange, errors, isCreate }) => {
  const { users, loading, loadUsersAsync } = useUsers();
  const [open, setOpen] = useState<boolean>(false);
  const styles = useFormsStyles();
  const vdsStyles = vdsStyleOverrides();

  const handleOpen = useCallback(() => {
    setOpen(true);
    if (!users) {
      loadUsersAsync();
    }
  }, [users, loadUsersAsync]);

  const handleClose = useCallback(
    () => setOpen(false),
    []
  );

  const usernames = users ? users.map(user => user.username) : [];

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Container maxWidth="sm">
            <Helmet>
              <title>
                {
                  isCreate ?
                    "Product Details | Add new product (1 of 5) | Visa Prototype Validation Platform"
                    : "Product Details | Edit product (1 of 4) | Visa Prototype Validation Platform"
                }
              </title>
            </Helmet>
            {
              isCreate ? <TypographyHighLight headerTitle="Product Details | Add new product (1 of 5)" variant='h5'></TypographyHighLight>
                : <TypographyHighLight headerTitle="Product Details | Edit product (1 of 4)" variant='h5'></TypographyHighLight>
            }
            <Typography component="p" className={styles.h3}>Please fill in your product details</Typography>
            <Input
              errorText={errors.name}
              label="Product name *"
              name={"Product_name"}
              value={details.name}
              id="product-name-id"
              onChange={(e: ChangeEvent<HTMLInputElement>) => { onInputValueChange("name", e.target.value) }}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => { onInputValueChange("name", e.target.value) }}
              showErrorText={!!errors.name}
              invalid={!!errors.name}
              aria-required
              className={vdsStyles.inputFullWidth}
            ></Input>

            <br />
            <Input
              errorText={errors.description}
              label="Description"
              name={"details_description"}
              value={details.description}
              onChange={(e: ChangeEvent<HTMLInputElement>) => { onInputValueChange("description", e.target.value) }}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => { onInputValueChange("description", e.target.value) }}
              showErrorText={!!errors.description}
              invalid={!!errors.description}
              className={vdsStyles.inputFullWidth}
            ></Input>
            <br />

            <Input
              errorText={errors.documentationUrl}
              label="Documentation URL"
              name={"Documentation_URL"}
              value={details.documentationUrl}
              onChange={(e: ChangeEvent<HTMLInputElement>) => { onInputValueChange("documentationUrl", e.target.value) }}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => { onInputValueChange("documentationUrl", e.target.value) }}
              showErrorText={!!errors.documentationUrl}
              invalid={!!errors.documentationUrl}
              className={vdsStyles.inputFullWidth}
            ></Input>
            <br />

            <Input
              errorText={errors.contextRoot}
              label="Context Root *"
              name={"Context_Root"}
              value={details.contextRoot}
              id="context-root-id"
              onChange={(e: ChangeEvent<HTMLInputElement>) => { onInputValueChange("contextRoot", e.target.value) }}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => { onInputValueChange("contextRoot", e.target.value) }}
              showErrorText={!!errors.contextRoot}
              invalid={!!errors.contextRoot}
              aria-required
              className={vdsStyles.inputFullWidth}
            ></Input>
            <br />
            <FormControl className={clsx(styles.formControlFull, styles.marginTop)} required error={!!errors.serviceOwners}>
              <FormLabel className={styles.label} htmlFor='details_owners'>Owners (Use Backspace for removing the owners)</FormLabel>
              <Autocomplete
                multiple
                id="details_owners"
                options={usernames}
                getOptionLabel={username => username}
                value={details.productOwners}
                onChange={(_, value) => onInputValueChange('productOwners', value)}
                loading={loading}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress aria-label='Loading' color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <br />
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
};

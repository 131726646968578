import { createTheme, makeStyles } from '@material-ui/core/styles';

export const serviceWizardStyles = makeStyles(theme => ({
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
    divider: {
    variant:"fullWidth",
    height:"7px",
    margin:"30px",
    backgroundColor:'#F2F4F8'
  },
  backBtn: {
    marginLeft: theme.spacing(5),
    height: "64px",
    width: "150px",
    marginTop: "50px"
  },
  nextBtn: {
    height: "64px",
    width: "150px",
    marginTop: "50px"
  },
  cancelBtn: {
    padding: "14px 3px 19px 1px;",
    height: "64px",
    width: "100px",
    marginTop: "50px"
  },
  marginT30: {
    marginTop: '30px',
  },
  marginB30: {
      marginBottom: '30px'
  },
  label: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
    marginBottom: '8px',
    color:' #1A1F71'
  },
  instruction1: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    marginBottom: theme.spacing(1)
  }
  }));

export const selectTheme = createTheme({
    overrides: {
        MuiInputLabel: {
            outlined: {
                backgroundColor: '#fff',
                paddingLeft: 2,
                paddingRight: 2,
                color: '#1A1F71'
            }
        },
    }
});

import { MultiDataType, MultiType } from '../../../model/multi-type';
import { logger } from '../../../services/logger.service';
import {
  B2BApprovalRequest,
  B2BEntitlementConfigs,
  B2BOutboundHost,
  B2BPendingApplicationOperationMap,
  B2BPendingOperation, B2BRateLimits, B2BServiceHeader,
  B2BServiceHostChangeDetails,
  B2BServiceReviewRequest,
  ServiceConfiguration,
  ServiceHeader,
  ServiceHost,
  ServiceOperation
} from '../model/service';
import {
  B2BEntitlementConfigCreate,
  B2BOutboundEventsCreate,
  B2BOutboundHostCreate,
  B2BRateLimitCreate,
  B2BServiceCreate, B2BServiceHeadersCreate,
  B2BServiceHostCreate,
  ServiceConfigurationCreate,
  ServiceHeaderCreate,
  ServiceHostCreate,
  ServiceOperationCreate
} from '../model/service-create';
import {
  B2BOperationUpdate,
  B2BOutboundEventsUpdate,
  B2BReviewRequestUpdate,
  B2BServiceHostUpdate,
  B2BServiceUpdate,
  ServiceConfigurationUpdate,
  ServiceHeaderUpdate,
  ServiceHostUpdate,
  ServiceOperationUpdate
} from '../model/service-update';
import { B2BOperation, B2BOutboundEvent, B2BService, B2BServiceHost, B2BServiceOwner } from './../model/service';
import { B2BOperationCreate } from './../model/service-create';
import { B2BApprovalRequestUpdate, B2BRateLimitUpdate } from './../model/service-update';
import {
  B2BApprovalRequestRS,
  B2BApprovalRequestUpdateRQ,
  B2BEntitlementConfigsRQ,
  B2BOperationRQ,
  B2BOperationRS,
  B2BOutboundEventRQ,
  B2BOutboundEventRS,
  B2BOutboundHostRQ,
  B2BOutboundHostRS,
  B2BPendingApplicationOperationMapRS,
  B2BPendingOperationRS, B2BRateLimitsRQ, B2BRateLimitsRS, B2BReviewRequestRS, B2BReviewRequestUpdateRQ, B2BServiceHeaderRQ, B2BServiceHeaderRS,
  B2BServiceHostChangeDetailsRQ,
  B2BServiceHostRQ,
  B2BServiceHostRS,
  B2BServiceOwnerRS,
  B2BServiceRQ,
  B2BServiceRS,
  B2BServiceUpdateRQ,
  B2BSrvExportToJson,
  B2C2ConsumerRequestRS,
  ServiceConfigurationRQ,
  ServiceConfigurationRS,
  ServiceHeaderRQ,
  ServiceHeaderRS,
  ServiceHostRQ,
  ServiceHostRS,
  ServiceOperationRQ,
  ServiceOperationRS,
  B2BEntitlementConfigsRS,
} from './dtos';

export function mapServiceHostCreate(model: ServiceHostCreate): ServiceHostRQ {
  const dto: ServiceHostRQ = {
    data_center: model.dataCenter,
    url: model.url,
    // is_active: model.is_active
  };
  return dto;
}

export function mapServiceOperationCreate(model: ServiceOperationCreate): ServiceOperationRQ {
  const dto: ServiceOperationRQ = {
    name: model.name,
    method_type: model.method,
    internal_path: model.internalPath,
    external_path: model.externalPath,
  };
  return dto;
}

export function mapServiceHostUpdate(model: Partial<ServiceHostUpdate>): Partial<ServiceHostRQ> {
  const dto: Partial<ServiceHostRQ> = {
    data_center: model.dataCenter,
    url: model.url,
    // is_active: model.is_active
  };
  return dto;
}

export function mapServiceOperationUpdate(model: Partial<ServiceOperationUpdate>): Partial<ServiceOperationRQ> {
  const dto: Partial<ServiceOperationRQ> = {
    name: model.name,
    method_type: model.method,
    internal_path: model.internalPath,
    external_path: model.externalPath,
  };
  return dto;
}

export function mapServiceHeaderCreate(model: ServiceHeaderCreate): ServiceHeaderRQ {
  const dto: ServiceHeaderRQ = {
    name: model.name,
    description: model.description,
  };
  return dto;
}

export function mapServiceHeaderUpdate(model: Partial<ServiceHeaderUpdate>): Partial<ServiceHeaderRQ> {
  const dto: Partial<ServiceHeaderRQ> = {
    name: model.name,
    description: model.description,
  };
  return dto;
}

export function mapServiceConfigurationCreate(model: ServiceConfigurationCreate): ServiceConfigurationRQ {
  const dto: ServiceConfigurationRQ = {
    name: model.name,
    description: model.description,
    default_value: typeof model.defaultValue === 'string' ? model.defaultValue as string : JSON.stringify(model.defaultValue),
    data_type: model.dataType,
  };
  return dto;
}

export function mapServiceConfigurationUpdate(model: Partial<ServiceConfigurationUpdate>): Partial<ServiceConfigurationRQ> {
  const dto: Partial<ServiceConfigurationRQ> = {
    name: model.name,
    description: model.description,
    default_value: typeof model.defaultValue === 'string' ? model.defaultValue as string : JSON.stringify(model.defaultValue),
    data_type: model.dataType,
  };
  return dto;
}

export function mapServiceHost(dto: ServiceHostRS): ServiceHost {
  const model: ServiceHost = {
    id: dto.id,
    dataCenter: dto.data_center,
    url: dto.url,
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    modifiedAt: dto.modified_at,
    // is_active: dto.is_active
  };
  return model;
}

export function mapServiceOperation(dto: ServiceOperationRS): ServiceOperation {
  const model: ServiceOperation = {
    id: dto.id,
    name: dto.name,
    method: dto.method_type,
    internalPath: dto.internal_path,
    externalPath: dto.external_path,
    allowedDomains: dto.allowed_domains,
    serviceId: dto.service_id,
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    modifiedAt: dto.modified_at,
  };
  return model;
}

export function mapServiceHeader(dto: ServiceHeaderRS): ServiceHeader {
  const model: ServiceHeader = {
    id: dto.id,
    serviceId: dto.service_id,
    name: dto.name,
    description: dto.description,
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    modifiedAt: dto.modified_at,
  };
  return model;
}

export function mapServiceConfiguration(dto: ServiceConfigurationRS): ServiceConfiguration {
  const model: ServiceConfiguration = {
    id: dto.id,
    serviceId: dto.service_id,
    name: dto.name,
    description: dto.description,
    defaultValue: parseMultiTypeValue(dto.data_type, dto.default_value),
    dataType: dto.data_type || 'string',
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    modifiedAt: dto.modified_at,
  };
  return model;
}

export function parseMultiTypeValue(data_type: MultiDataType, value: string): MultiType {
  try {
    return !data_type || data_type === 'string' ? value as string : JSON.parse(value) as MultiType;
  } catch (e) {
    logger.exception(`Error parsing value: < ${value} > of type < ${data_type} >`, e);
    return '';
  }
}

/*
  Map B2B Service methods
*/

export function mapB2BService(dto: B2BServiceRS): B2BService {
  const model: B2BService = {
    name: dto.name,
    description: dto.description,
    documentationUrl: dto.documentation_url,
    contextRoot: dto.context_root,
    serviceGuid: dto.service_guid,
    status: dto.status,
    operations: dto.operations ? dto.operations.map(mapB2BOperation) : [],
    pendingOperations: dto.pending_operations ? dto.pending_operations.map(mapB2BPendingOperation) : [],
    serviceHosts: dto.service_hosts ? dto.service_hosts.map(mapB2BServiceHost) : [],
    serviceOwners: dto.service_owners ? dto.service_owners.map(mapServiceOwner) : [],
    serviceHeaders: dto.service_headers ? dto.service_headers.map(mapB2BServiceHeader): [],
    outboundEvents: dto.outbound_events ? dto.outbound_events.map(mapB2BOutboundEvent): [],
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    lastModifiedBy: dto.last_modified_by,
    lastModifiedAt: dto.last_modified_at,
    requestGuid:dto.request_guid,
    deploymentRegion: dto.deployment_region,
    autoDC: dto.auto_dc,
    healthEndpoint: dto.health_endpoint,
    entitlementConfigs:mapB2BEntitlementConfigs(dto.entitlement_configs)
  };
  return model;
}

export function mapB2BOperation(dto: B2BOperationRS): B2BOperation {
  const model: B2BOperation = {
    name: dto.name,
    description: dto.description,
    internalPath: dto.internal_path,
    externalPath: dto.external_path,
    methodType: dto.method_type,
    mleType: dto.mle_type,
    operationGuid: dto.operation_guid,
    serviceGuid: dto.service_guid,
    rateLimits: dto.rate_limits ? dto.rate_limits.map(mapB2BRateLimits) : [],
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
    status:dto.status
  };
  return model;
}

export function mapB2BRateLimits(dto: B2BRateLimitsRS): B2BRateLimits {
  const model: B2BRateLimits = {
    name: dto.name,
    type: dto.type,
    numberOfCalls: dto.number_of_calls,
    timeWindow: dto.time_window,
    numberOfClients: dto.number_of_clients,
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
  };
  return model;
}

export function mapB2BServiceHeader(dto: B2BServiceHeaderRS): B2BServiceHeader {
  const model: B2BServiceHeader = {
    serviceHeaderGuid: dto.service_header_guid,
    name: dto.header_name,
    type: dto.value_type,
    required: String(dto.is_required),
    defaultValue: dto.default_header_value,
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
  };
  return model;
}

export function mapB2BOutboundEvent(dto: B2BOutboundEventRS): B2BOutboundEvent {
  const model: B2BOutboundEvent = {
    eventGuid: dto.event_guid,
    eventName: dto.event_name,
    outboundHosts: dto.outbound_hosts.map(mapB2BOutboundHosts),
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
  };
  return model;
}

export function mapB2BOutboundHosts(dto: B2BOutboundHostRS): B2BOutboundHost {
  const model: B2BOutboundHost = {
    outboundHostGuid: dto.outbound_host_guid,
    outboundEventGuid: dto.outbound_event_guid,
    is_active: dto.is_active,
    callbackUrl: dto.callback_url,
    dataCenter: dto.host_type,
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
  };
  return model;
}

export function mapB2BPendingApplicationOperationMap(dto: B2BPendingApplicationOperationMapRS): B2BPendingApplicationOperationMap {
  const model: B2BPendingApplicationOperationMap = {
    applicationOperationGuid: dto.application_operation_guid,
    operationName: dto.operation_name,
    operationGuid: dto.operation_guid,
    internalPath: dto.internal_path,
    externalPath: dto.external_path,
    methodType: dto.method_type,
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
  };
  return model;
}

export function mapB2BPendingOperation(dto: B2BPendingOperationRS): B2BPendingOperation {
  const model: B2BPendingOperation = {
    name: dto.name,
    description: dto.description,
    internalPath: dto.internal_path,
    externalPath: dto.external_path,
    methodType: dto.method_type,
    mleType: dto.mle_type,
    pendingOperationGuid: dto.operation_guid,
    requestGuid: dto.request_guid,
    rateLimits: dto.rate_limits ? dto.rate_limits.map(mapB2BRateLimits) : [],
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
    operationGuid: dto.operation_guid
  };
  return model;
}

export function mapB2BServiceHost(dto: B2BServiceHostRS): B2BServiceHost {
  const model: B2BServiceHost = {
    url: dto.url,
    dataCenter: dto.host_type,
    serviceHostGuid: dto.service_host_guid,
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
    is_active: dto.is_active
  };
  return model;
}

export function mapServiceOwner(dto: B2BServiceOwnerRS): B2BServiceOwner {
  const model: B2BServiceOwner = {
    serviceOwnerGuid: dto.service_owner_guid,
    subjectName: dto.subject_name,
    createdBy: dto.created_by,
    lastModifiedBy: dto.last_modified_by,
    createdAt: dto.created_at,
    lastModifiedAt: dto.last_modified_at,
  };
  return model;
}

export function mapB2BServiceCreate(model: B2BServiceCreate): B2BServiceRQ {
  const dto: B2BServiceRQ = {
    name: model.name,
    description: model.description || '',
    documentation_url: model.documentationUrl || '',
    context_root: model.contextRoot || '',
    service_hosts: model.serviceHosts.map(mapB2BServiceHostCreate),
    operations: model.operations.map(mapB2BOperationCreate),
    service_owners: model.serviceOwners,
    service_headers: model.serviceHeaders!.map(mapB2BServiceHeadersCreate),
    deployment_region: model.deploymentRegion,
    outbound_events: model.outboundEvents?.map(mapB2BOutboundEventsCreate),
    auto_dc: model.autoDC,
    health_endpoint:model.healthEndpoint,
    entitlement_configs:mapB2BEntitlementConfigsCreate(model.entitlementConfigs)
  };
  return dto;
}

export function mapB2BServiceHostCreate(model: B2BServiceHostCreate): B2BServiceHostRQ {
  const dto: B2BServiceHostRQ = {
    host_type: model.dataCenter,
    url: model.url,
    is_active: model.is_active
  };
  return dto;
}

export function mapB2BOutboundHostsCreate(model: B2BOutboundHostCreate): B2BOutboundHostRQ {
  const dto: B2BOutboundHostRQ = {
    is_active: model.is_active,
    callback_url: model.callbackUrl,
    host_type: model.dataCenter,
  };
  return dto;
}

export function mapB2BOutboundEventsCreate(model: B2BOutboundEventsCreate): B2BOutboundEventRQ {
  const dto: B2BOutboundEventRQ = {
    event_name: model.eventName,
    outbound_hosts: model.outboundHosts.map(mapB2BOutboundHostsCreate),
  };
  return dto;
}

export function mapB2BEntitlementConfigs(dto: B2BEntitlementConfigsRS | undefined): B2BEntitlementConfigs {
  const model: B2BEntitlementConfigs = {
    productProvisioningType: dto?.product_provisioning_type ? dto.product_provisioning_type : "",
    clientOnboardingCallBackUrl: dto?.client_onboarding_callback_url ?  dto.client_onboarding_callback_url : "",
  };
  return model;
}

export function mapB2BEntitlementConfigsCreate(model: B2BEntitlementConfigCreate | undefined): B2BEntitlementConfigsRQ | undefined {
  if(model!=undefined) {
  const dto: B2BEntitlementConfigsRQ = {
    product_provisioning_type: model.productProvisioningType,
    client_onboarding_callback_url: (model.clientOnboardingCallBackUrl?.length>0 ? model.clientOnboardingCallBackUrl : undefined),
  };
  return dto;
}
return undefined;
}

export function mapB2BServiceHeadersCreate(model: B2BServiceHeadersCreate): B2BServiceHeaderRQ {
  const dto: B2BServiceHeaderRQ = {
    header_name: model.name,
    value_type: model.type,
    is_required: Boolean(model.required === "true"),
    default_header_value: model.defaultValue,
  };
  return dto;
}

export function mapB2BOperationCreate(model: B2BOperationCreate): B2BOperationRQ {
  const dto: B2BOperationRQ = {
    name: model.name,
    method_type: model.methodType,
    mle_type: model.mleType,
    internal_path: model.internalPath,
    external_path: model.externalPath,
    description: model.description,
    rate_limits: model.rateLimits? model.rateLimits.map(mapB2BRateLimitsCreate) : undefined,
  };
  return dto;
}

export function mapB2BRateLimitsCreate(model: B2BRateLimitCreate): B2BRateLimitsRQ {
  const dto: B2BRateLimitsRQ = {
    name: model.name,
    type: model.type,
    number_of_calls: model.numberOfCalls,
    time_window: model.timeWindow,
    number_of_clients: model.numberOfClients,
  };
  return dto;
}

export function mapB2BOutboundEventsUpdate(model: B2BOutboundEventsUpdate): B2BOutboundEventRQ {
  const dto: B2BOutboundEventRQ = {
    event_name: model.eventName,
    outbound_hosts: model.outboundHosts.map(mapB2BOutboundHostsCreate),
  };
  return dto;
}

export function mapB2BServiceUpdate(model:  Partial<B2BServiceUpdate>):  Partial<B2BServiceUpdateRQ> {
  const dto: Partial<B2BServiceUpdateRQ> = {
    name: model.name,
    description: model.description,
    documentation_url: model.documentationUrl,
    context_root: model.contextRoot,
    service_hosts: model.serviceHosts ? model.serviceHosts.map(mapB2BServiceHostUpdate) : undefined,
    service_owners: model.serviceOwners,
    service_headers: model.serviceHeaders ? model.serviceHeaders.map(mapB2BServiceHeadersCreate) : undefined,
    outbound_events: model.outboundEvents ? model.outboundEvents?.map(mapB2BOutboundEventsUpdate) : undefined,
    status: model.status,
    deployment_region: model.deploymentRegion,
    auto_dc: model.autoDC,
    health_endpoint: model.healthEndpoint,
    change_details: model.changeDetails ? mapB2BServiceHostChangeDetailsUpdate(model.changeDetails): undefined,
    entitlement_configs: model.entitlementConfigs ? mapB2BEntitlementConfigsCreate(model.entitlementConfigs) : undefined
  };
  return dto;
}

export function mapB2BServiceHostChangeDetailsUpdate(model: B2BServiceHostChangeDetails): B2BServiceHostChangeDetailsRQ {
  const dto: B2BServiceHostChangeDetailsRQ = {
    change_request_number: model.changeRequestNumber,
    change_justification: model.changeJustification
  };
  return dto;
}

export function mapB2BServiceHostUpdate(model: Partial<B2BServiceHostUpdate>): Partial<B2BServiceHostRQ> {
  const dto: Partial<B2BServiceHostRQ> = {
    host_type: model.dataCenter,
    url: model.url,
    is_active: model.is_active
  };
  return dto;
}

export function mapB2BOperationUpdate(model: Partial<B2BOperationUpdate>): Partial<B2BOperationRQ> {
  const dto: Partial<B2BOperationRQ> = {
    name: model.name,
    method_type: model.methodType,
    mle_type: model.mleType,
    internal_path: model.internalPath,
    external_path: model.externalPath,
    description: model.description,
    rate_limits: model.rateLimits ? model.rateLimits.map(mapB2BRateLimitsUpdate) : [],
  };
  return dto;
}

export function mapB2BRateLimitsUpdate(model: B2BRateLimitUpdate): B2BRateLimitsRQ {
  const dto: B2BRateLimitsRQ = {
    name: model.name,
    type: model.type,
    number_of_calls: model.numberOfCalls,
    time_window: model.timeWindow,
    number_of_clients: model.numberOfClients,
  };
  return dto;
}

export function mapB2BSrvToJson(model: B2BServiceUpdate): B2BSrvExportToJson {
  const dto: B2BSrvExportToJson = {
    name: model.name,
    description: model.description,
    documentation_url: model.documentationUrl,
    context_root: model.contextRoot,
    deployment_region: model.deploymentRegion,
    service_hosts: model.serviceHosts ? model.serviceHosts.map(mapB2BServiceHostUpdate) : undefined,
    outbound_events: model.outboundEvents ? model.outboundEvents.map(mapB2BOutboundEventsUpdate) : undefined,
    operations: model.operations ? model.operations.map(mapB2BOperationUpdate) : undefined,
    service_headers:model.serviceHeaders ? model.serviceHeaders.map(mapB2BServiceHeadersCreate):undefined,
    service_owners: model.serviceOwners,
    status: model.status,
    auto_dc: model.autoDC,
    health_endpoint: model.healthEndpoint
  };
  return dto;
}

export function mapB2BServiceHostRQ(dto: B2BServiceHostRQ): B2BServiceHostCreate {
  const model: B2BServiceHostCreate = {
    dataCenter: dto.host_type,
    url: dto.url,
    is_active: dto.is_active
  };
  return model;
}

export function mapB2BRateLimitsRQ(dto: B2BRateLimitsRQ): B2BRateLimitCreate {
  const model: B2BRateLimitCreate = {
    name: dto.name,
    type: dto.type,
    numberOfCalls: dto.number_of_calls,
    timeWindow: dto.time_window,
    numberOfClients: dto.number_of_clients,
  };
  return model;
}

export function mapB2BOperationRQ(dto: B2BOperationRQ): B2BOperationCreate {
  const model: B2BOperationCreate = {
    name: dto.name,
    mleType:dto.mle_type,
    methodType: dto.method_type,
    internalPath: dto.internal_path,
    externalPath: dto.external_path,
    description: dto.description,
    rateLimits: dto.rate_limits ? dto.rate_limits.map(mapB2BRateLimitsRQ) : [],
  };
  return model;
}

export function mapB2BServiceRQ(dto: B2BServiceRQ): B2BServiceCreate {
  const model: B2BServiceCreate = {
    name: dto.name,
    description: dto.description,
    documentationUrl: dto.documentation_url,
    contextRoot: dto.context_root,
    serviceOwners: dto.service_owners,
    serviceHosts: dto.service_hosts ? dto.service_hosts.map(mapB2BServiceHostRQ) : [],
    operations: dto.operations ? dto.operations.map(mapB2BOperationRQ) : [],
    deploymentRegion: dto.deployment_region,
    autoDC: dto.auto_dc,
    healthEndpoint: dto.health_endpoint
  };
  return model;
}

export function mapB2BApprovalRequest(dto: B2BApprovalRequestRS): B2BApprovalRequest {
  const model: B2BApprovalRequest = {
    createdBy: dto.created_by,
    createdAt: dto.created_at,
    lastModifiedBy: dto.last_modified_by,
    lastModifiedAt: dto.last_modified_at,
    reviewerSubjectName: dto.reviewer_subject_name,
    saNumber: dto.sa_number,
    businessJustification: dto.business_justification,
    sendBackMessage:dto.sent_back_message,
    requestGuid: dto.request_guid,
    requestorSubjectName: dto.requestor_subject_name,
    requestType: dto.request_type,
    requestStatus: dto.request_status,
    applicationOperationMap: dto.application_operation_map ? dto.application_operation_map.map(mapB2BPendingApplicationOperationMap) : [],
    operations: dto.operations ? dto.operations.map(mapB2BPendingOperation) : [],
    approvalRequestEntity: {
      name: dto.approval_request_entity?.name,
      description: dto.approval_request_entity?.description,
      documentationUrl: dto.approval_request_entity?.documentation_url,
      contextRoot: dto.approval_request_entity?.context_root,
      serviceGuid: dto.approval_request_entity?.service_guid,
      applicationGuid: dto.approval_request_entity?.application_guid,
      status: dto.approval_request_entity?.status,
      operations: dto.approval_request_entity?.operations ? dto.approval_request_entity?.operations.map(mapB2BOperation) : [],
      serviceHosts: dto.approval_request_entity?.service_hosts ? dto.approval_request_entity?.service_hosts.map(mapB2BServiceHost) : [],
      serviceOwners: dto.approval_request_entity?.service_owners ? dto.approval_request_entity?.service_owners.map(mapServiceOwner) : [],
      createdBy: dto.approval_request_entity?.created_by,
      createdAt: dto.approval_request_entity?.created_at,
      lastModifiedBy: dto.approval_request_entity?.last_modified_by,
      lastModifiedAt: dto.approval_request_entity?.last_modified_at,
    },
  }
  return model;
}

export function mapApprovalRequestUpdate(model: B2BApprovalRequestUpdate): B2BApprovalRequestUpdateRQ {
  const dto: B2BApprovalRequestUpdateRQ = {
    sa_number: model.saNumber || '',
    business_justification: model.businessJustification || '',
    request_status: model.requestStatus,
    send_back_message: model.sendBackMessage || '',
  };

  if(!model.sendBackMessage) {
    delete dto.send_back_message;
  }
  return dto;
}

export function mapReviewRequestUpdate(model: B2BReviewRequestUpdate): B2BReviewRequestUpdateRQ {
  const dto: B2BReviewRequestUpdateRQ = {
    sa_number: model.saNumber,
    business_justification: model.businessJustification,
    request_guid: model.requestGuid
  };
  return dto;
}

export function mapB2BReviewRequest(dto: B2BReviewRequestRS): B2BServiceReviewRequest {
  const model: B2BServiceReviewRequest = {
    saNumber: dto.sa_number,
    businessJustification: dto.business_justification,
    requestGuid: dto.request_guid,
    requestStatus: dto.request_status,
  }
  return model;
}

export function mapB2C2ConsumerRequest(dto: B2C2ConsumerRequestRS): B2C2ConsumerRequestRS {
  const model: B2C2ConsumerRequestRS = {
    appId: dto.appId,
    requestId: dto.requestId,
    status: dto.status,
    subjectId: dto.subjectId
  }
  return model;
}
import { makeStyles, Theme } from '@material-ui/core';

export const useProfileStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(4),
    width: 128,
    height: 128,
  },
  title: {
    color: '#2A2E69'
  },
  itemTitle: {
    marginLeft: "8px",
    fontSize: theme.typography.pxToRem(14),
    color: "#222222"
  },
  itemDetail: {
    fontWeight: "normal",
    fontSize: theme.typography.pxToRem(14),
    color: "#222222"
  },
  divider: {
    backgroundColor: "#F2F4F8",
    height: "7px",
  },
  helpBox: {
    backgroundColor: "#ECECE8",
    padding: theme.spacing(8),
    marginTop: "25px",
    fontWeight: "normal",
  }
}));

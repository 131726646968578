import React, { ChangeEvent, useState } from "react"
import { serviceHeadersType } from "../../../../../../services/model/service";
import Select from '@visa/vds/select';
import { Table } from "@tanstack/react-table";
import { Row } from "../../../../../../products/views/product-edit/product-edit-headers";

export const HeaderTypeCell: React.FC<Table<Row>> = (table) => {
    const [headerTypeError, setHeaderTypeError] = useState<string>("");
    const headersTypeMap = serviceHeadersType.reduce<Record<string, string>>(
        (acc, headerType) => ({ ...acc, [headerType]: headerType }),
        {}
    );
    return <Select id={"header_type"}
        label="Header Type *"
        fullWidth
        value={table.options.meta?.dirtyRow?.dataType || ''}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setHeaderTypeError(table.options.meta?.isIndividualCellValid("dataType", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "dataType");
        }}
        showErrorText={!!headerTypeError}
        errorText={headerTypeError}
        invalid={!!headerTypeError}
    >
        <option value="" disabled> Select Header Type</option>
        {Object.entries(headersTypeMap).map(([key, value]) => <option key={key} value={value}>{key}</option>)}
    </Select>
}
import { useBlockingLoading } from '../../../hooks/use-blocking-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { useDispatch } from '../../../state/app-store';
import { networkAppsNewSrv } from '../services/network-apps.service';
import { appsActions } from '../state/apps-actions';
import { B2BReviewRequestUpdate } from '../model/application-update';
import { B2C2ReviewRequestUpdate } from '../../products/model/product-update';

export interface UseB2C2AppReviewRequestResult {
  loading: boolean;
  createB2C2AppReviewRequestAsync: (applicationGuid: string, b2c2ReviewRequest: B2C2ReviewRequestUpdate) => Promise<boolean>;
}

export function B2C2AppReviewRequestUpdate(): UseB2C2AppReviewRequestResult {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useBlockingLoading();
  const { pushNotification } = useNotifications();

  const createB2C2AppReviewRequestAsync = async (applicationGuid: string, b2c2ReviewRequest: B2C2ReviewRequestUpdate): Promise<boolean> => {
    startLoading();
    try {
      const res = await networkAppsNewSrv.createB2C2AppReviewRequestAsync(applicationGuid, b2c2ReviewRequest);
      dispatch(appsActions.b2c2ReviewRequest(res));
      pushNotification({
        message: `Update B2C Application Review Requests succeeded.`,
        variant: 'success',
        type: 'bar',
      });
      return true;
    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      pushNotification({
        message: `Update B2C Application Review Requests failed with '${error}'. Please, try again later.`,
        variant: 'error',
        type: 'bar',
      });
      return false;
    } finally {
      stopLoading();
    }
  };

  return {
    loading,
    createB2C2AppReviewRequestAsync,
  };
}

import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { Row } from "../../../../app-edit/b2c2-app-edit-b2c2b-auth";
import { X_PAY_TOKEN } from "../../../../../constants";
import { b2c2ApplicationB2C2BArgsAttrSchema, b2c2ApplicationB2C2BArgsAttributeSchema } from "../../../../../model/b2c2-application-validations";
import { ValidationError } from 'yup';

export const SharedSecretEdit: React.FC<Table<Row>> = (table) => {
    const [sharedSecretError, setSharedSecretError] = useState<string>("");


    if (table.options.meta?.dirtyRow?.type === X_PAY_TOKEN) {
        return <Input
            style={{ height: "unset" }}
            errorText={sharedSecretError}
            label="Shared Secret *"
            name={"sharedSecret"}
            value={table.options.meta?.dirtyRow?.attr?.sharedSecret || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {            
                let attribute = {...table.options?.meta?.dirtyRow?.attr,"sharedSecret": e.target.value}
                try{
                    b2c2ApplicationB2C2BArgsAttributeSchema.validateSyncAt("sharedSecret", {"sharedSecret": e.target.value})
                    setSharedSecretError("");
                }catch (e) {
                    setSharedSecretError((e as ValidationError).message);
                  }
                table.options?.meta?.setEditCelldata("", attribute, "attr");
            }}
            showErrorText={!!sharedSecretError}
            invalid={!!sharedSecretError}
        ></Input>
    } else if (!table.options.meta?.dirtyRow?.type) {
        return <></>;
    } else {
        return <div>N/A</div>;
    }
}
import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { ProductEndpointUpdate } from "../../../../../../products/model/product-update";

export const EndpointInternalPathCell: React.FC<Table<ProductEndpointUpdate>> = (table) => {
    const [internalPathError, setInternalPathError] = useState<string>("");
    return <Input
        style={{ height: "unset" }}
        errorText={internalPathError}
        label="Internal Path *"
        name={"internal_path"}
        value={table.options.meta?.dirtyRow?.internalPath || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setInternalPathError(table.options.meta?.isIndividualCellValid("internalPath", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "internalPath");
        }}
        onBlur={()=>setInternalPathError(table.options?.meta?.isIndividualCellValid("internalPath",table.options.meta.dirtyRow?.internalPath) || "")}
        showErrorText={!!internalPathError}
        invalid={!!internalPathError}
    ></Input>
}
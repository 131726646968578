import { Table } from "@tanstack/table-core"
import React, { ChangeEvent, useState } from "react"
import Tr from '@visa/vds/tr';
import Td from '@visa/vds/td';
import Select from '@visa/vds/select';
import { vdsStyleOverrides } from "../../../../../../vds-overrides.styles";
import { TableFormAddCancelAction } from "../../table-cells/b2c2-apps/table-form-add-cancel";
import { appConfigValues } from "../../../../../appsNew/model/application-create";

export const B2C2AppsAddConfig: React.FC<Table<any>> = (table) => {
    const options: any[] = table.options.meta?.data || [];
    const vdsStyles = vdsStyleOverrides();
    const [configKeyError, setConfigKeyError] = useState<string>("");
    const [valueError, setValueError] = useState<string>("");
    return <Tr >
        <Td>
            <Select
                name="Key"
                label="Key *"
                value={table.options.meta?.dirtyRow?.configKey}
                className={vdsStyles.selectFullWidth}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    table.options?.meta?.setEditCelldata("", e.target.value, "configKey");
                    setConfigKeyError(table.options?.meta?.isIndividualCellValid("configKey", e.target.value) || "")
                }}
                onBlur={(e: ChangeEvent<HTMLSelectElement>) => {
                    setConfigKeyError(table.options?.meta?.isIndividualCellValid("configKey", e.target.value) || "")
                }}
                showErrorText={!!configKeyError}
                errorText={configKeyError}
                invalid={!!configKeyError}
                aria-required
            >
                <option value="" disabled defaultChecked>Select Key</option>
                {options.sort().map(config => (
                    <option key={config.configKey} value={config.configKey}>{config.configKey}</option>
                ))}
            </Select>
        </Td>
        <Td>
            <Select
                name="Value"
                label="Value *"
                value={table.options.meta?.dirtyRow?.configValue}
                className={vdsStyles.selectFullWidth}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    table.options?.meta?.setEditCelldata("", e.target.value, "configValue");
                    setValueError(table.options?.meta?.isIndividualCellValid("configValue", e.target.value) || "")
                }}
                onBlur={(e: ChangeEvent<HTMLSelectElement>) => {
                    setValueError(table.options?.meta?.isIndividualCellValid("configValue", e.target.value) || "")
                }}
                showErrorText={!!valueError}
                errorText={valueError}
                invalid={!!valueError}
                aria-required
            >
                <option value="" disabled defaultChecked>Select Value</option>
                {appConfigValues.sort().map(appConfigValue => (
                    <option key={appConfigValue.key} value={appConfigValue.value}>{appConfigValue.value}</option>
                ))}
            </Select>
        </Td>
        <Td>
            <TableFormAddCancelAction {...table}></TableFormAddCancelAction>
        </Td>
    </Tr>
}
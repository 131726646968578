import React, {useCallback} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {DashboardDialogStyles, DashboardDialogTitleStyles} from "./dashboard-dialog.styles";
import {DialogActions, DialogContent, Grid} from "@material-ui/core";
import {buttonStyles} from "../../../../styles/button.styles";
import clsx from "clsx";


export default function DashboardDialogs() {
    const styles = DashboardDialogStyles();
    const btnStyles = buttonStyles();
    const [dialog, setDialog] = React.useState(1);
    const handleClose = useCallback(() => {
        setDialog(0);
    }, []);
    const handleNext = useCallback(() => {
        setDialog(2);
    }, []);
    const handleBack = useCallback(() => {
        setDialog(1);
    }, []);

    const DialogTitle = DashboardDialogTitleStyles((props: any) => {
        const {children, classes, onClose, ...other} = props;
        return (
            <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    return (
        <div>
            <Dialog onClose={handleClose}  open={dialog === 1}>
                <DialogTitle id="dialog-title" onClose={handleClose}>
                    <b>Welcome to the Prototype Validation Platform</b>
                </DialogTitle>
                <DialogContent >
                    <Typography variant="body1">
                        You have been granted Super Developer role and have access to the following activities:
                    </Typography>
                    <Typography  variant="body1">
                        <ul>
                            <li><b>Create new apps and view existing apps</b></li>
                            <li><b>Create new services and view existing services</b></li>
                        </ul>
                    </Typography>
                    <Typography  variant="body1">
                        If you want to learn more about the different admin roles and/or want additional admin access
                        please visit the Help page.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container component="main" className={styles.dialogSlide}>
                        <Grid component="div" item sm={9} md={10} >
                            <Typography variant="body1" >1 of 2</Typography>
                        </Grid>
                        <Grid item sm={3} md={2} >
                            <Button autoFocus onClick={handleNext} type="submit"
                                variant="outlined"
                                className={clsx(btnStyles.btnBlue, styles.navBtn)}>
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog onClose={handleClose} open={dialog === 2}>
                <DialogTitle id="dialog-title-2" onClose={handleClose}>
                    <b>Getting started</b>
                </DialogTitle>
                <DialogContent>
                    <Typography component="p" variant="body1">
                        From the Dashboard you can view all existing apps and services on this portal.
                    </Typography>
                    <br/>
                    <Typography component="p" variant="body1">
                        To create a new app or service simply click on the "+ Add" button and follow the directions. You
                        can visit our Help page for more details on how to create a new app or service.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container component="main" className={styles.dialogSlide}>
                        <Grid component="div" item sm={5} md={7} >
                            <Typography variant="body1" >2 of 2</Typography>
                        </Grid>
                        <Grid item sm={7} md={5} >
                        <Button autoFocus onClick={handleBack}
                            type="submit" variant="outlined"
                            className={clsx(btnStyles.btnBlue, styles.navBtn)}>
                            Back
                        </Button>
                        <Button autoFocus onClick={handleClose}
                            type="submit"
                            variant="outlined"
                            className={clsx(btnStyles.btnBlue, styles.closeBtn)}>
                            Got it!
                        </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const config = {
  paths: {
    home: '/',
    login: '/auth/login',
  },
  urls: {
    auth: {
      authority: process.env.PUBLIC_URL,
    },
    user: process.env.PUBLIC_URL + "/adminservice/api/v1/users",
    b2bApps: process.env.PUBLIC_URL + "/adminservice/api/v1/b2b/applications",
    b2bServices: process.env.PUBLIC_URL + "/adminservice/api/v1/b2b/services",
    b2bApprovalRequests: process.env.PUBLIC_URL + "/adminservice/api/v1/b2b/approval-requests",
    b2bProvisioningContracts: process.env.PUBLIC_URL + "/adminservice/api/v1/b2b/provisioning",
    b2c2Apps: process.env.PUBLIC_URL + "/adminservice/b2c/api/v2/applications",
    products: process.env.PUBLIC_URL + "/adminservice/b2c/api/v2/products",
    b2cApprovalRequests: process.env.PUBLIC_URL + "/adminservice/b2c/api/v2/admin-requests",
    b2cConsumerRequests: process.env.PUBLIC_URL + "/adminservice/b2c/api/v2/consumer-requests",
    b2cCredentials: process.env.PUBLIC_URL + "/adminservice/b2c/api/v2/credentials",
  }, 
  externalUrls: {
    askNowRequestInnovationRole: "https://visaasknow.service-now.com/sp/?id=sc_cat_item&sys_id=f56ede6fdb65470811dd58a8dc9619ab",
    createSupportTicket: "https://issues.trusted.visa.com/secure/CreateIssue!default.jspa",
    visaWikiAdminRoles: "https://visawiki.trusted.visa.com/display/ICABP/How+to+for+VPVP+admin+portal#HowtoforVPVPadminportal-RolesDefinition:",
    visaWikiRequestRole: "https://visawiki.trusted.visa.com/display/ICABP/How+to+for+VPVP+admin+portal#HowtoforVPVPadminportal-Stepstorequestaccess:",
    visaWikiCreateApp: "https://visawiki.trusted.visa.com/display/ICABP/How+to+for+VPVP+admin+portal#HowtoforVPVPadminportal-VideoTutorialforCreatingappandService:",
    visaWikiCreateService: "https://visawiki.trusted.visa.com/display/ICABP/How+to+for+VPVP+admin+portal#HowtoforVPVPadminportal-VideoTutorialforCreatingappandService:",
    visaWikiAccessAppsAndServices: "https://visawiki.trusted.visa.com/display/ICABP/How+to+for+VPVP+admin+portal#HowtoforVPVPadminportal-Accessyourservicesasbelow:"
  },
  logger: {
    cal_url: "",
    external_client_id: "",
    merchant_api_key: ""
  }
};
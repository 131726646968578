import { TokenData, TokensDto } from '../model/tokens.dto';
import { User } from '../model/user';

export function getUserFromTokens(tokensInfo: TokensDto): User {
  const tokenData = getTokenData(tokensInfo.access_token);
  return {
    access_token: tokensInfo.access_token,
    profile: {
      username: tokensInfo.username,
      first_name: tokensInfo.first_name,
      last_name: tokensInfo.last_name,
      owned_app_ids: tokensInfo.owned_app_ids,
    },
    expires_at: tokenData.exp,
    expires_in: tokenExpiresIn(tokenData.exp),
    expired: tokenExpired(tokenData.exp),
    scopes: tokensInfo.groups,
  };
}

export function getCalculatedUser(user: User): User {
  return {
    ...user,
    expires_in: tokenExpiresIn(user.expires_at),
    expired: tokenExpired(user.expires_at),
  };
}

function getTokenData(idToken: string): TokenData {
  let tokenInfoEncoded = idToken.split('.')[1]
  let tokenDecoded = atob(tokenInfoEncoded)
  return JSON.parse(tokenDecoded) as TokenData;
}

function tokenExpiresIn(expires: number): number {
  let expirationInMillis = expires * 1000;
  let currentTimeInMillis = new Date().getTime();
  let secondsLeft = (expirationInMillis - currentTimeInMillis) / 1000;
  return secondsLeft;
}

function tokenExpired(expires: number): boolean {
  return tokenExpiresIn(expires) < 10;
}

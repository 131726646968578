import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlockingLoading } from '../../../components/blocking-loading/blocking-loading';
import { config } from '../../../config';
import { useLogout } from '../../../lib/oidc-client-react';
import { loginStyles } from './login/login.styles';

export const Logout: React.FC = () => {
  const styles = loginStyles();
  const navigate = useNavigate();
  const { loggedOut } = useLogout();
  useEffect(() => {
    if (loggedOut) {
      navigate(config.paths.login);
    }
  }, [loggedOut, navigate]);
  return (
    <div className={styles.center}>
      <BlockingLoading open={loggedOut} />
      <Typography component="h4" variant="subtitle1">Signing out...</Typography>
    </div>
  );
}

import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlockingLoading } from '../../../components/blocking-loading/blocking-loading';
import { config } from '../../../config';
import { useNotifications } from '../../../hooks/use-notifications';
import { useLoginCallback } from '../../../lib/oidc-client-react';
import { redirectUrlSrv } from '../../../services/redirect-url.service';
import { loginStyles } from './login/login.styles';

export const AuthCallback: React.FC = () => {
  const styles = loginStyles();
  const { loading, authenticated, error } = useLoginCallback();
  const navigate = useNavigate();
  const { pushNotification } = useNotifications();

  useEffect(() => {
    if (authenticated) {
      const redirectUrl = redirectUrlSrv.getLocation();
      navigate(redirectUrl);
    } else if (error) {
      const errorMessage = error.message ? error.message : 'Authentication failed.';
      pushNotification({
        message: errorMessage,
        variant: 'error',
        type: 'bar',
      });
      navigate(config.paths.login);
    }
  }, [authenticated, error, navigate, pushNotification]);
  return (
    <div className={styles.center}>
      <BlockingLoading open={loading} />
      <Typography component="h4" variant="subtitle1">Authorizing ...</Typography>
    </div>
  );
}

import React, { createContext, useContext, useReducer, Reducer, ReducerState, Dispatch, ReducerAction } from 'react';
import { GlobalState, initialGlobalState, globalReducer } from './global-reducer';
import { Action } from './actions';

const defaultValue: [ReducerState<Reducer<GlobalState, Action>>, Dispatch<ReducerAction<Reducer<GlobalState, Action>>>] = [
  initialGlobalState, () => {}
];
export const StateContext = createContext(defaultValue);

interface StoreProviderProps {
  reducer: Reducer<GlobalState, Action>;
  initialState: GlobalState;
  children: React.ReactNode;
}

const StoreProvider = ({ reducer, initialState, children }: StoreProviderProps) => (
  <StateContext.Provider
    value={useReducer(reducer, initialState)}
    children={children}
  />
);

export const GlobalStoreProvider = ({ children }: { children: React.ReactNode }) => (
  <StoreProvider reducer={globalReducer} initialState={initialGlobalState}>{children}</StoreProvider>
);

export const useGlobalStore = () => useContext(StateContext);

export const useGlobalDispatch = () => useGlobalStore()[1];

import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ProductB2C2BArgsCreate } from '../../model/product-create';
import { useProductPreviewStyles } from "../product-view/product-preview.styles";
import { ProductPreviewB2C2BArgsPlaceholder } from './product-preview-b2c2b-args-placeholder';
import { useMediaQuery, useTheme } from '@mui/material';
export interface ProductPreviewB2C2BArgsProps {
    productB2C2BArgs?: ProductB2C2BArgsCreate;
}

export const ProductPreviewB2C2BArgs: React.FC<ProductPreviewB2C2BArgsProps> = ({ productB2C2BArgs }) => {
  const styles = useProductPreviewStyles();
  const theme = useTheme();
  const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));
  if (!productB2C2BArgs) {
    return (
      <ProductPreviewB2C2BArgsPlaceholder />
    );
  }
    return (
        
          <Grid container direction={mediaCheck ? 'column' : 'row'} spacing={3}>
            <Grid item xs={12} lg={2}><b>Use VP2 Connector</b></Grid>
            <Grid item xs={12} lg={10}>{productB2C2BArgs?.useVp2Connector ? 'Yes' : 'No'}</Grid>
            {productB2C2BArgs?.useVp2Connector ?
            <>
              <Grid item xs={12} lg={2}><b>Business</b></Grid>
              <Grid item xs={12} lg={10}>{productB2C2BArgs?.business}</Grid>
            </> : <></>}
          </Grid>
      
  );
};

import { UserRole } from '../model/user-role';
import { useUserProfile } from './use-user-profile.hook';

export enum UserPermission {
  Pending = 0,
  Unknown = 1,
  Denied = 2,
  Granted = 3,
}

export function useHasPermission(role: UserRole): UserPermission {
  const { userProfile, loading } = useUserProfile();
  if (loading) {
    return UserPermission.Pending;
  } else if (!userProfile) {
    return UserPermission.Unknown;
  } else {
    return (userProfile.roles.has(role))? UserPermission.Granted : UserPermission.Denied;
  }
}

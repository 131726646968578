import { Box, Button, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormsStyles } from '../../../../styles/forms.styles';
import { buttonStyles } from '../../../../styles/button.styles';
import clsx from 'clsx';
import { useUserProfile } from '../../../auth/hooks/use-user-profile.hook';
import { useB2BServiceDelete } from '../../../services/hooks/use-b2b-service-delete';
import DeleteConfirmDialog from '../../../../views/dialog/delete-confirm-dialog';
import { ProductPreview } from './product-preview';
import { useProduct } from '../../hooks/use-product';
import { useLoading } from '../../../../hooks/use-loading';
import { useB2C2ApprovalRequest } from '../../hooks/use-b2c2-approval-request';
import { Grid } from '@mui/material';
import ButtonText from '@visa/vds/button-text';
import { vdsStyleOverrides } from "../../../../vds-overrides.styles";

export const B2C2ProductView: React.FC = () => {
  const styles = useFormsStyles();
  const btnStyles = buttonStyles();
  const navigate = useNavigate();
  const { productId } = useParams();
  const { currentProduct, loading, activeProduct, pendingProduct } = useProduct(productId);
  const { startLoading } = useLoading();
  const { userProfile } = useUserProfile();
  const { deleteB2BServiceAsync } = useB2BServiceDelete();
  const [deleteDialog, setDeleteDialog] = useState(false);

  const { b2c2ApprovalRequest } = useB2C2ApprovalRequest(currentProduct?.requestId);
  const pendingrequestStatus = useB2C2ApprovalRequest(pendingProduct?.requestId);

  useEffect(() => {
    // if (currentProduct === undefined &&  activeProduct === undefined) {
    //   navigate('/404', { replace: true });
    // }
    startLoading()
  }, [pendingProduct, activeProduct, navigate]);

  const handleBack = useCallback(() => {
    navigate('/b2c/products');
  }, [navigate]);

  const handleEdit = useCallback(() => {
    navigate('edit');
  }, [navigate]);

  //edit-services
  const handleDeleteBtnClick = useCallback(() => {
    setDeleteDialog(true)
  }, [setDeleteDialog]);

  const handleDeleteDialogClose = useCallback(() => setDeleteDialog(false), [setDeleteDialog]);

  const handleDelete = useCallback(async () => {
    const success = await deleteB2BServiceAsync(productId);
    if (success) {
      navigate('/b2c/products');
    } else {
      handleDeleteDialogClose();
    }
  }, [productId, deleteB2BServiceAsync, navigate, handleDeleteDialogClose]);

  const isProductOwner = useCallback(() => {
    return !!currentProduct?.createdBy && !!userProfile?.username && currentProduct.productOwners.some(owner => owner === userProfile.username);
  }, [activeProduct, userProfile]);

  const isPendingProductOwner = useCallback(() => {
    return !!pendingProduct?.createdBy && !!userProfile?.username && pendingProduct.createdBy === userProfile.username;
  }, [pendingProduct, userProfile]);


  return (
    <main>
     <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <ProductPreview product={pendingProduct} productActive={activeProduct} isProductOwner={isProductOwner()} />
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={12} md={12}>
            {(isProductOwner() && isPendingProductOwner()) &&
              <Box display="flex" justifyContent="center" bgcolor="#ECECE8" className={styles.instructionBox} >
                <Typography className={styles.instructionText}>READ ONLY - For access contact the product owner(s).
                </Typography></Box>}
          </Grid>
          <Grid item md={2}>
            <ButtonText isFullWidth onClick={handleBack} colorScheme="primary">
              Back
            </ButtonText>
          </Grid>

          <Grid item  md={4}>
            <Grid container direction='row' columnGap={2}>
              {(isProductOwner() || isPendingProductOwner()) &&
                <><Grid item lg={5}>
                  <ButtonText colorScheme="primary" onClick={handleDeleteBtnClick}
                    isFullWidth>
                    Delete
                  </ButtonText>
                </Grid>
                <Grid lg={5}>
                  <ButtonText colorScheme="primary" disabled={(!currentProduct || b2c2ApprovalRequest?.status === "IN_REVIEW" || pendingrequestStatus.b2c2ApprovalRequest?.status == "IN_REVIEW")} onClick={handleEdit}
                    isFullWidth>
                    Edit
                  </ButtonText>
                </Grid></>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isProductOwner() && deleteDialog && <DeleteConfirmDialog handleDelete={handleDelete} handleClose={handleDeleteDialogClose} isApp={false} />}
    </main>
  );
};

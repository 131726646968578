import { IconButton, SvgIcon } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Icon } from './icon-feedback.svg';
import { DarkBlueTooltip } from '../../../../components/tooltips/tooltips.styles';
import { config } from '../../../../config';
import {  newTabStyles } from '../styled-menu/styled-menu';

export const FeedbackMenu: React.FC = () => {
const newTabStylesBadge= newTabStyles();
    return (
        <div>
            <DarkBlueTooltip placement="bottom" title="Feedback">
                <IconButton
                    aria-label="Feedback (opens a new tab)"
                    aria-controls="user-menu"
                    href={config.externalUrls.createSupportTicket}
                    target="_blank"
                    color="inherit"
                    title={undefined}
                >
                    <div className={newTabStylesBadge.newTabIcon}></div>
                    <SvgIcon>
                        <Icon />
                    </SvgIcon>
                </IconButton>
            </DarkBlueTooltip>
        </div>
    );
};
import { logger } from '../../../services/logger.service';
import { B2C2ApplicationUpdate } from '../model/application-update';
import { b2c2ApplicationCreateSchema } from '../model/b2c2-application-validations';
import { B2C2ApplicationCreate } from '../model/application-create';
import { B2C2ApplicationRQ } from './dtos';
import { mapB2C2ApplicationRQ, mapB2C2ApplicationUpdate } from './model-maps';

const mapWithId = <T>(item: T, index: number): T & { id: number } => ({
  ...item,
  id: -(index + 1),
});

export async function importB2C2AppFromJson(jsonContent: string): Promise<B2C2ApplicationCreate | null> {
  try {
    const b2c2ApplicaionCreate = mapB2C2ApplicationRQ(JSON.parse(jsonContent) as B2C2ApplicationRQ);
    await b2c2ApplicationCreateSchema.validate(b2c2ApplicaionCreate);

    const b2c2ApplicationUpdate: B2C2ApplicationCreate = {
      ...mapWithId(b2c2ApplicaionCreate, 0),
    };
    return  b2c2ApplicationUpdate;
  } catch (ex) {
    logger.exception('Invalid JSON content', ex);
    return null;
  }
}

export function exportB2C2AppToJson(app: B2C2ApplicationUpdate): string {
  const dto = mapB2C2ApplicationUpdate(app);
  return JSON.stringify(dto);
}

export const jsonB2C2App = {
  importB2C2AppFromJson,
  exportB2C2AppToJson,
}

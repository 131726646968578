import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const addNewStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
    },
    content: {
    },
    cardRoot: {
        width: "100%"
    },
    media: {
        height: 100,
    },
    cardContainer: {
        marginTop: theme.spacing(6)
    },
    cardBtn: {
        borderRadius: 0,
        borderWidth: 2,
        color: '#fff',
        backgroundColor: '#003EA9',
        textTransform: 'none',
        fontSize: '20px',
        '&:hover': {
            color: '#003EA9',
            borderColor: '#003EA9'
        },
        width:'100%',
        height: 'auto',
        padding: theme.spacing(0)
    }
}));

import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { ChangeEvent } from "react";
import { B2C2Application } from "../../../model/application";
import { Table } from "@tanstack/react-table";
import { useNavigate } from "react-router";
import Select from '@visa/vds/select';
import ButtonText from '@visa/vds/button-text';
import { SearchBar } from "../common-component/search-bar";
import { vdsStyleOverrides } from "../../../../../vds-overrides.styles";
export interface IB2C2AppListFilter {
    table: Table<any>;
    b2c2Applications: B2C2Application[];
    ownedApps: B2C2Application[];
    dataOption: DataOptionsType;
    changeDataOptions: Function;
    search: string;
    setSearch: Function;
}
export type DataOptionsType = 'all' | 'my';
export const B2C2AppListFilter: React.FC<IB2C2AppListFilter> = ({ b2c2Applications, ownedApps, dataOption, table, changeDataOptions, search, setSearch }) => {
    const theme = useTheme();
    const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));
    const navigate = useNavigate();
    const vdsStyles = vdsStyleOverrides();
    return <Grid container style={{ backgroundColor: "#F2F4F8" }} justifyContent={mediaCheck ? "space-between" : "flex-start"} alignItems={"center"} rowGap={2} padding={1} >
        <Grid item xs={12} lg={6}>
            <Typography variant="h5" component={"h2"} style={{ fontWeight: 500, margin: "8px" }}>
                Total Apps: {b2c2Applications.length}
            </Typography>
            <Typography variant="h5" component={"h2"} style={{ fontWeight: 500, margin: "8px" }}>
                My Apps: {ownedApps.length}
            </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
            <Grid container alignItems={"center"} justifyContent={!mediaCheck ? "flex-end" : undefined} rowGap={2} padding={1} columnGap={2}>
                <Grid item xs={12} lg={4}>
                    <Select
                        name="Application_Filter"
                        label="Application Filter"
                        value={dataOption}
                        className={vdsStyles.selectFullWidth}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            if (e.target.value === "all") {
                                changeDataOptions('all')
                            } else {
                                changeDataOptions('my')
                            }
                        }}
                        defaultValue={"my"}
                    >
                        <option value="all">All applications</option>
                        <option value="my">My applications</option>
                    </Select>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <SearchBar label=" Search B2C Applications" search={search} setSearch={setSearch} ></SearchBar>
                </Grid>
                <Grid item xs={12} lg={2}>
                <ButtonText onClick={() => { navigate('add', { relative: 'path' }); }} isFullWidth>
                    Add  Apps
                </ButtonText>
                </Grid>
            </Grid>
        </Grid>
    </Grid>;
}
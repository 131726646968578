import { logger } from '../../../services/logger.service';
import { b2bServiceCreateSchema } from '../model/service-validations';
import { B2BOperationCreate, B2BServiceCreate, B2BServiceHostCreate } from './../model/service-create';
import { B2BServiceUpdate } from './../model/service-update';
import { B2BServiceRQ } from './dtos';
import { mapB2BServiceRQ, mapB2BSrvToJson } from './model-maps';

const mapWithId = <T>(item: T, index: number): T & { id: number } => ({
  ...item,
  id: -(index + 1),
});

export async function importB2BSrvFromJson(content: string): Promise<B2BServiceCreate | null> {
  try {
    const b2bServiceCreate = mapB2BServiceRQ(JSON.parse(content) as B2BServiceRQ);
    await b2bServiceCreateSchema.validate(b2bServiceCreate);

    const serviceHosts: B2BServiceHostCreate[] = b2bServiceCreate.serviceHosts.map((item, index) => ({
      ...mapWithId(item, index),
    }));
    const operations: B2BOperationCreate[] = b2bServiceCreate.operations.map((item, index) => ({
      ...mapWithId(item, index),
    }));
    const b2bServiceUpdate: B2BServiceCreate = {
      ...mapWithId(b2bServiceCreate, 0),
      serviceHosts,
      operations,
    };
    return b2bServiceUpdate;
  } catch (ex) {
    logger.exception('Invalid JSON content', ex);
    return null;
  }
}

export function exportB2BSrvToJson(service: B2BServiceUpdate): string {
  const dto = mapB2BSrvToJson(service);
  return JSON.stringify(dto);
}

export const jsonB2BService = {
  importB2BSrvFromJson,
  exportB2BSrvToJson,
}

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useProductPreviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
    },
      content: {
          marginBottom: '20px'
      },
      details: {
          fontSize: '14px',
          padding: '10px'
      },
     title: {
          color:"#2A2E69",
          fontSize:"20px",
          fontWeight:600,
          letterSpacing: "-0.5px",
          lineHeight: "28px",
         marginBottom: "30px"
      },
      subTitle: {
          lineHeight: "24px",
          marginBottom: "10px",
          height: "24px",
          size: "16px",
          textAlign: "left",
          color: "#616188"
        },
      divider:  {
          height:"7px",
          margin:"30px",
          backgroundColor:'#F2F4F8'
      },
      dividerB2C: {
          margin: theme.spacing(3, 0, 3, 0),
          variant: "fullWidth",
          height: "7px",
          backgroundColor: '#F2F4F8'
      },
      divider2x:{
        marginTop:"420px",
        variant: "fullWidth",
        height: "7px",
        backgroundColor: '#F2F4F8',
      },
      editButton: {
          height: "48px",
          fontSize: "14px",
          color: "#003EA7",
          borderColor: "#003EA9",
          borderWidth: "2px",
          borderRadius: "0",
          textTransform: "none"
         },
      chipStyle: {
          margin:'4px',
          wordWrap: 'break-word'
      },
      chipStyleRB: {
          margin: theme.spacing(0, 0.4, 0.4, 0),
          wordWrap: 'break-word'
      },
      tableStyle: {
          backgroundColor: "white",
          color: "black",
      },
      operationList: {
          border: '1px solid grey',
          borderRadius: '25px'
      }
}),
);

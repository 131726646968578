import { Reducer } from 'react';
import { appsReducer, AppsState, b2bAppsReducer, B2BAppsState, initialAppsState, initialB2BAppsState, initialProvisioningState, ProvisioningState, provisioningReducer, CredentialDetailState, initialCredentialDetailState, credentialDetailReducer } from '../features/apps/state/apps-reducer';
import { b2c2AppsReducer, B2C2AppsState, initialB2C2AppsState } from '../features/appsNew/state/apps-reducer';
import { b2c2ApprovalRequestsReducer, B2C2ApprovalRequestsState, initialB2C2ApprovalRequestsState, initialProductsState, productsReducer, ProductsState } from '../features/products/state/products-reducer';
import { b2bApprovalRequestsReducer, B2BApprovalRequestsState, initialB2BApprovalRequestsState } from '../features/services/state/services-reducer';
import { initialUserState, usersReducer, UsersState } from '../features/user/state/users-reducer';
import { b2bServicesReducer, B2BServicesState, initialB2BServicesState } from './../features/services/state/services-reducer';
import { Action, types } from './actions';

export interface AppState {
  users: UsersState;
  apps: AppsState;
  b2bapps: B2BAppsState;
  b2bServices: B2BServicesState;
  b2bProvisioning: ProvisioningState;
  credentialDetail: CredentialDetailState;
  b2bApprovalRequests: B2BApprovalRequestsState;
  b2c2ApprovalRequests: B2C2ApprovalRequestsState;
  products: ProductsState,
  b2c2apps: B2C2AppsState
}

export const initialAppState: AppState = {
  users: initialUserState,
  apps: initialAppsState,
  b2bapps: initialB2BAppsState,
  b2c2apps: initialB2C2AppsState,
  b2bServices: initialB2BServicesState,
  b2bProvisioning: initialProvisioningState,
  credentialDetail: initialCredentialDetailState,
  b2bApprovalRequests: initialB2BApprovalRequestsState,
  b2c2ApprovalRequests: initialB2C2ApprovalRequestsState,
  products: initialProductsState
}

export const appReducer: Reducer<AppState, Action> = (state: AppState, action: Action) => {
  return action.type === types.reset
    ? initialAppState
    : {
      users: usersReducer(state.users, action),
      apps: appsReducer(state.apps, action),
      b2bapps: b2bAppsReducer(state.b2bapps, action),
      b2bProvisioning: provisioningReducer(state.b2bProvisioning, action),
      credentialDetail: credentialDetailReducer(state.credentialDetail, action),
      b2bServices: b2bServicesReducer(state.b2bServices, action),
      b2bApprovalRequests: b2bApprovalRequestsReducer(state.b2bApprovalRequests, action),
      b2c2ApprovalRequests: b2c2ApprovalRequestsReducer(state.b2c2ApprovalRequests, action),
      products: productsReducer(state.products, action),
      b2c2apps: b2c2AppsReducer(state.b2c2apps, action)
    };
};

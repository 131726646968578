// import { CalApiLogger } from '@piloting-platform/cal-node';
// import { config } from '../config';

// const apiLogger = new CalApiLogger();

// apiLogger.setup({
//   cal_url: config.logger.cal_url,
//   external_client_id: config.logger.external_client_id,
//   merchant_api_key: config.logger.merchant_api_key,
// });

export enum LogLevel {
  info, error, severe
}

function info(message: string, data: any): void {
  log(message, data, LogLevel.info);
}

function error(message: string, data: any): void {
  log(message, data, LogLevel.error);
}

function log(message: string, data: any, level: LogLevel): void {
  // if (apiLogger.validate()) {
  //   apiLogger.log({
  //     uri: document.location.href,
  //     log_level: level === LogLevel.severe
  //       ? CalApiLogger.LOG_LEVEL.SEVERE
  //       : level === LogLevel.error
  //         ? CalApiLogger.LOG_LEVEL.ERR
  //         : CalApiLogger.LOG_LEVEL.INFO,
  //     message: message,
  //     data: (typeof data === 'string' || typeof data === 'undefined') ? data : JSON.stringify(data),
  //   });
  // } else {
  //   console.log(`[${level}]`, message, data);
  // }
  // TODO: uncomment and remove
  console.log(`[${level}]`, message, data);
}

function exception(message: string, exception: any) {
  // if (apiLogger.validate()) {
  //   apiLogger.error(
  //     document.location.href,
  //     (typeof exception === 'string') ? exception : JSON.stringify(exception),
  //     message
  //   );
  // } else {
  //   console.error('[exception]', message, exception);
  // }
  // TODO: uncomment and remove
  console.error('[exception]', message, exception);
}

export const logger = {
  info,
  error,
  exception,
}


import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotifications } from '../../../../hooks/use-notifications';
import { useCredentialDelete } from '../../hooks/use-b2c2-credential-delete';
import { CredentialDetail } from '../../model/credential-detail';
import { useCredentialsDetails } from '../../hooks/use-b2c2-credential-details';
import { useB2C2CredentialDetailsStyles } from './b2c2-credential-details.styles';
import { ColumnDef, SortingState, Table, getCoreRowModel, getFilteredRowModel, getSortedRowModel } from '@tanstack/table-core';
import { useReactTable } from '@tanstack/react-table';
import { TableCore } from '../table-abstraction/table-core';
import { B2C2CredentialDialog } from './b2c2-credential-dialog';
import Grid from '@visa/vds/grid';
import Row from '@visa/vds/row';
import Col from '@visa/vds/col';
import ButtonGroup from '@visa/vds/button-group';
import ButtonIcon from '@visa/vds/button-icon';
import Dialog from '@visa/vds/dialog';
import DialogCardBody from '@visa/vds/dialog-card-body';
import DialogCardClose from '@visa/vds/dialog-card-close';
import DialogCardHead from '@visa/vds/dialog-card-head';
import DialogCardHeadTitle from '@visa/vds/dialog-card-head-title';
import Icon from '@visa/vds/icon';
import Typography from '@visa/vds/typography';
import { dialogStyles } from "../table-abstraction/dialogStyles.styles";

type Row = CredentialDetail & { tableData?: any };

export interface B2C2CredentialDetailsProps {
    onClose: () => void;
}

export const B2C2CredentialDetails: React.FC<B2C2CredentialDetailsProps> = ({ onClose }) => {

    const _styles = dialogStyles();
    const styles = useB2C2CredentialDetailsStyles();
    const { credentialDetails, loading } = useCredentialsDetails();
    const { pushNotification } = useNotifications();
    const { deleteCredentialAsync } = useCredentialDelete();
    const [credentials, setCredentials] = useState<CredentialDetail[] | null>(credentialDetails);
    const [dialogState, setDialogState] = useState({ open: false });
    const [mleKeyId, setMleKeyId] = useState("");
    const [dialogStateCert, setDialogStateCert] = useState({ open: true });

    useEffect(() => {
        if (!loading && credentialDetails !== undefined) {
            setCredentials(credentialDetails);
        }
    }, [loading, credentialDetails]);

    useEffect(() => {
        if (!loading && credentialDetails !== undefined) {
            setCredentials(credentialDetails);
        }
    }, [loading, credentialDetails]);
    
    const handleDelete = useCallback(async (keyId: string): Promise<void> => {
        if (credentials !== undefined) {
            const success = await deleteCredentialAsync(keyId);
            if (!success) {
                pushNotification({ message: `Unable to delete credential ${keyId}`, type: 'snackbar', variant: 'error' });
                return;
            }
            if (!!credentials && credentials.length === 0) {
                return Promise.reject();
            }
            if (!!credentials) {
                const updatedCredentials = credentials.filter(credential => credential.keyId !== keyId);
                setCredentials(updatedCredentials);
            }
            setMleKeyId(keyId);
            setDialogState({ open: true })
            
        }
    }, [credentials, deleteCredentialAsync]);

    const rows = useMemo<Row[]>(() => {
        if (!!credentials && credentials !== undefined) {
            return credentials.map(host => ({ ...host }));
        } else {
            return [];
        }
    }, [credentials]);

    const columns = useMemo<ColumnDef<Row>[]>(() => ([
        {
            header: 'Key Id',
            accessorKey: 'keyId'
        },
        {
            header: 'File Name',
            accessorKey: 'fileName'
        },
        {
            header: 'Created At',
            accessorKey: 'createdAt'
        },
        {
            header: 'Last Modified At',
            accessorKey: 'lastModifiedAt'
        },
        {
            header: "Action",
            accessorKey: "id",
            enableSorting: false,
            cell: (context)=><>
                <ButtonIcon ariaLabel={`Delete ${context.getValue()}`} iconType="light-tiny" 
                    onClick={() => context.table.options?.meta?.deleteRow && context.table.options?.meta?.deleteRow(context.row.id)}>
                    <Icon name="delete" resolution="tiny" />
                </ButtonIcon>
            </>
        }
    ]), []);

    const [sort, setSort] = useState<SortingState>([]);
  const [currentEditingRow, setCurrentEditingRow] = useState<string[]>([]);
  const [dirtyRow, setDirtyRow] = useState<Row | null>(() => null);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const table = useReactTable({
    data: rows,
    columns: columns,
    getCoreRowModel:getCoreRowModel(),
    getRowId: (e) => e.keyId,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSort,
    enableSorting: true,
    state: {
      globalFilter: search,
      sorting: sort,
    },
    meta: {
      updateEditingRowState: (id: string) => {
        setCurrentEditingRow([id]);
        setDirtyRow(() => ({ ...rows.filter(e => e.keyId === id)[0] }));
      },
      currentEditingRowId: currentEditingRow,
      revertingToStaticState: () => {
        setCurrentEditingRow([]);
        setDirtyRow(null);
      },
      setEditCelldata: (id, newData, columnid) => {
        setDirtyRow((oldState) => {
          if (!oldState) {
            return oldState;
          }
          return {
            ...oldState,
            [columnid]: newData
          }    
        }
        );
      },
      dirtyRow: dirtyRow,
      deleteRow: (id: string) => {
        handleDelete(id);
      },
      isValid:(table: Table<any>)=>{
        return true;
      },
      isIndividualCellValid:(columnName:string, newData: any)=>{
            return "";
      },
    }
  })



    return ( dialogState.open ? <B2C2CredentialDialog mleKeyId={mleKeyId} dialogState={dialogState} setDialogState={setDialogState} onClose={onClose}/> :
        <Grid>
        <Row>
          <Col>
          <Dialog
            aria-labelledby="credential-dialog-title"
            aria-describedby="credential-dialog-description"
            open={true}
            className={_styles.vdsDialogBoxCardForCred}
            onExit={onClose}
          >
            <DialogCardClose>
                <ButtonGroup direction="right">
                    <ButtonIcon
                        ariaLabel="close"
                        iconType="light-tiny"
                        onClick={onClose}
                    >
                        <Icon name="close" resolution="tiny" />
                    </ButtonIcon>
                </ButtonGroup>
            </DialogCardClose>
            <DialogCardHead>
              <DialogCardHeadTitle id="credential-dialog-title"><b>Uploaded Certs</b></DialogCardHeadTitle>
            </DialogCardHead>
            <DialogCardBody id="credential-dialog-description">
              <Typography>
                    Max limit of 30 certificates/keys reached. Delete some to upload new ones.
              </Typography>
              <TableCore caption='Uploaded Certs' table={table}></TableCore>
            </DialogCardBody>
          </Dialog>
          </Col>
        </Row>
      </Grid> 
    );
}
import { Reducer } from 'react';
import { globalTypes } from './global-actions';
import { Action } from './actions';
import { GlobalNotification } from '../model/global-notification';

export interface GlobalState {
  loading: number;
  blockingLoading: boolean;
  notifications: GlobalNotification[];
}

export const initialGlobalState: GlobalState = {
  loading: 0,
  blockingLoading: false,
  notifications: [],
}

export const globalReducer: Reducer<GlobalState, Action> = (state: GlobalState, action: Action): GlobalState => {
  switch (action.type) {
    case globalTypes.startLoading: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case globalTypes.stopLoading: {
      return {
        ...state,
        loading: state.loading > 0 ? state.loading - 1 : 0,
      };
    }
    case globalTypes.startBlockingLoading: {
      return {
        ...state,
        blockingLoading: true,
      };
    }
    case globalTypes.stopBlockingLoading: {
      return {
        ...state,
        blockingLoading: false,
      };
    }
    case globalTypes.pushNotification: {
      const notification: GlobalNotification = action.payload;
      return {
        ...state,
        notifications: [...state.notifications, notification],
      };
    }
    case globalTypes.popNotification: {
      const notification: GlobalNotification = action.payload;
      const notifications = (state.notifications.indexOf(notification) === 0)
        ? state.notifications.slice(1)
        : state.notifications;
      return {
        ...state,
        notifications,
      };
    }
  
    default:
      return state;
  }
}

import React, { createContext, Dispatch, Reducer, ReducerAction, ReducerState, useContext, useReducer } from 'react';
import { Action } from './actions';
import { initialUserState, reducer, UserState } from './reducer';

const defaultValue: [ReducerState<Reducer<UserState, Action>>, Dispatch<ReducerAction<Reducer<UserState, Action>>>] = [
  initialUserState, () => { }
];
export const StateContext = createContext(defaultValue);

interface StoreProviderProps {
  reducer: Reducer<UserState, Action>;
  initialState: UserState;
  children: React.ReactNode;
}

const StoreProvider = ({ reducer, initialState, children }: StoreProviderProps) => (
  <StateContext.Provider
    value={useReducer(reducer, initialState)}
    children={children}
  />
);

export const AuthStoreProvider = ({ children }: { children: React.ReactNode }) => (
  <StoreProvider reducer={reducer} initialState={initialUserState}>{children}</StoreProvider>
);

export const useAuthStore = () => useContext(StateContext);

export const useAuthState = () => useAuthStore()[0];
export const useAuthDispatch = () => useAuthStore()[1];

import { Reducer } from 'react';
import { Action } from '../../../state/actions';
import { B2C2Application } from '../model/application';
import { ApplicationApproval } from '../model/application-approval';
import { appsTypes } from './apps-actions';

export interface ApprovalsState {
  list: ApplicationApproval[] | null;
  loading: boolean;
  error: string | null;
}


export interface B2C2AppsState {
  b2c2Applications: B2C2Application[];
  loading: boolean;
  error: string | null;
}


export const initialB2C2AppsState: B2C2AppsState = {
  b2c2Applications: [],
  error: null,
  loading: false,
}



export const b2c2AppsReducer: Reducer<B2C2AppsState, Action> = (state: B2C2AppsState, action: Action): B2C2AppsState => {
  switch (action.type) {
    case appsTypes.loadB2C2Applications: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case appsTypes.loadB2C2ApplicationsSuccess: {
      const b2c2Applications = action.payload as B2C2Application[];
      return {
        ...state,
        loading: false,
        error: null,
        b2c2Applications,
      };
    }
    case appsTypes.loadB2C2ApplicationsError: {
      const error = action.payload as string;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case appsTypes.addB2C2Application: {
      if (!state.b2c2Applications) {
        return state;
      }
      const b2c2Application = action.payload as B2C2Application;
      return {
        ...state,
        b2c2Applications: [
          ...state.b2c2Applications ? state.b2c2Applications : [],
          b2c2Application,
        ],
      };
    }
    case appsTypes.updateB2C2Application: {
      if (!state.b2c2Applications) {
        return state;
      }
      const b2c2Application = action.payload as B2C2Application;
      return {
        ...state,
        b2c2Applications: [
          ...state.b2c2Applications ? state.b2c2Applications.map(b2c2App => b2c2App.applicationGuid === b2c2Application.applicationGuid ? b2c2Application : b2c2App) : [b2c2Application],
        ],
      };
    }
    case appsTypes.deleteB2C2Application: {
      if (!state.b2c2Applications) {
        return state;
      }
      const applicationGuid = action.payload as string;
      return {
        ...state,
        b2c2Applications: [
          ...state.b2c2Applications ? state.b2c2Applications.filter(b2c2App => b2c2App.applicationGuid !== applicationGuid) : [],
        ],
      };
    }

    default:
      return state;
  }
}

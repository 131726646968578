import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { SearchBar } from "../common-component/search-bar";
export interface IB2C2ConsumerRequestFilter {
    dataEntries: any[];
    search: string;
    setSearch: Function;
}
export const B2C2ConsumerRequestFilter: React.FC<IB2C2ConsumerRequestFilter> = ({ dataEntries, setSearch, search}) => {
    const theme = useTheme();
    const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));
    return <Grid
        container
        style={{ backgroundColor: "#F2F4F8" }}
        justifyContent={mediaCheck ? "space-between" : "flex-start"}
        alignItems={"center"}
        rowGap={2}
        padding={1}
    >
        <Grid item xs={12} lg={9} padding={1}>
            <Typography component={"h2"} variant='h5'>
                Total requests: {dataEntries.length}
            </Typography>
        </Grid>
        <Grid item xs={12} lg={3} padding={1} >
            <SearchBar label=" Search B2C Consumer" search={search} setSearch={setSearch}></SearchBar>
        </Grid>
    </Grid>
}
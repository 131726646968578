import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { DialogActions, DialogContent } from '@material-ui/core';
import { DialogStyles } from './dialog.styles';
import { DialogTitle } from './dialog-title';


export interface DeleteConfirmDialogProps {
    handleDelete: () => Promise<void>;
    handleClose: () => void;
    isApp: boolean
}

export default function DeleteConfirmDialog(dialogProps: DeleteConfirmDialogProps) {
    const styles = DialogStyles();
    return (
        <div>
            <Dialog open={true} role="dialog" aria-modal="true" aria-labelledby="dialog-title" onEscapeKeyDown={dialogProps.handleClose} disableBackdropClick>
                <DialogTitle id="dialog-title">
                    <b>Confirm deletion</b>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" className={styles.content}>
                        This operation cannot be undone. Are you sure you want to delete the {dialogProps.isApp? 'app': 'service'}?
                    </Typography>
                </DialogContent>
                <DialogActions className={styles.dialogActions}>
                    <Button onClick={dialogProps.handleDelete} type="submit"
                            variant="outlined"
                            className={styles.shortBtn}>
                        Confirm
                    </Button>
                    <Button onClick={dialogProps.handleClose} type="submit"
                            variant="outlined"
                            className={styles.shortBtn}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
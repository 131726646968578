
import { CellContext } from "@tanstack/react-table"
import React from "react"
import { B2C2ApplicationB2C2BArgsAuthN } from '../../../../../model/application';
import { MaskedCell } from "../../../../app-edit/b2c2-masked-cell";
import { BasicAuthPasswordEdit } from "./basic-auth-password-edit";

export const AddBasicAuthPasswordCell: React.FC<CellContext<B2C2ApplicationB2C2BArgsAuthN, any>> = (context) => {


    if (context.table.options.meta?.currentEditingRowId[0] !== context.row.id) {
        return !!context.getValue() ?
            <MaskedCell value={context.getValue()} /> : <div>N/A</div>
    }

    return <BasicAuthPasswordEdit {...context.table} />

}

import React, { useMemo } from 'react';
import { B2C2ApplicationAllowedEndpoints } from '../../model/application';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../table-abstraction/table-core';

export interface B2C2AppPreviewAllowedEndpointsProps {
    allowedEndpoints?: B2C2ApplicationAllowedEndpoints[];
}


export const B2C2AppPreviewAllowedEndpoints: React.FC<B2C2AppPreviewAllowedEndpointsProps> = ({ allowedEndpoints }) => {

    const columns: ColumnDef<B2C2ApplicationAllowedEndpoints, any>[] = useMemo(() => [
        {
            header: 'Endpoint name',
            accessorKey: 'endpointName'
        },
        {
            header: 'Endpoint Id',
            accessorKey: 'endpointId'
        },
        {
            header: 'Method type',
            accessorKey: 'methodType'
        },
        {
            header: 'Internal path',
            accessorKey: 'internalPath',
        },
        {
            header: 'External path',
            accessorKey: 'externalPath'
        }
    ], []);
    const rows = useMemo<B2C2ApplicationAllowedEndpoints[]>(() => allowedEndpoints ? allowedEndpoints.map(allowedEndpoint => ({ ...allowedEndpoint })) : [], [allowedEndpoints]);
    const tableDatas = useReactTable({
        columns: columns,
        data: rows,
        getCoreRowModel: getCoreRowModel(),
        enableSorting: false
    });

    return (<>
        <TableCore caption='Allowed Endpoints' table={tableDatas}></TableCore>
    </>
    );
};
import { Grid, ThemeProvider, Typography } from '@material-ui/core';
import { AddBox, Search } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { ValidationError } from 'yup';
import { DataTableSelect } from '../../../../components/data-table-fields/data-table-select';
import { DataTableTextInput } from '../../../../components/data-table-fields/data-table-text-input';
import { FormErrors } from "../../../../hooks/use-form.hook";
import { serviceHeadersRequired, serviceHeadersType } from "../../../services/model/service";
import { B2BServiceHeadersCreate } from "../../../services/model/service-create";
import {
  b2bServiceHeadersUpdateSchema
} from '../../../services/model/service-validations';
import { backgroundGrey, hostTheme, productEditStyles } from '../product-edit/product-edit.styles';
import { ProductHeaderUpdate } from '../../model/product-update';
import { productHeaderCreateSchema } from '../../model/product-validations';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { Helmet } from 'react-helmet';
import { ColumnDef, SortingState, Table, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { TableCore } from '../../../appsNew/views/table-abstraction/table-core';
import { TableFormEditDeleteAction } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-apps/table-form-edit-delete-action';
import { HeaderSearchAddButton } from '../../../appsNew/views/table-abstraction/filter-implementation/header-search-addButton';
import { EditCellWrapper } from '../../../appsNew/views/table-abstraction/table-cells/cell-wrappers/EditCellWrappers';
import { AddHeaderNameCall } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/headers/add-header-name-cell';
import { HeaderTypeCell } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/headers/add-header-type-cell';
import { AddHeaderRequiredCell } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/headers/add-header-required-cell';
import { AddHeaderDefaultValueCall } from '../../../appsNew/views/table-abstraction/table-cells/b2c2-product/headers/add-header-default-value-cell';
import { AddHeaderRow } from '../../../appsNew/views/table-abstraction/table-rows/b2c2-product/add-headers-row';


export interface ProductEditHeadersProps {
  headers: ProductHeaderUpdate[];
  onChange: (headers: ProductHeaderUpdate[]) => void;
  isCreate?: boolean | false;
}

export type Row = ProductHeaderUpdate;

const headersRequiredMap = serviceHeadersRequired.reduce<Record<string, string>>(
    (acc, headerRequired) => ({ ...acc, [headerRequired]: headerRequired }),
    {}
);

const headersTypeMap = serviceHeadersType.reduce<Record<string, string>>(
    (acc, headerType) => ({ ...acc, [headerType]: headerType }),
    {}
);

export const ProductEditHeaders: React.FC<ProductEditHeadersProps> = ({ headers, onChange, isCreate }) => {
  const styles = productEditStyles();
  const [errors, setErrors] = useState<FormErrors>({});

  const columns = useMemo<ColumnDef<Row>[]>(() => ([
    {
      header: 'Header Name',
      accessorKey: 'name',
      cell: (context)=><EditCellWrapper context={context} element={<AddHeaderNameCall {...context.table}/>}/>
    },
    {
      header: 'Header Type',
      accessorKey: 'dataType',
      cell: (context)=><EditCellWrapper context={context} element={<HeaderTypeCell {...context.table}/>}/>
    },
    {
      header: 'Required',
      accessorKey: 'isRequired',
      cell: (context)=><EditCellWrapper context={context} element={<AddHeaderRequiredCell {...context}/>}/>,
      meta:{
        styles:{
          minWidth: "50px"
        }
      }
    },
    {
      header: 'Default Value',
      accessorKey: 'defaultValue',
      cell: (context)=><EditCellWrapper context={context} element={<AddHeaderDefaultValueCall {...context.table}/>}/>
    },
    {
      header: "Action",
      accessorKey: "id",
      cell: TableFormEditDeleteAction,
      enableSorting: false,
      meta:{
        styles:{
          minWidth: "50px"
        }
      }
    }
  ]), []);

  const rows = useMemo<Row[]>(()  => {
    if (headers !== undefined) {
      return headers.map(header => ({ ...header }));
    } else {
      return [];
    }
  }, [headers]);


  const [sort, setSort] = useState<SortingState>([]);
  const [currentEditingRow, setCurrentEditingRow] = useState<string[]>([]);
  const [dirtyRow, setDirtyRow] = useState<Row | null>(() => null);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const table = useReactTable({
    data: rows,
    columns: columns,
    getCoreRowModel:getCoreRowModel(),
    getRowId: (e) => `${e.id}`,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSort,
    enableSorting: true,
    state: {
      globalFilter: search,
      sorting: sort,
    },
    meta: {
      updateEditingRowState: (id: string) => {
        setCurrentEditingRow([id]);
        setDirtyRow(() => ({ ...rows.filter(e => e.id === Number(id))[0] }));
      },
      currentEditingRowId: currentEditingRow,
      revertingToStaticState: () => {
        setCurrentEditingRow([]);
        setDirtyRow(null);
      },
      setEditCelldata: (id, newData, columnid) => {
        setDirtyRow((oldState) => {
          if (!oldState) {
            return oldState;
          }
          return {
            ...oldState,
            [columnid]: newData
          }    
        }
        );
      },
      dirtyRow: dirtyRow,
      handleUpdate: (id: string) => {
        onChange(headers.map(header => `${header.id}` !== id ? header : { ...header, ...dirtyRow }));
          setCurrentEditingRow([]);
          setDirtyRow(null);
      },
      deleteRow: (id: string) => {
        onChange(headers.filter(header => `${header.id}` !== id));
      },
      isValid:(table: Table<any>)=>{
        return productHeaderCreateSchema.isValidSync(table.options.meta?.dirtyRow);
      },
      isIndividualCellValid:(columnName:string, newData: any)=>{
          try{
            productHeaderCreateSchema.validateSyncAt(columnName,{[columnName]:newData});
            return "";
          } catch(e){
            return (e as ValidationError).message;
          }
      },
      isAdd: isAdd,
      addRowNode: AddHeaderRow,
      startAddMode: () => {
        setCurrentEditingRow(["new"]);
        setDirtyRow({
          id:0,
          name: "",
          dataType: "",
          isRequired: false,
          defaultValue: ""
        });
        setIsAdd(true);
      }, 
      stopAddMode: () => {
        setCurrentEditingRow([]);
        setDirtyRow(null);
        setIsAdd(false);
      },
      handleAdd: () => {
        if (!dirtyRow) {
          return;
        }
        const minId = headers.reduce((min, header) => Math.min(header.id === undefined? min : header.id, min), 0);
        onChange([
          ...headers,
          { ...dirtyRow, id: minId - 1 },
        ]);
        setCurrentEditingRow([]);
        setDirtyRow(null);
        setIsAdd(false);
      }
    }
  })


  return (
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Helmet>
        <title>
          {
            isCreate ?
              "Allowed Headers | Add new product (4 of 5) | Visa Prototype Validation Platform"
              : "Allowed Headers | Edit product (4 of 4) | Visa Prototype Validation Platform"
          }
        </title>
      </Helmet>
      {
        isCreate ? <TypographyHighLight headerTitle="Allowed Headers | Add new product (4 of 5)" variant='h5'></TypographyHighLight>
          : <TypographyHighLight headerTitle="Allowed Headers | Edit product (4 of 4) " variant='h5'></TypographyHighLight>
      }
        <Typography color="primary" className={styles.instruction1}>
          Specify headers for your product
        </Typography>
        
        <Typography className={styles.instruction2}><em>Note: This is an optional step</em></Typography>
        <HeaderSearchAddButton table={table} addButtonFunction={table.options.meta?.startAddMode ? table.options.meta?.startAddMode : () => { }} heading='Headers' search={search} setSearch={setSearch}></HeaderSearchAddButton>
      <TableCore caption='Headers' table={table}></TableCore>
      </Grid>
  );
};
export function deepMerge(...sources: any[]): any {
  const target: any = {};

  // Merge the object into the target object
  let merger = (obj: any) => {
      for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          if (Object.prototype.toString.call(obj[prop]) === '[object Object]'){
            target[prop] = deepMerge(target[prop], obj[prop]);
          } else {
            target[prop] = obj[prop];
          }
         }
      }
  };

   //Loop through each object and conduct a merge
   for (let i = 0; i < sources.length; i++) {
      merger(sources[i]);
   }
  return target;
};

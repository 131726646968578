import { HeaderGroup, Row, Table, flexRender } from "@tanstack/react-table";
import ScreenReader from '@visa/vds/screen-reader';
import Tbody from '@visa/vds/tbody';
import Td from '@visa/vds/td';
import Th from '@visa/vds/th';
import Thead from '@visa/vds/thead';
import Tr from '@visa/vds/tr';
import DataTable from '@visa/vds/data-table';
import React from "react";
import { useTableCoreStyles } from "./table-core.style";
import Icon from '@visa/vds/icon';

export interface ITableCore<T> {
    table: Table<T>;
    caption: string;
    loading?: boolean
}
export const TableCore: React.FC<ITableCore<any>> = ({ table, caption, loading = false }) => {
    const styles = useTableCoreStyles();
    const descendingIcon = <Icon name="sortable-descending" resolution="tiny" className="sort-icon" />;
    const ascendingIcon = <Icon name="sortable-ascending" resolution="tiny" className="sort-icon" />;
    const sortIcon = <Icon name="sortable" resolution="tiny" className="sort-icon" />;
    const AddRowComponent: React.FC<Table<any>> | null = table.options.meta?.addRowNode ? table.options.meta.addRowNode : null
    return <div style={{ overflow: "auto", minWidth: "100%", width: "80vw" }}  tabIndex={0}>
        <DataTable style={{ border: "2px #F2F4F8 solid", width: "100%", display: "table", "borderSpacing": 0, "borderCollapse": "collapse" }} key={caption}>
            <ScreenReader tag="caption">{caption}</ScreenReader>
            <Thead style={{ "verticalAlign": "middle", display: "table-header-group" }}>
                {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>, headerIndex) => (
                    <Tr key={headerIndex} style={{
                        display: "table-row",
                        outline: 0,
                        verticalAlign: "middle",
                    }}>
                        {headerGroup.headers.map((header, index) => {
                            return !header.column.columnDef?.enableHiding ? <Th
                                scope='col'
                                role="columnheader"
                                tabIndex={header.column.getCanSort() ? "0": "-1"}
                                aria-sort={header.column.getIsSorted() === 'asc' ? 'ascending' : (header.column.getIsSorted() === 'desc' ? 'descending' : undefined)}
                                key={caption + "_ " + index + "_" + headerIndex}
                                style={{
                                    cursor: header.column.getCanSort() ? "pointer" : undefined,
                                    ...header.column.columnDef.meta?.styles || {}
                                }}
                                className={styles.tableHeader}
                                onKeyPress={(event: KeyboardEvent) => {
                                    if (header.column.getCanSort() && (event.key === 'Enter' || event.key === ' ')) {
                                        event.preventDefault();
                                        const toggleSortFunction = header.column.getToggleSortingHandler();
                                        if (!!toggleSortFunction) {
                                            toggleSortFunction(event)
                                        }
                                    }
                                }
                                }
                                onClick={header.column.getToggleSortingHandler()}
                            >

                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}

                                {
                                    header.column.getCanSort() ? {
                                        asc: ascendingIcon,
                                        desc: descendingIcon,
                                        false: sortIcon,
                                    }[header.column.getIsSorted() as string] : <></>
                                }
                            </Th> : <></>
                        })}
                    </Tr>
                ))}
            </Thead>
            <Tbody className={styles.tableBody}>
                {loading ? <Tr className={styles.tableRow}><Td colSpan={table.getHeaderGroups()[0].headers.length} className={styles.tableData} style={{ textAlign: "center" }}>Loading</Td></Tr>

                    : (table.options.meta?.isAdd && table.getRowModel().rows.length === 0) ?
                        <></> :
                        table.getRowModel().rows.length === 0 ?
                            <Tr className={styles.tableRow}>
                                <Td colSpan={table.getHeaderGroups()[0].headers.length} className={styles.tableData} style={{ textAlign: "center" }}>No data Available</Td></Tr> :
                            table.getRowModel().rows.map((row: Row<any>) => {
                                return (<>
                                    <Tr key={row.index} className={styles.tableRow}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <Td className={styles.tableData}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </Td>
                                            )
                                        })}
                                    </Tr>

                                </>
                                )
                            })}
                {table.options.meta?.isAdd && !!AddRowComponent && <AddRowComponent {...table}></AddRowComponent>}
            </Tbody>
        </DataTable>
    </div>;
}
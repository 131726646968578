import React, { RefObject, useEffect } from "react";
import { Dispatch, Reducer, ReducerAction, ReducerState, createContext, useReducer } from "react";
import { IAction, ILandmarkState } from "./actions";

const enum ActionEnum {
  SET_SKIP_TO_MAIN_CONTENT, REMOVE_SET_TO_MAIN_CONTENT
}

export type LandmarkContextType= [ReducerState<Reducer<ILandmarkState, IAction<ActionEnum,ILandmarkState>>>, Dispatch<ReducerAction<Reducer<ILandmarkState, IAction<ActionEnum,ILandmarkState>>>>];

const reducer: Reducer<ILandmarkState, IAction<ActionEnum,ILandmarkState>> = (state: ILandmarkState, action: IAction<ActionEnum,ILandmarkState>): ILandmarkState => {
  switch (action.type) {
    case ActionEnum.SET_SKIP_TO_MAIN_CONTENT:
      return {
        ...state,
        isSkipToRequired: true,
        onSkipFocusElement: action.payload.onSkipFocusElement
      }
    case ActionEnum.REMOVE_SET_TO_MAIN_CONTENT:
      return {
        isSkipToRequired: false
      }
    default:
      return state;
  }
}

const initialState: ILandmarkState = {
  isSkipToRequired: false
}


const defaultValue: LandmarkContextType = [
  initialState, () => { }
];
export const LandmarkContext = createContext(defaultValue);

export const AppLandmarkProvider:React.FC<{ children: React.ReactNode }> = ({ children }: { children: React.ReactNode }) => (
  
  <LandmarkContext.Provider
    value={useReducer(reducer, initialState)}
    children={children}
  />
);

export const useLandmarkHook= (ref:RefObject<HTMLElement>, dispatch:Dispatch<ReducerAction<Reducer<ILandmarkState, IAction<ActionEnum,ILandmarkState>>>>)=>useEffect(()=>{
  dispatch({
    type: ActionEnum.SET_SKIP_TO_MAIN_CONTENT,
    payload:{
      isSkipToRequired: true,
      onSkipFocusElement: ref,
    }

  });

  return ()=>{
    dispatch({
      type: ActionEnum.REMOVE_SET_TO_MAIN_CONTENT,
      payload:{
        isSkipToRequired: false,
        onSkipFocusElement: null,
      }
  
    });
  }
 },[ref.current])

 export const useHighlightH1 = (h1Ref: RefObject<HTMLHeadingElement>)=>{
  useEffect(
    ()=>{
      setTimeout(()=>h1Ref.current?.focus(),500);
      return h1Ref.current?.blur();
    },[h1Ref.current]);
 }
import { Grid, ThemeProvider, Typography } from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { DataTableSelect } from '../../../../components/data-table-fields/data-table-select';
import { DataTableTextInput } from '../../../../components/data-table-fields/data-table-text-input';
import { FormErrors } from '../../../../hooks/use-form.hook';
import { TWO_WAY_SSL, X_PAY_TOKEN } from '../../constants';
import { b2c2ApplicationB2C2BArgsAuthNSchema } from '../../model/b2c2-application-validations';
import { appEditStyles, backgroundGrey, hostTheme } from './app-edit.styles';
import * as yup from 'yup';
import { B2C2ApplicationB2C2BArgsAuthN } from '../../model/application';
import { useNotifications } from '../../../../hooks/use-notifications';
import { MaskedCell } from './b2c2-masked-cell';
import { ColumnDef, SortingState, Table, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { EditCellWrapper } from '../../../appsNew/views/table-abstraction/table-cells/cell-wrappers/EditCellWrappers';
import { TableCore } from '../table-abstraction/table-core';
import { HeaderSearchAddButton } from '../table-abstraction/filter-implementation/header-search-addButton';
import { TableFormEditDeleteAction } from '../table-abstraction/table-cells/b2c2-apps/table-form-edit-delete-action';
import { AddTypeCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b-auth/add-type-cell';
import { AddSharedSecretCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b-auth/add-shared-secret-cell';
import { AddApiKeyCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b-auth/add-api-key-cell';
import { AddWsiExternalIdCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b-auth/add-wsi-externalId-cell';
import { AddBasicAuthUserIdCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b-auth/add-basic-auth-userId-cell';
import { AddBasicAuthPasswordCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b-auth/add-basic-auth-password-cell';
import { AddClientKeyIdCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b-auth/add-client-keyId-cell';
import { AddServerCertIdCell } from '../table-abstraction/table-cells/b2c2-apps/app-b2c2b-auth/add-server-certId-cell';
import { ValidationError } from 'yup';
import { B2C2BAuth } from '../table-abstraction/table-rows/b2c2-apps/add-b2c2b-auth-row';

const AUTHN_TYPE: string[] = [
    X_PAY_TOKEN,
    TWO_WAY_SSL,
];

export interface AppEditB2C2BAuthNsProps {
    authNs: B2C2ApplicationB2C2BArgsAuthN[];
    onAuthNsChange: (data: B2C2ApplicationB2C2BArgsAuthN[]) => void;
}

export type Row = B2C2ApplicationB2C2BArgsAuthN & { tableData?: any };

const authNTypeMap = AUTHN_TYPE.reduce<Record<string, string>>(
    (acc, authNType) => ({ ...acc, [authNType]: authNType }),
    {}
);
export const AppEditB2C2BAuthNs: React.FC<AppEditB2C2BAuthNsProps> = ({
    authNs, onAuthNsChange
}) => {
    const styles = appEditStyles();
    const { pushNotification } = useNotifications();
    const [errors, setErrors] = useState<FormErrors>({});
    const host = window.location.host;
    const splits = host.split("-");
    let isWSIExternalIdEditable = false;
    if (splits.length > 2) {
        if (splits[2] === "qapenb.oce" || splits[2] === "qaintb.oce") {
            isWSIExternalIdEditable = true;
        }
    }
    const validateData = useCallback((data: Row) => {
        if (data.type === TWO_WAY_SSL) {
            if (isWSIExternalIdEditable && !data.attr.wsiExternalId) {
                pushNotification({ message: `Valid field wsiExternalId must be provided to continue`, type: 'snackbar', variant: 'error' });
                return true;
            }
        }

        const twoWaySSLCount = authNs.filter(authn => authn.type === TWO_WAY_SSL).length;
        const xPayTokenCount = authNs.filter(authn => authn.type === X_PAY_TOKEN).length;
        console.log("twoWaySSLCount : " + twoWaySSLCount);
        if (twoWaySSLCount > 3) {
            pushNotification({ message: `Maximum limit of 2-way-ssl is reached 1234`, type: 'snackbar', variant: 'error' });
            return true;
        }
        if (xPayTokenCount > 5) {
            pushNotification({ message: `Maximum limit of x-pay-token is reached`, type: 'snackbar', variant: 'error' });
            return true;
        }
        return false;

    }, [authNs]);

    const validateDataLimitWhenAdd = useCallback((data: Row, authNs: B2C2ApplicationB2C2BArgsAuthN[]) => {
        const twoWaySSLCount = authNs.filter(authn => authn.type === TWO_WAY_SSL).length;
        const xPayTokenCount = authNs.filter(authn => authn.type === X_PAY_TOKEN).length;
        if (twoWaySSLCount >= 3 && data.type === TWO_WAY_SSL) {
            pushNotification({ message: `Maximum limit of 2-way-ssl is reached 5678`, type: 'snackbar', variant: 'error' });
            return true;
        }
        if (xPayTokenCount >= 5 && data.type === X_PAY_TOKEN) {
            pushNotification({ message: `Maximum limit of x-pay-token is reached`, type: 'snackbar', variant: 'error' });
            return true;
        }
        return false;
    }, []);

    const validateDataLimitWhenUpdate = useCallback((data: Row, authNs: B2C2ApplicationB2C2BArgsAuthN[], oldData?: Row) => {
        const twoWaySSLCount = authNs.filter(authn => authn.type === TWO_WAY_SSL).length;
        const xPayTokenCount = authNs.filter(authn => authn.type === X_PAY_TOKEN).length;
        if (data.type !== oldData?.type) {
            if (data.type === TWO_WAY_SSL) {
                if (twoWaySSLCount >= 3) {
                    pushNotification({ message: `Maximum limit of 2-way-ssl is reached`, type: 'snackbar', variant: 'error' });
                    return true;
                }
            } else if (data.type === X_PAY_TOKEN) {
                if (xPayTokenCount >= 5) {
                    pushNotification({ message: `Maximum limit of x-pay-token is reached`, type: 'snackbar', variant: 'error' });
                    return true;
                }
            }
        }
        return false;
    }, []);

    const rows = useMemo<Row[]>(() => authNs ? authNs.map(authN => ({ ...authN })) : [], [authNs]);

    const columns = useMemo<ColumnDef<B2C2ApplicationB2C2BArgsAuthN>[]>(() => ([

        {
            header: 'Type',
            accessorKey: 'type',
            cell: (context) => <EditCellWrapper context={context} element={<AddTypeCell {...context.table} />} />,
        },
        {
            header: 'Shared Secret',
            accessorKey: 'attr.sharedSecret',
            cell: AddSharedSecretCell
        },
        {
            header: 'API Key',
            accessorKey: 'attr.apiKey',
            cell: AddApiKeyCell
        },
        {
            header: 'WSI External Id',
            accessorKey: 'attr.wsiExternalId',
            cell: AddWsiExternalIdCell
        },
        {
            header: 'Basic Auth User Id',
            accessorKey: 'attr.basicAuthUserId',
            cell: AddBasicAuthUserIdCell
        },
        {
            header: 'Basic Auth Password',
            accessorKey: 'attr.basicAuthPassword',
            cell: AddBasicAuthPasswordCell
        },
        {
            header: 'Client Key Id',
            accessorKey: 'attr.clientKeyId',
            cell: AddClientKeyIdCell
        },
        {
            header: 'Server Cert Id',
            accessorKey: 'attr.serverCertId',
            cell: AddServerCertIdCell
        },
        {
            header: "Actions",
            accessorKey: "id",
            enableSorting: false,
            cell: TableFormEditDeleteAction,
            meta: {
                styles: {
                    minWidth: "150px"
                }
            }
        }
    ]), []);


    const [sort, setSort] = useState<SortingState>([]);
    const [search, setSearch] = useState<string>("");
    const [currentEditingRow, setCurrentEditingRow] = useState<string[]>([]);
    const [dirtyRow, setDirtyRow] = useState<Row | null>(() => null);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const table = useReactTable({
        columns: columns,
        data: rows,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (e) => `${e.id}`,
        // debugTable: true,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSort,
        enableSorting: true,
        state: {
            sorting: sort,
            globalFilter: search
        },
        meta: {
            //data: {},
            updateEditingRowState: (id: string) => {
                setCurrentEditingRow([id]);
                setDirtyRow(() => ({ ...rows.filter(e => String(e.id) === id)[0] }));
            },
            currentEditingRowId: currentEditingRow,
            revertingToStaticState: () => {
                setCurrentEditingRow([]);
                setDirtyRow(null);
            },
            setEditCelldata: (id, newData, columnid) => {
                setDirtyRow((oldState) => {
                    if (!oldState) {
                        return oldState;
                    }
                    return {
                        ...oldState,
                        [columnid]: newData
                    }

                }
                );
            },
            dirtyRow: dirtyRow,
            handleUpdate: (id: string) => {
                if (!dirtyRow) {
                    return;
                }
                if (validateData(dirtyRow)) {
                    return;
                }
                if (validateDataLimitWhenUpdate(dirtyRow, authNs, authNs.filter(authN => `${authN.id}` == id)[0])) {
                    return;
                }
                if (dirtyRow?.type === 'X_PAY_TOKEN') {
                    let attribute: Row = {
                        id: dirtyRow?.id,
                        type: dirtyRow?.type,
                        attr: {
                            sharedSecret: dirtyRow?.attr?.sharedSecret,
                            apiKey: dirtyRow?.attr?.apiKey,
                            wsiExternalId: '',
                            basicAuthUserId: '',
                            basicAuthPassword: '',
                            clientKeyId: '',
                            serverCertId: ''
                        },
                        authnId: '',
                        appId: '',
                    };
                    onAuthNsChange(authNs.map(authN => `${authN.id}` !== id ? authN : { ...authN, ...attribute }));
                }
                if (dirtyRow?.type === 'TWO_WAY_SSL') {
                    let attribute: Row = {
                        id: dirtyRow?.id,
                        type: dirtyRow?.type,
                        attr: {
                            sharedSecret: '',
                            apiKey: '',
                            wsiExternalId: dirtyRow?.attr?.wsiExternalId,
                            basicAuthUserId: dirtyRow?.attr?.basicAuthUserId,
                            basicAuthPassword: dirtyRow?.attr?.basicAuthPassword,
                            clientKeyId: dirtyRow?.attr?.clientKeyId,
                            serverCertId: dirtyRow?.attr?.serverCertId,
                        },
                        authnId: '',
                        appId: '',
                    };
                    onAuthNsChange(authNs.map(authN => `${authN.id}` !== id ? authN : { ...authN, ...attribute }));
                }

                setCurrentEditingRow([]);
                setDirtyRow(null);
            },
            deleteRow: (id: string) => {
                onAuthNsChange(authNs.filter(authN => `${authN.id}` !== id));
            },
            isValid: (table: Table<any>) => {
                return b2c2ApplicationB2C2BArgsAuthNSchema.isValidSync(table.options.meta?.dirtyRow);
            },
            isIndividualCellValid: (columnName: string, newData: any) => {
                try {
                    b2c2ApplicationB2C2BArgsAuthNSchema.validateSyncAt(columnName, { [columnName]: newData });
                    return "";
                } catch (e) {
                    return (e as ValidationError).message;
                }
            },
            isAdd: isAdd,
            addRowNode: B2C2BAuth,
            startAddMode: () => {
                setCurrentEditingRow(["new"]);
                setDirtyRow({
                    id: 0,
                    type: '',
                    attr: {
                        sharedSecret: '',
                        apiKey: '',
                        wsiExternalId: '',
                        basicAuthUserId: '',
                        basicAuthPassword: '',
                        clientKeyId: '',
                        serverCertId: ''
                    },
                    authnId: '',
                    appId: ''
                });
                setIsAdd(true);
            }, stopAddMode: () => {
                setCurrentEditingRow([]);
                setDirtyRow(null);
                setIsAdd(false);
            },
            handleAdd: () => {
                if (!dirtyRow) {
                    return;
                }
                if (validateData(dirtyRow)) {
                    return;
                }
                if (validateDataLimitWhenAdd(dirtyRow, authNs)) {
                    return;
                }
                const minId = authNs.reduce((min, authN) => Math.min(authN.id, min), 0);
                onAuthNsChange([
                    ...authNs,
                    { ...dirtyRow, id: minId - 1 },
                ]);
                setCurrentEditingRow([]);
                setDirtyRow(null);
                setIsAdd(false);
            }
        }
    });
    return (
        <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography component="h2" className={styles.smallTitle}>
                    Note: The maximum limit for AuthN type 2-way-ssl is 3, for x-pay-token is 5.
                </Typography>
                <HeaderSearchAddButton table={table} addButtonFunction={table.options.meta?.startAddMode ? table.options.meta.startAddMode : () => { }} heading='AuthN' search={search} setSearch={setSearch} ></HeaderSearchAddButton>
                <TableCore caption='AuthN' table={table} loading={false}></TableCore>
            </Grid>
        </>
    );
};

import { Button, FormControl, Grid, Paper, TextField, Typography } from '@material-ui/core';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from '../../../../hooks/use-form.hook';
import { useNotifications } from '../../../../hooks/use-notifications';
import { logger } from "../../../../services/logger.service";
import { buttonStyles } from "../../../../styles/button.styles";
import { useProductApprovalPreviewStyles } from '../approvals/b2c2-product-approval-preview.styles';
import ProductDialog from "../product-dialog/product-dialog";
import { useUpdateB2C2Approval } from '../../hooks/use-update-b2c2-approval';
import { B2C2ApprovalRequest } from '../../model/product';
import { B2C2ApprovalRequestUpdate } from '../../model/product-update';
import { b2c2ApprovalRequestUpdateSchema } from '../../model/product-validations';
// import { B2BApprovalRequest } from '../../model/service';
// import { B2BApprovalRequestUpdate } from '../../model/service-update';
// import { b2bApprovalRequestUpdateSchema } from '../../model/service-validations';
// import ServiceDialog from "../service-dialog/service-dialog";
// import { useServiceApprovalPreviewStyles } from "./b2b-service-approval-preview.styles";
// import { B2BUpdateStatus } from './b2b-service-update-status';

export interface B2C2ApprovalRequestEditProps {
  approvalRequest?: B2C2ApprovalRequest | null;
}

export const B2C2EditRequestStatus: React.FC<B2C2ApprovalRequestEditProps> = ({ approvalRequest }) => {
  const styles = useProductApprovalPreviewStyles();
  const navigate = useNavigate();
  const { updateB2C2ApprovalRequestAsync } = useUpdateB2C2Approval();
  const btnStyles = buttonStyles();
  const [, setBlockNavigation] = useState(false);
  const [dialog, setDialog] = React.useState(0);
  const [flag, setFlag] = useState(false);
  const b2c2ApprovalRequestUpdate = useMemo<B2C2ApprovalRequestUpdate>(
    () => ({
      requestGuid: approvalRequest?.requestId,
      saNumber: approvalRequest?.saNumber,
      businessJustification: approvalRequest?.businessJustification,
      status: "",
      reason: "",
      sendBackMsg: "",
    }), [approvalRequest]
  );
  const { inputs, errors, handleInputChange } = useForm(b2c2ApprovalRequestUpdate, b2c2ApprovalRequestUpdateSchema);
  const { pushNotification } = useNotifications();
  const handleDialog = useCallback((index: number) => {
    setDialog(index);
  }, []);

  const handleCancel = useCallback(() => {
    if (approvalRequest) {
      navigate('/b2c/products/approvals/' + approvalRequest?.requestId);
    } else {
      logger.error("Unable to cancel request", "Invalid approval request")
    }
  }, [approvalRequest, navigate]);

  const handleSubmitClick = useCallback(async () => {
    if (inputs?.status === 'OPEN' && !inputs?.sendBackMsg) {
      pushNotification({ message: `Send back message is required`, type: 'snackbar', variant: 'error' });
      return false;
    }
    setBlockNavigation(true);
    handleDialog(5);
  }, [handleDialog, inputs]);

  const handleSubmit = useCallback(async () => {
    handleDialog(0);
    setBlockNavigation(false);
    if (!b2c2ApprovalRequestUpdate)
      return;
    if (!!approvalRequest) {
      const success = await (updateB2C2ApprovalRequestAsync(approvalRequest.requestId, inputs));
      if (success) {
        navigate('/b2c/products/approvals/');
        // window.location.reload();
      } else {
        logger.error('Unable to show approvals page', "Update Approval Request unsuccessful");
      }
    }
  }, [handleDialog, b2c2ApprovalRequestUpdate, approvalRequest, updateB2C2ApprovalRequestAsync, inputs, navigate]);

  const handleBack = useCallback(() => {
    navigate('/b2c/products/approvals');
  }, [navigate]);

  const backToAllRequestApprovals = <Button variant="contained" onClick={handleBack} role="link" className={btnStyles.btnBlue} aria-label='Back to admin request approvals'>Back</Button>;
  const handleInfoMsg = (msg: string) => {
    if (msg === 'APPROVED')
      return "granting";
    else if (msg === 'DENIED')
      return "denying"
    else if (msg === 'OPEN')
      return 'sending back';
  }

  const handleTitle = (msg: string) => {
    const requestType = approvalRequest?.requestType || '';
    const isApplication = requestType.toLowerCase().search('application');
    let titleStr = 'Product';
    if (isApplication) {
      titleStr = 'Application';
    }
    if (msg === 'APPROVED')
      return `${titleStr} Approval`;
    else if (msg === 'DENIED')
      return `${titleStr} Denial`;
    else if (msg === 'OPEN')
      return `Send Back ${titleStr}`;
  }

  if (!approvalRequest) {
    return null;
  }
  if (approvalRequest?.status === "DENIED" || approvalRequest?.status === "APPROVED" || approvalRequest?.status === "OPEN") {
    return backToAllRequestApprovals;
  }
  if (approvalRequest?.status === "PENDING") {
    return (
      !flag ?
        <Grid container direction={'row'} spacing={3} justifyContent='space-between'>
          {backToAllRequestApprovals}
          <Button variant="contained" id="approval-pending-update" onClick={() => setFlag(!flag)} className={btnStyles.btnBlue}>Update</Button>
        </Grid> :
        null
      // <B2BUpdateStatus approvalRequest={approvalRequest}/>
    )
  }
  return (
    <>
      {dialog ?
        <ProductDialog
          dialogTitle={handleTitle(inputs.status)}
          message={handleInfoMsg(inputs.status)}
          handleDialog={handleDialog}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          dialog={dialog}
          handleSubmitReview={function (sanumber: string, businessJustification: string): void {
            throw new Error('Function not implemented.');
          }} /> :
        <div />}
      <Grid container direction={'row'} spacing={4}>
        <Grid item xs={2}><label htmlFor='request-status'><b>Select Status*</b></label></Grid>
        <FormControl variant='standard'>
          <RadioGroup row
            id="request-status"
            aria-required={true}
            name="status"
            value={inputs.status}
            onChange={handleInputChange}
          >
            <FormControlLabel value="APPROVED" control={<Radio color="primary" inputProps={{
              'aria-label': 'Approved',
            }} />} label="Approve" />
            <FormControlLabel value="DENIED" control={<Radio color="primary" inputProps={{
              tabIndex: -1,
              'aria-label': 'Deny',
            }} />} label="Deny" />
            <FormControlLabel value="OPEN" control={<Radio color="primary" inputProps={{
              tabIndex: -1,
              'aria-label': 'Open',
            }} />} label="Send Back" labelPlacement='end' />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container direction={'row'} spacing={10}>
        <Grid item xs={2}><label htmlFor='sendBackMessage' style={{ fontWeight: 'bold' }}>Message</label></Grid>
        <Grid item xs={10}>
          <TextField
            required
            id="sendBackMessage"
            name="sendBackMsg"
            error={!!errors.sendBackMsg}
            value={inputs.sendBackMsg}
            onChange={handleInputChange}
            variant="outlined"
            size={"small"} />
        </Grid>
      </Grid>
      <Grid container direction={'row'} spacing={3} justifyContent='space-between'>
        {backToAllRequestApprovals}
        <Button variant="contained" id="b2b-approval-update-status" onClick={handleSubmitClick} className={btnStyles.btnBlue}>Update</Button>
      </Grid>
    </>
  );
}
import { Paper, Tab, Tabs, ThemeProvider } from '@material-ui/core';
import ButtonText from '@visa/vds/button-text';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from '../../../../hooks/use-form.hook';
import { useNotifications } from '../../../../hooks/use-notifications';
import { buttonStyles } from '../../../../styles/button.styles';
import { useB2C2ApprovalRequests } from '../../../products/hooks/use-b2c2-approval-requests';
import { useB2C2Application } from '../../hooks/use-b2c2-application';
import { useB2C2ApplicationUpdate } from '../../hooks/use-b2c2-application-update';
import { useB2C2IsApplicationOwner } from '../../hooks/use-b2c2-is-application-owner';
import { B2C2ApplicationB2C2BArgsMle, B2C2ApplicationHeaders, B2C2ApplicationB2C2BArgsAuthN } from '../../model/application';
import { B2C2ApplicationCommunicationTemplateCreate } from '../../model/application-create';
import { B2C2ApplicationConfigUpdate, B2C2ApplicationUpdate } from '../../model/application-update';
import { b2c2ApplicationUpdateSchema, b2c2EditApplicationSchema } from '../../model/b2c2-application-validations';
import AppDialog, { AppDialogStates } from '../app-dialog/app-dialog';
import { AppEditDetailsPlaceHolder } from './app-edit-details-placeholder';
import { hostTheme, useAppEditStyles } from './app-edit.styles';
import { B2C2AppEditAllowedEndpoints } from './b2c2-app-edit-allowed-endpoints';
import { B2C2AppEditConfigs } from './b2c2-app-edit-configs';
import { B2C2AppEditDetails } from './b2c2-app-edit-details';
import { B2C2AppEditHeaders } from './b2c2-app-edit-headers';
import { AppEditIdentities } from './b2c2-app-edit-identities';
import { AppEditB2C2BMles } from './b2c2-app-edit-b2c2b-mle';
import { B2C2AppEditVdpAppId } from './b2c2-app-edit-vdp-app-id';
import { AppEditB2C2BAuthNs } from './b2c2-app-edit-b2c2b-auth';
import { Typography } from '@mui/material';
import { useProducts } from '../../../products/hooks/use-products';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';


enum Panels {
  Details = 0,
  AllowedEndpoints = 1,
  Headers = 2,
  AppConfigs = 3,
  Custom = 4,
  B2C2BArgs = 5,
}

interface TabErrors {
  details?: boolean;
  owners?: boolean;
  headers?: boolean;
  allowedEndpoints?: boolean;
  appConfigs?: boolean;
  custom?: boolean;
  b2c2bArgs?: boolean;
}

const defaultB2C2Application: B2C2ApplicationUpdate = {
  name: '',
  description: '',
  namespace: '',
  allowedEndpoints: [],
  supportGdl: '',
  owners: [],
  appProductHeaders: [],
  whitelistedIdentities: [],
  fromId: '',
  fromDisplayName: '',
  templates: [],
  b2c2bArgs: undefined
};

export const B2C2AppEdit: React.FC = () => {
  const styles = useAppEditStyles();
  const btnStyles = buttonStyles();
  const navigate = useNavigate();
  const { pushNotification } = useNotifications();
  const { products } = useProducts();
  const [blockNavigation, setBlockNavigation] = useState(false);
  const { applicationGuid } = useParams();
  const applicationGuidParam = applicationGuid ? applicationGuid : '';
  const { b2c2Application, b2c2PendingApplication, loading, b2c2ActiveApplication } = useB2C2Application(applicationGuidParam);
  const { updateB2C2ApplicationAsync } = useB2C2ApplicationUpdate();
  const [tabErrors, setTabErrors] = useState<TabErrors>({});
  const [currentPanel, setCurrentPanel] = useState<Panels>(Panels.Details);
  const location: any = useLocation();
  const { refreshApprovals } = useB2C2ApprovalRequests();

  let currentB2C2Application = b2c2ActiveApplication;
  if (location?.state?.alignment === 'ACTIVE') {
    currentB2C2Application = b2c2Application;
  } else if (location?.state?.alignment === 'PENDING') {
    currentB2C2Application = b2c2PendingApplication
  }

  const b2c2ApplicationUpdate = useMemo<B2C2ApplicationUpdate | null>(() => currentB2C2Application ? {
    ...currentB2C2Application,
    allowedEndpoints: currentB2C2Application.applicationEndpointsnMap.map(op => op.endpointId),
    owners: currentB2C2Application.owners.map(owner => owner),
    appProductHeaders: currentB2C2Application.appProductHeaders,
    consumerRequests: currentB2C2Application.consumerRequests
  } : null, [currentB2C2Application]);

  const [b2c2bArgsRequired, setB2c2bArgsRequired] = useState(products!?.filter(product => product.productEndpoints.some(op => !!(b2c2ApplicationUpdate === null || b2c2ApplicationUpdate.allowedEndpoints === undefined ? [] : b2c2ApplicationUpdate.allowedEndpoints).includes(op.endpointId))).some(product => !!product.productB2C2BArgs?.useVp2Connector));
  const { inputs, errors, handleInputChange, handleInputValueChange, handleValidate } = useForm(b2c2ApplicationUpdate || defaultB2C2Application, !b2c2bArgsRequired ? b2c2EditApplicationSchema : b2c2ApplicationUpdateSchema);
  const [dialog, setDialog] = useState<AppDialogStates>(AppDialogStates.Close);
  const handleDialog = useCallback((dialogState: AppDialogStates) => setDialog(dialogState), []);

  const isApplicationOwner = useB2C2IsApplicationOwner(applicationGuidParam);

  useEffect(() => {
    if (b2c2ActiveApplication === null) { // application not yet loaded
      return;
    }
    // if (b2bActveApplication === undefined ) {
    //   navigate('/404', { replace: true });
    // }
    if (isApplicationOwner === false) {
      navigate('/401', { replace: true });
    }
  }, [b2c2ActiveApplication, isApplicationOwner, navigate]);

  const handleSubmit = useCallback(async () => {
    if (!b2c2Application || !inputs) {
      return;
    }

    setBlockNavigation(false);
    handleDialog(AppDialogStates.Close);
    const success = await updateB2C2ApplicationAsync(b2c2Application, inputs);
    if (success) {
      refreshApprovals();
      handleDialog(AppDialogStates.Success);
    } else {
      setBlockNavigation(true);
    }
  }, [b2c2ActiveApplication, inputs, handleDialog, updateB2C2ApplicationAsync]);

  useEffect(() => {
    const errorList = [tabErrors.details, tabErrors.owners, tabErrors.allowedEndpoints];
    if (!errorList[currentPanel]) {
      const nextError = errorList.indexOf(true);
      if (nextError >= 0) {
        setCurrentPanel(nextError);
      }
    }
  }, [tabErrors, currentPanel]);

  const handleCancel = useCallback(() => navigate('/b2c/apps'), [navigate]);

  const handleEditComplete = useCallback(() => {
    navigate('/b2c/apps/' + applicationGuid);
  }, [navigate, applicationGuid]);

  const handleTabChange = useCallback((_: React.ChangeEvent<{}>, panel: number) => setCurrentPanel(panel), []);

  const handleBack = useCallback(() => setCurrentPanel(currentPanel => currentPanel - 1), []);
  const handleNext = useCallback(() => setCurrentPanel(currentPanel => currentPanel + 1), []);

  const areDetailsInvalid = useCallback(
    (errors: Record<string, any>) => errors.name || errors.description || errors.owners,
    []
  );

  const openCancelDialog = useCallback(() => handleDialog(AppDialogStates.CancelChanges), [handleDialog]);
  const handleContinue = useCallback(async (evt: React.MouseEvent<any>) => {
    let errorFlag = false;
      if(currentPanel === Panels.Details && (errors.name || errors.description || errors.namespace || errors.supportGdl)){
        if(errors["name"]){
          document.getElementById("appname-id")?.focus();
        }else if(errors["description"]){
          document.getElementById("appdescription-id")?.focus();
        }else if(errors["namespace"]){
          document.getElementById("namespace-id")?.focus();
        }else if(errors["supportGdl"]){
          document.getElementById("supportGdl-id")?.focus();
        }
          errorFlag = true;
      }
      if(currentPanel === Panels.AllowedEndpoints && inputs?.allowedEndpoints?.length === 0){
        errorFlag = true;
      }
      if(currentPanel === Panels.Custom &&
        (errors.fromId || errors.fromDisplayName)){
          if(errors.fromId){
            document.getElementById("fromid-id")?.focus();
          }else if(errors.fromDisplayName){
            document.getElementById("fromdisplayname-id")?.focus();
          }
          errorFlag = true;
      }
      if(errorFlag){
        pushNotification({ message: 'Fix errors before continue', type: 'snackbar', variant: 'error' });
      }else{
        handleNext();
      }

      if(currentPanel === Panels.B2C2BArgs && b2c2bArgsRequired){
        setCurrentPanel(currentPanel);
        if(inputs?.b2c2bArgs?.mle?.length === 0){
          pushNotification({ message: 'Atleast one MLEs is required', type: 'snackbar', variant: 'error' });
        } else if(inputs?.b2c2bArgs?.authN?.length === 0){
          pushNotification({ message: 'Atleast one AuthN is required', type: 'snackbar', variant: 'error' });
        }else{
          handleSave();
        }
      } else if(currentPanel === Panels.B2C2BArgs){
        setCurrentPanel(currentPanel);
        handleSave();
      }

     

  }, [handleInputValueChange, areDetailsInvalid,inputs, currentPanel, b2c2bArgsRequired])

  const handleSave = useCallback(async () => {
    const [, errors] = await handleValidate();

    setTabErrors(tabErrors => ({
      ...tabErrors,
      details: areDetailsInvalid(errors),
      name: errors.name,
      description: errors.description,
      namespaces: errors.namespace,
      owners: errors.owners,
      supportGdl: errors.supportGdl,
      allowedEndpoints: errors.allowedEndpoints,
      status: errors.status,
      headers: errors.headers,
      appConfigs: errors.appConfigs,
      fromId: errors.fromId,
      fromDisplayName: errors.fromDisplayName,
      b2c2bArgs: errors.b2c2bArgs,
    }));
    if (Object.values(errors).some(err => !!err)) {
      pushNotification({ message: 'Fix errors before continue', type: 'snackbar', variant: 'error' });
    } else {
      handleDialog(AppDialogStates.ConfirmChanges);
    }
  }, [handleValidate, pushNotification, areDetailsInvalid, handleDialog]);


  const handleApplicationHeadersChange = useCallback(
    (applicationHeaders: B2C2ApplicationHeaders[]) => handleInputValueChange('appProductHeaders', applicationHeaders),
    [handleInputValueChange]
  );

  const handleAllowedEndpointsChange = useCallback(
    (allowedEndpoints: string[]) => {
      setB2c2bArgsRequired(products!.filter(product => product.productEndpoints.some(op => !!allowedEndpoints.includes(op.endpointId))).some(product => !!product.productB2C2BArgs?.useVp2Connector));
      handleInputValueChange('allowedEndpoints', allowedEndpoints);
    },
    [handleInputValueChange]
  );

  const handleAppConfigsChange = useCallback(
    (appConfigs: B2C2ApplicationConfigUpdate[]) => handleInputValueChange('appConfigs', appConfigs),
    [handleInputValueChange]
  );

  const handleIdentitiesChange = useCallback(
    (identities: string[]) => {
      handleInputValueChange('whitelistedIdentities', identities);
    },
    [handleInputValueChange]
  );

  const handleFormIdChange = useCallback(
    (formId: string) => {
      handleInputValueChange('fromId', formId);
    },
    [handleInputValueChange]
  );

  const handleDisplayNameChange = useCallback(
    (formDisplayName: string) => {
      handleInputValueChange('fromDisplayName', formDisplayName);
    },
    [handleInputValueChange]
  );

  const handleTemplateChange = useCallback(
    (data: B2C2ApplicationCommunicationTemplateCreate[]) => {
      handleInputValueChange('templates', data);
    },
    [handleInputValueChange]
  );

  const handleMlesChange = useCallback((mles: B2C2ApplicationB2C2BArgsMle[]) => {
    return handleInputValueChange('b2c2bArgs', {
      ...inputs.b2c2bArgs,
      mle: mles,
    });
  },
  [handleInputValueChange]
  );

  const handleAuthNsChange = useCallback((authN: B2C2ApplicationB2C2BArgsAuthN[]) => {
    return handleInputValueChange('b2c2bArgs', {
      ...inputs.b2c2bArgs,
      authN: authN,
    });
  },
  [handleInputValueChange]
  );

  const handleVdpAppIdChange = useCallback((vdpAppId: string) => {
    handleInputValueChange('b2c2bArgs', {
      ...inputs.b2c2bArgs,
      vdpAppId: vdpAppId,
    });
  },
  [handleInputValueChange]
  );

  function addMleId(inputs: B2C2ApplicationUpdate): void {
    if (inputs.b2c2bArgs?.mle !== undefined) {
      for (var i = 0; i < inputs.b2c2bArgs?.mle.length; i++) {
        if (inputs.b2c2bArgs?.mle[i].id === undefined) {
          inputs.b2c2bArgs.mle[i].id = i + 1;
        }
      }
    }
  }

  function addAuthNId(inputs: B2C2ApplicationUpdate): void {
    if (inputs.b2c2bArgs?.authN !== undefined) {
      for (var i = 0; i < inputs.b2c2bArgs?.authN.length; i++) {
        if (inputs.b2c2bArgs?.authN[i].id === undefined) {
          inputs.b2c2bArgs.authN[i].id = i + 1;
        }
      }
    }
  }
  const theme  = useTheme();
  const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div>
      <Routes>
        when={blockNavigation}
        message={'You may have pending changes that you will lose if you continue. Do you want to continue?'}
      </Routes>
            {dialog !== AppDialogStates.Close && (
	    <AppDialog
          handleDialog={handleDialog}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          handleEditComplete={handleEditComplete}
          navLinkOnSuccess="/b2c/apps"
          dialog={dialog}
        />
      )}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} role="navigation" title="Application Stepper status" aria-label="All Stages of edit app">
      <ThemeProvider theme={hostTheme}>
          <Paper square>
            <Tabs                      
              classes={{indicator: styles.bigIndicator}}
              value={currentPanel}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              orientation={mediaCheck ? "vertical" : "horizontal"}
            //onChange={handleTabChange}
            >
              <Tab label="Details" disabled={loading} icon={tabErrors.details ? (<WarningIcon color="error" />) : undefined} />
              <Tab label="Allowed Endpoints" disabled={loading} icon={tabErrors.allowedEndpoints ? (<WarningIcon color="error" />) : undefined} />
              <Tab label="Allowed Headers" disabled={loading} icon={tabErrors.headers ? (<WarningIcon color="error" />) : undefined} />
              <Tab label="App Configs" disabled={loading} icon={tabErrors.appConfigs ? (<WarningIcon color="error" />) : undefined} />
              <Tab label="Customizations" disabled={loading} icon={tabErrors.custom ? (<WarningIcon color="error" />) : undefined} />
              <Tab label="B2C2B Args" disabled={loading} icon={tabErrors.b2c2bArgs ? (<WarningIcon color="error" />) : undefined} />
            </Tabs>
          </Paper>
        </ThemeProvider>
      </Grid>
      <Grid container component={"main"} justifyContent='center'>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {currentPanel === Panels.Details && (
                    <>
                        {loading
                            ? (<AppEditDetailsPlaceHolder />)
                            : (<B2C2AppEditDetails details={inputs} onInputChange={handleInputChange} onInputValueChange={handleInputValueChange} errors={errors} isCreate={false} />)
                        }
                    </>
                )}
                {currentPanel === Panels.AllowedEndpoints &&
                  <B2C2AppEditAllowedEndpoints endpointIds={inputs.allowedEndpoints} onEndpointChange={handleAllowedEndpointsChange} isCreate={false} />
                        
                }
                {currentPanel === Panels.Headers &&
                  <B2C2AppEditHeaders applicationHeaders={inputs.appProductHeaders || []} endpointIds={inputs.allowedEndpoints!} onHeaderChange={handleApplicationHeadersChange} isCreate={false} />
                }
                {currentPanel === Panels.AppConfigs &&
                  <B2C2AppEditConfigs configs={inputs.configs || []} onChange={handleAppConfigsChange} isCreate={false} />
                }
                {currentPanel === Panels.Custom &&
                        <AppEditIdentities whitelistedIdentities={inputs.whitelistedIdentities}
                            templates={inputs.templates}
                            fromId={inputs.fromId}
                            fromDisplayName={inputs.fromDisplayName}
                            onChange={handleIdentitiesChange}
                            onTemplateChange={handleTemplateChange}
                            handleFormIdChange={handleFormIdChange}
                            handleDisplayNameChange={handleDisplayNameChange}
                            formErrors={tabErrors}
                            isCreate={false}
                        />  
                }
                {currentPanel === Panels.B2C2BArgs && <>
                    <Helmet>
                        <title>
                            B2C2B Args (6 of 6) | Edit B2C Application | Visa Prototype Validation Platform
                        </title>
                    </Helmet>
                    <TypographyHighLight headerTitle="B2C2B Args (6 of 6) | Edit B2C Application" variant='h5' />
                    {!b2c2bArgsRequired ?
                        <Typography className={styles.instructions}>
                            No need to fill B2C2B Args since none of the chosen endpoints require B2C2B Args.
                        </Typography> :
                        <>
                            <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Typography className={styles.instruction1}>
                                        Please fill in your App B2C2B Args
                                    </Typography>
                                    {b2c2bArgsRequired && <Typography component="h2" className={styles.smallTitle}>
                                        Note: You are required to enter the App B2C2B Args as you have selected at least one endpoint that requires B2C2B Args.
                                    </Typography>}
                                    <B2C2AppEditVdpAppId b2c2bArgs={inputs.b2c2bArgs} onVdpAppIdChange={handleVdpAppIdChange} errors={errors.b2c2bArgs} />
                                    <>
                                        {addMleId(inputs)}
                                        <AppEditB2C2BMles
                                            mles={inputs.b2c2bArgs?.mle === undefined ? [] : inputs.b2c2bArgs?.mle}
                                            existingMles={b2c2ApplicationUpdate?.b2c2bArgs?.mle}
                                            onMlesChange={handleMlesChange}
                                        />
                                    </>
                                    <>
                                        {addAuthNId(inputs)}
                                        <AppEditB2C2BAuthNs
                                            authNs={inputs.b2c2bArgs?.authN === undefined ? [] : inputs.b2c2bArgs?.authN}
                                            onAuthNsChange={handleAuthNsChange}
                                        />
                                    </>
                                </Grid>
                            </Grid>
                        </>
                    }
                </>}
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Grid container direction='row' columnGap={2}>
                            <Grid item lg={5}>
                                <ButtonText
                                    onClick={openCancelDialog}
                                    colorScheme="primary"
                                    isFullWidth
                                >
                                    Cancel
                                </ButtonText>
                            </Grid>
                            {currentPanel !== Panels.Details && (
                                <Grid lg={5}>
                                    <ButtonText
                                        onClick={handleBack}
                                        colorScheme="primary"
                                        isFullWidth>
                                        Back
                                    </ButtonText>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        {currentPanel !== Panels.B2C2BArgs && (
                            <ButtonText
                                onClick={handleContinue}
                                colorScheme="primary"
                                isFullWidth
                            >
                                Save & Continue
                            </ButtonText>
                        )}
                        {currentPanel === Panels.B2C2BArgs && (
                            <ButtonText
                                colorScheme="primary"
                                isFullWidth
                                onClick={handleContinue}
                            >
                                Save & Review
                            </ButtonText>
                        )}
                    </Grid>
                </Grid>
            </Grid>
      </Grid>
    </div>
  );
};
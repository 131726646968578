import { Button, Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { RefObject, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { BlockingLoading } from '../../../../components/blocking-loading/blocking-loading';
import { CombinedLogo, LogoColorTheme } from '../../../../components/logos/CombinedLogo';
import { config } from '../../../../config';
import { useLogin, useRefreshToken } from '../../../../lib/oidc-client-react';
import { redirectUrlSrv } from '../../../../services/redirect-url.service';
import { retrySSOSrv } from '../../../../services/retry-sso.service';
import { buttonStyles } from '../../../../styles/button.styles';
import { SSO_RETRIES_ALLOWED } from '../../../apps/constants';
import { loginStyles } from './login.styles';
import signInImage from './signInImage.png';
import { LandmarkContext, LandmarkContextType, useHighlightH1, useLandmarkHook } from '../../../../state/skip-to-main-store';

export const Login: React.FC = () => {
  const styles = loginStyles();
  const { login } = useLogin();
  const btnStyles = buttonStyles();
  const { loading, authenticated } = useRefreshToken();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectUrl = useMemo(() => {
    const locationState: any = location.state;
    const pathname = locationState && locationState.pathname ? locationState.pathname : config.paths.home;
    const search = locationState && locationState.search ? locationState.search : '';
    return `${pathname}${search}`;
  }, [location.state]);

  useEffect(() => {
    if (authenticated) {
      setTimeout(() => navigate(redirectUrl));
    }
  }, [authenticated, navigate, redirectUrl]);

  const handleGetStarted = useCallback(() => {
    navigate('/get-started');
  }, [navigate]);

  const handleLogin = useCallback(() => {
    redirectUrlSrv.saveLocation(redirectUrl);
    retrySSOSrv.setNoOfRetries(SSO_RETRIES_ALLOWED);
    login();
  }, [login, redirectUrl]);
  const mainHighlightRef:RefObject<HTMLHeadingElement> = useRef(null);
  const context:LandmarkContextType = useContext(LandmarkContext);
  useLandmarkHook(mainHighlightRef,context[1]);
  useHighlightH1(mainHighlightRef);
  return (
    <>
      <Helmet>
        <title>Welcome | Visa Prototype Validation Platform </title>
      </Helmet>
      <BlockingLoading open={loading} />
        <header className={styles.logoHeader}>
          <CombinedLogo logoColor={LogoColorTheme.BLUE}></CombinedLogo>
        </header>
      <Grid container className={styles.flexOne} component={"main"}>
        {loading || authenticated ? (
          <Grid component="div" item className={styles.center}>
            <Typography component="h4" variant="subtitle1">Loading application...</Typography>
          </Grid>
        ) : (
          <>
            <Grid component="div" item xs={12} lg={6} className={styles.hero} >
              {/* <CombinedLogo logoColor={LogoColorTheme.BLUE} /> */}
              <div className={styles.heroDiv}>
                <img src={signInImage} alt="" aria-hidden="true" className={styles.heroImg} />
              </div>
              <Typography component="h1" variant="h4" className={styles.welcome} innerRef={ mainHighlightRef } tabIndex={-1}>Welcome to Visa Prototype Validation Platform</Typography>
              <Typography component="p" className={styles.welcomeText}>
                Visa’s Prototype Validation Platform (VPVP) is a piloting platform/API gateway that enables rapid product concept validation and innovation pilots around consumer payments, new payment flows, and value-added services.
              </Typography>
              <Button
                role="link"
                aria-label="Get Started"
                type="submit"
                variant="outlined"
                className={clsx(btnStyles.btnWhite, styles.btnGetStarted)}
                onClick={handleGetStarted}>
                Get Started
              </Button>
            </Grid>
            <Grid item xs={12} lg={6} component={Paper} square className={styles.center}>
              <div className={styles.paper}>
                <Typography component="h2" variant="h4" className={styles.signInText}>Sign in with your Visa credentials</Typography>
                <Button role="link"
                  aria-label="Sign In"
                  type="submit"
                  variant="outlined"
                  className={btnStyles.btnBlue}
                  onClick={handleLogin}
                >
                  Sign In
                </Button>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

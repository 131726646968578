import { useMemo } from 'react';
import { useUserProfile } from '../../auth/hooks/use-user-profile.hook';
import { Product } from '../model/product';
import { useProducts } from './use-products';

export interface UseOwnedProductsResult {
    products: Product[];
    ownedProducts: Product[];
    loading: boolean;
}

export function useOwnedProducts(): UseOwnedProductsResult {
    const { userProfile } = useUserProfile();
    const { loading, products } = useProducts();

    const username = userProfile?.username || '';

    const ownedProducts = useMemo(
        () => products
            ? products.filter(prd => {
                if (prd.productOwners) { 
                    return prd.productOwners.some(owner => owner === username)
                }
            })
            : []
        , [products, username]
    );

    return {
        products,
        ownedProducts,
        loading,
    };
}
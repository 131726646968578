import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useErrorsStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '90vh',
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  marginLeft: {
    marginLeft: theme.spacing(4),
  },
}));

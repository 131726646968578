import { B2BApprovalEntity, B2BEntity, B2BEntityDto, B2C2ApprovalEntity, Entity, Identity } from '../../../model/entities';
import { HttpMethod } from '../../../model/http-method';
import { MultiType } from '../../../model/multi-type';
import { ServiceConfiguration } from '../../services/model/service';
import { B2C2ApplicationCommunicationTemplateCreate } from './application-create';
import { ApplicationCommunicationTemplateUpdate, ApplicationCommunicationUpdate, ApplicationConfigUpdate, ApplicationConsumerUpdate, B2C2ApplicationConfigUpdate } from './application-update';

// UPP is deprecated (included to support compatibility with current applications)
export type ApplicationType = 'WEB' | 'MOBILE' | 'IOT' | 'UPP'; 
export const applicationTypes = ['WEB', 'MOBILE', 'IOT', 'UPP'];

export type ApplicationConsumerStatus = 'ENABLED' | 'DISABLED' | 'DELETED';
export const applicationConsumerStatuses = ['ENABLED', 'DISABLED', 'DELETED'];

export type AppStatusType = 'ACTIVE' | 'DISABLED'; 
export const appStatusTypes = ['ACTIVE', 'PENDING']; 

export type AppOutboundEventType = 'VPVP_VDP_EVENT_1' | 'VPVP_VDP_EVENT_2' | 'VPVP_VDP_EVENT_3' | 'VPVP_VDP_EVENT_4' | 'VPVP_VDP_EVENT_5'; 
export const appOutboundEventTypes = ['VPVP_VDP_EVENT_1', 'VPVP_VDP_EVENT_2', 'VPVP_VDP_EVENT_3', 'VPVP_VDP_EVENT_4', 'VPVP_VDP_EVENT_5']; 

export type ApplicationAuthNType = 'X_PAY_TOKEN' | 'TWO_WAY_SSL';
export const ApplicationAuthNTypes = ['X_PAY_TOKEN', 'TWO_WAY_SSL'];

export interface ApplicationRoleOperation extends Identity, Entity {
  name: string;
  methodType: HttpMethod;
  internalPath: string;
  externalPath: string;
  allowedDomains: string[];
  serviceId: number;
}

export interface ApplicationConsumer extends Entity, ApplicationConsumerUpdate {
  firstName: string;
  lastName: string;
}

export interface B2C2ApplicationConsumer extends B2C2ConsumerRequests {
}

export interface ApplicationRole extends Identity, Entity {
  name: string;
  approvedEmailIds: string[];
  operations: ApplicationRoleOperation[];
  consumerUsers: ApplicationConsumer[];
}

export interface ApplicationServiceConfig extends Identity, Entity {
  value: MultiType;
  serviceConfig: ServiceConfiguration;
  applicationId: number;
}

export interface ApplicationConfig extends Entity, ApplicationConfigUpdate {
}

export interface ApplicationCommunicationTemplate extends Entity, ApplicationCommunicationTemplateUpdate {
  appCommId: number;
}

export interface ApplicationCommunicationTemplates {
  accountActivation: ApplicationCommunicationTemplate;
  accountActivationSuccess: ApplicationCommunicationTemplate;
  passwordExpiry: ApplicationCommunicationTemplate;
  resetPassword: ApplicationCommunicationTemplate;
  accountDeactivation: ApplicationCommunicationTemplate;
  forgotUsername: ApplicationCommunicationTemplate;
  changePassword: ApplicationCommunicationTemplate;
}

export interface ApplicationCommunication extends Entity, ApplicationCommunicationUpdate {
  templates: ApplicationCommunicationTemplates;
}

export interface B2C2Application extends B2BEntity {
  name: string;
  description: string;
  namespace: string;
  applicationGuid: string;
  status: string;
  supportGdl: string;
  appProductHeaders: B2C2ApplicationHeaders[];
  owners: string[];
  requestGuid:string;
  whitelistedIdentities: string[];
  fromId: string;
  fromDisplayName: string;
  configs: B2C2ApplicationConfigUpdate[];
  allowedEndpoints: any[];
  templates: B2C2ApplicationCommunicationTemplateCreate[];
  applicationEndpointsnMap: B2C2ApplicationAllowedEndpoints[];
  consumerRequests: B2C2ConsumerRequests[];
  b2c2bArgs: B2C2ApplicationB2C2BArgs;
}

export interface B2C2ConsumerRequests extends B2BApprovalEntity {
  appId: string
  requestId: string
  status: string
  subjectId: string
}
export interface B2C2ConsumerRQ{
  status: string
}
export interface B2BApplicationOutboundEvent extends B2BApprovalEntity {
  eventGuid: string,
  applicationEventGuid?: string,
  applicationGuid?: string,
  eventType: AppOutboundEventType,
}

export interface B2C2ApplicationHeaders{
  headerId: string,
  headerValue: string,
  appHeaderId: string,
}

export interface B2C2ApplicationAllowedEndpoints extends B2BEntityDto {
  appEndpointId: string;
  appId: string;
  productId: string;
  endpointName: string;
  endpointId: string;
  externalPath: string;
  internalPath: string;
  methodType: HttpMethod;
  status: string;
}

export interface B2BApplicationOwner extends B2BEntity {
  applicationOwnerGuid: string;
  subjectName: string;
}

export interface B2BApplicationReviewRequest  {
  businessJustification?:string;
  saNumber?:string;
  requestGuid?:string;
  requestStatus?:string;
}

export interface B2C2ApplicationB2C2BArgs {
  vdpAppId: string,
  mle: B2C2ApplicationB2C2BArgsMle[],
  authN: B2C2ApplicationB2C2BArgsAuthN[],
}

export interface B2C2ApplicationB2C2BArgsMle extends Identity, B2C2ApprovalEntity {
  mleKeyId: string;
  mleStatus: string;
  mleClientKeyId: string;
  mleServerCertId: string;
  mleId?: string;
  appId?: string;
}

export interface B2C2ApplicationB2C2BArgsAuthN extends Identity, B2C2ApprovalEntity {
  type: string;
  attr: B2C2ApplicationB2C2BArgsAttr;
  authnId: string;
  appId: string;
}

export interface B2C2ApplicationB2C2BArgsAttr extends B2C2ApprovalEntity {
  sharedSecret?: string;
  apiKey?: string;
  clientKeyId?: string;
  serverCertId?: string;
  basicAuthUserId?: string;
  basicAuthPassword?: string;
  wsiExternalId?: string;
}

export interface B2C2ApplicationB2C2BBasicAuthCreds {
  userId: string;
  password: string;
}

export interface B2C2ApplicationB2C2BKeyId {
  keyId: string;
}
import { useGlobalStore } from '../state/global-store';
import { globalActions } from '../state/global-actions';

export interface UseLoadingResult {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

export function useBlockingLoading(): UseLoadingResult {
  const [state, dispatch] = useGlobalStore();

  return {
    loading: state.blockingLoading,
    startLoading: () => dispatch(globalActions.startBlockingLoading()),
    stopLoading: () => dispatch(globalActions.stopBlockingLoading()),
  };
}

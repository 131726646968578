import { Reducer } from 'react';
import { Action } from '../../../state/actions';
import { B2BApprovalRequest } from '../model/service';
import { B2BService } from './../model/service';
import { servicesTypes } from './services-actions';

export interface B2BApprovalRequestsState {
  b2bApprovalRequests: B2BApprovalRequest[];
  loading: boolean;
  error: string | null;
}

export interface B2BServicesState {
  b2bServices: B2BService[];
  approvals: Record<string, B2BApprovalRequestsState>;
  loading: boolean;
  error: string | null;
}

export const initialB2BServicesState: B2BServicesState = {
  b2bServices: [],
  approvals: {},
  error: null,
  loading: false,
}

export const initialB2BApprovalRequestsState: B2BApprovalRequestsState = {
  b2bApprovalRequests: [],
  error: null,
  loading: false,
}

export const b2bServicesReducer: Reducer<B2BServicesState, Action> = (state: B2BServicesState, action: Action): B2BServicesState => {
  switch (action.type) {
    case servicesTypes.loadB2BServices: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case servicesTypes.loadB2BServicesSuccess: {
      const b2bServices = action.payload as B2BService[];
      return {
        ...state,
        loading: false,
        error: null,
        b2bServices,
      };
    }
    case servicesTypes.loadB2BServicesError: {
      const error = action.payload as string;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case servicesTypes.addB2BService: {
      if (!state.b2bServices) {
        return state;
      }
      const b2bService = action.payload as B2BService;
      return {
        ...state,
        b2bServices: [
          ...state.b2bServices ? state.b2bServices : [],
          b2bService,
        ],
      };
    }
    case servicesTypes.updateB2BService: {
      if (!state.b2bServices) {
        return state;
      }
      const b2bService = action.payload as B2BService;
      return {
        ...state,
        b2bServices: [
          ...state.b2bServices ? state.b2bServices.map(srv => srv.serviceGuid === b2bService.serviceGuid ? b2bService : srv) : [b2bService],
        ],
      };
    }
    case servicesTypes.deleteB2BService: {
      if (!state.b2bServices) {
        return state;
      }
      const serviceGuid = action.payload as string;
      return {
        ...state,
        b2bServices: [
          ...state.b2bServices ? state.b2bServices.filter(b2bService => b2bService.serviceGuid !== serviceGuid) : [],
        ],
      };
    }
    
    default:
      return state;
  }
}

export const b2bApprovalRequestsReducer: Reducer<B2BApprovalRequestsState, Action> = (state: B2BApprovalRequestsState, action: Action): B2BApprovalRequestsState => {
  switch (action.type) {
    case servicesTypes.loadB2BApprovalRequests: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case servicesTypes.loadB2BApprovalRequestsSuccess: {
      const b2bApprovalRequests = action.payload as B2BApprovalRequest[];
      return {
        ...state,
        loading: false,
        error: null,
        b2bApprovalRequests,
      };
    }
    case servicesTypes.loadB2BApprovalRequestsError: {
      const error = action.payload as string;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case servicesTypes.updateB2BApprovalRequest: {
      if (!state.b2bApprovalRequests) {
        return state;
      }
      const b2bApprovalRequest = action.payload as B2BApprovalRequest;
      return {
        ...state,
        b2bApprovalRequests: [
          ...state.b2bApprovalRequests ? state.b2bApprovalRequests.map(request => request.requestGuid === b2bApprovalRequest.requestGuid ? b2bApprovalRequest : request) : [b2bApprovalRequest],
        ],
        loading: false,
        error: null,
      };
    }
    
    default:
      return state;
  }
}

import React, { ChangeEvent, useState } from "react"
import Select from '@visa/vds/select';
import { Table } from "@tanstack/react-table";
import { Row } from "../../../../app-edit/b2c2-app-edit-b2c2b-auth";
import { TWO_WAY_SSL, X_PAY_TOKEN } from "../../../../../constants";

const AUTHN_TYPE: string[] = [
    X_PAY_TOKEN,
    TWO_WAY_SSL,
];

export const AddTypeCell: React.FC<Table<Row>> = (table) => {
    const [typeError, setTypeError] = useState<string>("");
    
    const authNTypeMap = AUTHN_TYPE.reduce<Record<string, string>>(
        (acc, authNType) => ({ ...acc, [authNType]: authNType }),
        {}
    );
    return <Select id={"authn_type"}
        label="Type"
        fullWidth
        value={table.options.meta?.dirtyRow?.type || ''}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setTypeError(table.options.meta?.isIndividualCellValid("type", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "type");
        }}
        showErrorText={!!typeError}
        errorText={typeError}
        invalid={!!typeError}
        autoFocus={!!table.options.meta?.dirtyRow?.type}
    >
        <option value="" disabled> Select Type</option>
        {Object.entries(authNTypeMap).map(([key, value]) => <option key={key} value={value}>{key}</option>)}
    </Select>
}
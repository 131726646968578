import React, { useContext } from "react";
import { skipToStyles } from "./skipToMainContent.style";
import { SkipToLoginContext } from "../../state/skip-to-login-store";
import { Button } from "@material-ui/core";

export const SkipToLogin: React.FC = () => {
    const style = skipToStyles();
    const state = useContext(SkipToLoginContext)[0]
    return state.isSkipToRequired ?
                <Button
                    tabIndex={0}
                    className={style.skipButton}
                    onClick={() => state.onSkipFocusElement?.current?.focus()
                    }
                    >
                    Skip to Login
                </Button>
        : <></>;
}
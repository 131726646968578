import { actionCreator } from '../../../state/actions';
import { B2C2ApprovalRequest, B2C2ProductReviewRequest, Product } from '../model/product';

const types = {
  loadProducts: 'products/load_products',
  loadProductsSuccess: 'products/load_products_success',
  loadProductsError: 'products/load_products_error',
  addProduct: 'products/add_product',
  updateProduct: 'products/update_product',
  deleteProduct: 'products/delete_product',
  loadB2C2ApprovalRequests: 'products/load_b2c2_approval_requests',
  loadB2C2ApprovalRequestsSuccess: 'products/load_b2c2_approval_requests_success',
  updateB2C2ApprovalRequest: 'products/update_b2c2_approval_request',
  loadB2C2ApprovalRequestsError: 'products/load_b2c2_approval_requests_error',
  b2c2reviewRequest: 'product/b2c2_review_request'
}

const loadProducts = () => actionCreator(types.loadProducts);
const loadProductsSuccess = (products: Product[]) => actionCreator(types.loadProductsSuccess, products);
const loadProductsError = (error: string) => actionCreator(types.loadProductsError, error);
const addProduct = (product: Product) => actionCreator(types.addProduct, product);
const updateProduct = (Product: Product) => actionCreator(types.updateProduct, Product);
const deleteProduct = (id: string) => actionCreator(types.deleteProduct, id);
const loadB2C2ApprovalRequests = () => actionCreator(types.loadB2C2ApprovalRequests);
const loadB2C2ApprovalRequestsSuccess = (approvalRequests: B2C2ApprovalRequest[]) => actionCreator(types.loadB2C2ApprovalRequestsSuccess, approvalRequests);
const loadB2C2ApprovalRequestsError = (error: string) => actionCreator(types.loadB2C2ApprovalRequestsError, error);
const updateB2C2ApprovalRequest = (approvalRequest: B2C2ApprovalRequest) => actionCreator(types.updateB2C2ApprovalRequest, approvalRequest);
const b2c2ReviewRequest = (approvalRequest: B2C2ProductReviewRequest) => actionCreator(types.b2c2reviewRequest, approvalRequest);

export const productActions = {
  loadProducts,
  loadProductsSuccess,
  loadProductsError,
  addProduct,
  updateProduct,
  deleteProduct,
  loadB2C2ApprovalRequests,
  loadB2C2ApprovalRequestsSuccess,
  loadB2C2ApprovalRequestsError,
  updateB2C2ApprovalRequest,
  b2c2ReviewRequest
}

export const productTypes = types;

import React from 'react';
import clsx from 'clsx';
import { notificationSnackbarStyles } from './notification-snackbar.styles';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { NotificationProps } from './notification-props';
import { notificationVariantIcon } from './notification-variant-icon';

export const NotificationSnackbar: React.FC<NotificationProps> = ({ message, variant }) => {
  const styles = notificationSnackbarStyles();
  const Icon = notificationVariantIcon[variant];

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      ContentProps={{ 'aria-describedby': 'client-snackbar' }}
      open={true}
      aria-live='assertive'
    >
      <SnackbarContent
        className={styles[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={styles.message}       aria-live='assertive'
          >
            <Icon className={clsx(styles.icon, styles.iconVariant)} />
            {message}
          </span>
        }
      />
    </Snackbar>
  );
}

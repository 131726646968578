import { Table } from "@tanstack/table-core"
import React, { useState } from "react"
import Tr from '@visa/vds/tr';
import Td from '@visa/vds/td';
import { TableFormAddCancelAction } from "../../table-cells/b2c2-apps/table-form-add-cancel";
import { Row } from "../../../../../appsNew/views/app-edit/b2c2-app-edit-b2c2b-auth";
import { AddTypeCell } from "../../table-cells/b2c2-apps/app-b2c2b-auth/add-type-cell";
import { SharedSecretEdit } from "../../table-cells/b2c2-apps/app-b2c2b-auth/shared-secret-edit";
import { ApiKeyEdit } from "../../table-cells/b2c2-apps/app-b2c2b-auth/api-key-edit";
import { WsiExternalIdEdit } from "../../table-cells/b2c2-apps/app-b2c2b-auth/wsi-externalId-edit";
import { BasicAuthUserIdEdit } from "../../table-cells/b2c2-apps/app-b2c2b-auth/basic-auth-userId-edit";
import { BasicAuthPasswordEdit } from "../../table-cells/b2c2-apps/app-b2c2b-auth/basic-auth-password-edit";
import { ClientKeyIdEdit } from "../../table-cells/b2c2-apps/app-b2c2b-auth/client-keyId-edit";
import { ServerCertIdEdit } from "../../table-cells/b2c2-apps/app-b2c2b-auth/server-certId-edit";

export const B2C2BAuth: React.FC<Table<Row>> = (table) => {
    const host = window.location.host;
    const splits = host.split("-");
    let isWSIExternalIdEditable = false;
    if (splits.length > 2) {
        if (splits[2] === "qapenb.oce" || splits[2] === "qaintb.oce") {
            isWSIExternalIdEditable = true;
        }
    }
    return <Tr >
        <Td><AddTypeCell {...table} /></Td>
        <Td><SharedSecretEdit {...table} /></Td>
        <Td><ApiKeyEdit {...table} /></Td>
        {isWSIExternalIdEditable ?<><Td><WsiExternalIdEdit {...table} /></Td></> : <div>N/A</div>}
        <Td><BasicAuthUserIdEdit {...table} /></Td>
        <Td><BasicAuthPasswordEdit {...table} /></Td>
        <Td><ClientKeyIdEdit {...table} /></Td>
        <Td><ServerCertIdEdit {...table} /></Td>
        <Td><TableFormAddCancelAction {...table as Table<any>} /></Td>
    </Tr>
}
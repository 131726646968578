import { User } from '../model/user';
import { UserRS } from './dtos';

export function mapUser(dto: UserRS): User {
  const model: User = {
    id: dto.id,
    username: dto.username,
    createdAt: dto.created_at,
    modifiedAt: dto.modified_at,
  };
  return model;
}

import {
  Grid,
} from '@material-ui/core';
import React, { ChangeEvent} from "react"
import { FormErrors } from '../../../../hooks/use-form.hook';
import { useFormsStyles } from '../../../../styles/forms.styles';
import { B2C2ApplicationB2C2BArgs, appStatusTypes } from '../../model/application';
import { appWizardStyles } from '../app-wizard/app-wizard.styles';
import Input from '@visa/vds/input';
import { vdsStyleOverrides } from '../../../../vds-overrides.styles';

export interface B2C2AppEditVdpAppIdProps {
  b2c2bArgs: B2C2ApplicationB2C2BArgs | undefined;
  onVdpAppIdChange: (vdpAppId: string) => void;
  errors: FormErrors;
}

export const B2C2AppEditVdpAppId: React.FC<B2C2AppEditVdpAppIdProps> = ({ b2c2bArgs, onVdpAppIdChange, errors }) => {
  const styles = useFormsStyles();
  const wizardStyles = appWizardStyles();
  const vdsStyles = vdsStyleOverrides();

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container className={wizardStyles.marginBtm}>
          <Grid item xs={6}>
          <Input
            errorText={errors?.vdpAppId}
            label="VDP App Id *"
            name={"b2c2bArgs_vdpAppId"}
            value={b2c2bArgs?.vdpAppId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onVdpAppIdChange(e.target.value)}
            showErrorText={b2c2bArgs !== undefined && !!errors?.vdpAppId}
            invalid={b2c2bArgs !== undefined && !!errors?.vdpAppId}
            className={vdsStyles.inputFullWidth}
            aria-required
          ></Input>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
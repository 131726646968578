import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { Row } from "../../../../../../products/views/product-edit/product-edit-headers";

export const AddHeaderDefaultValueCall: React.FC<Table<Row>> = (table) => {
    const [defaultValuError, setDefaultValuError] = useState<string>("");
    return <Input
        style={{ height: "unset" }}
        errorText={defaultValuError}
        label="Default Value *"
        name={"default_name"}
        value={table.options.meta?.dirtyRow?.defaultValue || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setDefaultValuError(table.options.meta?.isIndividualCellValid("defaultValue", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "defaultValue");
        }}
        showErrorText={!!defaultValuError}
        invalid={!!defaultValuError}
    ></Input>
}
import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import { blockingLoadingStyles } from './blocking-loading.styles';

export interface BlockingLoadingProps {
  open: boolean;
}

export const BlockingLoading: React.FC<BlockingLoadingProps> = ({ open }) => {
  const styles = blockingLoadingStyles();
  return (
    <Backdrop className={styles.overlay} open={open}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
}

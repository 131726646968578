import React from 'react';
import { useRoutes } from 'react-router-dom';
import { AddNew } from '../../views/add-new/add-new';
import { ProductList } from './views/product-list/product-list';
import { ProductEdit } from './views/product-edit/product-edit';
import { B2C2ProductView } from './views/product-view/product-view';
import { ProductWizard } from './views/product-wizard/product-wizard';
import { B2C2ProductApprovals } from './views/approvals/b2c2-product-approvals';
import { B2C2ConsumerRequest } from './views/approvals/b2c2-consumer-requests';
import { B2C2ApprovalRequestView } from './views/approvals/b2c2-approval-request-view';

const routes: any[] = [
  { path: '/', element: <ProductList /> },
  { path: 'new', element: <ProductWizard /> },
  { path: 'add',
    element: <AddNew
        title='Add a new B2C Product'
        isApp={false}
        wizardNavLink='/b2c/products/new'
        jsonType='b2cProduct'
    /> },
  { path: ':productId', element: <B2C2ProductView /> },
  { path: ':productId/edit', element: <ProductEdit /> },
  { path: 'approvals', element: <B2C2ProductApprovals /> },
  { path: 'approvals/:requestGuid', element: <B2C2ApprovalRequestView /> },
  { path: 'requests', element: <B2C2ConsumerRequest /> },
  { path: '*', redirectTo: '/404' },
];

export const B2C2ProductsRouter: React.FC = () => useRoutes(routes);
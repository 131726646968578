import { Table } from "@tanstack/table-core"
import React, { ChangeEvent, useState } from "react"
import { Row } from "../../../../app-edit/b2c2-app-edit-identities"
import Input from '@visa/vds/input';
import { vdsStyleOverrides } from "../../../../../../../vds-overrides.styles";

export const B2C2AppsTemplateExpiryCell: React.FC<Table<Row>> = (table) => {
  const [expiryMinutesError, setExpiryMinutesError] = useState<string>("");
  const vdsStyles = vdsStyleOverrides();
  return <Input
    style={{ height: "unset" }}
    className={vdsStyles.inputFullWidth}
    label="Expiry Minutes"
    value={table.options.meta?.dirtyRow?.expiryMinutes || ''}
    onChange={(e: ChangeEvent<HTMLInputElement>) => {
      setExpiryMinutesError(table.options.meta?.isIndividualCellValid("expiryMinutes", e.target.value) || "");
      table.options?.meta?.setEditCelldata("", e.target.value, "expiryMinutes");
    }}
    //onBlur={() => setExpiryMinutesError(table.options.meta?.isIndividualCellValid("expiryMinutes", table.options.meta?.dirtyRow?.expiryMinutes) || "")}
    disabled= {(table.options.meta?.dirtyRow?.templateType === 'VERIFY_OTP' || table.options.meta?.dirtyRow?.templateType === 'RESET_PASSWORD_OTP')? false: true}
    aria-required
    errorText={expiryMinutesError}
    showErrorText={!!expiryMinutesError}
    invalid={!!expiryMinutesError}
    autoFocus
  ></Input>
}
import { networkUserSrv } from '../services/network-user.service';
import { useUsersStore } from '../state/users-selectors';
import { usersActions } from '../state/users-actions';
import { useNotifications } from '../../../hooks/use-notifications';
import { User } from '../model/user';

export interface UseUsersResult {
  users: User[] | null;
  loading: boolean;
  loadUsersAsync: () => Promise<void>;
}

export function useUsers(): UseUsersResult {
  const [state, dispatch] = useUsersStore();
  const { pushNotification } = useNotifications();

  const loadUsersAsync = async () => {
    dispatch(usersActions.loadUsers());
    try {
      const services = await networkUserSrv.getUsersAsync();
      dispatch(usersActions.loadUsersSuccess(services));
    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      dispatch(usersActions.loadUsersError(error));
      pushNotification({
        message: `Load users failed with '${error}'`,
        variant: 'error',
        type: 'snackbar',
      });
    }
  }

  return {
    users: state.users,
    loading: state.loading,
    loadUsersAsync,
  };
}

import { Table } from "@tanstack/table-core"
import React from "react"
import Tr from '@visa/vds/tr';
import Td from '@visa/vds/td';
import { TableFormAddCancelAction } from "../../table-cells/b2c2-apps/table-form-add-cancel";
import { B2cHeaderGuidSelectCell } from "../../table-cells/b2c2-apps/headers/header-guid-cell";
import { B2C2AppsHeaderValueCell } from "../../table-cells/b2c2-apps/headers/header-Value-Cell";
import { Row } from "../../../../views/app-edit/b2c2-app-edit-headers";

export const B2C2AppsAddHeader: React.FC<Table<Row>> = (table) => {

    return <Tr >
        <Td>
            <B2cHeaderGuidSelectCell {...table} />
        </Td>
        <Td>{table.options.meta?.dirtyRow?.headerName}</Td>
        <Td>
           <B2C2AppsHeaderValueCell {...table}/>
        </Td>
        <Td>
            <TableFormAddCancelAction {...table as Table<any>}/>
        </Td>
    </Tr>
}
import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { Row } from "../../../../app-edit/b2c2-app-edit-b2c2b-mle";

export const AddMleKeyCall: React.FC<Table<Row>> = (table) => {
    const [mleKeyError, setMleKeyError] = useState<string>("");
    const mleDatas = table.options.meta?.data?.mles || {};
    return <Input
        style={{ height: "unset" }}
        errorText={mleKeyError}
        label="MLE Key Id *"
        name={"mleKeyId"}
        value={table.options.meta?.dirtyRow?.mleKeyId || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setMleKeyError(table.options.meta?.isIndividualCellValid("mleKeyId", e.target.value) || "");
            if(mleDatas.some((mleData: { mleKeyId: string; }) => mleData.mleKeyId === e.target.value)){
                setMleKeyError("MLE Key Id should be unique");
            }
            table.options?.meta?.setEditCelldata("", e.target.value, "mleKeyId");
        }}
        autoFocus
        showErrorText={!!mleKeyError}
        invalid={!!mleKeyError}
    ></Input>
}
import * as yup from 'yup';
import { B2BOperation } from '../../services/model/service';
import { ApplicationCommunicationTypes, ApplicationTemplateTypes, B2C2ApplicationCommunicationTemplateCreate, B2C2ApplicationConfigCreate, B2C2ApplicationCreate } from './application-create';
import { B2C2ApplicationUpdate } from './application-update';
import {appOutboundEventTypes, B2C2ApplicationHeaders, B2BApplicationOutboundEvent, B2C2ApplicationB2C2BArgs, B2C2ApplicationB2C2BArgsMle, B2C2ApplicationB2C2BBasicAuthCreds, B2C2ApplicationB2C2BArgsAuthN, B2C2ApplicationB2C2BArgsAttr} from "./application";
import { MultiDataType } from '../../../model/multi-type';

export const b2bApplicationOperationCreateSchema: yup.ObjectSchema<yup.Shape<B2BOperation, any>> = yup.object().shape<B2BOperation>({
  operationGuid: yup.string().required('Required'),
  name: yup.string().required('Required'),
  serviceGuid: yup.string().required('Required'),
  externalPath: yup.string().required('Required'),
  internalPath: yup.string().required('Required'),
  methodType: yup.mixed().oneOf(['GET', 'POST', 'PUT', 'PATCH', 'DELETE']).required('Required'),
  description: yup.string().required('Required'),
  createdBy: yup.string(),
  createdAt: yup.string().required('Required'),
  lastModifiedBy: yup.string(),
  lastModifiedAt: yup.string().required('Required'),
  mleType: yup.string(),
  status:yup.string()
});

export const b2c2ApplicationTemplateCreateSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationCommunicationTemplateCreate, any>> = yup.object().shape<B2C2ApplicationCommunicationTemplateCreate>({
  templateType: yup.mixed().oneOf(ApplicationTemplateTypes),
  commType: yup.mixed(),
  expiryMinutes: yup.number().moreThan(-1, "Invalid expiryMinutes"),
  content: yup.string(),
  id: yup.number()
});

export const b2c2ApplicationConfigCreateSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationConfigCreate, any>> = yup.object().shape<B2C2ApplicationConfigCreate>({
  configKey: yup.string().required('Required'),
  // dataType: yup.mixed().oneOf(dataTypes),
  configValue: yup.mixed().when('dataType', (dataType: MultiDataType) => {
    switch (dataType) {
      case 'number':
        return yup.number().required('Required');
      case 'boolean':
        return yup.boolean().required('Required');
      case 'string[]':
        return yup.array(yup.string()).required('Required');
      case 'number[]':
        return yup.array(yup.number()).required('Required');
      case 'string':
      default:
        return yup.string().required('Required');
    }
  }),
});

export const b2bApplicationHeaderCreateSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationHeaders, any>> = yup.object().shape<B2C2ApplicationHeaders>({
  headerId: yup.string().required('Required'),
  headerValue: yup.string().required('Required'),
  appHeaderId: yup.string()
});

export const b2bApplicationOutboundEventsCreateSchema: yup.ObjectSchema<yup.Shape<B2BApplicationOutboundEvent, any>> = yup.object().shape<B2BApplicationOutboundEvent>({
  eventGuid: yup.string().required('Required'),
  eventType: yup.mixed().oneOf(appOutboundEventTypes).required('Required'),
});

export const b2c2ApplicationB2C2BArgsMleSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationB2C2BArgsMle, any>> = yup.object().shape<B2C2ApplicationB2C2BArgsMle>({
  id: yup.number(),
  mleKeyId: yup.string().required("Required").matches(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, 'Invalid UUID format'),
  mleStatus: yup.string().required('Required'),
  mleClientKeyId: yup.string().required("Required").matches(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, 'Invalid UUID format'),
  mleServerCertId: yup.string().required("Required").matches(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, 'Invalid UUID format'),
  mleId: yup.string(),
  appId: yup.string(),
  createdBy: yup.string(),
  createdAt: yup.string(),
  lastModifiedBy: yup.string(),
  lastModifiedAt: yup.string(),
});

export const b2c2ApplicationB2C2BBasicAuthCredsSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationB2C2BBasicAuthCreds, any>> = yup.object().shape<B2C2ApplicationB2C2BBasicAuthCreds>({
  userId: yup.string(),
  password: yup.string(),
});

export const b2c2ApplicationB2C2BArgsAttrSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationB2C2BArgsAttr, any>> = yup.object().shape<B2C2ApplicationB2C2BArgsAttr>({
  sharedSecret: yup.string(),
  apiKey: yup.string(),
  clientKeyId: yup.string().matches(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, 'Invalid UUID format'),
  serverCertId: yup.string().matches(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, 'Invalid UUID format'),
  basicAuthUserId: yup.string().required(),
  basicAuthPassword: yup.string().required(),
  wsiExternalId: yup.string(),
  createdBy: yup.string(),
  createdAt: yup.string(),
  lastModifiedBy: yup.string(),
  lastModifiedAt: yup.string(),
});

export const b2c2ApplicationB2C2BArgsAttributeSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationB2C2BArgsAttr, any>> = yup.object().shape<B2C2ApplicationB2C2BArgsAttr>({
  sharedSecret: yup.string().required(),
  apiKey: yup.string().required(),
  clientKeyId: yup.string(),
  serverCertId: yup.string(),
  basicAuthUserId: yup.string(),
  basicAuthPassword: yup.string(),
  wsiExternalId: yup.string(),
  createdBy: yup.string(),
  createdAt: yup.string(),
  lastModifiedBy: yup.string(),
  lastModifiedAt: yup.string(),
});

export const b2c2ApplicationB2C2BArgsAuthNSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationB2C2BArgsAuthN, any>> = yup.object().shape<B2C2ApplicationB2C2BArgsAuthN>({
  id: yup.number(),
  type: yup.string().required('Required'),
  attr: yup.object().when('type',{
    is:(type:string)=>(type==='X_PAY_TOKEN'),
    then:b2c2ApplicationB2C2BArgsAttributeSchema,
    otherwise:b2c2ApplicationB2C2BArgsAttrSchema
  }),
  authnId: yup.string(),
  appId: yup.string(),
  createdBy: yup.string(),
  createdAt: yup.string(),
  lastModifiedBy: yup.string(),
  lastModifiedAt: yup.string(),
});

export const appB2C2BArgsCreateSchema: yup.ObjectSchema<
  yup.Shape<B2C2ApplicationB2C2BArgs, any>
> = yup.object().shape<B2C2ApplicationB2C2BArgs>({
  vdpAppId: yup.string().required("Required").matches(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, 'Invalid UUID format'),
  mle: yup.array(b2c2ApplicationB2C2BArgsMleSchema),
  authN: yup.array(b2c2ApplicationB2C2BArgsAuthNSchema),
});

export const appB2C2BArgsEditSchema: yup.ObjectSchema<
  yup.Shape<B2C2ApplicationB2C2BArgs, any>
> = yup.object().shape<B2C2ApplicationB2C2BArgs>({
  vdpAppId: yup.string(),
  mle: yup.array(b2c2ApplicationB2C2BArgsMleSchema),
  authN: yup.array(b2c2ApplicationB2C2BArgsAuthNSchema),
});

export const b2c2ApplicationCreateSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationCreate, any>> = yup.object().shape<B2C2ApplicationCreate>({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
  namespace: yup.string().required('Required'),
  supportGdl: yup.string().required('Required').matches(/@visa\.com$/, ' must be of *@visa.com format'),
  allowedEndpoints: yup.array<string>().required('Required'),
  owners: yup.array<string>(),
  appProductHeaders: yup.array(b2bApplicationHeaderCreateSchema),
  whitelistedIdentities: yup.array<string>(),
  configs: yup.array(b2c2ApplicationConfigCreateSchema).notRequired(),
  templates: yup.array(b2c2ApplicationTemplateCreateSchema).notRequired(),
  // fromId: yup.string(),
  // fromDisplayName: yup.string()
  fromId: yup.string().when('configs', {
    is: (configs) => {
      let con = configs ? configs.find((con : any) => con.configKey === 'vp2_user_mgmt') : {};
      return con.configValue || con.configValue === "true";
    },
    then : yup.string().required('Required'),
    otherwise: yup.string()
  }),
  fromDisplayName: yup.string().when('configs', {
    is: (configs) => {
      let con = configs ? configs.find((con : any) => con.configKey === 'vp2_user_mgmt') : {};
      return con.configValue || con.configValue === "true";
    },
    then : yup.string().required('Required'),
    otherwise: yup.string()
  }),
  b2c2bArgs: appB2C2BArgsCreateSchema,
});

export const b2c2ApplicationEndpointCreateSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationHeaders, any>> = yup.object().shape<B2C2ApplicationHeaders>({
  headerId: yup.string().required('Required'),
  headerValue: yup.string().required('Required'),
  appHeaderId: yup.string()
});

export const b2c2ApplicationUpdateSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationUpdate, any>> = yup.object().shape<B2C2ApplicationUpdate>({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
  namespace: yup.string().required('Required'),
  supportGdl: yup.string().required('Required').matches(/@visa\.com$/, ' must be of *@visa.com format'),
  allowedEndpoints: yup.array<string>().required('Required'),
  owners: yup.array<string>(),
  appProductHeaders: yup.array(b2bApplicationHeaderCreateSchema),
  whitelistedIdentities: yup.array<string>(),
  configs: yup.array(b2c2ApplicationConfigCreateSchema).notRequired(),
    templates: yup.array(b2c2ApplicationTemplateCreateSchema).notRequired(),
    fromId: yup.string().when('configs', {
      is: (configs) => {
        let con = configs ? configs.find((con : any) => con.configKey === 'vp2_user_mgmt') : {};
        return con.configValue || con.configValue === "true";
      },
      then : yup.string().required('Required'),
      otherwise: yup.string()
    }),
    fromDisplayName: yup.string().when('configs', {
      is: (configs) => {
        let con = configs ? configs.find((con : any) => con.configKey === 'vp2_user_mgmt') : {};
        return con.configValue || con.configValue === "true";
      },
      then : yup.string().required('Required'),
      otherwise: yup.string()
    }),
  b2c2bArgs: appB2C2BArgsCreateSchema,
});

export const b2c2EditApplicationSchema: yup.ObjectSchema<yup.Shape<B2C2ApplicationUpdate, any>> = yup.object().shape<B2C2ApplicationUpdate>({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
  namespace: yup.string().required('Required'),
  supportGdl: yup.string().required('Required').matches(/@visa\.com$/, ' must be of *@visa.com format'),
  allowedEndpoints: yup.array<string>().required('Required'),
  owners: yup.array<string>(),
  appProductHeaders: yup.array(b2bApplicationHeaderCreateSchema),
  whitelistedIdentities: yup.array<string>(),
  configs: yup.array(b2c2ApplicationConfigCreateSchema).notRequired(),
    templates: yup.array(b2c2ApplicationTemplateCreateSchema).notRequired(),
    fromId: yup.string().when('configs', {
      is: (configs) => {
        let con = configs ? configs.find((con : any) => con.configKey === 'vp2_user_mgmt') : {};
        return con.configValue || con.configValue === "true";
      },
      then : yup.string().required('Required'),
      otherwise: yup.string()
    }),
    fromDisplayName: yup.string().when('configs', {
      is: (configs) => {
        let con = configs ? configs.find((con : any) => con.configKey === 'vp2_user_mgmt') : {};
        return con.configValue || con.configValue === "true";
      },
      then : yup.string().required('Required'),
      otherwise: yup.string()
    }),
  b2c2bArgs: appB2C2BArgsEditSchema,
});
import { Box, Button, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormsStyles } from '../../../../styles/forms.styles';
import { useB2C2Application } from '../../hooks/use-b2c2-application';
import { B2C2Application } from '../../model/application';
import { B2C2AppPreview } from './b2c2-app-preview';
import clsx from 'clsx';
import { buttonStyles } from '../../../../styles/button.styles';
import { B2C2ApplicationUpdate } from '../../model/application-update';
import { useB2C2ApplicationDelete } from '../../hooks/use-b2c2-application-delete';
import DeleteConfirmDialog from '../../../../views/dialog/delete-confirm-dialog';
import { useB2C2IsApplicationOwner } from '../../hooks/use-b2c2-is-application-owner';
import { useUserProfile } from '../../../auth/hooks/use-user-profile.hook';
import { useNotifications } from '../../../../hooks/use-notifications';
import { useB2C2ApprovalRequest } from '../../../products/hooks/use-b2c2-approval-request';
import { Helmet } from 'react-helmet';
import ButtonText from '@visa/vds/button-text';
import { vdsStyleOverrides } from "../../../../vds-overrides.styles";

export const B2C2AppView: React.FC = () => {
    const vdsStyles = vdsStyleOverrides();
    const styles = useFormsStyles();
    const btnStyles = buttonStyles();
    const navigate = useNavigate();
    const { applicationGuid } = useParams();
    const applicationGuidParam = applicationGuid ? applicationGuid : '';
    const { b2c2Application, b2c2PendingApplication, b2c2ActiveApplication } = useB2C2Application(applicationGuidParam);
    const { deleteB2C2ApplicationAsync } = useB2C2ApplicationDelete();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [alignment, setAlignment] = React.useState('PENDING');
    const { userProfile } = useUserProfile();

    useEffect(() => {
        if (b2c2Application === null && b2c2PendingApplication === null) { // application not yet loaded
            return;
        }
        if (b2c2Application === undefined && b2c2PendingApplication === null) {
            navigate('/404', { replace: true });
        }
    }, [b2c2Application, b2c2PendingApplication, navigate]);

    const b2c2App = useMemo<B2C2ApplicationUpdate | null>(() => b2c2ActiveApplication ? {
        ...b2c2ActiveApplication,
        owners: b2c2ActiveApplication.owners.map(owner => owner),
        allowedHeaders: b2c2ActiveApplication.appProductHeaders,
    } : null, [b2c2ActiveApplication]);

    const handleBack = useCallback(() => {
        navigate('/b2c/apps');
    }, [navigate]);

    const handleEdit = useCallback(() => {
        if (b2c2ApprovalRequest?.status == "PENDING" || b2c2ApprovalRequest?.status == "DENIED") {
            pushNotification({ message: 'Cannot Edit this', type: 'snackbar', variant: 'error' });
            return false;
        }

        navigate('edit', { state: { alignment } });
        //navigate('edit');
    }, [navigate, alignment]);

    const handleDeleteBtnClick = useCallback(() => setDeleteDialog(true), [setDeleteDialog]);

    const handleDeleteDialogClose = useCallback(() => setDeleteDialog(false), [setDeleteDialog]);

    const { b2c2ApprovalRequest } = useB2C2ApprovalRequest(b2c2PendingApplication?.requestGuid);
    const { pushNotification } = useNotifications()

    const handleDelete = useCallback(async () => {
        if (applicationGuid) {
            const success = await deleteB2C2ApplicationAsync(applicationGuid);
            if (success) {
                navigate('/b2c/apps');
            } else {
                handleDeleteDialogClose();
            }
        }

    }, [applicationGuid, deleteB2C2ApplicationAsync, navigate, handleDeleteDialogClose]);

    const app = useMemo<B2C2Application | null>(() => b2c2Application ? {
        ...b2c2Application,
    } : null, [b2c2Application]);
    const PendingApp = useMemo<B2C2Application | null>(() => b2c2PendingApplication ? {
        ...b2c2PendingApplication,
    } : null, [b2c2PendingApplication]);

    const isApplicationOwner = useB2C2IsApplicationOwner(applicationGuidParam);
    /* const isApplicationOwner = useCallback(() => {
        return !!PendingApp?.createdBy && !!userProfile?.username && PendingApp.createdBy === userProfile.username;
    }, [PendingApp, userProfile]); */

    const isPendingAppOwner = useCallback(() => {
        return !!app?.createdBy && !!userProfile?.username && app.createdBy === userProfile.username;
    }, [app, userProfile]);


    return (
        <main>
           
                <Helmet>
                    <title>
                        App Details | Visa Prototype Validation Platform
                    </title>
                </Helmet>
	  <Grid container spacing={2}>
                <B2C2AppPreview b2c2Application={app} b2c2PendingApplications={PendingApp} b2c2AppUpdate={b2c2App} alignment={alignment} setAlignment={setAlignment} isApplicationOwner={isApplicationOwner} />
                <Grid container justifyContent="space-between">

                    <Grid item md={2}>
                        <ButtonText isFullWidth onClick={handleBack} colorScheme="primary">
                            Back
                        </ButtonText>
                    </Grid>

                    <Grid item md={4}>
                        <Grid container direction='row' columnGap={2}>
                            {(isApplicationOwner || isPendingAppOwner()) &&
                                <><Grid item lg={5}>
                                    <ButtonText colorScheme="primary" onClick={handleDeleteBtnClick}
                                        isFullWidth>
                                        Delete
                                    </ButtonText>
                                </Grid>
                                    <Grid lg={5}>
                                        <ButtonText colorScheme="primary" onClick={handleEdit}
                                            isFullWidth disabled={b2c2ApprovalRequest?.status == "IN_REVIEW" || !isApplicationOwner}>
                                            Edit
                                        </ButtonText>
                                    </Grid></>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(isApplicationOwner === false && isPendingAppOwner()) &&
                <Box display="flex" justifyContent="center" bgcolor="#ECECE8" className={styles.instructionBox}>
                    <Typography className={styles.instructionText}>READ ONLY - For access contact the app
                        owner(s).</Typography> </Box>}
            {(isApplicationOwner && deleteDialog) && (
                <DeleteConfirmDialog handleDelete={handleDelete} handleClose={handleDeleteDialogClose} isApp={true} />
            )}
        </main>
    );
};
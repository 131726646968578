import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { Row } from "../../../../app-edit/b2c2-app-edit-b2c2b-auth";
import { X_PAY_TOKEN } from "../../../../../constants";
import { b2c2ApplicationB2C2BArgsAttrSchema, b2c2ApplicationB2C2BArgsAttributeSchema } from "../../../../../model/b2c2-application-validations";
import { ValidationError } from "yup";

export const ApiKeyEdit: React.FC<Table<Row>> = (table) => {
    const [apiKeyError, setApiKeyError] = useState<string>("");


    if (table.options.meta?.dirtyRow?.type === X_PAY_TOKEN) {
        return <Input
            style={{ height: "unset" }}
            errorText={apiKeyError}
            label="API Key *"
            name={"apiKey"}
            value={table.options.meta?.dirtyRow?.attr?.apiKey || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                let attribute = {...table.options?.meta?.dirtyRow?.attr,"apiKey": e.target.value}
                try{
                    b2c2ApplicationB2C2BArgsAttributeSchema.validateSyncAt("apiKey", {"apiKey": e.target.value})
                    setApiKeyError("");
                }catch (e) {
                    setApiKeyError((e as ValidationError).message);
                  }
                table.options?.meta?.setEditCelldata("", attribute, "attr");
            }}
            showErrorText={!!apiKeyError}
            invalid={!!apiKeyError}
        ></Input>
    } else if (!table.options.meta?.dirtyRow?.type) {
        return <></>;
    } else {
        return <div>N/A</div>;
    }
}
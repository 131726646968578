import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Select from '@visa/vds/select';
import { ProductEndpointUpdate } from "../../../../../../products/model/product-update";

export const B2BMleTypeCell: React.FC<Table<ProductEndpointUpdate>> = (table) => {
    const [b2cmleTypeError, setB2cMleTypeError] = useState<string>("");
    const mleTypeMap = table.options.meta?.data?.mleTypesMap || {};
    return <Select
        nam="B2c_MLE_Type"
        label="B2C MLE Type"
        fullWidth
        value={table.options.meta?.dirtyRow?.b2cMleType || ''}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setB2cMleTypeError(table.options.meta?.isIndividualCellValid("b2cMleType", e.target.value) || "");
            table.options?.meta?.setEditCelldata("", e.target.value, "b2cMleType");
        }}
        onBlur={()=>setB2cMleTypeError(table.options?.meta?.isIndividualCellValid("b2cMleType",table.options.meta.dirtyRow?.b2cMleType) || "")}
        showErrorText={!!b2cmleTypeError}
        errorText={b2cmleTypeError}
        invalid={!!b2cmleTypeError}
    >
        <option value="" disabled>Select MLE Type</option>
        {Object.entries(mleTypeMap).map(([key, value]) => <option key={key} value={value as string}>{key}</option>)}
    </Select>
}
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useHasPermission, UserPermission} from '../../../../features/auth/hooks/use-has-permission.hook';
import {UserRole} from '../../../../features/auth/model/user-role';
import {AppEntry, B2BAppStatusListCard} from './b2b-app-list-card';
import {ActionItem} from './list-card';
import DashboardDialogs from "../dialog/dashboard-dialog";
import { useB2C2OwnedApplications } from '../../../../features/appsNew/hooks/use-b2c2-owned-applications';

export const B2C2AppsCard: React.FC = () => {
  const { b2c2Applications, ownedApps } = useB2C2OwnedApplications();
  const navigate = useNavigate();
  const b2bAppDeveloper = useHasPermission(UserRole.B2BApplicationDeveloper) === UserPermission.Granted;

  const newAppAction: ActionItem = {
    title: "Add App",
    action: () => navigate('/b2c/apps/add'),
  };
  const viewAllAction: ActionItem = {
    title: "View All Apps",
    action: () => navigate('/b2c/apps'),
  };
  const handleAppClick = useCallback(
    (applicationGuid: string) => navigate(`/b2c/apps/${applicationGuid}`),
    [navigate]
  );

    const apps: AppEntry[] = !b2c2Applications ? [] : b2c2Applications.slice(-4).map(app => {
    return {
      applicationGuid: app.applicationGuid,
      name: app.name,
      description: app.description,
        dateModified: app.lastModifiedAt,
        action: 'view',
        status: app.status,
    };
  });

  const myApps: AppEntry[] = !ownedApps ? [] : ownedApps.slice(-4).map(app => {
    return {
      applicationGuid: app.applicationGuid,
      name: app.name,
      description: app.description,
      dateModified: app.lastModifiedAt,
      action: 'view',
      status: app.status,
    };
  });

  return (
      <>
        {b2bAppDeveloper? <></>: <DashboardDialogs/>}
          <B2BAppStatusListCard
              title="Apps"
              entries={apps}
              ownedEntries={myApps}
              topAction={b2bAppDeveloper ? newAppAction : undefined}
              onEntryClick={b2bAppDeveloper ? handleAppClick : undefined}
              bottomAction={b2bAppDeveloper ? viewAllAction : undefined}
              isB2B={false}
          />
      </>
  );
};

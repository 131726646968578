import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import createTheme from '@material-ui/core/styles/createTheme';
import { CSSProperties } from '@material-ui/core/styles/withStyles';


export const useProductEditStyles = makeStyles((theme: Theme) =>

    createStyles({

        errorsHeading: {
            fontSize: theme.typography.pxToRem(13),
            color: theme.palette.error.main,
        },
        errorText: {
            color: theme.palette.error.main,
        },
        spaceLeft: {
            marginLeft: theme.spacing(2),
        },
        bigIndicator: {
            height: 4,
        },
        cancelBtn: {
            height: "64px",
            width: "100px",
            marginTop: "50px"
        },
        backBtn: {
            marginLeft: theme.spacing(5),
            height: "64px",
            width: "150px",
            marginTop: "50px"
        },
        nextBtn: {
            marginRight: theme.spacing(5),
            height: "64px",
            width: "150px",
            marginTop: "50px"
        },
        submitBtn: {
            height: "64px",
            width: "150px",
            marginTop: "50px",

        },
        marginT30: {
            marginTop: '70px',
        },
        marginB30: {
            marginBottom: '30px'
        },
        label: {
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '28px',
            marginBottom: '8px',
            color:' #1A1F71'
        },
        switchTrack: {
            backgroundColor: '#000'
        },
        switchBase: {
            color: '#000',
            "&.Mui-checked": {
                color: '#003EA9'
            },
            "&.Mui-checked + .MuiSwitch-track": {
                backgroundColor: '#003EA9'
            }
        },
        width50: {
            width: '50%'
        },
        widthFitContent: {
            width: 'fit-content'
        },
        displayFlexColumn: {
            display: 'flex',
            flexDirection: 'column'
        },
        marginB2P: {
            marginBottom: '2%'
        },
        marginB0: {
            marginBottom: '0'
        },
        marginT2P: {
            marginTop: '2%'
        },
        instruction1: {
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "28px",
            marginBottom: theme.spacing(1)
        }
    }),
);

export const productEditStyles = makeStyles(theme => ({
    addBtn: {
        fontSize: '42px'
    },
    title: {
        fontSize: '20px'
    },
    instruction: {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
        marginBottom: "30px"
    },
    instruction1: {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
        marginBottom: theme.spacing(1)
    },
    instruction2: {
        fontSize: "16px",
        fontWeight: 500,
        marginBottom: theme.spacing(3)
    },
    tableBackgroundColor: {
        backgroundColor: '#F2F4F8'
    },
    smallTitle: {
        color: "#616188",
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: "30px"
    },
    expandText: {
        color: 'grey',
        fontSize: '14px'
    },
    chipItemGrid: {
        padding: '5px'
    },
    commContainerGrid: {
        border: '1px solid grey',
        width: '270px'
    },
    chipContainerGrid: {
        border: '1px solid grey'
    }
}));

export const searchStyle: CSSProperties = {
    border: '1px solid grey',
    height: '42px',
    fontSize: '20px',
    color: '#616188'
};

export const backgroundGrey: CSSProperties = {
    backgroundColor: '#F2F4F8'
};

export const hostTheme = createTheme({
    overrides: {
        MuiTabs: {
            root: {
                marginBottom: "70px"
            },
            flexContainer: {
                display: "flex",
                backgroundColor: "#F2F4F8",
                justifyContent: "center"
            },
        },
        MuiTableRow: {
            root: {
                background: "white",
            },
        },
        MuiTableSortLabel: {
            root: {
                fontSize: "14px",
                fontWeight: 600

            }
        },
        MuiTab: {
            root: {
                boxSizing: "content-box",
                minHeight: "36px"
            },
            textColorPrimary: {
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "16px",
                primary: "003EA9",
                MuiSelected: {
                    color: "#003EA9",
                }
            },
        },
    },
});
import React from 'react';
import { withStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core';

export const StyledMenu = withStyles({
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  paper: {
    border: '1px solid #1A1F71',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles(theme => ({
  root: {
    backgroundColor: "#F2F4F8",
    '& .MuiListItemIcon-root': {
      color: "#2A2E69",
      minWidth: '25px',
    },
    '&:focus': {
      backgroundColor: "#0051DC",
      color: "#FFFFFF",
      '& .MuiSvgIcon-root': {
        color: "#FFFFFF",
      },
    },
  },
}))(MenuItem);

export const newTabStyles = makeStyles((theme) => (
  {
    newTabIcon: {
      position: "relative",
      " &::after": {
        top: "-1.6rem",
        backdropFilter: "brightness(0) invert(1)",
        position: "absolute",
        right: "-2.4rem",
        background: "url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtElEQVR4nO3WMQrCMBTG8f8lGnqBjoLXCNLzuDrp/ezgbrsUegA7SER4BSm1JvASh+aDBykh/HhpAoGcreUAdIBTrhawa3AbAZ3qvga7yPU124W14zI8T97qvx+uPfCcXY0hBfzOMeReasIG6FPDBmhkrk8Fmw+0ke+T/HNV+PEDnbLThEeglnEBXGXBDSgDoGDYenQaBdbu1BsulDv1hi8LqJUDF/UhUAFn6VwbdUq7l0NQXjnq/HgLbaytAAAAAElFTkSuQmCC\")",
        content: "''",
        width: "0.8rem",
        height: "0.8rem",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }
    }
  }

));
import { Button, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { Routes, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from '../../../../hooks/use-form.hook';
import { useNotifications } from '../../../../hooks/use-notifications';
import { buttonStyles } from '../../../../styles/button.styles';
import OneButtonDialog from '../../../../views/dialog/one-button-dialog';
import { useB2C2ApplicationCreate } from '../../hooks/use-b2c2-application-create';
import { B2C2ApplicationHeaders, B2C2ApplicationB2C2BArgsMle, B2C2ApplicationB2C2BArgsAuthN } from "../../model/application";
import { B2C2ApplicationCommunicationTemplateCreate, B2C2ApplicationCreate } from '../../model/application-create';
import { b2c2ApplicationCreateSchema } from '../../model/b2c2-application-validations';
import SuccessIcon from '../app-dialog/icon-success.svg';
import { B2C2AppEditDetails } from '../app-edit/b2c2-app-edit-details';
import { B2C2AppEditHeaders } from "../app-edit/b2c2-app-edit-headers";
import { appWizardStyles } from './app-wizard.styles';
import { AppEditIdentities, TEMPLATE_TYPES } from '../app-edit/b2c2-app-edit-identities';
import { B2C2ApplicationConfigUpdate } from '../../model/application-update';
import { B2C2AppEditConfigs } from '../app-edit/b2c2-app-edit-configs';
import { B2C2AppEditAllowedEndpoints } from '../app-edit/b2c2-app-edit-allowed-endpoints';
import { useB2C2ApprovalRequests } from '../../../products/hooks/use-b2c2-approval-requests';
import { AppEditB2C2BMles } from '../app-edit/b2c2-app-edit-b2c2b-mle';
import { B2C2AppEditVdpAppId } from '../app-edit/b2c2-app-edit-vdp-app-id';
import { AppEditB2C2BAuthNs } from '../app-edit/b2c2-app-edit-b2c2b-auth';
import { useProducts } from '../../../products/hooks/use-products';
import { Step, StepButton, StepLabel, Stepper, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import ButtonText from '@visa/vds/button-text';
enum Steps {
  Details = 0,
  AllowedEndpoints = 1,
  Headers = 2,
  AppConfigs = 3,
  Custom = 4,
  B2C2BArgs = 5,
}

const steps: string[] = ['Details', 'Allowed Endpoints', 'Allowed Headers', 'App Configs', 'Customizations', 'B2C2B Args'];
const defaultB2C2Application: B2C2ApplicationCreate = {
  name: '',
  description: '',
  namespace: '',
  allowedEndpoints: [],
  supportGdl: '',
  owners: [],
  appProductHeaders: [],
  whitelistedIdentities: [],
  fromId: '',
  fromDisplayName: '',
  templates: [],
  b2c2bArgs: undefined
};

export const B2C2AppWizard: React.FC = () => {
  const styles = appWizardStyles();
  const btnStyles = buttonStyles();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { pushNotification } = useNotifications();
  const { products } = useProducts();
  const [blockNavigation, setBlockNavigation] = useState(true);
  const [activeStep, setActiveStep] = useState<Steps>(Steps.Details);
  const initialB2C2Application: B2C2ApplicationCreate = !!state && !!state.wizardObject ? state.wizardObject : defaultB2C2Application;
  const { createB2C2ApplicationAsync } = useB2C2ApplicationCreate();
  const { inputs, errors, handleInputChange, handleInputValueChange, handleValidate } = useForm(initialB2C2Application, b2c2ApplicationCreateSchema);
  const { refreshApprovals } = useB2C2ApprovalRequests();
  const [b2c2bArgsRequired, setB2c2bArgsRequired] = useState(false);
  const handleSubmit = useCallback(async () => {
    setBlockNavigation(false);
    const [, errors] = await handleValidate();
    let isOtpTemplatesInvalid =
      activeStep === Steps.Custom
        ? inputs.templates != null
          ? inputs.templates?.some(
            (template) =>
              (template.templateType === TEMPLATE_TYPES[2] ||
                template.templateType === TEMPLATE_TYPES[3]) &&
              template.commType === null &&
              template.expiryMinutes === 0 &&
              template.content === ''
          )
          : false
        : false;
    let isPasswordTemplatesInvalid =
      activeStep === Steps.Custom
        ? inputs.templates != null
          ? inputs.templates?.some(
            (template) =>
              (template.templateType === TEMPLATE_TYPES[0] ||
                template.templateType === TEMPLATE_TYPES[1] || template.templateType === TEMPLATE_TYPES[4] || template.templateType === TEMPLATE_TYPES[5] ||
                template.templateType === TEMPLATE_TYPES[6]
                ) &&
              template.content === '' && template.commType === null
          )
          : false
        : false;
    if (
      activeStep === Steps.Custom &&
      (errors.fromId || errors.fromDisplayName)
    ) {
      pushNotification({
        message: "Fix errors before continue",
        type: "snackbar",
        variant: "error",
      });
    } else if (activeStep === Steps.Custom && isOtpTemplatesInvalid) {
      pushNotification({
        message: `Atleast one of Comm Type, Expiry Minutes or Content is required`,
        type: "snackbar",
        variant: "error",
      });
    } else if (activeStep === Steps.Custom && isPasswordTemplatesInvalid) {
      pushNotification({
        message: `Comm Type or Content is required`,
        type: "snackbar",
        variant: "error",
      });
    } else if (b2c2bArgsRequired && !inputs.b2c2bArgs?.vdpAppId) {
      pushNotification({ message: 'vdpAppId is required as you have selected at least one endpoint that requires B2C2B Args', type: 'snackbar', variant: 'error' });
    } else if (b2c2bArgsRequired && !inputs.b2c2bArgs) {
      pushNotification({ message: 'B2C2B Args is required as you have selected at least one endpoint that requires B2C2B Args', type: 'snackbar', variant: 'error' });
    } else {
      const success = await createB2C2ApplicationAsync(inputs);
      if (success && success.status) {
        refreshApprovals();
        navigate("/b2c/apps/" + success.applicationGuid);
        // window.location.reload()
        // handleOpenDialog()
      } else {
        setBlockNavigation(true);
      }
    }

  }, [inputs, createB2C2ApplicationAsync, navigate]);

  const handleCancel = useCallback(() => {
    navigate('/b2c/apps');
  }, [navigate]);

  const isFinalStep = useCallback((): boolean => {
    return (activeStep === steps.length - 1);
  }, [activeStep]);

  const areDetailsInvalid = useCallback(
    (errors: Record<string, any>) => errors.name || errors.description || errors.owners || errors.namespace || errors.supportGdl,
    []
  );

  const handleValidateStep = useCallback(async () => {
    const [, errors] = await handleValidate();
    let isOtpTemplatesInvalid =
    activeStep === Steps.Custom
      ? inputs.templates != null
        ? inputs.templates?.some(
          (template) =>
            (template.templateType === TEMPLATE_TYPES[2] ||
              template.templateType === TEMPLATE_TYPES[3]) &&
            template.commType === null &&
            template.expiryMinutes === 0 &&
            template.content === ''
        )
        : false
      : false;
    
  let isPasswordTemplatesInvalid =
    activeStep === Steps.Custom
      ? inputs.templates != null
        ? inputs.templates?.some(
          (template) =>
            (template.templateType === TEMPLATE_TYPES[0] ||
              template.templateType === TEMPLATE_TYPES[1] || template.templateType === TEMPLATE_TYPES[4] || template.templateType === TEMPLATE_TYPES[5] ||
              template.templateType === TEMPLATE_TYPES[6]
              ) 
              && template.content === '' && template.commType === null
        )
        : false
      : false;
    if (activeStep === Steps.Details && areDetailsInvalid(errors)){
      if(errors["name"]){
        document.getElementById("appname-id")?.focus();
      }else if(errors["description"]){
        document.getElementById("appdescription-id")?.focus();
      }else if(errors["namespace"]){
        document.getElementById("namespace-id")?.focus();
      }else if(errors["supportGdl"]){
        document.getElementById("supportGdl-id")?.focus();
      }
      pushNotification({ message: 'Fix errors before continue', type: 'snackbar', variant: 'error' });
    } else if(activeStep === Steps.AllowedEndpoints && inputs.allowedEndpoints.length === 0){
      pushNotification({ message: 'Select Minimum One Endpoint, before continue', type: 'snackbar', variant: 'error' });
    }else if (
      activeStep === Steps.Custom &&
      (errors.fromId || errors.fromDisplayName)
    ) {
      if(errors.fromId){
        document.getElementById("fromid-id")?.focus();
      }else if(errors.fromDisplayName){
        document.getElementById("fromdisplayname-id")?.focus();
      }
      pushNotification({
        message: "Fix errors before continue",
        type: "snackbar",
        variant: "error",
      });
    } else if (activeStep === Steps.Custom && isOtpTemplatesInvalid) {
      setActiveStep(4);
      pushNotification({
        message: `Fix errors before continue`,
        type: "snackbar",
        variant: "error",
      });
    } else if (activeStep === Steps.Custom && isPasswordTemplatesInvalid) {
      setActiveStep(4);
      pushNotification({
        message: `Fix errors before continue`,
        type: "snackbar",
        variant: "error",
      });
    }else {
      setActiveStep(activeStep => activeStep + 1);
    }
  }, [activeStep, handleValidate, pushNotification, areDetailsInvalid, inputs.allowedEndpoints.length]);

  const handleBack = useCallback(() => setActiveStep(activeStep => activeStep - 1), []);

  const handleBackOperations = () => { }//useCallback(() => setActiveStep(Steps.AllowedOperations), []);
  const handleBackHeaders = () => { }// useCallback(() => setActiveStep(Steps.Headers), []);
  const handleBackDetails = () => { }// useCallback(() => setActiveStep(Steps.Details), []);
  const handleBackOutboundEvents = () => { }// useCallback(() => setActiveStep(Steps.OutboundEvents), []);

  const handleAllowedEndpointsChange = useCallback(
    (allowedEndpoints: string[]) => {
      handleInputValueChange('allowedEndpoints', allowedEndpoints);
      setB2c2bArgsRequired(products!.filter(product => product.productEndpoints.some(op => !!allowedEndpoints.includes(op.endpointId))).some(product => !!product.productB2C2BArgs?.useVp2Connector));
    },
    [handleInputValueChange]
  );
  const handleAllowedHeadersChange = useCallback(
    (headers: B2C2ApplicationHeaders[] | undefined) => {
      handleInputValueChange('appProductHeaders', headers);
    },
    [handleInputValueChange]
  );
  const handleIdentitiesChange = useCallback(
    (identities: string[]) => {
      handleInputValueChange('whitelistedIdentities', identities);
    },
    [handleInputValueChange]
  );
  const handleFormIdChange = useCallback(
    (formId: string) => {
      handleInputValueChange('fromId', formId);
    },
    [handleInputValueChange]
  );

  const handleDisplayNameChange = useCallback(
    (formDisplayName: string) => {
      handleInputValueChange('fromDisplayName', formDisplayName);
    },
    [handleInputValueChange]
  );

  const handleAppConfigsChange = useCallback(
    (appConfigs: B2C2ApplicationConfigUpdate[]) => handleInputValueChange('configs', appConfigs),
    [handleInputValueChange]
  );

  const handleTemplateChange = useCallback(
    (data: B2C2ApplicationCommunicationTemplateCreate[]) => {
      handleInputValueChange('templates', data);
    },
    [handleInputValueChange]
  );
  const handleVdpAppIdChange = useCallback(
    (vdpAppId: string) => {
      handleInputValueChange('b2c2bArgs', {
        ...inputs.b2c2bArgs,
        vdpAppId: vdpAppId,
      });
    },
    [handleInputValueChange]
  );

  const handleMlesChange = useCallback((mles: B2C2ApplicationB2C2BArgsMle[]) => {
    return handleInputValueChange('b2c2bArgs', {
      ...inputs.b2c2bArgs,
      mle: mles,
    });
  },
    [handleInputValueChange]
  );

  const handleAuthNsChange = useCallback((authN: B2C2ApplicationB2C2BArgsAuthN[]) => {
    return handleInputValueChange('b2c2bArgs', {
      ...inputs.b2c2bArgs,
      authN: authN,
    });
  },
    [handleInputValueChange]
  );

  const getActiveStepContent = useCallback((): any => {
    switch (activeStep) {
      case 0:
        return <B2C2AppEditDetails details={inputs} onInputChange={handleInputChange} onInputValueChange={handleInputValueChange} errors={errors} isCreate />;
      case 1:
        return <B2C2AppEditAllowedEndpoints endpointIds={inputs.allowedEndpoints} onEndpointChange={handleAllowedEndpointsChange} isCreate />;
      case 2:
        return <B2C2AppEditHeaders applicationHeaders={inputs.appProductHeaders} endpointIds={inputs.allowedEndpoints} onHeaderChange={handleAllowedHeadersChange} isCreate />;
      case 3:
        return <B2C2AppEditConfigs configs={inputs.configs || []} onChange={handleAppConfigsChange} isCreate />;
      case 4:
        return <AppEditIdentities whitelistedIdentities={inputs.whitelistedIdentities}
          templates={inputs.templates}
          fromId={inputs.fromId}
          fromDisplayName={inputs.fromDisplayName}
          onChange={handleIdentitiesChange}
          onTemplateChange={handleTemplateChange}
          handleFormIdChange={handleFormIdChange}
          handleDisplayNameChange={handleDisplayNameChange}
          formErrors={errors}
          isCreate
        />;
      case 5:
        return (<Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Helmet>
              <title>
                B2C2B Args (6 of 6) | Add new B2C Application | Visa Prototype Validation Platform
              </title>
            </Helmet>
            <TypographyHighLight headerTitle="B2C2B Args (6 of 6) | Add new B2C Application" variant='h5' />
            {!b2c2bArgsRequired ?
              <Typography className={styles.instructions}>
                No need to fill B2C2B Args since none of the chosen endpoints require B2C2B Args.
              </Typography>
              :
              <>
                <Typography color="primary" className={styles.instructions}>
                  Please fill in your App B2C2B Args
                </Typography>
                {b2c2bArgsRequired && <Typography component="h2" className={styles.smallTitle}>
                  Note: You are required to enter the App B2C2B Args as you have selected at least one endpoint that requires B2C2B Args.
                </Typography>}
                <B2C2AppEditVdpAppId b2c2bArgs={inputs.b2c2bArgs} onVdpAppIdChange={handleVdpAppIdChange} errors={errors.b2c2bArgs} />
                <AppEditB2C2BMles
                  mles={inputs.b2c2bArgs?.mle === undefined ? [] : inputs.b2c2bArgs?.mle}
                  existingMles={undefined}
                  onMlesChange={handleMlesChange}
                />
                <AppEditB2C2BAuthNs
                  authNs={inputs.b2c2bArgs?.authN === undefined ? [] : inputs.b2c2bArgs?.authN}
                  onAuthNsChange={handleAuthNsChange}
                />
              </>}
          </Grid>
        </Grid>
        )
      default:
        setActiveStep(0);
        return '';
    }
  }, [activeStep, inputs, handleInputChange, handleInputValueChange, errors, handleAllowedEndpointsChange, handleAllowedHeadersChange, handleBackDetails, handleBackOperations, handleBackHeaders, handleBackOutboundEvents, handleVdpAppIdChange]);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true)
  };
  const theme = useTheme();
  const mediaCheck = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <Routes>
        when={blockNavigation}
        message={'You may have pending changes that you will lose if you continue. Do you want to continue?'}
      </Routes>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} role="navigation" title="Application Stepper status" aria-label="Application Stepper status">
          <Stepper activeStep={activeStep} alternativeLabel={!mediaCheck} orientation={mediaCheck ? 'vertical' : 'horizontal'} >
            {steps.map((label, index) => (
              <Step key={label} completed={false} sx={() => ({
                ".MuiStepIcon-text": {
                  fill: "white"
                },
                ".MuiSvgIcon-root": {
                  color: "#1c2172"
                },
              })} aria-current={activeStep === index ? "step" : undefined}>
                <StepLabel color="inherit" aria-label={`${index + 1}`}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Divider className={styles.divider} />
          </Grid>
        </Grid>
        <Grid container component={"main"} justifyContent='center'>
          {getActiveStepContent()}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={11}>
            <Grid container justifyContent="space-between" direction='row' wrap='nowrap'>
              <Grid container direction='row' columnGap={2} lg={4}>
                <Grid item lg={4}>
                  <ButtonText isFullWidth onClick={handleCancel}>
                    Cancel
                  </ButtonText>
                </Grid>
                {
                  activeStep === Steps.Details ?
                    <></> :
                    <Grid item lg={5}>
                      <ButtonText
                        isFullWidth
                        onClick={handleBack}
                        aria-label={"Back to previous step"}
                      >
                        Back
                      </ButtonText>
                    </Grid>
                }
              </Grid>
              <Grid item lg={2}>
                <ButtonText
                  isFullWidth
                  onClick={isFinalStep() ? handleSubmit : handleValidateStep}
                >
                  {isFinalStep() ? 'Save & Preview' : 'Continue'}
                </ButtonText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {dialogOpen ? <OneButtonDialog title={'Success!'} titleIcon={SuccessIcon} btnNavigate={'/b2c/apps'} btnText={'Go To App Page'}
        content={'Your app has been saved. To view the state of your app, click on the button below'} /> : <div />}
    </>
  );
};
import { Grid,Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useProducts } from '../../../products/hooks/use-products';
import { ProductHeader } from '../../../products/model/product';
import { Product } from '../../../products/model/product';
import { B2C2ApplicationHeaders } from "../../model/application";
import { AppEditDetailsPlaceHolder } from "./app-edit-details-placeholder";
import { appEditStyles, backgroundGrey, hostTheme, searchStyle } from './app-edit.styles';
import { Helmet } from 'react-helmet';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { ColumnDef, SortingState, Table, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { TableFormEditDeleteAction } from '../table-abstraction/table-cells/b2c2-apps/table-form-edit-delete-action';
import { TableCore } from '../table-abstraction/table-core';
import { B2C2AppsHeaderNameCell } from '../table-abstraction/table-cells/b2c2-apps/headers/header-name-cell';
import { B2C2AppsHeaderValueCell } from '../table-abstraction/table-cells/b2c2-apps/headers/header-Value-Cell';
import { B2C2AppsAddHeader } from '../table-abstraction/table-rows/b2c2-apps/add-header-row';
import { HeaderSearchAddButton } from '../table-abstraction/filter-implementation/header-search-addButton';
import { b2bApplicationHeaderCreateSchema } from '../../model/b2c2-application-validations';
import { ValidationError } from 'yup';
import { EditCellWrapper } from '../table-abstraction/table-cells/cell-wrappers/EditCellWrappers';
export interface Row {
  product?: string;
  headerId: string;
  headerName: string;
  value: string;
  appHeaderId: string;
}

export interface B2C2AppEditHeadersProps {
  applicationHeaders: B2C2ApplicationHeaders[] | undefined;
  endpointIds: string[];
  onHeaderChange: (headers: B2C2ApplicationHeaders[]) => void;
  isCreate?: boolean;
}

export const B2C2AppEditHeaders: React.FC<B2C2AppEditHeadersProps> = ({ applicationHeaders = [], endpointIds, onHeaderChange, isCreate = true }) => {
  const { products, loading }:{ products: Product[], loading: boolean } = useProducts();
  const styles = appEditStyles();

  // we get a list of endpointIds and we derieve a list of products which contains atleast one of the endpointIds 
  const activeProducts: Product[] = useMemo(
    () => (products!.filter(products => products.productEndpoints.some(op => !!endpointIds.includes(op.endpointId)))), 
    [products, endpointIds]);

    // from the list of products from the previous step we get a list of all the products headers and concatenate into a single array. The array od of objects.
  const productHeaders:ProductHeader[] = useMemo<ProductHeader[]>(() => (
    activeProducts.reduce<ProductHeader[]>(
      (result, prd) => !prd.productHeaders
        ? result
        : prd.productHeaders.reduce<ProductHeader[]>(
          (result, prdHeader) => result.concat(prdHeader),
          result
        ),
      []
    )
  ), [activeProducts]);

  // from the previous products headers array, we form a map of the type {productHeaderGuid: product Header} for easier fetching 
  const productHeadersMap = useMemo<ReadonlyMap<string, ProductHeader>>(() => (
    activeProducts.reduce<Map<string, ProductHeader>>(
      (result, prd) => !prd.productHeaders
        ? result
        : prd.productHeaders.reduce<Map<string, ProductHeader>>(
          (result, prdHeader) =>
            result.set(prdHeader.headerId, prdHeader)
          ,
          result),
      new Map<string, ProductHeader>()
    )
  ), [activeProducts]);

  // here we create a map between the existing applications or already created application Headers ID and boolean. This will help us keep a track of options and now future available headers
  const dataServiceHeaders = useMemo<ReadonlyMap<string, boolean>>(() => (
    applicationHeaders!.reduce<Map<string, boolean>>(
      (result, applicationHeader) => result.set(applicationHeader.headerId, true),
      new Map<string, boolean>(),
    )
  ), [applicationHeaders]);
  
// here we map the existing or already created application headers so as to form rows of the table
  const rows = useMemo<Row[]>(() => applicationHeaders!.map(item => {
    const prdHeader = productHeadersMap.get(item.headerId);
    return {
      headerName: !prdHeader ? '' : prdHeader.name,
      headerId: !prdHeader ? '' : prdHeader.headerId,
      value: item.headerValue,
      appHeaderId: item.appHeaderId
    };
  }), [applicationHeaders, productHeadersMap]);
  const options: ProductHeader[] = useMemo(() => {
    if (productHeaders) {
      return productHeaders.filter(productHeader =>
        !dataServiceHeaders.get(productHeader.headerId))
    }
    return [];
  }, [productHeaders, dataServiceHeaders]);

  const columns = useMemo<ColumnDef<Row>[]>(() => ([
    {
      header: 'Header GUID',
      accessorKey: 'headerId',
    },
    {
      header: 'Header Name',
      accessorKey: 'headerName',
      cell: (context)=><EditCellWrapper context={context} element={<B2C2AppsHeaderNameCell {...context.table}/>}/>
    },
    {
      header: 'Value',
      accessorKey: 'value',
      cell: (context)=><EditCellWrapper context={context} element={<B2C2AppsHeaderValueCell {...context.table}/>}/>
    },
    {
      header: "Action",
      cell: TableFormEditDeleteAction,
      enableSorting: false,
      accessorKey: "headerName"
    }
  ]), []);

  const [sort, setSort] = useState<SortingState>([]);
  const [search, setSearch] = useState<string>("");
  const [currentEditingRow, setCurrentEditingRow] = useState<string[]>([]);
  const [dirtyRow, setDirtyRow] = useState<Row | null>(() => null);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const table = useReactTable({
    columns:  columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (e) => e.headerId,
    // debugTable: true,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSort,
    enableSorting: true,
    state: {
      sorting: sort,
      globalFilter: search
    },
    meta: {
      data: options,
      updateEditingRowState: (id: string) => {
        setCurrentEditingRow([id]);
        setDirtyRow(() => ({ ...rows.filter(e => e.headerId === id)[0] }));
      },
      currentEditingRowId: currentEditingRow,
      revertingToStaticState: () => {
        setCurrentEditingRow([]);
        setDirtyRow(null);
      },
      setEditCelldata: (id, newData, columnid) => {
        setDirtyRow((oldState) => {
          if (!oldState) {
            return oldState;
          }
          if(columnid==="headerId"){
            return {
              ...oldState,
              [columnid]: newData,
              headerName: productHeadersMap.get(newData)?.name || ""
            }
          } else {
            return {
              ...oldState,
              [columnid]: newData
            }
          }
          
        }
        );
      },
      dirtyRow: dirtyRow,
      handleUpdate: (id: string) => {
          onHeaderChange(applicationHeaders.map(applicationHeader => {
            return applicationHeader.headerId !== dirtyRow?.headerId ? applicationHeader : {
              headerId: dirtyRow?.headerId,
              headerValue: dirtyRow.value,
              appHeaderId: dirtyRow.appHeaderId,
            }
          }));
          setCurrentEditingRow([]);
          setDirtyRow(null);
      },
      deleteRow: (id: string) => {
        onHeaderChange(applicationHeaders!.filter(applicationHeader => applicationHeader.headerId !== id));
      },
      isValid:(table: Table<any>)=>{
        return b2bApplicationHeaderCreateSchema.isValidSync({
          headerId: table.options.meta?.dirtyRow.headerId,
          headerValue: table.options.meta?.dirtyRow.value,
          appHeaderId: table.options.meta?.dirtyRow.appHeaderId,
        })
      },
      isIndividualCellValid:(columnName:string, newData: any)=>{
          try{
            b2bApplicationHeaderCreateSchema.validateSyncAt(columnName,{[columnName]:newData});
            return "";
          } catch(e){
            return (e as ValidationError).message;
          }
      },
      isAdd: isAdd,
      addRowNode: B2C2AppsAddHeader,
      startAddMode: () => {
        setCurrentEditingRow(["new"]);
        setDirtyRow({
          headerId: '',
          headerName: '',
          value: '',
          appHeaderId:'',
        });
        setIsAdd(true);
      }, stopAddMode: () => {
        setCurrentEditingRow([]);
        setDirtyRow(null);
        setIsAdd(false);
      },
      handleAdd: () => {
        if (!dirtyRow) {
          return;
        }
        onHeaderChange([
          ...applicationHeaders!,
          {
            headerId: dirtyRow.headerId,
            headerValue: dirtyRow.value,
            appHeaderId: dirtyRow.appHeaderId,
          },
        ]);
        setCurrentEditingRow([]);
        setDirtyRow(null);
        setIsAdd(false);
      }
    }
  });

  return (
    <Grid container>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Helmet>
        <title>
          {isCreate ? "Allowed Headers (3 of 6) | Add new B2C Application | Visa Prototype Validation Platform" : `Allowed Headers (3 of 6) | Edit B2C Application | Visa Prototype Validation Platform`}
        </title>
      </Helmet>
      <TypographyHighLight headerTitle={isCreate ? "Allowed Headers (3 of 6) | Add new B2C Application" : `Allowed Headers (3 of 6) | Edit B2C Application`} variant='h5' />
      <Typography color="primary" className={styles.instruction1}>
        Specify your headers
      </Typography>
      <Typography component="p" className={styles.smallTitle}>
        This is an optional step
      </Typography>
        {loading ? (<AppEditDetailsPlaceHolder />) : <>
        <HeaderSearchAddButton table={table} heading='App Headers' search={search} setSearch={setSearch} addButtonFunction={table.options.meta?.startAddMode ? table.options.meta.startAddMode : () => { }}></HeaderSearchAddButton>
        <TableCore caption='App Headers' table={table}></TableCore></>}
      </Grid>

    </Grid>
  );
};

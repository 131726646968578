
import { config } from '../../../config';
import { networkSrv } from '../../../services/network.service';
import { B2C2ApplicationHeaders } from '../model/application';

export function deleteB2C2ApplicationHeaderAsync(applicationGuid: string,header : B2C2ApplicationHeaders): Promise<void> {
    return networkSrv.deleteAsync(`${config.urls.b2c2Apps}/${applicationGuid}/headers/${header.appHeaderId}`);
  }

export const networkAppsHeaderSrv = {
    deleteB2C2ApplicationHeaderAsync,
  }
import { actionCreator } from '../../../state/actions';
import { User } from '../model/user';

const types = {
  loadUsers: 'users/load_users',
  loadUsersSuccess: 'users/load_users_success',
  loadUsersError: 'users/load_users_error',
}

const loadUsers = () => actionCreator(types.loadUsers);
const loadUsersSuccess = (users: User[]) => actionCreator(types.loadUsersSuccess, users);
const loadUsersError = (error: string) => actionCreator(types.loadUsersError, error);

export const usersActions = {
  loadUsers,
  loadUsersSuccess,
  loadUsersError,
}

export const usersTypes = types;

import { B2C2ApplicationConfigBase } from '../model/application-create';

export interface UseApplicationConfigsResult {
  configs: B2C2ApplicationConfigBase[];
  loading: boolean;
}

export function useApplicationConfigs(): UseApplicationConfigsResult {
  const configs: B2C2ApplicationConfigBase[] = [{
    configKey: 'vp2_user_mgmt',
    dataType: 'boolean',
  }, {
    configKey: 'signup_whitelisted_only',
    dataType: 'boolean',
  }];
  const loading = false;

  return {
    configs,
    loading,
  };
}

import { Tooltip, withStyles } from '@material-ui/core';

export const BlueTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#0051DC",
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

export const DarkBlueTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#1A1F71",
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

export const GreyTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#D3D3D3',
        color: "#000",
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);
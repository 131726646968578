import {
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { FormErrors } from '../../../../hooks/use-form.hook';
import { useFormsStyles } from '../../../../styles/forms.styles';
import { useUsers } from '../../../user/hooks/use-users.hook';
import { B2C2ApplicationUpdate } from '../../model/application-update';
import { Helmet } from 'react-helmet';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import Input from "@visa/vds/input";
import { vdsStyleOverrides } from '../../../../vds-overrides.styles';

export interface B2C2AppEditDetailsProps {
  details: B2C2ApplicationUpdate;
  onInputChange: (event: React.ChangeEvent<any>) => void;
  onInputValueChange: (name: string, value: any) => void;
  errors: FormErrors;
  isCreate: boolean;
}

export const B2C2AppEditDetails: React.FC<B2C2AppEditDetailsProps> = ({ details, onInputChange, onInputValueChange, errors, isCreate }) => {
  const styles = useFormsStyles();
  const { users, loading, loadUsersAsync } = useUsers();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
    if (!users) {
      loadUsersAsync();
    }
  }, [users, loadUsersAsync]);

  const handleClose = useCallback(
    () => setOpen(false),
    []
  );

  const usernames = users ? users.map(user => user.username) : [];
 const vdsStyles = vdsStyleOverrides();
  return (
    <Grid container justifyContent='center'>
      <Grid item xl={5} lg={5} md={11} sm={11} xs={11}>
        <Helmet>
          {isCreate ?
            <title>
              Details (1 of 6) | Add new B2C Application | Visa Prototype Validation Platform
            </title>
            : <title>
              Details (1 of 6) | Edit B2C Application | Visa Prototype Validation Platform
            </title>
          }
        </Helmet>
        <TypographyHighLight headerTitle={isCreate ? "Details (1 of 6) | Add new B2C Application" : `Details (1 of 6) | Edit B2C Application`} variant='h5' />
        <Typography variant="h6" component="p" className={styles.h3}>
          Please fill in your app details
        </Typography>
        <Typography variant="caption" component="p" className={styles.h3}>
          '*' denotes that the field is required.
        </Typography>

        {/* App Name  */}
        <Input
          errorText={errors.name}
          label="App name *"
          name={"App_name"}
          value={details.name}
          id="appname-id"
          onChange={(e: ChangeEvent<HTMLInputElement>) => onInputValueChange("name", e.target.value)}
          onBlur={(e: ChangeEvent<HTMLInputElement>) => onInputValueChange("name", e.target.value)}
          showErrorText={!!errors.name}
          invalid={!!errors.name}
          className={vdsStyles.inputFullWidth}
          aria-required
        ></Input>

        {/* App Description */}
        <Input
          errorText={errors.description}
          label="App Description *"
          name={"App_Description"}
          value={details.description}
          id="appdescription-id"
          onChange={(e: ChangeEvent<HTMLInputElement>) => onInputValueChange("description", e.target.value)}
          onBlur={(e: ChangeEvent<HTMLInputElement>) => onInputValueChange("description", e.target.value)}
          showErrorText={!!errors.description}
          invalid={!!errors.description}
          className={vdsStyles.inputFullWidth}
          aria-required
        ></Input>
        <Input
          errorText={errors.namespace}
          label="Namespace *"
          name={"namespace"}
          value={details.namespace}
          id="namespace-id"
          onChange={(e: ChangeEvent<HTMLInputElement>) => onInputValueChange("namespace", e.target.value)}
          onBlur={(e: ChangeEvent<HTMLInputElement>) => onInputValueChange("namespace", e.target.value)}
          showErrorText={!!errors.namespace}
          invalid={!!errors.namespace}
          className={vdsStyles.inputFullWidth}
          aria-required
        ></Input>
        <Input
          errorText={errors.supportGdl}
          label="Support DL *"
          name={"supportGdl"}
          type={'email'}
          value={details.supportGdl}
          id="supportGdl-id"
          onChange={(e: ChangeEvent<HTMLInputElement>) => onInputValueChange("supportGdl", e.target.value)}
          onBlur={(e: ChangeEvent<HTMLInputElement>) => onInputValueChange("supportGdl", e.target.value)}
          showErrorText={!!errors.supportGdl}
          invalid={!!errors.supportGdl}
          className={vdsStyles.inputFullWidth}
          aria-required
        ></Input>

        <FormControl className={clsx(styles.formControlFull, styles.marginTop)}>
          <label htmlFor='details_owners' className={styles.label}>Owners (Use Backspace for removing the owners)</label>
          <Autocomplete
            aria-label='Owners of the new application. (Use Backspace for removing the owners)'
            disableClearable
            multiple
            id="details_owners"
            options={usernames}
            getOptionLabel={username => username}
            value={details.owners}
            onChange={(_, value) => onInputValueChange('owners', value)}
            loading={loading}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            disableCloseOnSelect
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} aria-label='Loading' /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
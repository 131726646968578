import { B2BApprovalEntity, Entity } from '../../../model/entities';
import { B2BEntity } from './../../../model/entities';
import { HttpMethod } from './../../../model/http-method';
import {
  ServiceConfigurationUpdate,
  ServiceHeaderUpdate,
  ServiceHostUpdate,
  ServiceOperationUpdate
} from './service-update';

export type ServiceHostDataCenter = 'GTM' | 'OCE' | 'OCC' | 'OCB' | 'OCS' | 'OCIB' | 'OCIM'| 'OCHB' | 'OCHS' | 'OCTE' | 'OCTN' | 'AWS';

export const serviceHostDataCenters = ['GTM', 'OCE', 'OCC', 'OCB', 'OCS', 'OCIB', 'OCIM', 'OCHB', 'OCHS', 'OCTE', 'OCTN', 'AWS'];
export const b2cServiceHostDataCenters = ['OCE', 'OCC', 'OCB', 'OCS', 'OCD'];

export const additionalDataCenter = 'GTM';

export const serviceHeadersType = ['STRING'];

export const serviceHeadersRequired = ['true', 'false'];

export type ServiceStatusType = 'ACTIVE' | 'DISABLED'; 
export const serviceStatusTypes = ['ACTIVE', 'DISABLED']; 

export type ApprovalRequestStatus = 'PENDING' | 'APPROVED' | 'DENIED'; 
export const approvalRequestStatus = ['PENDING', 'APPROVED', 'DENIED']; 

export type ApprovalRequestType = 'NEW_SERVICE' | 'NEW_OPERATION' | 'UPDATE_OPERATION'; 
export const approvalRequestTypes = ['NEW_SERVICE', 'NEW_OPERATION', 'UPDATE_OPERATION']; 

export type RateLimitType = 'OVERALL_THROTTLE' | 'OVERALL_QUOTA' | 'THROTTLE_PER_CLIENT' | 'QUOTA_PER_CLIENT';
export const rateLimitTypes = ['OVERALL_THROTTLE', 'OVERALL_QUOTA', 'THROTTLE_PER_CLIENT', 'QUOTA_PER_CLIENT'];

export interface ServiceOperation extends Entity, ServiceOperationUpdate {
  serviceId: number;
  allowedDomains: string[];
}

export interface ServiceConfiguration extends Entity, ServiceConfigurationUpdate {
  serviceId: number;
}

export interface ServiceHeader extends Entity, ServiceHeaderUpdate {
  serviceId: number;
}

export interface ServiceHost extends Entity, ServiceHostUpdate {
}
export interface B2BService extends B2BEntity {
  name: string;
  description: string;
  documentationUrl: string;
  contextRoot: string;
  serviceGuid: string;
  status: string;
  operations: B2BOperation[];
  pendingOperations: B2BPendingOperation[];
  serviceHosts: B2BServiceHost[];
  serviceOwners: B2BServiceOwner[];
  serviceHeaders: B2BServiceHeader[];
  outboundEvents: B2BOutboundEvent[];
  requestGuid: string;
  isEditable?:boolean;
  deploymentRegion?: string;
  autoDC: boolean;
  healthEndpoint?: string;
  changeDetails?: B2BServiceHostChangeDetails;
  entitlementConfigs?: B2BEntitlementConfigs;
}

export interface B2BEntitlementConfigs {
  productProvisioningType: string,
  clientOnboardingCallBackUrl: string
}

export interface B2BServiceHostChangeDetails {
  changeRequestNumber?: string;
  changeJustification?: string;
}

export interface B2C2Product extends B2BEntity {
  name: string;
  description: string;
  documentationUrl: string;
  contextRoot: string;
  serviceGuid: string;
  status: string;
  operations: B2BOperation[];
  pendingOperations: B2BPendingOperation[];
  serviceHosts: B2BServiceHost[];
  serviceOwners: B2BServiceOwner[];
  serviceHeaders: B2BServiceHeader[];
  outboundEvents: B2BOutboundEvent[];
  requestGuid: string;
  isEditable?:boolean;
  deploymentRegion?: string;
  requiredConfigurations?: ServiceConfiguration[];
}

export interface B2BOperation extends B2BEntity {
  name: string;
  description: string;
  internalPath: string;
  externalPath: string;
  methodType: HttpMethod;
  mleType: string;
  status:string;
  operationGuid: string;
  serviceGuid: string;
  rateLimits?: B2BRateLimits[];
}

export interface B2BPendingOperation extends B2BEntity {
  name: string;
  description: string;
  internalPath: string;
  externalPath: string;
  methodType: HttpMethod;
  mleType: string;
  pendingOperationGuid: string;
  rateLimits: B2BRateLimits[];
}

export interface B2BServiceHost extends B2BEntity {
  url: string,
  dataCenter: string,
  serviceHostGuid: string,
  is_active: string
}

export interface B2BServiceHeader extends B2BEntity {
  name: string,
  serviceHeaderGuid: string,
  defaultValue: string,
  type: string,
  required: string,
}

export interface B2BOutboundEvent extends B2BEntity {
  eventGuid: string,
  eventName: string,
  outboundHosts: B2BOutboundHost[],
}

export interface B2BOutboundHost extends B2BEntity {
  outboundHostGuid: string,
  outboundEventGuid: string,
  is_active: string,
  callbackUrl: string,
  dataCenter: ServiceHostDataCenter;
}

export interface B2BServiceOwner extends B2BEntity {
  serviceOwnerGuid: string,
  subjectName: string,
}

export interface B2BPendingOperation extends B2BEntity {
  name: string;
  description: string;
  internalPath: string;
  externalPath: string;
  methodType: HttpMethod;
  mleType: string;
  operationGuid: string;
  requestGuid: string;
  rateLimits: B2BRateLimits[];
}

export interface B2BRateLimits extends B2BEntity {
  name: string;
  type: RateLimitType;
  numberOfCalls: string;
  timeWindow: string;
  numberOfClients: string;
}

export interface B2BPendingApplicationOperationMap extends B2BEntity {
  applicationOperationGuid: string,
  //requestGuid: string,
  //applicationGuid: string;
  operationName: string;
  operationGuid: string;
  internalPath: string;
  externalPath: string;
  methodType: HttpMethod;
}

export interface B2BApprovalRequest extends B2BEntity {
  reviewerSubjectName?: string;
  saNumber?: string;
  businessJustification?: string;
  requestGuid: string;
  requestorSubjectName: string;
  requestType: string;
  requestStatus: string;
  applicationOperationMap?: B2BPendingApplicationOperationMap[];
  operations?: B2BPendingOperation[];
  approvalRequestEntity?: ApprovalRequestEntity;
  sendBackMessage?:string;
}

export interface ApprovalRequestEntity extends B2BApprovalEntity {
  name?: string;
  description?: string;
  contextRoot?: string;
  documentationUrl?: string;
  applicationGuid?: string;
  serviceGuid?: string;
  status?: string;
  operations?: B2BOperation[];
  serviceHosts?: any[];
  serviceOwners?: any[];
  deploymentRegion?: string;
}

export interface B2BServiceReviewRequest  {
  businessJustification:string;
  saNumber:string;
  requestGuid:string;
  requestStatus:string;
}

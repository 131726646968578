import { Table } from "@tanstack/table-core";
import React from "react";
import Tr from '@visa/vds/tr';
import Td from '@visa/vds/td';
import { TableFormAddCancelAction } from "../../../../views/table-abstraction/table-cells/b2c2-apps/table-form-add-cancel";
import { B2CHostTypeEditCell } from "../../table-cells/b2c2-product/host/host-type-edit-cell";
import { ProductHostUpdate } from "../../../../../products/model/product-update";
import { B2CUrlLocationEditCell } from "../../table-cells/b2c2-product/host/url-location-edit-cell";

export const B2CAddHostsRow: React.FC<Table<ProductHostUpdate>> = (table) => {
    return <Tr >
        <Td><B2CHostTypeEditCell {...table}/></Td>
        <Td><B2CUrlLocationEditCell {...table}/></Td>
        <Td><TableFormAddCancelAction {...table as Table<any>} ></TableFormAddCancelAction></Td>
    </Tr>
}

import { logger } from '../../../services/logger.service';
import { ProductCreate } from '../model/product-create';
import { ProductEndpointUpdate, ProductHeaderUpdate, ProductHostUpdate, ProductUpdate } from '../model/product-update';
import { productCreateSchema } from '../model/product-validations';
import { ProductRQ, ProductRS } from './dtos';
import { mapProductRQ } from './model-maps';

const mapWithId = <T>(item: T, index: number): T & { id: number } => ({
  ...item,
  id: -(index + 1),
});

export async function importFromJson(content: string): Promise<ProductUpdate | null> {
  try {
    const productCreate = mapProductRQ(JSON.parse(content) as ProductRS);
    await productCreateSchema.validate(productCreate);

    const productHosts: ProductHostUpdate[] = productCreate.productHosts.map((item, index) => ({
      ...mapWithId(item, index),
    }));
    const productEndpoints: ProductEndpointUpdate[] = productCreate.productEndpoints.map((item, index) => ({
      ...mapWithId(item, index),
    }));
    const productHeaders: ProductHeaderUpdate[] | undefined = productCreate.productHeaders?.map((item, index) => ({
      ...mapWithId(item, index),
    }));
    const productOwners: string[] | undefined = productCreate.productOwners;
    const productUpdate: ProductUpdate = {
      ...mapWithId(productCreate, 0),
      productHosts,
      productEndpoints,
      productHeaders,
      productOwners,
    };
    return productUpdate;
  } catch (ex) {
    logger.exception('Invalid JSON content', ex);
    return null;
  }
}

export function exportToJson(product: ProductCreate): string {
  const dto = mapProductRQ(product);
  return JSON.stringify(dto);
}

export const jsonProductService = {
  importFromJson,
  exportToJson,
}

import { Table } from "@tanstack/table-core"
import React, { ChangeEvent, useState } from "react"
import { ProductHostUpdate } from "../../../../../../products/model/product-update";
import Input from "@visa/vds/input";
import { vdsStyleOverrides } from "../../../../../../../vds-overrides.styles";

export const B2CUrlLocationEditCell: React.FC<Table<ProductHostUpdate>> = (table) => {
  const [urlLocationError, setUrlLocationError] = useState<string>("");
  const vdsStyles = vdsStyleOverrides();
  return <Input
    style={{ height: "unset" }}
    errorText={urlLocationError}
    label="URL location *"
    name="UrlLocation"
    value={table.options.meta?.dirtyRow?.url || ''}
    onChange={(e: ChangeEvent<HTMLInputElement>) => {
      setUrlLocationError(table.options.meta?.isIndividualCellValid("url", e.target.value) || "");
      table.options?.meta?.setEditCelldata("", e.target.value, "url");
    }}
    onBlur={()=>setUrlLocationError(table.options.meta?.isIndividualCellValid("url", table.options.meta?.dirtyRow?.url) || "")}
    aria-required
    showErrorText={!!urlLocationError}
    invalid={!!urlLocationError}
    className={vdsStyles.inputFullWidth}
  ></Input>
}
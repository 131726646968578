import { actionCreator } from '../../../state/actions';
import { B2C2ProductReviewRequest } from '../../products/model/product';
import { B2C2Application } from '../model/application';
import { ApplicationApproval } from '../model/application-approval';

const types = {
  loadApplications: 'apps/load_applications',
  loadApplicationById: 'apps/load_application_by_id',
  loadB2C2Applications: 'apps/load_b2c2_applications',
  loadApplicationsSuccess: 'apps/load_applications_success',
  loadApplicationByIdSuccess: 'apps/load_application_by_id_success',
  loadB2C2ApplicationsSuccess: 'apps/load_b2c2_applications_success',
  loadApplicationsError: 'apps/load_applications_error',
  loadB2C2ApplicationsError: 'apps/load_b2c2_applications_error',
  addApplication: 'apps/add_application',
  addB2C2Application: 'apps/add_b2c2_application',
  updateApplication: 'apps/update_application',
  updateB2C2Application: 'apps/update_b2c2_application',
  deleteApplication: 'apps/delete_application',
  deleteB2C2Application: 'apps/delete_b2c2_application',
  loadApplicationApprovals: 'apps/load_application_approvals',
  loadApplicationApprovalsSuccess: 'apps/load_application_approvals_success',
  loadApplicationApprovalsError: 'apps/load_application_approvals_error',
  updateApplicationApproval: 'apps/update_application_approval',
  b2BreviewRequest: 'apps/b2b_review_request'
};

const loadApplications = () => actionCreator(types.loadApplications);
const loadApplicationById = (id: number) => actionCreator(types.loadApplicationById);
const loadB2C2Applications = () => actionCreator(types.loadB2C2Applications);
const loadB2C2ApplicationsSuccess = (applications: B2C2Application[]) => actionCreator(types.loadB2C2ApplicationsSuccess, applications);
const loadApplicationsError = (error: string) => actionCreator(types.loadApplicationsError, error);
const loadB2C2ApplicationsError = (error: string) => actionCreator(types.loadB2C2ApplicationsError, error);
const addB2C2Application = (application: B2C2Application) => actionCreator(types.addB2C2Application, application);
const updateB2C2Application = (application: B2C2Application) => actionCreator(types.updateB2C2Application, application);
const deleteApplication = (id: number) => actionCreator(types.deleteApplication, id);
const deleteB2C2Application = (applicationGuid: string) => actionCreator(types.deleteB2C2Application, applicationGuid);
const loadApplicationApprovals = (appId: number) => actionCreator(types.loadApplicationApprovals, appId);
const loadApplicationApprovalsSuccess = (payload: { appId: number, approvals: ApplicationApproval[] }) => actionCreator(types.loadApplicationApprovalsSuccess, payload);
const loadApplicationApprovalsError = (payload: { appId: number, error: string }) => actionCreator(types.loadApplicationApprovalsError, payload);
const updateApplicationApproval = (payload: { appId: number, approval: ApplicationApproval }) => actionCreator(types.updateApplicationApproval, payload);
const b2c2ReviewRequest = (approvalRequest: B2C2ProductReviewRequest) => actionCreator(types.b2BreviewRequest, approvalRequest);


export const appsActions = {
  loadApplications,
  loadApplicationById,
  loadB2C2Applications,
  loadB2C2ApplicationsSuccess,
  loadApplicationsError,
  loadB2C2ApplicationsError,
  addB2C2Application,
  updateB2C2Application,
  deleteApplication,
  deleteB2C2Application,
  loadApplicationApprovals,
  loadApplicationApprovalsSuccess,
  loadApplicationApprovalsError,
  updateApplicationApproval,
  b2c2ReviewRequest
};

export const appsTypes = types;

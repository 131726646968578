import {createStyles, makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export const drawerWidth = 240;

export const appDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    listItem: {
        color: '#1A1F71',
        '&:hover': {
            color:'white',
            background: "#0051DC",
            '& $dashIcon': {
                fill: '#fff',
            }
        }
    },
    linkStyle:{
      width: "100%",
      height: "100%",
      textDecoration: "none",
      display:"flex",
      color: "#1A1F71",
      "&:hover":{
        color: "white",
        fill:"white"
      }
    },
    dashIcon: {
        fill: '#1A1F71',
    }
  }),
);

import { Table } from "@tanstack/react-table"
import React, { ChangeEvent, useState } from "react"
import Input from '@visa/vds/input';
import { Row } from "../../../../app-edit/b2c2-app-edit-b2c2b-auth";
import { TWO_WAY_SSL, X_PAY_TOKEN } from "../../../../../constants";


export const WsiExternalIdEdit: React.FC<Table<Row>> = (table) => {
    const host = window.location.host;
    const splits = host.split("-");
    let isWSIExternalIdEditable = false;
    if (splits.length > 2) {
    if (splits[2]==="qapenb.oce" || splits[2]==="qaintb.oce") {
        isWSIExternalIdEditable = true;
    }
    }
    const [wsiExternalIdError, setWsiExternalIdError] = useState<string>("");

    if (!isWSIExternalIdEditable) {
        return <div>N/A</div>
    }
    if (table.options.meta?.dirtyRow?.type === TWO_WAY_SSL) {
        return <Input
            style={{ height: "unset" }}
            errorText={wsiExternalIdError}
            label="WSI External Id"
            name={"wsiExternalId"}
            value={table.options.meta?.dirtyRow?.attr?.wsiExternalId || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                let attribute = {...table.options?.meta?.dirtyRow?.attr,"wsiExternalId": e.target.value}
                table.options?.meta?.setEditCelldata("", attribute, "attr");
            }}
            showErrorText={!!wsiExternalIdError}
            invalid={!!wsiExternalIdError}
        ></Input>
    } else if (!table.options.meta?.dirtyRow?.type) {
        return <></>;
    } else {
        return <div>N/A</div>;
    }
}
import { Product } from '../model/product';
import { useProducts } from './use-products';

export interface UseProductResult {
  currentProduct: Product | undefined | null;
  loading: boolean;
  activeProduct: Product | undefined | null;
  pendingProduct: Product | undefined | null;
}
export function useProduct(productId: string|undefined): UseProductResult {
  const { loading, products } = useProducts();

  let currentProduct: Product | undefined | null = null;
  let activeProduct: Product | undefined | null = null;
  let pendingProduct: Product | undefined | null = null;

  if (products) {
    pendingProduct = products.find(prd => prd.productId === productId && prd.statusType ==='PENDING');
    activeProduct = products.find(prd => prd.productId === productId && prd.statusType ==='ACTIVE');
    currentProduct = products.find(prd => prd.productId === productId );
  }

  return {
    currentProduct,
    loading,
    activeProduct,
    pendingProduct
  };
}
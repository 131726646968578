import { Avatar, CircularProgress, IconButton, Link, ListItemIcon, ListItemText, SvgIcon, useMediaQuery } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DarkBlueTooltip } from '../../../../components/tooltips/tooltips.styles';
import { config } from '../../../../config';
import { useIsAuthenticated } from '../../../../lib/oidc-client-react';
import { useUserProfile } from '../../hooks/use-user-profile.hook';
import { getUserAvatar } from '../../services/user-avatar.service';
import { StyledMenu, StyledMenuItem } from '../styled-menu/styled-menu';
import { ReactComponent as AdminIcon } from './icon-admin.svg';
import { ReactComponent as ProfileIcon } from './icon-profile.svg';
import { ReactComponent as SignOutIcon } from './icon-signout.svg';
import { userMenuStyles } from './user-menu.styles';
import { ReactComponent as FeedbackIcon } from './icon-feedback-blue.svg';
import { ReactComponent as HelpIcon } from './icon-help-blue.svg';
import { useTheme } from '@mui/material';

export const UserMenu: React.FC = () => {
  const { loading, userProfile } = useUserProfile();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const styles = userMenuStyles();
  const theme = useTheme();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('sm'));
  
  const avatarUrl = getUserAvatar(userProfile);

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleProfile = useCallback(() => {
    handleClose();
    navigate('/profile');
  }, [handleClose, navigate]);
  const handleAdminRoleAccess = useCallback(() => {
    handleClose();
  }, [handleClose]);
  const handleLogout = useCallback(() => {
    handleClose();
    navigate('/auth/logout');
  }, [handleClose, navigate]);
  const handleFeedback = useCallback(() => {
    window.open(config.externalUrls.createSupportTicket, "_blank");
    handleClose();
  }, [handleClose, navigate]);
  const handleHelp = useCallback(() => {
    handleClose();
    navigate('/help'); handleClose();
  }, [handleClose, navigate]);

  if (!isAuthenticated) {
    return null;
  }
  return (
    <>
      <DarkBlueTooltip placement="bottom" title="Profile">
        <IconButton
          aria-label="account of current user"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <Avatar className={styles.avatar} src={avatarUrl} alt="Profile Picture" />
          {loading && <CircularProgress size={36} className={styles.progress} aria-label='Loading' />}
          <KeyboardArrowDown />
        </IconButton>
      </DarkBlueTooltip>
      <StyledMenu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
          {/* Feedback */}
          {!matchesMediaQuery ? <>
            <StyledMenuItem onClick={handleFeedback} tabIndex={!matchesMediaQuery?0:-1}>
            <ListItemIcon >
              <SvgIcon fontSize="small">
                <FeedbackIcon className={styles.icon}></FeedbackIcon>
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Feedback" aria-label="Feedback (opens a new tab)"
              className={styles.link} primaryTypographyProps={
                {
                  className: styles.link
                }
              } />
          </StyledMenuItem>
          {/* Help menu */}
          <StyledMenuItem onClick={handleHelp}>
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <HelpIcon className={styles.icon} />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Help" />
          </StyledMenuItem>
          </>: <></>}
        <StyledMenuItem onClick={handleProfile} tabIndex={!matchesMediaQuery?-1:0}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <ProfileIcon className={styles.icon} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleAdminRoleAccess} className={styles.listOnFocus} >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <AdminIcon className={styles.icon} />
            </SvgIcon>
          </ListItemIcon>
          <Link rel="noopener" className={styles.link} href={config.externalUrls.visaWikiAdminRoles} target="_blank" aria-label="Admin Role Access (opens a new tab)">
            <ListItemText primary="Admin Role Access" />
          </Link>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleLogout}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <SignOutIcon className={styles.icon} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

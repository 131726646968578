import { GlobalConfig } from '../model/global-config';

declare global {
  interface Window {
    AppConfig?: GlobalConfig;
  }
}

export function getGlobalConfig(): GlobalConfig | undefined {
  return window.AppConfig;
}

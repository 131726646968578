import { UserManager, UserManagerSettings } from '../../oidc-client';

let userManager: UserManager;

export const setup = (settings: UserManagerSettings): void => {
  userManager = new UserManager(settings)
}

export const getUserManager = (): UserManager => {
  if (!userManager) {
    throw new Error('UserManager is not configured. You must call setup before using using the UserManager.');
  }
  return userManager;
}

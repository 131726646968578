import { CellContext } from "@tanstack/table-core"
import React, { ChangeEvent, useState } from "react"
import Select from '@visa/vds/select';
import { Row } from "../../../../app-edit/b2c2-app-edit-configs"
import { vdsStyleOverrides } from "../../../../../../../vds-overrides.styles";
import { appConfigValues } from "../../../../../model/application-create";

export const ConfigValueTypeSelect: React.FC<CellContext<Row, any>> = (context) => {
    const [valueError, setValueError] = useState<string>("");
    const vdsStyles = vdsStyleOverrides();
    if (context.table.options.meta?.currentEditingRowId[0] !== context.row.id) {
        return <>{String(context.getValue())}</>;
    }

    return <Select
        name="Value"
        label="Value *"
        value={context.table.options.meta?.dirtyRow?.configValue}
        className={vdsStyles.selectFullWidth}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            context.table.options?.meta?.setEditCelldata("", e.target.value, "configValue");
            setValueError(context.table.options?.meta?.isIndividualCellValid("configValue", e.target.value) || "")
        }}
        onBlur={(e: ChangeEvent<HTMLSelectElement>) => {
            setValueError(context.table.options?.meta?.isIndividualCellValid("configValue", e.target.value) || "")
        }}
        showErrorText={!!valueError}
        errorText={valueError}
        invalid={!!valueError}
        aria-required
        autoFocus
    >
        <option value="" disabled defaultChecked>Select Value</option>
        {appConfigValues.sort().map(appConfigValue => (
            <option key={appConfigValue.key} value={appConfigValue.value}>{appConfigValue.value}</option>
        ))}
    </Select>
}
export const CUSTOMIZED_COMMUNICATION = 'CUSTOMIZED_COMMUNICATION';
export const WHITELIST_VISA_EMAILS_FOR_ROLES = 'WHITELIST_VISA_EMAILS_FOR_ROLES';
export const ROLES_PATTERN = /^([a-zA-Z0-9_]+)$/ ;
export const ERR_INVALID_OAUTH2_AUTHENTICATION = {
    subcode: '500020',
    reason: 'Invalid Request for Downstream Service'
};
export const SSO_RETRIES_ALLOWED = 4;
export const STOP_SSO_RETRIES = 1;
export const SLE_SA_VALIDATION_REGEX = /^[Ss][Ll][Ee]-[Ss][Aa]-[0-9]+$/;
export const SA_VALIDATION_REGEX = /^[Ss][Aa]-[0-9]+$/;
export const JUSTIFICATION_VALIDATION_REGEX = /^[A-Za-z0-9_\/=&;,.%? -]*$/;

import { useBlockingLoading } from '../../../hooks/use-blocking-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { logger } from '../../../services/logger.service';
import { objectDiff } from '../../../services/object-diff.service';
import { updateEntityListAsync } from '../../../services/update-entity-list.product';
import { useDispatch } from '../../../state/app-store';
import { cloneProductDetails, Product, ProductDetails, ProductEndpoints, ProductHeader, ProductHost } from '../model/product';
import { ProductEndpointUpdate, ProductHeaderUpdate, ProductHostUpdate, ProductUpdate } from '../model/product-update';
import { networkServicesProducts } from '../services/network-services.products';
import { productActions } from '../state/product-actions';
import { networkProductEndpointsSrv } from '../services/network-product-endpoints.service';
import { networkProductHeadersSrv } from '../services/network-product-headers.service';
import { networkProductHostsSrv } from '../services/network-product-hosts.service';

export interface UseProductUpdateResult {
  loading: boolean;
  updateProductAsync: (oldService: Product, newService: ProductUpdate) => Promise<boolean>;
}

async function updateHostsAsync(productId: string, oldProduct: Product, newProduct: ProductUpdate): Promise<void> {
  
  return updateEntityListAsync<ProductHost, ProductHostUpdate>(
    productId,
    oldProduct?.productHosts[0]?.hostId,
    oldProduct.productHosts,
    newProduct.productHosts,
    networkProductHostsSrv.addProductHostsAsync,
    networkProductHostsSrv.updateProductHostsAsync,
    networkProductHostsSrv.deleteProductHostsAsync,
  );
}

function updateEndpointsAsync(productId: string, oldProduct: Product, newProduct: ProductUpdate): Promise<void> {
  return updateEntityListAsync<ProductEndpoints, ProductEndpointUpdate>(
    productId,
    oldProduct?.productEndpoints[0]?.endpointId,
    oldProduct.productEndpoints,
    newProduct.productEndpoints,
    networkProductEndpointsSrv.addProductEndpointsAsync,
    networkProductEndpointsSrv.updateProductEndpointsAsync,
    networkProductEndpointsSrv.deleteProductEndpointAsync,
  );
}

async function updateHeadersAsync(productId: string, oldProduct: Product, newProduct: ProductUpdate): Promise<void> {
  return updateEntityListAsync<ProductHeader, ProductHeaderUpdate>(
    productId,
    oldProduct?.productHeaders[0]?.headerId,
    oldProduct.productHeaders,
    newProduct.productHeaders,
    networkProductHeadersSrv.addProductHeadersAsync,
    networkProductHeadersSrv.updateProductHeaderAsync,
    networkProductHeadersSrv.deleteProductHeaderAsync,
  );
}

async function executeAndLogOnError(action: () => Promise<void>): Promise<void> {
  await action();
}

export function useProductUpdate(): UseProductUpdateResult {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useBlockingLoading();
  const { pushNotification } = useNotifications();

  const updateProductAsync = async (oldProduct: Product, newProduct: ProductUpdate): Promise<boolean> => {
    oldProduct.productHosts.forEach((oldHost) => {
      newProduct.productHosts.forEach((newHost) => {
        if (oldHost.dataCenter === newHost.dataCenter) {
          newHost.id = oldHost.id;
        }
      });
    });
    startLoading();
    try {
      const productId = oldProduct.productId;
      if (newProduct.productB2C2BArgs !== undefined && newProduct.productB2C2BArgs?.useVp2Connector !== oldProduct.productB2C2BArgs?.useVp2Connector) {
        const product = await networkServicesProducts.updateProductB2C2BAsync(productId, newProduct.productB2C2BArgs);
        dispatch(productActions.updateProduct(product));
      }
      await Promise.all([
        executeAndLogOnError(() => updateHostsAsync(productId, oldProduct, newProduct)),
        executeAndLogOnError(() => updateEndpointsAsync(productId, oldProduct, newProduct)),
        executeAndLogOnError(() => updateHeadersAsync(productId, oldProduct, newProduct)),
      ]);
      const newProductClone = cloneProductDetails(newProduct);
      const partialProductDetails = objectDiff<ProductDetails>(newProductClone, oldProduct);
      const oldOwners = oldProduct.productOwners?.sort();
      const newOwners = newProduct.productOwners?.sort();
      if (oldOwners !== undefined && newOwners !== undefined) {
        if (oldOwners.length !== newOwners.length) {
          partialProductDetails.productOwners = newOwners;
        }
        for (var i = 0; i < oldOwners?.length; i++ ) {
          if (oldOwners[i] !== newOwners[i]) {
            partialProductDetails.productOwners = newOwners;
          }
        }
      } else if (oldOwners !== undefined) {
        partialProductDetails.productOwners = newOwners;
      }

      const prd = await networkServicesProducts.updateProductDetailsAsync(productId, partialProductDetails);
      dispatch(productActions.updateProduct(prd));
      pushNotification({
        message: `Update product succeeded.`,
        variant: 'success',
        type: 'bar',
      });
      return true;
    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      logger.error(error, e);
      pushNotification({
        message: `Update product failed with '${error}'. Please, try again later.`,
        variant: 'error',
        type: 'bar',
      });
      return false;
    } finally {
      stopLoading();
    }
  };

  return {
    loading,
    updateProductAsync,
  };
}

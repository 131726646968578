import { CssBaseline, useMediaQuery, useTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import React, { useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { AppRouter } from './app-router';
import appTheme from './app-theme';
import { appStyles } from './app.styles';
import { AppDrawer } from './components/app-drawer/app-drawer';
import { B2BAppDrawer } from './components/app-drawer/b2b-app-drawer';
import { AppFooter } from './components/app-footer/app-footer';
import { AppGlobalComponents } from './components/app-global-components/app-global-components';
import { AppHeader } from './components/app-header/app-header';
import { EmptyHeader } from './components/app-header/empty-header';
import { config } from './config';
import { UserManagerSettings } from './lib/oidc-client';
import { AuthStoreProvider, setup } from './lib/oidc-client-react';
import { AppStoreProvider } from './state/app-store';
import { GlobalStoreProvider } from './state/global-store';
import { AppLandmarkProvider } from './state/skip-to-main-store';
import { AppSkipToLoginProvider } from './state/skip-to-login-store';
import IdleTimerContainer from './IdleTimerContainer';
import Icons from '@visa/vds/icons';
import Col from '@visa/vds/col';
const settings: UserManagerSettings = {
  authority: config.urls.auth.authority,
};
setup(settings);

const App: React.FC = () => {
  const styles = appStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <HashRouter>
      <AuthStoreProvider>
        <Col ></Col>
        <ThemeProvider theme={appTheme}>
          <div className={styles.root}>
            {/* <CssBaseline /> */}
            <GlobalStoreProvider>
              <AppStoreProvider>
                <AppLandmarkProvider>
                  <AppSkipToLoginProvider>
                    <Routes>
                      {/* Top navbar */}
                      <Route path="/login" element={<EmptyHeader />} />
                      <Route path="/register" element={<EmptyHeader />} />
                      <Route path="/404" element={<EmptyHeader />} />
                      <Route path="/" element={<AppHeader drawerOpen={false} onDrawerToggle={handleDrawerToggle} isHomePage={true} />} />
                      <Route path="/help" element={<AppHeader drawerOpen={false} onDrawerToggle={handleDrawerToggle} isHomePage={true} />} />
                      <Route path="/profile" element={<AppHeader drawerOpen={false} onDrawerToggle={handleDrawerToggle} isHomePage={true} />} />
                      <Route path="*" element={<AppHeader drawerOpen={drawerOpen} onDrawerToggle={handleDrawerToggle} isHomePage={false} />} />
                    </Routes>
                    <div className={styles.main}>
                      {/* Sidebar drawer */}
                      <Routes>
                        <Route path="/login" element={<EmptyHeader />} />
                        <Route path="/register" element={<div />} />
                        <Route path="/404" element={<div />} />
                        <Route path="/" element={<div />} />
                        {(matchesMediaQuery || drawerOpen) && (
                          <Route path="/b2b/*" element={<B2BAppDrawer open={drawerOpen} onToggle={handleDrawerToggle} />} />
                        )}
                        {(matchesMediaQuery || drawerOpen) && (
                          <Route path="/b2c/*" element={<AppDrawer open={drawerOpen} onToggle={handleDrawerToggle}/>} />
                        )}
                      </Routes>
                      <div className={styles.content}>
                        <AppGlobalComponents />
                        {/* Main route */}
                        <AppRouter />
                        <IdleTimerContainer />
                      </div>
                    </div>
                  </AppSkipToLoginProvider>
                </AppLandmarkProvider>
              </AppStoreProvider>
            </GlobalStoreProvider>
            <AppFooter />
          </div>
        </ThemeProvider>
      </AuthStoreProvider>
      <Icons></Icons>
    </HashRouter>
  );
};

export default App;




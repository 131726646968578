import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHasPermission, UserPermission } from '../../../../features/auth/hooks/use-has-permission.hook';
import { UserRole } from '../../../../features/auth/model/user-role';
import { ActionItem } from './list-card';
import { B2C2ProductEntry, B2C2ProductStatusListCard } from './b2c2-product-list-card';
import { useOwnedProducts } from '../../../../features/products/hooks/use-owned-products';

export const B2C2ProductsCard: React.FC = () => {
  const { products, ownedProducts } = useOwnedProducts();
  const navigate = useNavigate();
  const b2bServiceDeveloper = useHasPermission(UserRole.B2BServiceDeveloper) === UserPermission.Granted;

  const newServiceAction: ActionItem = {
      title: "Add Product",
    action: () => navigate('/b2c/products/add'),
  };
  const viewAllAction: ActionItem = {
      title: "View All Products",
    action: () => navigate('/b2c/products'),
  };
  const handleProductClick = useCallback(
    (productId: string) => navigate(`/b2c/products/${productId}`),
    [navigate]
  );

    const prds: B2C2ProductEntry[] = !products ? [] : products.slice(-4).map(prd => {
    return {
      productId: prd.productId,
      name: prd.name,
      description: prd.description,
      dateModified: prd.lastModifiedAt,
      documentationUrl: prd.documentationUrl,
      statusType: prd.statusType,
    };
  });

  const ownedPrds: B2C2ProductEntry[] = !ownedProducts ? [] : ownedProducts.slice(-4).map(prd => {
    return {
      productId: prd.productId,
      name: prd.name,
      description: prd.description,
      dateModified: prd.lastModifiedAt,
      documentationUrl: prd.documentationUrl,
      statusType: prd.statusType,
    };
  })

  return (
    <B2C2ProductStatusListCard
        title="Products"
        entries={prds}
        ownedEntries={ownedPrds}
        topAction={b2bServiceDeveloper ? newServiceAction : undefined}
        bottomAction={b2bServiceDeveloper ? viewAllAction : undefined}
        onEntryClick={b2bServiceDeveloper ? handleProductClick : undefined}
    />
  );
};

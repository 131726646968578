import { Chip, FormHelperText, Grid, IconButton, ThemeProvider, Typography, useTheme } from '@material-ui/core';
import { AddBox, OpenInNew, Search } from '@material-ui/icons';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { ValidationError } from 'yup';
import { FormErrors } from '../../../../hooks/use-form.hook';
import { b2c2ApplicationTemplateCreateSchema } from '../../model/b2c2-application-validations';
import WhitelistedIdentitiesDialog from '../app-dialog/whitelisted-identities-dialog';
import { appEditStyles } from './app-edit.styles';
import * as yup from 'yup';
import { ApplicationCommunicationType, ApplicationTemplateType, B2C2ApplicationCommunicationTemplateCreate } from '../../model/application-create';
import { Container, FormControl} from '@mui/material';
import clsx from 'clsx';
import { useFormsStyles } from '../../../../styles/forms.styles';
import { Helmet } from 'react-helmet';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { ColumnDef, SortingState, Table, getCoreRowModel, getFilteredRowModel, getSortedRowModel } from '@tanstack/table-core';
import { useReactTable } from '@tanstack/react-table';
import { TableCore } from '../table-abstraction/table-core';
import { HeaderSearchAddButton } from '../table-abstraction/filter-implementation/header-search-addButton';
import { CommTypeSelect } from '../table-abstraction/table-cells/b2c2-apps/app-identities/comm-type-select';
import { B2C2AddAppIdentites } from '../table-abstraction/table-rows/b2c2-apps/add-app-identites-row';
import { B2C2AppsTemplateContentCell } from '../table-abstraction/table-cells/b2c2-apps/app-identities/header-Content-Cell';
import { B2C2AppsTemplateExpiryCell } from '../table-abstraction/table-cells/b2c2-apps/app-identities/header-Expiry-Cell';
import Input from "@visa/vds/input";
import { vdsStyleOverrides } from '../../../../vds-overrides.styles';
import { EditCellWrapper } from '../table-abstraction/table-cells/cell-wrappers/EditCellWrappers';
import { useNotifications } from '../../../../hooks/use-notifications';
import { TableFormEditDeleteCustomizationsAction } from '../table-abstraction/table-cells/b2c2-apps/table-form-edit-delete-customizations';
import { TempTypeSelect } from '../table-abstraction/table-cells/b2c2-apps/app-identities/template-type-select';


export const TEMPLATE_TYPES: string[] = [
    'RESET_PASSWORD_SUCCESS',
    'CHANGE_PASSWORD_SUCCESS',
    'VERIFY_OTP',
    'RESET_PASSWORD_OTP',
    'CREATE_ACCOUNT',
    'CREATE_ACCOUNT_EXISTING',
    'DELETE_ACCOUNT'
];

const COMM_TYPE: string[] = [
    'OTP',
    'LINK',
    'NONE'
];
export interface AppEditIdentitiesProps {
    whitelistedIdentities?: string[];
    templates?: B2C2ApplicationCommunicationTemplateCreate[];
    onChange: (identities: string[]) => void;
    onTemplateChange: (data: B2C2ApplicationCommunicationTemplateCreate[]) => void;
    fromId: string;
    fromDisplayName: string;
    handleFormIdChange: (fromId: string) => void;
    handleDisplayNameChange: (fromDisplayName: string) => void;
    formErrors?: any;
    isCreate: boolean;
}

interface InputFields {
    templateType: ApplicationTemplateType;
    commType: ApplicationCommunicationType;
    expiryMinutes: number;
    content: string;
}

export interface Row extends InputFields {
    id?: number;
    tableData?: any;
}

const templateTypesMap = TEMPLATE_TYPES.reduce<Record<string, string>>(
    (acc, templateType) => ({ ...acc, [templateType]: templateType }),
    {}
);

const commTypesMap = COMM_TYPE.reduce<Record<string, string>>(
    (acc, commType) => ({ ...acc, [commType]: commType }),
    {}
);

export const AppEditIdentities: React.FC<AppEditIdentitiesProps> = ({
    whitelistedIdentities, templates, onChange, onTemplateChange,
    fromId, fromDisplayName, handleFormIdChange, handleDisplayNameChange, formErrors, isCreate
}) => {
    const vdsStyles = vdsStyleOverrides();
    const styles = appEditStyles();
    const [errors, setErrors] = useState<FormErrors>({});
    const [open, setOpen] = useState(false);
    const formStyles = useFormsStyles();
    const { pushNotification } = useNotifications();

    const updateRoleEmail = useCallback((emailId: string) => {
        let allEmailIds = (whitelistedIdentities !== undefined) ? whitelistedIdentities : [];
        allEmailIds.push(emailId);
        onChange(allEmailIds);
        if (!open) {
            setOpen(true);
        }
    }, [whitelistedIdentities, onChange, open]);

    const handleWhitelistDialog = useCallback(() => {
        if (!open) {
            setOpen(true);
        }
    }, [open]);


    const handleCloseEditEmails = useCallback(() => {
        setOpen(false);
    }, [open]);

    const columns1 = useMemo<ColumnDef<Row>[]>(() => ([
        {
            header: 'Template Type',
            accessorKey: 'templateType',
            cell: (context)=><EditCellWrapper context={context} element={<TempTypeSelect {...context.table}/>}/>
        },
        {
            header: 'Comm Type',
            accessorKey: 'commType',
            cell: (context)=><EditCellWrapper context={context} element={<CommTypeSelect {...context.table}/>}/>
        },
        {
            header: 'Expiry Minutes',
            accessorKey: 'expiryMinutes',
            cell: (context)=><EditCellWrapper context={context} element={<B2C2AppsTemplateExpiryCell {...context.table}/>}/>
        },
        {
            header: 'Content',
            accessorKey: 'content',
            cell: (context)=><EditCellWrapper context={context} element={<B2C2AppsTemplateContentCell {...context.table}/>}/>
        },
        {
            header: "Action",
            cell: TableFormEditDeleteCustomizationsAction,
            enableSorting: false,
            accessorKey: "id"
        },
    ]), []);

    const rows = useMemo<Row[]>(() => templates ? templates.map(tpl => ({ ...tpl })) : [], [templates]);

    const [sort, setSort] = useState<SortingState>([]);
    const [search, setSearch] = useState<string>("");
    const [currentEditingRow, setCurrentEditingRow] = useState<string[]>([]);
    const [dirtyRow, setDirtyRow] = useState<Row | null>(() => null);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const table = useReactTable({
        columns: columns1,
        data: rows,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (e) => String(e.id),
        // debugTable: true,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSort,
        enableSorting: true,
        state: {
            sorting: sort,
            globalFilter: search
        },
        meta: {
            data: {},
            updateEditingRowState: (id: string) => {
                setCurrentEditingRow([id]);
                setDirtyRow(() => ({ ...rows.filter(e => String(e.id) === id)[0] }));
            },
            currentEditingRowId: currentEditingRow,
            revertingToStaticState: () => {
                setCurrentEditingRow([]);
                setDirtyRow(null);
            },
            setEditCelldata: (id, newData, columnid) => {
                setDirtyRow((oldState) => {
                    if (!oldState) {
                        return oldState;
                    }
                    return {
                        ...oldState,
                        [columnid]: newData
                    }
                }
                );
            },
            dirtyRow: dirtyRow,
            handleUpdate: (id: string) => {
                if((dirtyRow?.templateType === 'RESET_PASSWORD_SUCCESS' ||
                dirtyRow?.templateType === 'CHANGE_PASSWORD_SUCCESS' || 
                dirtyRow?.templateType === 'CREATE_ACCOUNT' || 
                dirtyRow?.templateType === 'CREATE_ACCOUNT_EXISTING'|| 
                dirtyRow?.templateType === 'DELETE_ACCOUNT') && (!dirtyRow?.commType && !dirtyRow.content)){
                pushNotification({ message: 'Comm Type or Content is required', type: 'snackbar', variant: 'error' });
            }
            else if ((dirtyRow?.templateType === 'VERIFY_OTP' || dirtyRow?.templateType === 'RESET_PASSWORD_OTP') && (!dirtyRow?.commType && !dirtyRow?.expiryMinutes && !dirtyRow?.content)){
                pushNotification({ message: 'Atleast one of Comm Type, Expiry Minutes or Content is required', type: 'snackbar', variant: 'error' });
            }
                onTemplateChange(templates!.map(template => template.id !== dirtyRow?.id ? template : {
                    templateType: dirtyRow?.templateType || "",
                    commType: dirtyRow?.commType || null,
                    expiryMinutes: dirtyRow?.expiryMinutes || 0,
                    content: dirtyRow?.content || "",
                    id: dirtyRow?.id || 0,
                }));
                setCurrentEditingRow([]);
                setDirtyRow(null);
            },
            deleteRow: (id: string) => {
                onTemplateChange(templates!.filter(template => String(template.id) !== id));
            },
            isValid: (table: Table<any>) => {
                return b2c2ApplicationTemplateCreateSchema.isValidSync({
                    templateType: table.options.meta?.dirtyRow.templateType,
                    commType: table.options.meta?.dirtyRow.commType,
                    expiryMinutes: table.options.meta?.dirtyRow.expiryMinutes,
                    content: table.options.meta?.dirtyRow.content,
                    id: table.options.meta?.dirtyRow.id,
                })
            },
            isIndividualCellValid: (columnName: string, newData: any) => {
                try {
                    b2c2ApplicationTemplateCreateSchema.validateSyncAt(columnName, { [columnName]: newData });
                    return "";
                } catch (e) {
                    return (e as ValidationError).message;
                }
            },
            isAdd: isAdd,
            addRowNode: B2C2AddAppIdentites,
            startAddMode: () => {
                setCurrentEditingRow(["new"]);
                setDirtyRow({
                    templateType: '',
                    commType: null,
                    expiryMinutes: 0,
                    content: '',
                    id: 0,
                });
                setIsAdd(true);
            }, stopAddMode: () => {
                setCurrentEditingRow([]);
                setDirtyRow(null);
                setIsAdd(false);
            },
            handleAdd: () => {
                if (!dirtyRow) {
                    return;
                }
                if((dirtyRow?.templateType === 'RESET_PASSWORD_SUCCESS' ||
                    dirtyRow?.templateType === 'CHANGE_PASSWORD_SUCCESS' || 
                    dirtyRow?.templateType === 'CREATE_ACCOUNT' || 
                    dirtyRow?.templateType === 'CREATE_ACCOUNT_EXISTING'|| 
                    dirtyRow?.templateType === 'DELETE_ACCOUNT') && (!dirtyRow?.commType && !dirtyRow.content)){
                    pushNotification({ message: 'Comm Type or Content is required', type: 'snackbar', variant: 'error' });
                }
                else if ((dirtyRow?.templateType === 'VERIFY_OTP' || dirtyRow?.templateType === 'RESET_PASSWORD_OTP') && (!dirtyRow?.commType && !dirtyRow?.expiryMinutes && !dirtyRow?.content)){
                    pushNotification({ message: 'Atleast one of Comm Type, Expiry Minutes or Content is required', type: 'snackbar', variant: 'error' });
                }
                const minId = templates !== undefined ? templates.reduce((min, template) => Math.min(template.id === undefined ? min : template.id, min), 0) : 0;
                if (templates) {
                    onTemplateChange([
                        ...templates!,
                        {
                            templateType: dirtyRow.templateType,
                            commType: dirtyRow.commType,
                            expiryMinutes: dirtyRow.expiryMinutes,
                            content: dirtyRow.content,
                            id: minId - 1,
                        },
                    ]);
                } else {
                    onTemplateChange([
                        {
                            templateType: dirtyRow.templateType,
                            commType: dirtyRow.commType,
                            expiryMinutes: dirtyRow.expiryMinutes,
                            content: dirtyRow.content,
                            id: minId - 1,
                        },
                    ]);
                }
                setCurrentEditingRow([]);
                setDirtyRow(null);
                setIsAdd(false);
            }
        }
    });
    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Container maxWidth="sm">
                        <Helmet>
                            <title>
                                {isCreate ? "Customizations (5 of 6) | Add new B2C Application | Visa Prototype Validation Platform" : `Customizations (5 of 6) | Edit B2C Application | Visa Prototype Validation Platform`}
                            </title>
                        </Helmet>
                        <TypographyHighLight headerTitle={isCreate ? "Customizations (5 of 6) | Add new B2C Application" : `Customizations (5 of 6) | Edit B2C Application`} variant='h5' />
                        <FormControl className={clsx(formStyles.formControlFull, formStyles.marginTop)}>
                            {/* <FormLabel className={formStyles.label} htmlFor='Whiltelisted_email' required>Whiltelisted Emails</FormLabel> */}
                            <Typography className={formStyles.label} aria-label='Whiltelisted Emails Required'>Whiltelisted Emails *</Typography>
                            <Grid container className={styles.chipContainerGrid} alignItems={!!whitelistedIdentities && whitelistedIdentities.length > 0 ? 'flex-start' : 'center'}>
                                <Grid item xs={10} sm={10} className={styles.chipItemGrid} component={"ul"}>
                                    {(!!whitelistedIdentities && whitelistedIdentities.length > 0) ?
                                        whitelistedIdentities.map((email) => <Chip component="li" key={email} className={styles.chipStyle} variant="outlined"
                                            label={email} />)
                                        : <Typography className={styles.expandemailText} component={"li"} style={{ listStyleType: "none" }}>Expand to add more emails</Typography>
                                    }
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <IconButton id="Whiltelisted_email" aria-label="open whitelist dialog"
                                        onClick={() => handleWhitelistDialog()} role="link"><OpenInNew /></IconButton>
                                </Grid>
                            </Grid>
                        </FormControl>
                        <br />
                        <Input
                            errorText={formErrors.fromId}
                            label="From ID *"
                            name={"fromId"}
                            value={fromId}
                            id="fromid-id"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormIdChange(e.target.value)}
                            showErrorText={!!formErrors.fromId}
                            invalid={!!formErrors.fromId}
                            className={vdsStyles.inputFullWidth}
                            aria-required
                            ></Input>
                        <br />
                        <Input
                            errorText={formErrors.fromDisplayName}
                            label="From Displayname *"
                            name={"fromDisplayNameId"}
                            value={fromDisplayName}
                            id="fromdisplayname-id"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleDisplayNameChange(e.target.value)}
                            showErrorText={!!formErrors.fromDisplayName}
                            invalid={!!formErrors.fromDisplayName}
                            className={vdsStyles.inputFullWidth}
                            aria-required
                            ></Input>
                        <br />
                    </Container>
                </Grid>
            </Grid>
            <HeaderSearchAddButton table={table} heading='App Templates' search={search} setSearch={setSearch} addButtonFunction={table.options.meta?.startAddMode ? table.options.meta.startAddMode : () => { }}></HeaderSearchAddButton>
                    <TableCore caption='App Templates' table={table}></TableCore>
            <WhitelistedIdentitiesDialog
                whitelistedIdentities={whitelistedIdentities}
                open={open}
                setOpen={setOpen}
                onUpdate={updateRoleEmail}
                onClose={handleCloseEditEmails}
                onChange={onChange}
            />
        </Grid>
    );
};

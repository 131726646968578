import { useBlockingLoading } from '../../../hooks/use-blocking-loading';
import { useNotifications } from '../../../hooks/use-notifications';
import { useDispatch } from '../../../state/app-store';
import { B2C2ApprovalRequestUpdate } from '../model/product-update';
import { networkServicesProducts } from '../services/network-services.products';
import { productActions } from '../state/product-actions';

export interface UseB2C2ApprovalResult {
  updateB2C2ApprovalRequestAsync: (requestGuid: string, approval: B2C2ApprovalRequestUpdate) => Promise<boolean>;
  loading: boolean;
}

export function useUpdateB2C2Approval(): UseB2C2ApprovalResult {
  const { loading, startLoading, stopLoading } = useBlockingLoading();
  const { pushNotification } = useNotifications();
  const dispatch = useDispatch();

  const updateB2C2ApprovalRequestAsync = async (requestGuid: string, approvalUpdate: B2C2ApprovalRequestUpdate): Promise<boolean> => {
    startLoading();
    dispatch(productActions.loadB2C2ApprovalRequests());
    try {
      const approvalRequest = await networkServicesProducts.updateB2C2ApprovalRequestAsync(requestGuid, approvalUpdate);
      dispatch(productActions.updateB2C2ApprovalRequest(approvalRequest));
      return true;
    } catch (e:any) {
      const error = e.message ? e.message as string : 'Response error';
      dispatch(productActions.loadB2C2ApprovalRequestsError(error));
      pushNotification({
        message: `Update B2C Approval request failed with '${error}'. Please, try again later.`,
        variant: 'error',
        type: 'bar',
      });
      return false;
    } finally {
      stopLoading();
    }
  };

  return {
    updateB2C2ApprovalRequestAsync,
    loading,
  };
}

import { Button, Container, Grid } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorsStyles } from './errors.styles';

export const ServerError: React.FC = () => {
  const styles = useErrorsStyles();
  const navigate = useNavigate();

  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  const handleGoHome = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  return (
    <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center" className={styles.root}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Container maxWidth="sm">
          <h1>Oops!</h1>
          <h2>Server Error</h2>
          <div>Sorry, an error has occured. Try to refresh the page!</div>
          <div className={styles.marginTop}>
            <Button variant="contained" color="primary" onClick={handleRefresh}>
              <RefreshIcon /> Refresh
          </Button>
            <Button variant="contained" color="primary" className={styles.marginLeft} onClick={handleGoHome}>
              <HomeIcon /> Take me home
          </Button>
          </div>
        </Container>
      </Grid>
    </Grid>
  );
}

import { actionCreator } from '../../../state/actions';
import { B2BApprovalRequest, B2BServiceReviewRequest } from '../model/service';
import { B2BService } from './../model/service';

const types = {
  loadServices: 'services/load_services',
  loadB2BServices: 'services/load_b2b_services',
  loadServicesSuccess: 'services/load_services_success',
  loadB2BServicesSuccess: 'services/load_b2b_services_success',
  loadServicesError: 'services/load_services_error',
  loadB2BServicesError: 'services/load_b2b_services_error',
  loadB2BOperations: 'services/load_operations',
  addService: 'services/add_service',
  addB2BService: 'services/add_b2b_service',
  updateService: 'services/update_service',
  updateB2BService: 'services/update_b2b_service',
  deleteService: 'services/delete_service',
  deleteB2BService: 'services/delete_b2b_service',
  loadB2BApprovalRequests: 'services/load_b2b_approval_requests',
  loadB2BApprovalRequestsSuccess: 'services/load_b2b_approval_requests_success',
  loadB2BApprovalRequestsError: 'services/load_b2b_approval_requests_error',
  updateB2BApprovalRequest: 'services/update_b2b_approval_request',
  b2BreviewRequest: 'services/b2b_review_request'
}

const loadServices = () => actionCreator(types.loadServices);
const loadB2BServices = () => actionCreator(types.loadB2BServices);
const loadB2BServicesSuccess = (services: B2BService[]) => actionCreator(types.loadB2BServicesSuccess, services);
const loadServicesError = (error: string) => actionCreator(types.loadServicesError, error);
const loadB2BServicesError = (error: string) => actionCreator(types.loadB2BServicesError, error);
const loadB2BOperations = (serviceGuid: string) => actionCreator(types.loadB2BOperations);
const addB2BService = (service: B2BService) => actionCreator(types.addB2BService, service);
const updateB2BService = (service: B2BService) => actionCreator(types.updateB2BService, service);
const deleteService = (id: number) => actionCreator(types.deleteService, id);
const deleteB2BService = (serviceGuid: string|undefined) => actionCreator(types.deleteB2BService, serviceGuid);
const loadB2BApprovalRequests = () => actionCreator(types.loadB2BApprovalRequests);
const loadB2BApprovalRequestsSuccess = (approvalRequests: B2BApprovalRequest[]) => actionCreator(types.loadB2BApprovalRequestsSuccess, approvalRequests);
const loadB2BApprovalRequestsError = (error: string) => actionCreator(types.loadB2BApprovalRequestsError, error);
const updateB2BApprovalRequest = (approvalRequest: B2BApprovalRequest) => actionCreator(types.updateB2BApprovalRequest, approvalRequest);
const b2BReviewRequest = (approvalRequest: B2BServiceReviewRequest) => actionCreator(types.b2BreviewRequest, approvalRequest);

export const servicesActions = {
  loadServices,
  loadB2BServices,
  loadB2BServicesSuccess,
  loadServicesError,
  loadB2BServicesError,
  loadB2BOperations,
  addB2BService,
  updateB2BService,
  deleteService,
  deleteB2BService,
  loadB2BApprovalRequests,
  loadB2BApprovalRequestsSuccess,
  loadB2BApprovalRequestsError,
  updateB2BApprovalRequest,
  b2BReviewRequest
}

export const servicesTypes = types;

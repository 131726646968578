import { Theme, makeStyles } from '@material-ui/core';
import { amber, green, grey } from '@material-ui/core/colors';

export const notificationBarStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    padding: theme.spacing(2, 3),
    zIndex: theme.zIndex.drawer + 1,
  },
  success: {
    backgroundColor: green[800],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: grey[200],
    color: theme.palette.text.primary,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

import { IconButton, useTheme } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useB2C2OwnedApplications } from '../../hooks/use-b2c2-owned-applications';
import { ColumnDef, SortingState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { B2C2Application } from '../../model/application';
import { TypographyHighLight } from '../../../../components/app-header-highlight/typographyHighlight';
import { TableCore } from '../table-abstraction/table-core';
import { TablePagination } from '../table-abstraction/table-pagination';
import { B2C2AppListFilter, DataOptionsType } from '../table-abstraction/filter-implementation/b2c2-app-list-filter';
import Icon from '@visa/vds/icon';
import ButtonIcon from '@visa/vds/button-icon';

export const B2BAppList: React.FC = () => {
    const { b2c2Applications, ownedApps, loading } = useB2C2OwnedApplications();
    const navigate = useNavigate();
    const theme = useTheme();

    const handleAppClick = useCallback(
        (id: string) => navigate(`/b2c/apps/${id}`),
        [navigate]
    );
    const [search, setSearch] = useState<string>("");
    const [sorting, setSorting] = useState<SortingState>([]);
    const [dataOptions, setDataOptions] = useState<DataOptionsType>("my")
    const columns: ColumnDef<B2C2Application, any>[] = useMemo(() => [
        {
            header: 'App name',
            accessorKey: 'name',
            id: 'name',
        },
        {
            header: 'App Guid',
            accessorKey: 'applicationGuid',
            id: 'applicationGuid'
        },
        {
            header: 'Description',
            accessorKey: 'description',
            id: 'description'
        },
        {
            header: 'Date modified',
            accessorKey: "lastModifiedAt",
            cell: (e) => <>{Moment(e.row.original.lastModifiedAt).format('MMM DD YYYY')}</>,
            id: 'lastModifiedAt',
            sortType: 'datetime'
        },
        {
            header: 'State',
            accessorKey: 'status',
            id: 'status'
        },
        {
            header: 'Action',
            accessorKey: 'applicationGuid',
            id: "action",
            enableSorting: false,
            cell: ({ row: { original: { applicationGuid, name } } }) => (
                <ButtonIcon ariaLabel={"View Details for application " + name} iconType="light-tiny" onClick={() => handleAppClick(applicationGuid)} role="link" >
                    <Icon name="password-show" resolution="tiny" />
                </ButtonIcon>
            )
        }
    ], [handleAppClick]);
    const dataSelection: B2C2Application[] = useMemo(() => {
        if (loading) {
            return [];
        }
        if (dataOptions === 'all') {
            return b2c2Applications;
        }
        return ownedApps;
    }, [b2c2Applications, dataOptions, loading, ownedApps]);
    const b2c2TableHook = useReactTable({
        columns: columns,
        data: dataSelection,
        debugTable: true,
        manualPagination: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        enableSorting: true,
        state: {
            sorting,
            globalFilter: search
        },
        initialState: {
            pagination: {
                pageSize: 5,
                pageIndex: 0
            },
        },
    });
   return <main>
        <Helmet>
            <title>B2C Applications | Visa Prototype Validation Platform</title>
        </Helmet>
        <TypographyHighLight variant='h5' headerTitle={<div style={{ color: 'black', marginBottom: theme.spacing(4) }}>B2C Applications</div>} />
        <B2C2AppListFilter
            b2c2Applications={b2c2Applications}
            ownedApps={ownedApps}
            changeDataOptions={setDataOptions}
            dataOption={dataOptions}
            table={b2c2TableHook}
            search={search}
            setSearch={setSearch} />
        <TableCore table={b2c2TableHook} caption={"All B2C Applications : application name in column 1 and details in the next 4 columns.actions for each application are available in column 6"} loading={loading}/>
        <TablePagination table={b2c2TableHook} label='B2C Applications Controls' />
    </main>
};

import { Dispatch } from 'react';
import { useStore } from '../../../state/app-store';
import { AppsState, B2BAppsState, CredentialDetailState, ProvisioningState } from './apps-reducer';
import { Action } from '../../../state/actions';

export const useAppsStore = () => {
  const store = useStore();
  const appsStore: [AppsState, Dispatch<Action>] = [store[0].apps, store[1]];
  return appsStore;
}

export const useB2BAppsStore = () => {
  const store = useStore();
  const appsStore: [B2BAppsState, Dispatch<Action>] = [store[0].b2bapps, store[1]];
  return appsStore;
}

export const useB2BProvisioningStore = () => {
  const store = useStore();
  const provisioningStore: [ProvisioningState, Dispatch<Action>] = [store[0].b2bProvisioning, store[1]];
  return provisioningStore;
}

export const useCredentialDetailsStore = () => {
  const store = useStore();
  const credentialDetailStore: [CredentialDetailState, Dispatch<Action>] = [store[0].credentialDetail, store[1]];
  return credentialDetailStore;
}

export const useAppsState = () => useAppsStore()[0];

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const appFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      marginTop: 'auto',
      zIndex: theme.zIndex.drawer + 1,
    },
    bar: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(10),
        justifyContent: 'space-between'
      },      
    },
    title: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
      },      
    },
    copyright: {
      color: theme.palette.text.secondary,
      fontWeight: 'lighter',
      fontSize: 'smaller'
    },
  }),
);

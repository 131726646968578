import { Dispatch } from 'react';
import { Action } from '../../../state/actions';
import { useStore } from '../../../state/app-store';
import { B2C2ApprovalRequestsState, ProductsState } from './products-reducer';

export const useProductsStore = () => {
  const store = useStore();
  const productsStore: [ProductsState, Dispatch<Action>] = [store[0].products, store[1]];
  return productsStore;
}


export const useB2C2ApprovalRequestsStore = () => {
  const store = useStore();
  const approvalRequestsStore: [B2C2ApprovalRequestsState, Dispatch<Action>] = [store[0].b2c2ApprovalRequests, store[1]];
  return approvalRequestsStore;
}

export const useProductsState = () => useProductsStore()[0];

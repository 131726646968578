import {makeStyles, Theme} from '@material-ui/core/styles';

export const statusListCardStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: 0
  },
  cardHeader: {
    backgroundColor: '#F2F4F8',
    padding: theme.spacing(2, 3, 2, 3)
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  pointer: {
    cursor: 'pointer',
  },
  selectList: {
    height:'40px',
    borderRadius: 0,
    fontSize: '20px',
    width: '170px',
    marginRight: '10px',
  },
  addNewBtn: {
    padding: '1px 0px 1px 0px',
    width: '200px'
  },
  viewAllBtn: {
    padding: '1px 0px 1px 0px',
    width: '150px'
  }
}));

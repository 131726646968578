import { config } from '../../../config';
import { networkSrv } from '../../../services/network.service';
import { B2C2ProductReviewRequest } from '../../products/model/product';
import { B2C2ReviewRequestUpdate } from '../../products/model/product-update';
import { B2C2ReviewRequestRS, B2C2ReviewRequestUpdateRQ } from '../../products/services/dtos';
import { mapB2C2ReviewRequest, mapB2C2ReviewRequestUpdate } from '../../products/services/model-maps';
import { mapB2C2ConsumerRequest } from '../../services/services/model-maps';
import {  B2C2Application, B2C2ApplicationConsumer, B2C2ConsumerRQ } from '../model/application';
import { B2C2ApplicationCreate } from '../model/application-create';
import { B2C2ApplicationUpdate } from '../model/application-update';
import { B2C2ApplicationRQ, B2C2ApplicationRS } from './dtos';
import { mapB2C2ApplicationUpdate, mapB2C2Application, mapB2C2ApplicationCreate } from './model-maps';



export async function getB2C2ApplicationsAsync(): Promise<B2C2Application[]> {
  const b2bApplicationsRS = await networkSrv.getAsync<B2C2ApplicationRS[]>(`${config.urls.b2c2Apps}?expand=true&pending=false`);
  return b2bApplicationsRS.map(mapB2C2Application);
}

export async function getB2C2ApplicationsPendingAsync(): Promise<B2C2Application[]> {
  const b2bApplicationsRS = await networkSrv.getAsync<B2C2ApplicationRS[]>(`${config.urls.b2c2Apps}?pending=true&expand=true`);
  return b2bApplicationsRS.map(mapB2C2Application);
}


export async function createB2C2ApplicationAsync(b2bApplication: B2C2ApplicationCreate): Promise<any> {
  const b2bApplicationRQ = mapB2C2ApplicationCreate(b2bApplication);
  const b2bApplicationRS = await networkSrv.postAsync<B2C2ApplicationRQ, B2C2ApplicationRS>(config.urls.b2c2Apps, b2bApplicationRQ);
  return mapB2C2Application(b2bApplicationRS);
}

export function deleteB2C2ApplicationAsync(applicationGuid: string): Promise<void> {
  return networkSrv.deleteAsync(`${config.urls.b2c2Apps}/${applicationGuid}`);
}

export async function updateB2C2ApplicationAsync(applicationGuid: string, b2bApplication: B2C2ApplicationUpdate): Promise<B2C2Application> {
  const b2bApplicationRQ = mapB2C2ApplicationUpdate(b2bApplication);
  const b2bApplicationRS = await networkSrv.patchAsync<Partial<B2C2ApplicationRQ>, B2C2ApplicationRS>(`${config.urls.b2c2Apps}/${applicationGuid}`, b2bApplicationRQ);
  return mapB2C2Application(b2bApplicationRS);
}

export async function createB2C2AppReviewRequestAsync(applicationGuid: string, b2c2ReviewRequestUpdate: B2C2ReviewRequestUpdate): Promise<B2C2ProductReviewRequest> {
  const b2c2ReviewRequestRQ = mapB2C2ReviewRequestUpdate(b2c2ReviewRequestUpdate);
  const b2c2ReviewRequestRS = await networkSrv.postAsync<B2C2ReviewRequestUpdateRQ, B2C2ReviewRequestRS>(`${config.urls.b2c2Apps}/${applicationGuid}/review`, b2c2ReviewRequestRQ);
  return mapB2C2ReviewRequest(b2c2ReviewRequestRS);
}

export async function createB2C2AppConsumerRequestAsync(appId: string, requestId: string, b2c2ConsumerRequest: B2C2ConsumerRQ): Promise<B2C2ApplicationConsumer> {
  const b2bReviewRequestRS = await networkSrv.patchAsync<B2C2ConsumerRQ, B2C2ApplicationConsumer>(`${config.urls.b2c2Apps}/${appId}/consumer-requests/${requestId}`, b2c2ConsumerRequest);
  return mapB2C2ConsumerRequest(b2bReviewRequestRS);
}

export const networkAppsNewSrv = {
  getB2C2ApplicationsAsync,
  createB2C2ApplicationAsync,
  deleteB2C2ApplicationAsync,
  updateB2C2ApplicationAsync,
  getB2C2ApplicationsPendingAsync,
  createB2C2AppReviewRequestAsync,
  createB2C2AppConsumerRequestAsync
}
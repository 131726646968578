import { Table } from "@tanstack/table-core";
import React from "react";
import Tr from '@visa/vds/tr';
import Td from '@visa/vds/td';
import { TableFormAddCancelAction } from "../../table-cells/b2c2-apps/table-form-add-cancel";
import { ProductEndpointUpdate } from "../../../../../products/model/product-update";
import { EndpointNameCell } from "../../table-cells/b2c2-product/endpoints/endpoint-name-cell";
import { EndpointMethodTypeCell } from "../../table-cells/b2c2-product/endpoints/endpoint-method-type-cell";
import { EndpointInternalPathCell } from "../../table-cells/b2c2-product/endpoints/endpoint-internal-path-cell";
import { EndpointExternalPathCell } from "../../table-cells/b2c2-product/endpoints/endpoint-external-path-cell";
import { B2cMleTypeCell } from "../../table-cells/b2c2-product/endpoints/endpoint-b2cmle-type-cell";
import { B2BMleTypeCell } from "../../table-cells/b2c2-product/endpoints/endpoint-b2bmle-type-cell";

export const B2CAddEndpointsRow: React.FC<Table<ProductEndpointUpdate>> = (table) => {
    return <Tr >
        <Td><EndpointNameCell {...table}/></Td>
        <Td><EndpointMethodTypeCell {...table}/></Td>
        <Td><EndpointInternalPathCell {...table}/></Td>
        <Td><EndpointExternalPathCell {...table}/></Td>
        {table.options.meta?.data?.useVp2Connector &&<>
        <Td><B2cMleTypeCell {...table}/></Td>
        <Td><B2BMleTypeCell {...table}/></Td> </>}
        <Td><TableFormAddCancelAction {...table as Table<any>} ></TableFormAddCancelAction></Td>
    </Tr>
}

export function arrayEquals<T>(a: T[] | null | undefined, b: T[] | null | undefined, compare?: (a: T, b: T) => number): boolean {
  if (a === b) {
    return true;
  }
  if (!a || !b || a.length !== b.length) {
    return false;
  }
  const aSorted = a.sort(compare);
  const bSorted = b.sort(compare);
  return !aSorted.some((aItem, index) => compare ? compare(aItem, bSorted[index]) !== 0 : aItem !== bSorted[index]);
}

import { makeStyles } from '@material-ui/core/styles';

export const userMenuStyles = makeStyles(theme => ({
  avatar: {
    height: 48,
    width: 48,
  },
  progress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: 10,
    left: 10,
    zIndex: 1,
  },
  icon: {
    color: "inherit",
    fontWeight: "bold"
  },
  link:{
    color: "black",
    textDecoration: "none",
    position: "relative",
        " &::after":{
            position: "absolute",
            top: "0.5rem",
            right: "-1rem",
            background: "url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtElEQVR4nO3WMQrCMBTG8f8lGnqBjoLXCNLzuDrp/ezgbrsUegA7SER4BSm1JvASh+aDBykh/HhpAoGcreUAdIBTrhawa3AbAZ3qvga7yPU124W14zI8T97qvx+uPfCcXY0hBfzOMeReasIG6FPDBmhkrk8Fmw+0ke+T/HNV+PEDnbLThEeglnEBXGXBDSgDoGDYenQaBdbu1BsulDv1hi8LqJUDF/UhUAFn6VwbdUq7l0NQXjnq/HgLbaytAAAAAElFTkSuQmCC\")",
            content: "''",
            width: "0.8rem",
            height: "0.8rem",
            backgroundSize: "cover"
        }
    },
    listOnFocus:{
      "&:focus > a":{
          color: "white !important"
      }
    }
}));

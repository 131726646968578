import { Toolbar, Typography} from '@mui/material';
import React from 'react';
import {appFooterStyles} from './app-footer.styles';
import { CombinedLogo, LogoColorTheme } from '../logos/CombinedLogo';

export const AppFooter: React.FC = () => {
  const styles = appFooterStyles();
  return (
    <footer className={styles.footer}>
        <Toolbar className={styles.bar}>
          <CombinedLogo logoColor={LogoColorTheme.BLUE}/>
          <Typography variant="body2" className={styles.copyright}>
            {`Copyright © ${new Date().getFullYear()} Visa Inc. All Rights Reserved`}
          </Typography>
        </Toolbar>
    </footer>
  );
};

import { TokensDto } from './model/tokens.dto';
import { User } from './model/user';
import { UserManagerSettings } from './model/user-manager-settings';
import { networkSrv } from './services/network.service';
import { getCalculatedUser, getUserFromTokens } from './services/token.service';

export class UserManager {
  private settings: UserManagerSettings;
  private user: User | null;

  private get authorizeUrl(): string { return this.settings.authority + '/adminservice/api/v1/oauth2/redirect' }
  private get tokensUrl(): string { return this.settings.authority + '/adminservice/api/v1/oauth2/token' }
  private get refreshUrl(): string { return this.settings.authority + '/adminservice/api/v1/oauth2/renew' }
  private get revocationUrl(): string { return this.settings.authority + '/adminservice/api/v1/oauth2/revoke' }

  constructor(settings: UserManagerSettings) {
    this.settings = settings;
    this.user = null;
  }

  getUser = async (): Promise<User> => {
    for (let i = 0; i < 10; i++) {
      if (this.user) {
        return getCalculatedUser(this.user);
      }
      await this.timeout(500);
    }
    throw new Error('User not set');
  }

  signinRedirect = (): Promise<void> => {
    window.open(this.authorizeUrl, '_self');
    return Promise.resolve();
  }

  signinRedirectCallback = (): Promise<User> => {
    const urlParts = window.location.href.split('?')
    const query = urlParts.length > 0 ? `?${urlParts[1]}` : '';
    return this.getTokens(`${this.tokensUrl}${query}`);
  }

  signinSilent = (): Promise<User> => {
    return this.renewTokens(`${this.refreshUrl}`);
  }

  signoutRedirect = (): Promise<void> => {
    return networkSrv.deleteAsync(this.revocationUrl, {
      'x-access-token': this.user?.access_token || '',
    });
  }

  startSilentRenew = (): void => {
    // TODO: implement
    throw new Error('Not implemented');
  }

  stopSilentRenew = (): void => {
    // TODO: implement
    throw new Error('Not implemented');
  }

  private getTokens = async (url: string): Promise<User> => {
    const tokensDto = await networkSrv.postAsync<void, TokensDto>(url);
    this.user = getUserFromTokens(tokensDto);
    return this.user;
  }

  private renewTokens = async (url: string): Promise<User> => {
    const tokensDto = await networkSrv.patchAsync<void, TokensDto>(url);
    this.user = getUserFromTokens(tokensDto);
    return this.user;
  }

  private timeout = (ms: number): Promise<void> => 
    new Promise(resolve => setTimeout(resolve, ms));
}

import {makeStyles, Theme, withStyles} from '@material-ui/core/styles';

export const DashboardDialogTitleStyles = withStyles((theme: Theme) => ({
    dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        margin: 0,
        padding: theme.spacing(1),
    }
}));

export const DashboardDialogStyles = makeStyles((theme: Theme) => ({
    dialogSlide: {
        marginLeft: '15px',
        flex: 1
    },
    navBtn: {
        width:'100px',
        height:'20px',
        fontSize:'20px'
    },
    closeBtn: {
        float:'right',
        width:'110px',
        height:'20px',
        fontSize:'20px'
    }
}));
import { getGlobalConfig } from '../../../services/global-config.service';

function getAsync<RS>(url: string, headers?: Record<string, string>): Promise<RS> {
  return sendAsync(url, 'GET', null, headers);
}

function postAsync<RQ, RS>(url: string, body?: RQ): Promise<RS> {
  return sendAsync(url, 'POST', body);
}

function putAsync<RQ, RS>(url: string, body?: RQ): Promise<RS> {
  return sendAsync(url, 'PUT', body);
}

function patchAsync<RQ, RS>(url: string, body?: RQ): Promise<RS> {
  return sendAsync(url, 'PATCH', body);
}

function deleteAsync(url: string, headers?: Record<string, string>): Promise<void> {
  return sendAsync(url, 'DELETE', null, headers);
}

async function sendAsync<RQ, RS>(url: string, method: string, body?: RQ, extraHeaders?: Record<string, string>): Promise<RS> {
  const headers: HeadersInit = {
    ...extraHeaders,
    'Accept': 'application/json',
  };
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase())) {
    if (!!body) {
      headers['Content-Type'] = 'application/json';
    }
    const config = getGlobalConfig();
    if (!!config && !!config.csrf) {
      headers['csrf-token'] = config.csrf;
    }
  }
  const request: RequestInit = {
    method,
    headers,
    credentials: 'same-origin',
    body: body ? JSON.stringify(body) : undefined,
  };
  const response = await fetch(url, request);

  let jsonData: any = null;
  try {
    jsonData = await response.json();
  } catch { }

  if (!response.ok) {
    const errorData = jsonData ? jsonData as Error : null;
    const errorOptions={
      cause: errorData ? errorData : null
    };
    throw Error(errorData && errorData.message ? errorData.message : response.statusText,errorOptions);
  }

  const data = jsonData ? jsonData as RS : null;
  if (!data) {
    return {} as RS;
  }
  return data;
}

export const networkSrv = {
  getAsync,
  postAsync,
  putAsync,
  patchAsync,
  deleteAsync
}
